import React, {FC, useContext, useEffect, useState} from 'react';
import {ProductionWeek} from "../../../shared/interfaces/productionWeek.interface";
import {ChartLine} from "../../../shared/interfaces/common.interface";
import LineChart from "./LineChart";
import {AlertContext} from "../../../shared/context/AlertContext";

interface ViewRegisteredAndFixedProps {
  productionWeeks: ProductionWeek[]
}

const ViewRegisteredAndFixed: FC<ViewRegisteredAndFixedProps> = ({productionWeeks}) => {
  const [lines, setLines] = useState<ChartLine[]>([])
  const {setAlert} = useContext(AlertContext);

  useEffect(() => {
    const plannedReg: ChartLine = {
      color: "blue", points: [], name: "Planned" +
        " Reg Cables"
    }
    const plannedRel: ChartLine = {
      color: "yellow",
      points: [],
      name: "Planned Rel Cables"
    }
    const plannedFix: ChartLine = {
      color: "purple",
      points: [],
      name: "Planned Fix Cables"
    }
    const actualReg: ChartLine = {
      color: "pink",
      points: [],
      name: "Registered Cables"
    }
    const actualRel: ChartLine = {
      color: "teal", points: [], name: "Released" +
        " Cables"
    }
    const actualFix: ChartLine = {
      color: "red", points: [], name: "Fixed" +
        " Cables"
    }
    const start = 0;//project?.plannedCableRegistrationStart !== undefined ? parseInt(project?.plannedCableRegistrationStart?.replace('-', '')) : 0
    const end = 0;//project?.plannedCableFixingFinish !== undefined ? parseInt(project?.plannedCableFixingFinish?.replace('-', '')) : 0
    setAlert({type: "info", text: "This window does not show anything until the engineering, routing, and production programme is implemented."});
    productionWeeks.forEach(ele => {
      const codeNoDash = ele.code.replace('-', '');
      const parsedCode = parseInt(codeNoDash)
      if(parsedCode >= start && parsedCode <= end) {
        plannedReg.points.push({
          x: codeNoDash,
          y: ele.accumulatedCablesRegistered !== undefined ? ele.accumulatedCablesRegistered : 0
        })
        plannedRel.points.push({
          x: codeNoDash,
          y: ele.accumulatedCablesReleased !== undefined ? ele.accumulatedCablesReleased : 0
        })
        plannedFix.points.push({
          x: codeNoDash,
          y: ele.accumulatedCablesFixed !== undefined ? ele.accumulatedCablesFixed : 0
        })
        actualReg.points.push({
          x: codeNoDash,
          y: ele.actualCablesRegistered !== undefined ? ele.actualCablesRegistered : 0
        })
        actualRel.points.push({
          x: codeNoDash,
          y: ele.actualCablesReleased !== undefined ? ele.actualCablesReleased : 0
        })
        actualFix.points.push({
          x: codeNoDash,
          y: ele.actualCablesFixed !== undefined ? ele.actualCablesFixed : 0
        })
      }
    })
    setLines([
      plannedReg,
      actualReg,
      plannedRel,
      actualRel,
      plannedFix,
      actualFix
    ])
  }, [])
  return ( lines.length > 0 ? <LineChart lines={lines} name={"Cables -" +
    " Registered, Released and Fixed"} /> : null
  )
};


export default ViewRegisteredAndFixed;
