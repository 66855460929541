import * as React from 'react';
import {useModals} from "../../../shared/context/ModalContext";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export const ProjectCodeInfoModal: React.FunctionComponent<{open?: boolean}> = ({open}) => {
  const {closeModal} = useModals();

  return (
    <Dialog
      open={open || true}
      onClose={closeModal}
    >
      <DialogTitle>Naming Convention - Project</DialogTitle>
      <DialogContent>
        <DialogContentText>
          All project should be identified by the following name convention:<br/><br/>
          Build Number + Yard ID. E.g. B-731-LA<br/><br/>
          Use following ID for each Yard:<br/><br/>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{width: "100%"}}>
              VARD Aukra: AU<br/>
              VARD Brevik: BR<br/>
              VARD Søviknes: SO<br/>
              VARD Braila: BA<br/>
              VARD Vung Tau: VT<br/>
            </div>
            <div style={{width: "100%"}}>
              VARD Brattvåg: BV<br/>
              VARD Langsten: LA<br/>
              VARD Niteroi: NI<br/>
              VARD Tulcea: TU<br/>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
