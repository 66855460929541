import React, {FC, useContext} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import {UserContext} from "../shared/context/UserContext";
import ProjectHome from "./project/ProjectHome/ProjectHome";
import {ProjectProvider} from "../shared/context/ProjectContext";
import Projects from "./projects/Projects/Projects";
import ManageHome from "./manage/ManageHome";
import {InformationCard} from "./shared/InformationCard/InformationCard";
import Alert from "./shared/Alert/Alert";
import {GlobalClaims} from "../shared/claims";
import {ModalStack} from "../shared/context/ModalContext";
import {ProtectedRoute} from "./shared/layout/ProtectedRoute";

const Main: FC = () => {
  const {user} = useContext(UserContext);

  if(!user){
    return (
      <InformationCard {...{
        context: "Welcome.",
        header: "Please contact a VECT supervisor for further access.",
        subTitle: "You need a role in VECT to continue",
        content: "Your user is member of the Vard domain, that's how you got this far. Vard Electro Cable Tool does, however, require that a supervisor assign you to one or more roles.",
        whatNow: "Please contact a Vard Electro Cable Tool supervisor to continue."
      }} />
    );
  }

  const landingPage = user.id
    ? user.activeProject
      ? `project/${user.activeProject.codeUrlEncoded}`
      : "projects"
    : null;

  return (
      <Router>
        <Alert />
        <Switch>
          <Route path={"/project/:projectCode"}>
            <ProjectProvider>
              <ModalStack>
                <ProjectHome/>
              </ModalStack>
            </ProjectProvider>
          </Route>
          <Route path={"/projects"}>
            <ModalStack>
              <Projects/>
            </ModalStack>
          </Route>
          <ProtectedRoute path={"/manage"} claim={GlobalClaims.manage.wildcard} useExactMatch={false}>
            <ModalStack>
              <ManageHome />
            </ModalStack>
          </ProtectedRoute>
          {landingPage && <Redirect from="/" to={landingPage}/>}
        </Switch>
      </Router>
  )


}

export default Main;
