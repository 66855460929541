import React, { FC, useContext } from "react";
import Main from "./components/Main";
import UserProvider from "./shared/context/UserContext";
import AlertProvider from "./shared/context/AlertContext";
import { AuthContext } from "./shared/context/AuthContext";
import FancySplashScreen from "./components/shared/layout/FancySplashScreen/FancySplashScreen";
import { Container } from "@material-ui/core";

const App: FC = () => {
  const { authState } = useContext(AuthContext);
  return (
    <div className="App">
      <Container maxWidth={false}>
        <AlertProvider>
            {authState.isAuthenticated ? (
              <UserProvider>
                <Main />
              </UserProvider>
            ) : (
              <FancySplashScreen />
            )}
        </AlertProvider>
      </Container>
    </div>
  );
};
export default App;
