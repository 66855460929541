import {Option} from "../../shared/inputs/VectSelect";

/**
 * See also src/components/project/Bundle-Admin/BundleProperties.tsx for more hard coded options
 */

export enum PowerSignal {
  P = 1,
  S = 2
}

export const signalTypeOptions: Option[] = [
  {value: 1, label: "Power", selectedLabel: "P"},
  {value: 2, label: "Signal", selectedLabel: "S"},
  {value: 3, label: "Signal Cables", selectedLabel: "N"},
  {value: 4, label: "Automation Cables", selectedLabel: "AU"},
  {value: 5, label: "High Voltage Cables", selectedLabel: "J"},
  {value: 6, label: "Power Supply Cables", selectedLabel: "K"}
];

export const getRelevantPowerSignalOption = (value: number | string | undefined): (Option | undefined) => {
  if (typeof value === "number") {
    return signalTypeOptions.find(x => x.value === value as number);
  } else {
    return signalTypeOptions.find(x => x.selectedLabel === value as string);
  }
}

export enum Dp3 {
  PS = 1,
  SB = 2
}

export const dp3Options: Option[] = [
  {value: 1, label: "DP3 Zone PS"},
  {value: 2, label: "DP3 Zone SB"}
];

export const getRelevantDp3Option = (value: number | string | undefined): (Option | undefined) => {
  if (typeof value === "number") {
    return dp3Options.find(x => x.value === value as number);
  } else {
    return dp3Options.find(x => x.label === value as string);
  }
}

export enum ProductionStatus {
  Draft = 0,
  OnHold = 1,
  Ready = 2,
  Measured = 3,
  Checked = 4
}

export const productionStatusOptions: Option[] = [
  {
    value: 0,
    label: "Draft - not ready for production"
  },
  {
    value: 1,
    label: "Ready - but on HOLD",
  },
  {
    value: 2,
    label: "Ready for measuring",
  },
  {
    value: 3,
    label: "Measured - ready for checkout",
  },
  {
    value: 4,
    label: "Checked - ready for production",
  }
];

export const getRelevantProductionStatusOption = (value: number | string | undefined): Option | undefined => {
  if (typeof value === "number") {
    return productionStatusOptions.find(x => x.value === value as number);
  } else {
    return productionStatusOptions.find(x => x.label === value as string);
  }
}

export const selectOpts: Option[] = [
  {
    label: "Yes",
    value: 1
  }, {
    label: "No",
    value: 0
  }
]
