import React, {FunctionComponent, useCallback, useContext, useEffect, useMemo, useReducer, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {FormControlLabel, MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow,} from "@material-ui/core";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import Pagination from "../../shared/table/Pagination";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import FreePullCableRowItem from "./FreePullCableRow";
import {CableTypeListItem} from "../../../shared/interfaces/cableType.interface";
import {getYearWeekFormat} from "../../../shared/helpers/date";
import {tableTheme} from "../../shared/table/styles";
import {FreePullCable, FreePullCableProps} from "../../../shared/interfaces/freePullCable.interface";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {handleRowChange} from "../../../shared/helpers/table";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {compare} from "fast-json-patch";
import {AlertContext} from "../../../shared/context/AlertContext";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {Skeleton} from "@material-ui/lab";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {useStyles} from "../tag/Tags.styles";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {useModals} from "../../../shared/context/ModalContext";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {mapAreaForemanOptions, mapCableTypeOptions, mapSystemOptions} from "../../../shared/helpers/metadata";
import {ProjectClaims} from "../../../shared/claims";

const FreePullCables: FunctionComponent = () => {
  /* State */
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<FreePullCable[] | null>(null);
  // Options
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [cableTypes, setCableTypes] = useState<CableTypeListItem[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  // Filtering
  const [selectedSystem, setSelectedSystem] = useState<SystemListItem | null>(null);
  const [selectedCableType, setSelectedCableType] = useState<CableTypeListItem | null>(null);
  const [selectedAreaforeman, setSelectedAreaforeman] = useState<Areaforeman | null>(null);
  const [isEstimatedFreePull, setEstimatedFreePull] = useState<boolean>(false);
  // Management
  const [prevNewId, setPrevNewId] = useState<number>(0);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  /* State End */

  const classes = useStyles();
  const {project} = useContext(ProjectContext);
  const {setAlert, setSuccess} = useContext(AlertContext);
  const {openModal} = useModals();
  const {get, post, fileDownload} = useApi();

  const getPdf = async () => {
    const systemId = selectedSystem?.id;
    const cableTypeId = selectedCableType?.id;
    const afId = selectedAreaforeman?.id;
    const efp = isEstimatedFreePull;

    await fileDownload("Free Pull Cablelist.pdf", `/cableFreePull/${project?.id}/pdf`, {
      queryParams: {
        systemId,
        cableTypeId,
        afId,
        efp,
      }
    });
  }

  const excelExport = [
    {
      label: "Export data",
      action: async () => {
        await fileDownload("qxCableFP.xlsx", `/cableFreePull/${project?.id}/exportAsExcel`)
      }
    }
  ]

  useEffect(() => {
    let isSubscribed = true;
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };
      const systemId = selectedSystem?.id;
      const cableTypeId = selectedCableType?.id;
      const afId = selectedAreaforeman?.id;
      const efp = isEstimatedFreePull;

      (async () => {
        await post<PaginationRequest, any>(`/project/${project?.id}/cableFreePull`,
          paginationQuery,
          {},
          {
            systemId,
            cableTypeId,
            afId,
            efp,
          })
          .then(result => {
            if (isSubscribed) {
              setInitialValues(result.values);
              dispatch({
                type: "SET_STATE",
                payload: () => ({
                  ...result,
                  values: result.values.map((x: FreePullCable) => ({
                    ...x,
                    isMarked: false
                  }))
                })
              });
            }
          });
      })();
    } finally {
      setIsLoading(false);
      setIsDirty(false);
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    selectedSystem,
    selectedCableType,
    selectedAreaforeman,
    isEstimatedFreePull,
    state.pageSize,
    state.currentPage,
  ])

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (systems == null) {
        const systemsResult = await get<SystemListItem[]>(`/project/${project?.id}/system`);
        if (isSubscribed) {
          setSystems(systemsResult);
        }
      }
      if (foremen == null) {
        const foremenResult = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`);
        if (isSubscribed) {
          setForemen(foremenResult);
        }
      }
      if (cableTypes == null) {
        const cableTypesResult = await get<CableTypeListItem[]>(`/project/${project?.id}/cableTypes/select-options`);
        if (isSubscribed) {
          setCableTypes(cableTypesResult);
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  /*
 * Map Options
 */

  const systemOptions = useMemo((): Option[] | null => mapSystemOptions(systems), [systems]);
  const foremanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(foremen), [foremen]);
  const cableTypeOptions = useMemo((): Option[] | null => mapCableTypeOptions(cableTypes), [cableTypes]);

  const addNewItem = () => {
    if (!areDependenciesLoaded) {
      return;
    }
    const activeSystems = systems?.filter(x => x.isActive);
    const activeForemen = foremen?.filter(x => x.isActive);
    const activeCableTypes = cableTypes?.filter(x => x.isActive);

    if(activeSystems == null || activeSystems.length === 0
      || activeForemen == null || activeForemen.length === 0
      || activeCableTypes == null || activeCableTypes.length === 0) {
      return;
    }
    const newId = prevNewId - 1;

    const system = activeSystems[0];
    const foreman = activeForemen[0]
    const cableType = activeCableTypes[0];

    const newItem: FreePullCable = {
      id: newId,
      projectId: project ? project.id : 0,
      systemId: system.id,
      cableTypeId: cableType.id,
      areaForemanId: foreman.id,
      name: "",
      week: getYearWeekFormat(new Date()),
      registrationDate: new Date(),
      meters: 0,
      isFreePullEstimated: false,
      autoNumber: newId,
      state: "new",
      hasError: false
    };

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
  };

  const change = useCallback((cableFreePull: FreePullCable) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        return handleRowChange(
          prevValues,
          cableFreePull,
          setIsDirty);
      }
    });
  }, []);

  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: FreePullCable) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u)
        .filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<FreePullCableProps>>((n: FreePullCable) => ({
        referenceId: n.id,
        props: {
          cableTypeId: n.cableTypeId,
          systemId: n.systemId,
          areaForemanId: n.areaForemanId,
          name: n.name,
          week: n.week,
          registrationDate: n.registrationDate,
          meters: n.meters,
          isFreePullEstimated: n.isFreePullEstimated,
          autoNumber: n.autoNumber,
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted")
      .map((x: FreePullCable) => x.id);

    const body = {
      newItems,
      patchedItems,
      deletedItems
    }

    try {
      setIsLoading(true);
      const updatedPenetrationsResult = await post<PatchRequest<FreePullCableProps>, PatchResponse<FreePullCable>>(`project/${project!.id}/cableFreePull/patch`, body);
      refreshVisibleFreePullCables(updatedPenetrationsResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the cable free pull list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the cable free pull list"});
      }
    } finally {
      setIsLoading(false);
    }
  }

  const refreshVisibleFreePullCables = (updates: PatchResponse<FreePullCable>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: FreePullCable[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  /* Validators */

  const areDependenciesLoaded = cableTypeOptions != null
    && systemOptions != null
    && foremanOptions != null;

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  /* Filters */

  const systemFilter = useMemo(() => {
    return (
      <VectSelect
        id={"systemIdFilter"}
        label="System ID"
        isClearable={true}
        placeholder={"System ID"}
        value={selectedSystem?.id}
        change={value => {
          const action = () => {
            if(value === null) {
              setSelectedSystem(null);
            } else {
              const system = systems?.find(x => x.id === value);
              if (system) {
                setSelectedSystem(system);
              }
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={systemOptions}
      />
    );
  }, [systems, selectedSystem, isDirty]);

  const cableTypeFilter = useMemo(() => {
    return (
      <VectSelect
        id={"cableTypeIdFilter"}
        isClearable={true}
        label="CableType ID"
        placeholder={"CableType ID"}
        value={selectedCableType?.id}
        change={value => {
          const action = () => {
            if(value === null) {
              setSelectedCableType(null);
            } else {
              const cableType = cableTypes?.find(x => x.id === value);
              if (cableType) {
                setSelectedCableType(cableType);
              }
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={cableTypeOptions}
      />
    );
  }, [cableTypes, selectedCableType, isDirty]);

  const areaForemanFilter = useMemo(() => {
    return (
      <VectSelect
        id={"areaForemanIdFilter"}
        isClearable={true}
        label="Area Foreman ID"
        placeholder={"Area Foreman ID"}
        value={selectedAreaforeman?.id}
        change={value => {
          const action = () => {
            if(value === null) {
              setSelectedAreaforeman(null);
            } else {
              const areaForeman = foremen?.find(x => x.id === value);
              if (areaForeman) {
                setSelectedAreaforeman(areaForeman);
              }
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={foremanOptions}
      />
    );
  }, [foremen, selectedAreaforeman, isDirty]);

return (
  <>
    <VectUnsavedChangesPrompt isDirty={isDirty}/>
    <PanelHeader
      text={'Cable - "Free Pull"'}
      add={{
        action: addNewItem,
        disabled: !areDependenciesLoaded,
        claim: ProjectClaims.project.engineering.external
      }}
      save={{action: update, disabled: !areDependenciesLoaded || hasErrors || !isDirty}}
      getPdf={{action: getPdf}}
      excelExports={excelExport}
    />

    {/* Header filter */}
    {initialValues ? (
      <div className={classes.filterWrapper}>
        <div className={classes.filterItem}>
          {systemFilter}
        </div>
        <div className={classes.filterItem}>
          {cableTypeFilter}
        </div>
        <div className={classes.filterItem}>
          {areaForemanFilter}
        </div>
        <div style={{display: 'flex'}}>
          <FormControlLabel
            label="Estimated Free Pull"
            labelPlacement="start"
            control={
              <VectCheckbox
                color="primary"
                checked={isEstimatedFreePull || false}
                change={() => setEstimatedFreePull(!isEstimatedFreePull)}
              />
            }
          />
        </div>
      </div>
    ) : <Skeleton variant="rect" width="40rem" height="5rem"/>}

    {/* Tag Table Headers */}
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{width: "7rem"}}>System ID</TableCell>
          <TableCell style={{width: "10rem"}}>Cable Type ID</TableCell>
          <TableCell style={{width: "7rem"}}>Foreman</TableCell>
          <TableCell style={{width: "25rem"}}>Text</TableCell>
          <TableCell style={{width: "8rem"}}>Meter</TableCell>
          <TableCell style={{width: "6rem"}}>Estimated Free Pull</TableCell>
          <TableCell style={{width: "6rem"}}>yy-ww</TableCell>
          <TableCell style={{width: "11rem"}}>Reg. Date</TableCell>
          <TableCell/>
          <TableCell/>
        </TableRow>
      </TableHead>
      <MuiThemeProvider theme={tableTheme}>
        <TableBody>
          <LoadingIndicator isLoading={isLoading}>
            {state?.values?.map((cableFreePull: FreePullCable) => (
              <FreePullCableRowItem
                key={cableFreePull.id}
                freePullCable={cableFreePull}
                change={change}
                systemOptions={systemOptions}
                cableTypeOptions={cableTypeOptions}
                areaForemanOptions={foremanOptions}
              />
            ))}
          </LoadingIndicator>
        </TableBody>
      </MuiThemeProvider>
    </Table>
    <Pagination state={state} dispatch={dispatch}/>
  </>
);
}
;

export default FreePullCables;
