import {TableFooter, TableRow, Typography} from "@material-ui/core";
import React, {FunctionComponent} from "react";
import {TableCellInput, tableCellStyles, tableStyles} from "../../shared/table/styles";
import {AreaForemanForProjectTotals} from "../../../shared/interfaces/AreaForemanForProjectResult";

const CbsStatusTableFooter: FunctionComponent<{totals: AreaForemanForProjectTotals}> = (totals) => {
  const classes = tableCellStyles();
  const rowClasses = tableStyles();

  return (
    <TableFooter>
      <TableRow style={{ borderBottom: "none", backgroundColor: "#F0F0F0" }} className={rowClasses.tableRowWrapper}>
        <TableCellInput style={{width:"7rem", marginLeft: "39rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography variant="subtitle2">%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography variant="subtitle2">%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography variant="subtitle2">%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography variant="subtitle2">%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography variant="subtitle2">Sum:</Typography>
        </TableCellInput>
      </TableRow>
      <TableRow style={{ border: "none", backgroundColor: "#F0F0F0" }} className={rowClasses.tableRowWrapper}>
        <TableCellInput style={{width:"7rem", marginLeft: "39rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalRegisteredCount}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalReleasedCount}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalReleasedPercent}%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalRegisteredMeters}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalMeasuredPercent}%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalFixedCount}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalFixedPercent}%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalFixedMeters}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalFixedMetersPercent}%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalConnectedFromCount}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalConnectedFromPercent}%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalConnectedToCount}</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"5rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalConnectedToPercent}%</Typography>
        </TableCellInput>
        <TableCellInput style={{width:"7rem"}} className={classes.cell}>
          <Typography>{totals.totals.totalRegisteredMetersFreePull}</Typography>
        </TableCellInput>
      </TableRow>
    </TableFooter>
  );
};

export default CbsStatusTableFooter;
