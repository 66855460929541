import React, {useContext} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import ManageUsersHome from "./users/ManageUsersHome";
import ManageProjectsHome from "./projects/ManageProjectsHome";
import ManageBaselineHome from "./baseline/ManageBaselineHome";
import {ApplicationLink} from "../shared/layout/VectAppBar";
import {GlobalClaims} from "../../shared/claims";
import {UserContext} from "../../shared/context/UserContext";
import {PageSkeleton} from "../shared/PageSkeleton";
import {ProtectedRoute} from "../shared/layout/ProtectedRoute";


const ManageHome = (): JSX.Element => {
  const {path, url} = useRouteMatch();
  const{user, hasClaim} = useContext(UserContext);

  const navLinks: ApplicationLink[] = [
    {claim: GlobalClaims.manage.baseline.readWrite.all, name: "Baseline", route: `${url}/baseline`},
    {claim: GlobalClaims.manage.project.readWrite.all, name: "Projects", route: `${url}/projects`},
    {claim: GlobalClaims.manage.user.wildcard, useExactMatch: false, name: "Users", route: `${url}/users`},
  ];

  const isUserInitialized = user.id != null
  const hasBaselineClaims = hasClaim(GlobalClaims.manage.baseline.wildcard, false);
  const hasUserClaims = hasClaim(GlobalClaims.manage.user.wildcard, false);
  const hasProjectClaims = hasClaim(GlobalClaims.manage.project.wildcard, false);

  const projectPath = `${path}/projects`;
  const userPath = `${path}/users`;
  const baselinePath = `${path}/baseline`;
  const redirectPath = hasBaselineClaims
    ? baselinePath
    : hasProjectClaims
      ? projectPath
      : hasUserClaims
        ? userPath
        : baselinePath;

  return (
    <div>

      <Switch>
        <ProtectedRoute path={projectPath} claim={GlobalClaims.manage.project.wildcard} useExactMatch={false}>
          <ManageProjectsHome navLinks={navLinks}/>
        </ProtectedRoute>
        <ProtectedRoute path={userPath} claim={GlobalClaims.manage.user.wildcard} useExactMatch={false}>
          <ManageUsersHome navLinks={navLinks}/>
        </ProtectedRoute>
        <ProtectedRoute path={baselinePath} claim={GlobalClaims.manage.baseline.wildcard} useExactMatch={false}>
          <ManageBaselineHome navLinks={navLinks}/>
        </ProtectedRoute>
        <Route path={path}>
          {
            isUserInitialized
              ? <Redirect to={redirectPath}/>
              : <PageSkeleton/>
          }
        </Route>
      </Switch>
    </div>
  );

}



export default ManageHome;
