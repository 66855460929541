import React, {ReactNode} from "react";
import {Button, PropTypes, Typography} from "@material-ui/core";
import styles from "./panelHeader.module.css"
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import {CloseSharp, GridOn, PictureAsPdf} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
import {ExcelButton, PdfButton} from "../../../../shared/theme";
import {AuthProps} from "../../../../shared/interfaces/auth.interface";
import clsx from "clsx";
import {VectButton} from "../../navigation/VectButton";
import {useClaims} from "../../../../shared/hooks/useClaims";
import {Skeleton} from "@material-ui/lab";

type size = "normal" | "small";

export interface Action extends AuthProps{
  action: () => void,
  disabled?: boolean
  label?: string
}

export interface AdditionalAction extends Action{
  label: string
}

export interface SecondaryAction extends AdditionalAction {
  icon: ReactNode
  color?: PropTypes.Color
}

export interface NavigationLink extends AuthProps{
  label: string
  route: string
  icon?: ReactNode
}

export type PanelHeaderProps = {
  text?: string | null,
  size?: size,
  save?: Action,
  add?: Action,
  /**
   * An action to indicate that this page should be closed and navigation
   * returned to the page that opened this page.
   */
  close?: Action,
  secondaryActions?: SecondaryAction[],
  getPdf?: Action,
  excelExports?: AdditionalAction[],
  inline?: boolean,
  navigationLinks?: NavigationLink[]
}
const PanelHeader = ({
  text,
  save,
  add,
  close,
  secondaryActions,
  getPdf,
  excelExports,
  navigationLinks,
  inline = false,
  size = "normal"
}: PanelHeaderProps): JSX.Element => {
  const {isValid} = useClaims();
  let excelKeyIndex = 0;
  let navigationLinkKeyIndex = 0;
  let secondaryKeyIndex = 0;
  const variant = size === "normal" ? "h4" : "h6";
  const excelKey = () => `excel_${excelKeyIndex++}`;
  const navigationLinkKey = () => `navigate_${navigationLinkKeyIndex++}`;
  const secondaryKey = () => `secondary_${secondaryKeyIndex++}`;

  return (
    <div className={clsx(styles.container, inline && styles.inline)}>
      {
        text === null
          ? <Skeleton width="30rem" height="1.5rem" />
          : <Typography variant={variant}>{text}</Typography>
      }
      <div className={styles.buttonContainer}>
        {save && <Button size="small"
                         className={styles.item}
                         onClick={save.action}
                         color="primary"
                         disabled={save.disabled === true}
                         startIcon={<SaveIcon/>}>{save.label || "Save"}</Button>}
        {add && <Button size="small"
                        className={styles.item}
                        onClick={add.action}
                        color="primary"
                        disabled={add.disabled === true || (!isValid({claim: add.claim}))}
                        startIcon={<AddIcon/>}>{add.label || "Add item"}</Button>}
        {
          secondaryActions && secondaryActions.map(action => (
            <VectButton size="small"
                        claim={action.claim}
                        claims={action.claims}
                        useExactMatch={action.useExactMatch}
                    key={secondaryKey()}
                    className={styles.item}
                    onClick={action.action}
                    color={action.color || "secondary"}
                    disabled={action.disabled === true}
                    startIcon={action.icon}>{action.label}</VectButton>
          ))
        }
        {navigationLinks && navigationLinks.map(x =>
          <VectButton
            size="small"
            claim={x.claim}
            claims={x.claims}
            useExactMatch={x.useExactMatch}
            className={styles.item}
            key={navigationLinkKey()}
            component={RouterLink}
            to={x.route}
            startIcon={x.icon}
            color="default"
            disabled={!isValid(x)}
          >{x.label}</VectButton>)}
        {getPdf &&
        <PdfButton size="small"
                   className={styles.item}
                   onClick={getPdf.action}
                   disabled={getPdf.disabled === true}
                   startIcon={<PictureAsPdf/>}>{getPdf.label || "Export as PDF"}</PdfButton>}
        {excelExports && excelExports.map(x =>
          <ExcelButton key={excelKey()}
                       size="small"
                       className={styles.item}
                       onClick={x.action}
                       startIcon={<GridOn/>}>
            {x.label}
          </ExcelButton>)}
        {close && <VectButton size="small"
                              claim={close.claim}
                              claims={close.claims}
                              useExactMatch={close.useExactMatch}
                              className={styles.item}
                              onClick={close.action}
                              color="default"
                              disabled={close.disabled === true}
                              startIcon={<CloseSharp/>}>{close.label || "Close"}</VectButton>}
      </div>
    </div>
  )
}

export default PanelHeader;
