import {PaginatedResult} from "../interfaces/pagination.interface";
import {Action} from "../interfaces/common.interface";

export const tableReducer = (state: PaginatedResult, action: Action): PaginatedResult => {
  switch (action.type) {
    case "INCREASE_PAGE":
      return {...state, currentPage: state.currentPage+=1}
    case "DECREASE_PAGE" :
      return {...state, currentPage: state.currentPage-=1}
    case "SET_PAGE_SIZE":
      return {...state, pageSize: action.payload}
    case "GO_TO_PAGE":
      return {...state, currentPage: action.payload}
    case "SET_VALUES":
      return {...state, values: action.payload(state.values)}
    case "SET_STATE":
      return action.payload(state);
    default:
      return state;
  }
}
