import React, {useState} from "react";
import {
  Checkbox, Chip,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";


export interface MultiSelectItem {
  id: number
  value: string
}

export interface MultiSelectProps {
  className?: string;
  items: MultiSelectItem[];
  label?: string;
  labelId?: string;
  onChange: (itemIds: number[]) => void;
  selectId?: string;
  value?: number[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    input: {
      // width: "100%"
    }
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export const MultiSelect:  React.FC<MultiSelectProps> = (
  {
    className,
    items,
    label,
    labelId,
    onChange,
    selectId,
    value
  }): JSX.Element => {

  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState<number[]>(value || []);


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as number[];
    setSelectedIds(selected);
    onChange(selected);
  };

  const renderValue = (selected: unknown): JSX.Element => {
    const ids = selected as number[];
    const filteredItems = items.filter(x => ids.indexOf(x.id) > -1);
    return (
      <div className={classes.chips}>
      {filteredItems.map((item) => (
        <Chip key={item.id} size="small" label={item.value} className={classes.chip} />
        ))}
      </div>
    );
  }

  return (
    <FormControl className={className}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        labelId={labelId}
        id={selectId}
        multiple
        value={selectedIds}
        onChange={handleChange}
        input={<Input id={`${selectId || "_"}-chip`} className={classes.input}/>}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={selectedIds.indexOf(item.id) > -1} color="primary"/>
            <ListItemText primary={item.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
