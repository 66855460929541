import React, {FC, useEffect, useState} from "react";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {ProjectClaims} from "../../../shared/claims";
import {VectContainer} from "../../shared/layout/VectContainer";
import {VectButton} from "../../shared/navigation/VectButton";
import {footerStyles} from "../../../shared/styles/footerStyles";


interface OrganizeFooterProps {
  bundleOptions: Option[] | null;
  selectAllVisibleCables: () => void;
  updateSelectedBatch: (bundleId: number) => void;
}

const OrganizeFooter: FC<OrganizeFooterProps> = ({
  bundleOptions,
  selectAllVisibleCables,
  updateSelectedBatch
 }) => {
  const [bundleId, setBundleId] = useState<number | null>(null);
  const classNames = footerStyles();

  useEffect(() => {
    if(bundleOptions && bundleOptions.length > 0) {
      setBundleId(bundleOptions[0].value);
    }
  }, [bundleOptions])

  const onUpdateClicked = () => {
    if(bundleId != null) {
      updateSelectedBatch(bundleId);
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <VectContainer claims={[ProjectClaims.project.routing.external]}
                        className={classNames.controls}
        >
          <div style={{width: "40rem"}}>
            <VectSelect
              claim={ProjectClaims.project.routing.external}
              id={"bundleId"}
              label="Move cables to bundle ID"
              placeholder={"Bundle ID"}
              change={setBundleId}
              value={bundleId}
              options={bundleOptions}
            />
          </div>
        </VectContainer>

      </div>
      <div className={classNames.container}>
        <VectContainer claims={[ProjectClaims.project.routing.external]}
        className={classNames.controls}>
        <VectButton onClick={selectAllVisibleCables}
                    variant="contained"
                    color="default">
        Select all visible cables
      </VectButton>
      <VectButton claims={[ProjectClaims.project.routing.external]}
                  disabled={bundleId == null}
                  onClick={onUpdateClicked}
                  variant="contained"
                  color="primary">
        Update Selected
      </VectButton>
    </VectContainer>
      </div>
    </div>
  )
}
export default OrganizeFooter;
