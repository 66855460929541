import React, {FC, useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import BundleProperties from "./BundleProperties";
import {Bundle, PatchBundlesRequest, PatchBundlesResponse} from "../../../shared/interfaces/bundle.interface";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {ProductionResponsible} from "../../../shared/interfaces/productionResponsible.interface";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {initialPaginated, PaginatedResult, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {generalStyles, tableStyles, tableTheme} from "../../shared/table/styles";
import {
  BatchUpdateCableProps,
  BatchUpdateCablesRequest,
  Cable,
  CableListItem,
  CableWithReferenceLabels,
  handleSideEffects,
  refreshVisibleCables
} from "../../../shared/interfaces/cable.interface";
import Pagination from "../../shared/table/Pagination";
import BundleAdminCableRow from "./BundleAdminCableRow";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {CableClass} from "../../../shared/interfaces/cableClass.interface";
import {CableType} from "../../../shared/interfaces/cableType.interface";
import {useModals} from "../../../shared/context/ModalContext";
import {Modal} from "../../shared/Feedback/Modal";
import BatchUpdateBundleAdmin from "./BatchUpdateBundleAdminFooter";
import {Option} from "../../shared/inputs/VectSelect";
import {Skeleton} from "@material-ui/lab";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {PictureAsPdf} from "@material-ui/icons";
import {AlertContext} from "../../../shared/context/AlertContext";
import {compare} from "fast-json-patch";
import {handleRowChange, operationFilter} from "../../../shared/helpers/table";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {PatchResponse} from "../../../shared/interfaces/patchRequest";
import {BatchUpdateTagsModal} from "../cable/modals/BatchUpdateTagsModal";
import {useSession} from "../../../shared/hooks/useSession";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {validateYearWeekFormat} from "../../../shared/helpers/date";
import {dp3Options} from "../bundle-register/options";
import {mapAreaForemanOptions, mapAreaOptions, mapTagOptions} from "../../../shared/helpers/metadata";
import {Project} from "../../../shared/interfaces/project.interface";


const BundleAdmin: FC = () => {
  const [areDependenciesLoaded, setAreDependenciesLoaded] = useState(false);
  const [areaForemen, setAreaForemen] = useState<Areaforeman[] | null>([]);
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [batchUpdateCables, setBatchUpdateCables] = useState<BatchUpdateCableProps>({});
  const [bundle, setBundle] = useState<Bundle | null>(null);
  const [bundles, setBundles] = useState<Bundle[] | null>(null);
  const [cableClasses, setCableClasses] = useState<CableClass[] | null>(null);
  const [cableTypes, setCableTypes] = useState<CableType[] | null>(null);
  const [initialBundle, setInitialBundle] = useState<Bundle | null>(null);
  const [initialCables, setInitialCables] = useState<CableWithReferenceLabels[] | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mainVerticalZones, setMainVerticalZones] = useState<MainVerticalZone[] | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [productionResponsible, setProductionResponsible] = useState<ProductionResponsible[] | null>(null);
  const [state, dispatch] = useReducer(tableReducer, initialPaginated)
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const classes = tableStyles();
  const general = generalStyles();
  const {get, post, put, fileDownload} = useApi();
  const {openModal, closeModal} = useModals();
  const {project: context} = useContext(ProjectContext);
  const {setError, setAlert, setSuccess} = useContext(AlertContext);
  const {bundleId, setBundleId} = useSession();

  /**
   * Load cables for selected bundle
   */
  useEffect(() => {
    if(!project || !initialBundle){
      dispatch({type: "SET_STATE", payload: () => ({
        ...initialPaginated,
        pageSize: state?.pageSize
        })});
      return;
    }

    let isSubscribed = true;
    setIsLoading(true);
    const paginationQuery: PaginationRequest = {
      page: state.currentPage,
      pageSize: state.pageSize,
    };

    post<PaginationRequest, PaginatedResult>(`/project/${project.id}/cable?bundleId=${initialBundle.id}`, paginationQuery)
      .then(res => {
        if (isSubscribed) {
          setInitialCables(res.values);
          dispatch({type: "SET_STATE", payload: () => res});
        }
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    project,
    initialBundle,
    state?.pageSize,
    state?.currentPage,
  ]);

  /**
   * Load dependencies
   */
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const projectResult = await get<Project>(`project/${context?.code}`);
      
      const areaForemanResponse = await get<Areaforeman[]>(`/project/${projectResult.id}/areaForeman`);
      const productionResponsibleResponse = await get<ProductionResponsible[]>(`/project/${projectResult.id}/productionResponsible`);
      const bundleResponse = await get<Bundle[]>(`/project/${projectResult.id}/bundle`);
      const tagResponse = await get<Tag[]>(`/project/${projectResult.id}/tag`);
      const areaResponse = await get<Area[]>(`/project/${projectResult.id}/area`);
      const mvZoneResponse = await get<MainVerticalZone[]>(`/project/${projectResult.id}/mainVerticalZones`);
      const cableClassResponse = await get<CableClass[]>(`/cableClass`);
      const cableTypeResponse = await get<CableType[]>(`/project/${projectResult.id}/cableTypes/select-options`);
      const systemResponse = await get<SystemListItem[]>(`/project/${projectResult.id}/system`);
      if (isSubscribed) {
        setAreaForemen(areaForemanResponse);
        setProductionResponsible(productionResponsibleResponse);
        if(bundleResponse.length > 0) {
          const defaultBundle =
            bundleResponse.find( x=> x.id === bundleId) ||
            bundleResponse.find(x => x.code === "000") ||
            bundleResponse[0]

          setInitialBundle(defaultBundle);
        }
        setProject(projectResult)
        setBundles(bundleResponse)
        setTags(tagResponse);
        setAreas(areaResponse);
        setMainVerticalZones(mvZoneResponse);
        setCableClasses(cableClassResponse);
        setCableTypes(cableTypeResponse);
        setSystems(systemResponse);
        setAreDependenciesLoaded(true);

      }
    })();
    return () => {
      isSubscribed = false
    };
  }, [])

  useEffect(() => {
    setBundle(initialBundle);
    setBundleId(initialBundle != null ? initialBundle.id : null);
    setIsDirty(false)
  }, [initialBundle]);

  const areaForemanOpts = useMemo((): Option[] | null => mapAreaForemanOptions(areaForemen, {withShortLabel: true}), [areaForemen]);
  const areaOptions = useMemo((): Option[] | null => mapAreaOptions(areas, {withShortLabel: true}), [areas]);

  const cableClassOptions = useMemo((): Option[] | null => {
    if (cableClasses == null) {
      return null;
    }

    return cableClasses.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    }));
  }, [cableClasses]);

  const cableTypeOptions = useMemo((): Option[] | null => {
    if(cableTypes == null) {
      return null;
    }

    return cableTypes.map(x => ({
      value: x.id,
      label: x.code
    }));
  }, [cableTypes]);

  const hasErrors = useMemo((): boolean => {
    if(state?.values != null && state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0) {
      return true;
    }

    if(bundle && bundle.hasError) {
      return true;
    }

    if(batchUpdateCables){
      if(!validateYearWeekFormat(batchUpdateCables.cutWeek).isValid) {
        return true;
      }

      if(!validateYearWeekFormat(batchUpdateCables.pulledWeek).isValid) {
        return true;
      }
    }

    return false;
  }, [state, state.values, bundle, batchUpdateCables]);

  const mvZoneOptions = useMemo(():Option[] | null => {
    if(mainVerticalZones == null) {
      return null;
    }

    return mainVerticalZones.map(x => ({
      value: x.id,
      label: x.code
    }));
  }, [mainVerticalZones]);

  const tagOptions = useMemo((): Option[] | null => mapTagOptions(tags, {withShortLabel: true}), [tags])

  const change = useCallback(async (updated: Bundle) => {
    if(bundle == null) {
      return;
    }
    setBundle(updated);
    setIsDirty(true);
  }, [bundle]);

  const changeBatch = useCallback((value: number | boolean | string | undefined | null, field?: string) => {
    if (field != null) {
      setBatchUpdateCables((prevState) => {
        return {
          ...prevState,
          [field]: value,
        }
      });
    }
  }, []);

  const changeBundle = useCallback((value: number | null) => {
    if(bundles == null) {
      return;
    }
    const newBundle = value !== null ? bundles.find(x => x.id === value) || null : null;
    setInitialBundle(newBundle);

  }, [bundles, openModal]);

  const changeCable = useCallback((cable: CableWithReferenceLabels) => {
      if(!areDependenciesLoaded) {
        return;
      }
      const system = systems!.find(x=> x.id === cable.systemId);
      if(system == null) {
        setAlert({
          type: "error",
          text: "There is a mismatch between the system on the cable and the system list.",
          details: ["Please ensure that the system is set as active."]})
      }

      const sideEffects = (
        prevCable: CableWithReferenceLabels,
        currentCable: CableWithReferenceLabels,
      ): void => {
        handleSideEffects(prevCable, currentCable, system!, areas!, tags!);
      }

      dispatch({
        type: "SET_VALUES",
        payload: (prevValues) => {
          const prevBundle = prevValues.find(x => x.id === cable.id);

          // Set the cut week to the same as pulled week if pulled week is changed
          // and cut week is empty
          if (prevBundle.pulledWeek !== cable.pulledWeek) {
            if (cable.cutWeek === undefined || cable.cutWeek === null) {
              cable.cutWeek = cable.pulledWeek;
            }
          }

          return handleRowChange(
            prevValues,
            cable,
            setIsDirty,
            sideEffects);
        }
      });
    },
    [areDependenciesLoaded, systems, tags, areas]
  );

  const selectAllVisibleCables = useCallback(() => {
    dispatch({
      type: "SET_VALUES",
      payload: prevValues => {
        if(prevValues == null){
          return prevValues;
        }

        return prevValues.map(x => ({
          ...x,
          isMarked: true
        }));
      }
    });
  }, [])

  const refreshVisibleBundle = async () => {
    if(initialBundle && project) {
      setIsLoading(true);
      try {
        const newBundle = await get<Bundle>(`/project/${project.id}/bundle/${initialBundle.id}`);
        setBundles(prevState => {
          if(prevState == null || prevState.length === 0) {
            return [newBundle];
          }

          const itemIndex = prevState.findIndex(x => x.id === newBundle.id);
          const newState = [...prevState];
          newState[itemIndex] = newBundle;
          return newState;
        });
        setInitialBundle(newBundle);
      } catch (e: unknown) {
        const type = "error";
        const text = "I could not refresh the selected bundle.";
        if (e instanceof ProblemDetailError || e instanceof Error) {
          setAlert({type, text, error: e});
        } else {
          setAlert({type, text});
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  /**
   * @param cables      Cables included in the update
   * @param refreshList Id of the cables that should be updated after a successful update.
   */
  const updateBatch = async (cables: CableListItem[], refreshList: number[]) => {
    if (!areDependenciesLoaded || !project) {
      return;
    }

    const updateHandler = async (): Promise<void> => {
      if(!project || !bundle){
        return;
      }
      setIsLoading(true);
      try {
        const request: BatchUpdateCablesRequest = {
          ...batchUpdateCables,
          cableIds: cables.map(x => x.id),
          returnCableIds: refreshList,
          estimatedMeters: batchUpdateCables.estimatedMeters || undefined,
          meters: batchUpdateCables.meters || undefined,
          markPlacement: batchUpdateCables.markPlacement || undefined,
          penetrations: batchUpdateCables.penetrations || undefined
        }
        await post<BatchUpdateCablesRequest, PatchResponse<Cable>>(`/project/${project.id}/cable/batch-update`, request)
          .then(res => {
            refreshVisibleCables(dispatch, res, setInitialCables);
            setBatchUpdateCables({});
            setSuccess();
          })
          .catch((err: ProblemDetailError) => {
            setAlert({type: "error", text: "Something failed when updating the cable list", error: err});
          });
        await refreshVisibleBundle();

      } finally {
        setIsLoading(false);
        setIsDirty(false);
      }
    }


    if (openModal && (batchUpdateCables.fromTagId || batchUpdateCables.toTagId)) {
      openModal(BatchUpdateTagsModal, {
        cables: cables,
        tags: tags!,
        fromTagId: batchUpdateCables.fromTagId,
        toTagId: batchUpdateCables.toTagId,
        update: updateHandler
      })
    } else {
      await updateHandler();
    }
  }

  const updateAllBatch = () => {
    if (!bundle) {
      return;
    }

    const refreshList = state.values.map((x: CableWithReferenceLabels) => x.id)

    get<CableListItem[]>(`/project/${project?.id}/bundle/${bundle.id}/cables`)
      .then(res => updateBatch(res, refreshList))
      .catch((err: ProblemDetailError) => {
        setAlert({type: "error", text: "I failed to get a list of cables in this bundle", error: err});
      });

  };

  const updateSelectedBatch = () => {
    if (!bundle) {
      return;
    }
    const markedCableIds = state.values
      .filter((x: CableWithReferenceLabels) => x.isMarked)
      .map((x: CableWithReferenceLabels) => x.id);

    post<number[], any>(`/project/${project?.id}/bundle/${bundle.id}/cables/selection`, markedCableIds)
      .then(res => updateBatch(res, markedCableIds))

      .catch((err: ProblemDetailError) => {
        setAlert({type: "error", text: "I failed to read the selected cables in this bundle", error: err});
      });
  };

  const getPdf = async () => {
    if (initialBundle) {
      await fileDownload("Cable report on selected BUNDLE.pdf", `/project/${project?.id}/bundle/${initialBundle.id}/pdf`);
      await refreshVisibleBundle();
    }
  }

  const labelPrinted = async () => {
    await put(`/bundle/${project?.id}/labelPrinted/${initialBundle?.id}`,{})
    await refreshVisibleBundle()
  }

  const printLabel = async (preview: boolean) => {
      if(initialBundle?.id){
        if(initialBundle.code === "000"){
          if (openModal) {
            openModal(Modal, {
              title: "Invalid selection",
              description:
                `Bundle with id 000 can not be printed`,
              ok: () => {
                if (closeModal) {
                  closeModal();
                }
              },
              okText: "Ok",
            });
          }
          return;
        }
        else if(state.values.length === 0) {
          if (openModal) {
            openModal(Modal, {
              title: "Invalid selection",
              description:
                `The Bundle have no cables - therefore no printout.`,
              ok: () => {
                if (closeModal) {
                  closeModal();
                }
              },
              okText: "Ok",
            });
          }
          return;
        } else if(initialBundle.releasedDate === undefined || initialBundle.releasedDate === null) {
          if (openModal) {
            openModal(Modal, {
              title: "Invalid selection",
              description:
                `This Bundle is not released!`,
              ok: () => {
                if (closeModal) {
                  closeModal();
                }
              },
              okText: "Ok",
            });
          }
          return;
        } else if(initialBundle.labelledDate) {
          if (openModal) {
            openModal(Modal, {
              title: "Invalid selection",
              description:
                `The Labels for this Bundle are already been printed`,
              ok: () => {
                if (closeModal) {
                  closeModal();
                }
              },
              okText: "Ok",
            });
          }
          return;
        }
        else {
          await fileDownload("Cable Report on Selected BUNDLE.pdf", `/bundle/${project?.id}/label/${initialBundle?.id}`);
          if(!preview) {
            if (openModal) {
              openModal(Modal, {
                title: "Information",
                description:
                  `The Labels are now printed. Accepted? When clicking Yes, the labels can not be printed again`,
                ok: () => {
                  if (closeModal) {
                    labelPrinted();
                    closeModal();

                  }
                },
                okText: "Yes",
                cancelText: "No"
              });
            }
          }
        }
      }

  }



  const save = async () => {
    if(!initialBundle || !bundle) {
      return;
    }

    setIsLoading(true);
  try {
      const operations = compare(initialBundle, bundle)
        .filter(op => ![...operationFilter, "/updateDp3ZoneForAllCables"].includes(op.path));
      const cableUpdates = state.values.filter(x => x.state === "modified");
      const patchedCables = cableUpdates.map((u: CableWithReferenceLabels) => {
        const initialItem = initialCables && initialCables.find(x => x.id === u.id);
        const operations = compare(initialItem!, u).filter(op => !operationFilter.includes(op.path));

        return {referenceId: u.id, operations};
      });
      const body = {
        patchedItems: [{referenceId: bundle.id, operations}],
        cables: {
          patchedItems: patchedCables
        },
        updateDp3ZoneForAllCables: bundle.updateDp3ZoneForAllCables
      }
      await post<PatchBundlesRequest, PatchBundlesResponse>(`/project/${project!.id}/bundle`, body);
      await refreshVisibleBundle();
      setSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }


  }

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader text={"Bundle Administration"}
                   save={{
                     action: save,
                     disabled: !isDirty || hasErrors
                   }}
                   getPdf={{
                     action: getPdf,
                     disabled: isDirty,
                     label: "Print bundle list"}}
                   secondaryActions={[
                     {
                       action: () => printLabel(true),
                       disabled: isDirty,
                       label: "Preview label",
                       icon: <PictureAsPdf/>,
                       color: "default"
                     },
                     {
                       action: () => printLabel(false),
                       disabled: isDirty,
                       label: "Print label",
                       icon: <PictureAsPdf/>,
                       color: "default"
                     }
                   ]}
      />
      {areDependenciesLoaded ?
        <BundleProperties bundle={bundle}
                          bundles={bundles!}
                          areaForemen={areaForemen!}
                          isDirty={isDirty}
                          productionResponsible={productionResponsible!}
                          project={project!}
                          change={change} changeBundle={changeBundle}/> : <Skeleton variant="rect" height="5rem" width="50rem"/>}


      <Table>
        <TableHead>
          <TableRow className={classes.tableRowWrapper}>
            <div
              style={{width: "14rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <div className={general.xFlex}>
                <div className={general.yFlex}>
                  <TableCell>Cable nr:</TableCell>
                  <TableCell>Cable Type:</TableCell>
                  <TableCell>Cable ID:</TableCell>
                </div>

              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>From Tag:</TableCell>
              <TableCell>From Area:</TableCell>
              <TableCell>From MVZone:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>

                <TableCell>From Text:</TableCell>
              </div>
              <div>
                <TableCell>From:</TableCell>
                <TableCell>Conn Resp:</TableCell>
                <TableCell>From 3D Zone:</TableCell>
                <TableCell>Class Type:</TableCell>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>To Tag:</TableCell>
              <TableCell>To Area:</TableCell>
              <TableCell>To MVZone:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>To Text:</TableCell>
              </div>
              <div>
                <TableCell>To:</TableCell>
                <TableCell>Conn Resp:</TableCell>
                <TableCell>To 3D Zone:</TableCell>
                <TableCell>SRtP or Special Req.:</TableCell>
              </div>
            </div>
            <div
              style={{width: "10rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Calculated Meter:</TableCell>
              <TableCell>Meter:</TableCell>
              <TableCell>Cable Count: {state.values.length}</TableCell>
            </div>
            <div
              style={{width: "11rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Mark Placement Info:</TableCell>
              <TableCell>Penetrations to use:</TableCell>
              <TableCell>Cable
                sum: {state.values.reduce((total: number, cable: CableWithReferenceLabels) => {
                  const parsed = parseInt(cable.meters != null ? ""+cable.meters : "")
                  if(isNaN(parsed)) {
                    return total;
                  }
                  return total + parsed;
                }, 0)}</TableCell>

            </div>
            <div
              style={{width: "6rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Cut:</TableCell>
              <TableCell>Fixed:</TableCell>
              <TableCell>DP Zone:</TableCell>
            </div>
            <div
              style={{width: "4rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Marked:</TableCell>
            </div>
              <div
                style={{textAlign: "center"}}
                className={classes.tableHeaderColumnWrapper}
              >
                <TableCell>Actions</TableCell>
              </div>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              { areDependenciesLoaded
                ? state.values.map((x, index) => {
                    const item = x as CableWithReferenceLabels;
                    return (
                      <BundleAdminCableRow
                        key={index}
                        cable={item}
                        change={changeCable}
                        tagOptions={tagOptions!}
                        areaOptions={areaOptions!}
                        mvZoneOptions={mvZoneOptions!}
                        dpZoneOptions={dp3Options}
                        cableClassOptions={cableClassOptions}
                        cableTypeOptions={cableTypeOptions!}
                        areaForemanOptions={areaForemanOpts!}
                      />
                    );
                  })
                : null
              }
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch}/>
      <BatchUpdateBundleAdmin hasErrors={hasErrors || isDirty}
                              areDependenciesLoaded={areDependenciesLoaded}
                              batchUpdateCables={batchUpdateCables}
                              cableClassOptions={cableClassOptions}
                              cableTypes={cableTypes}
                              areaForemen={areaForemen}
                              change={changeBatch}
                              selectAllVisibleCables={selectAllVisibleCables}
                              updateSelectedBatch={updateSelectedBatch}
                              updateAllBatch={updateAllBatch} />

    </>
  )
}

export default BundleAdmin;
