import React, {FC} from 'react';
import {Project} from "../../../shared/interfaces/project.interface";
import {ProjectTotals} from "../../../shared/interfaces/projectTotals.interface";
import {Paper, Typography} from "@material-ui/core";
import styles from './projectStatus.module.css'

interface EstimatedAndRegisteredTotalsProps {
  project: Project,
  projectTotals: ProjectTotals
}

const EstimatedAndRegisteredTotals: FC<EstimatedAndRegisteredTotalsProps> = ({
                                                                               project,
                                                                               projectTotals
                                                                             }) => {
  return (
    <div className={styles.mainDiv}>
      <Paper style={{width: "30rem", marginRight: "3em", marginTop: "3em", padding: "10px"}}>
        <Typography variant={"h6"}>Estimated Project Totals</Typography>
        <span className={styles.spanRow}><Typography>Estimated Total Meters of Cables(sales)</Typography> <Typography>{projectTotals.cableTotals.totalCableEstimatedMeters} </Typography></span>
        <span
          className={styles.spanRow}><Typography>Estimated no of cables</Typography> <Typography>{projectTotals.cableTotals.totalCable}</Typography> </span>
        <br/>
        <span className={styles.spanRow}><Typography>Reported usage from cable store</Typography> <Typography>{project.reportedCableMeters || 0}</Typography> </span>
      </Paper>
      <Paper style={{width: "30rem", marginRight: "3em", marginTop: "3em", padding: "10px"}}>
        <Typography variant={"h6"}>Registered other totals</Typography>
        <span className={styles.spanRow}><Typography>'Free pull' meters registered on systems</Typography> <Typography>{projectTotals.freePullTotals.metersOnSystem}</Typography> </span>
        <span className={styles.spanRow}><Typography>'Free pull' meters not sorted on systems</Typography> <Typography>{projectTotals.freePullTotals.metersNotOnSystem}</Typography> </span>
        <span
          className={styles.spanRow}><Typography>Usage: Free Pull</Typography> <Typography>{projectTotals.cableTotals.cableTotalsWithFreePull}</Typography> </span>
        <span
          className={styles.spanRow}><Typography>Average length of cables</Typography> <Typography>{projectTotals.cableTotals.averageCableLength}</Typography> </span>
        <span className={styles.spanRow}><Typography>Pre Registered calculated meters</Typography> <Typography>{projectTotals.cableTotals.totalCableEstimatedMeters}</Typography> </span>
      </Paper>
      <Paper style={{width: "30rem", marginTop: "3em", padding: "10px"}}>
        <br/>
        <span
          className={styles.spanRow}><Typography>Tag(Components) registered</Typography> <Typography>{projectTotals.tagTotals.totalTags}</Typography> </span>
        <span
          className={styles.spanRow}>
          <Typography>Tag(Components) mounted</Typography>
          <span className={styles.percentageSpan}>
            <Typography>{projectTotals.tagTotals.tagsMounted}</Typography>
            <Typography> {projectTotals.tagTotals.mountedPercentage} %</Typography>
          </span>
        </span>
        <span
          className={styles.spanRow}><Typography>Registered connections</Typography> <span className={styles.percentageSpan}><Typography>{projectTotals.cableTotals.totalConnections}</Typography> <Typography>{projectTotals.cableTotals.totalConnectedPercentage} %</Typography></span> </span>
      </Paper>
    </div>
  )
};


export default EstimatedAndRegisteredTotals;
