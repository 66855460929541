import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SystemTagTotal } from "../../../../shared/interfaces/systemStatusInformation";

interface TagTotalsProps {
  tagTotals: SystemTagTotal;
}

const TagTotals: FC<TagTotalsProps> = ({ tagTotals }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Total</TableCell>
          <TableCell>Mounted</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow style={{ height: 38}}>
          <TableCell>Registered no of Tag (Components) </TableCell>
          <TableCell>{tagTotals.totalTagsSystem}</TableCell>
          <TableCell>{tagTotals.totalTagsMountedSystem}</TableCell>
        </TableRow>
        <TableRow style={{ height: 38}}>
          <TableCell>% of registered</TableCell>
          <TableCell>{tagTotals.totalPercentage}</TableCell>
          <TableCell>{tagTotals.mountedPercentage}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};


export default TagTotals;
