import React, {FC, useMemo} from 'react';
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {Typography} from "@material-ui/core";
import {Option, selectStylesWideMenu, VectSelect} from "../../shared/inputs/VectSelect";
import {mapAreaForemanOptions} from "../../../shared/helpers/metadata";
interface CBSAreaForemanProps {
  selectedForeman?: Areaforeman,
  areaForemen: Areaforeman[]
  changeForeman: (id: number | undefined | null) => void
}

const CBSAreaForeman: FC<CBSAreaForemanProps> = ({
                                                   areaForemen,
                                                   changeForeman,
                                                   selectedForeman
                                                 }) => {

  const areaForemanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(
    areaForemen,
    {
      withShortLabel: true,
      withCustomLabel: val => `${val.code} ${val.foremanText}, ${val.name}`
    }
  ), [areaForemen]);

  return (
    <div key={selectedForeman?.id}>
      <VectSelect label="CBS/Area foreman"
                  options={areaForemanOptions}
                  value={selectedForeman?.id}
                  change={changeForeman}
                  styles={selectStylesWideMenu()}

      />

      <Typography>CBS / Area Text:  {selectedForeman?.foremanText}</Typography>
      <Typography>CBS / Area Foreman:  {selectedForeman?.name}</Typography>

    </div>
  )
};


export default CBSAreaForeman;
