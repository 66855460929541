import React, {FunctionComponent, useCallback, useContext, useEffect, useState,} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {ProjectUserContext} from "../../../shared/interfaces/project.interface";
import {AreaImportRowItem} from "./AreaImportRowItem";
import {AlertContext} from "../../../shared/context/AlertContext";
import {useModals} from "../../../shared/context/ModalContext";
import {useApi} from "../../../shared/hooks/useApi";
import {FileInput} from "../../shared/inputs/FileInput";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {ProjectClaims} from "../../../shared/claims";
import {tableTheme} from "../../shared/table/styles";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {DialogActionsFinished} from "../../shared/import-modal/DialogActionsFinished";
import {DialogActionsReady} from "../../shared/import-modal/DialogActionsReady";
import {AreaListValidationResult, CreateImportAreaRequest, ImportAreaRequest} from "./support";


export const AreaImportModal: FunctionComponent<{
  project: ProjectUserContext,
  onClosed: () => void,
  open?: boolean
}> = ({
    project,
    onClosed,
  }) => {
  const [isFinished, setIsFinished] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [preview, setPreview] = useState<AreaListValidationResult | null>(null);
  const [requireAreaForemanWrite, setRequireAreaForemanWrite] = useState(false);
  const [requireMvZoneWrite, setRequireMvZoneWrite] = useState(false);
  const {setError, setSuccess} = useContext(AlertContext);
  const { hasClaim } = useContext(ProjectContext)
  const { closeModal } = useModals();
  const { post, postFormData } = useApi();

  useEffect(() => {
    const isValid = preview != null && preview.areas && preview.areas.length > 0 && preview.errorCount === 0;
    setIsValid(isValid);
    if(preview != null){
      if(preview.areas.findIndex(x => x.areaForemanId == null) > -1) {
        setRequireAreaForemanWrite(!hasClaim(ProjectClaims.project.areaForeman.write.all));
      }

      if(preview.areas.findIndex(x => x.mvZoneId == null) > -1) {
        setRequireMvZoneWrite(!hasClaim(ProjectClaims.project.mainVerticalZone.write.all));
      }

    } else {
      setRequireAreaForemanWrite(false);
      setRequireMvZoneWrite(false);
    }
  }, [preview])

  const onCancel = useCallback(() => {
    closeModal && closeModal();
  }, [closeModal]);

  const onClose = useCallback(() => {
    onClosed();
    closeModal && closeModal();
  }, [closeModal]);

  const onFileChange = useCallback(async (file: File) => {
    const formData = new FormData();
    formData.append("areaList", file, file.name);
    try {
      setIsValidating(true);
      setPreview(null);
      const result = await postFormData<AreaListValidationResult>(`project/${project.id}/area/import-template/process-excel-file`, formData);
      setPreview(result);
    } catch(err) {
      setError(err);
    } finally {
      setIsValidating(false);
    }
  }, []);

  const importAreas = useCallback(async () => {
    if(preview == null || preview.errorCount > 0){
      return;
    }

    try {
      const body = CreateImportAreaRequest(preview.areas)
      await post<ImportAreaRequest, any>(`project/${project.id}/area/import`, body);
      setIsFinished(true);
      const areaCount = preview.areas.length;
      const foremanCount = body.newAreaForemen.length;
      const mvZoneCount = body.newMainVerticalZones.length;
      const zone3dCount = body.new3dZones.length;


      const successDetails = [
        `${areaCount} ${(areaCount === 1 ? "area" : "areas")} was successfully imported.`,
        `${foremanCount} area ${(foremanCount === 1 ? "foreman" : "foremen")} was successfully imported.`,
        `${mvZoneCount} main vertical ${(mvZoneCount === 1 ? "zone" : "zones")} was successfully imported.`,
        `${zone3dCount} 3d ${(zone3dCount === 1 ? "zone" : "zones")} was successfully imported.`,
        "You may close this dialog now."
      ]
      setSuccess(successDetails);
    } catch(err) {
      setError(err);
    }
  }, [preview])

  let areaRowItemKey = 0;
  return (
    <div>
      <Dialog
        open={true}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          {`Import areas`}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0}}>
          <FileInput fileChange={onFileChange} accept=".xlsx" />
          <hr/>
          <Alert severity="info" style={{margin: "1rem 0 1rem 0"}}>
            Items in <b>bold</b> are new items that will be generated on import. Additional permissions may be required.
          </Alert>
          {preview && preview.errorCount > 0 && (
            <Alert severity="error" style={{margin: "1rem 0 1rem 0"}}>
              {`This cable list has ${preview.errorCount} ${(preview.errorCount === 1 ? "error" : "errors")}.`}
            </Alert>
          )}
          {
            requireAreaForemanWrite && (
              <Alert severity="warning" style={{margin: "1rem 0 1rem 0"}}>
                {`The ${ProjectClaims.project.areaForeman.write.all} permission is required when importing areas with new area foremen.`}
              </Alert>
            )
          }
          {
            requireMvZoneWrite && (
              <Alert severity="warning" style={{margin: "1rem 0 1rem 0"}}>
                {`The ${ProjectClaims.project.mainVerticalZone.write.all} permission is required when importing areas with new main vertical zones.`}
              </Alert>
            )
          }
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Area ID</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Resp. Foreman</TableCell>
                <TableCell>(new) Foreman text</TableCell>
                <TableCell>Main Vert. Zone</TableCell>
                <TableCell>3D Zone ID</TableCell>
                <TableCell>(new) 3D Zone text</TableCell>
              </TableRow>
            </TableHead>
            <MuiThemeProvider theme={tableTheme}>
              <TableBody>
                <LoadingIndicator isLoading={isValidating} columns={7} useTableCells>
                  {preview
                    ? preview.areas.map(x => <AreaImportRowItem key={areaRowItemKey++} {...x}/>)
                    : null}
                </LoadingIndicator>
              </TableBody>
            </MuiThemeProvider>
          </Table>
        </DialogContent>
        {isFinished
          ? <DialogActionsFinished close={onClose}/>
          : <DialogActionsReady importData={importAreas} importLabel="Import areas" enableImport={isValid && !requireMvZoneWrite && !requireAreaForemanWrite} close={onCancel}/>}
      </Dialog>
    </div>
  );
};
