import React, {CSSProperties} from "react";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import {useClaims} from "../../../shared/hooks/useClaims";

interface VectContainerProps extends AuthProps {
  className?: string;
  style?: CSSProperties;
}

export const VectContainer: React.FC<VectContainerProps> = ({
  children,
  claim,
  claims,
  className,
  style,
  useExactMatch}): JSX.Element => {

  const{isValid} = useClaims();

  return isValid({claim, claims, useExactMatch})
      ? <div className={className} style={style}>{children}</div>
      : <></>;
}
