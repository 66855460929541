import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {AlertContext} from "../../../shared/context/AlertContext";
import {Areaforeman, AreaforemanProps} from "../../../shared/interfaces/areaforeman.interface";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {handleRowChange} from "../../../shared/helpers/table";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import AreaForemanRowItem from "./AreaForemanRowItem";
import {tableTheme} from "../../shared/table/styles";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {compare} from "fast-json-patch";
import {ProjectClaims} from "../../../shared/claims";

const AreaForeman = (): JSX.Element => {
  // State
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<Areaforeman[] | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prevNewId, setPrevNewId] = useState<number>(0);

  // Destructuring
  const {post} = useApi();
  const {setAlert, setSuccess} = useContext(AlertContext);
  const {project} = useContext(ProjectContext);

  useEffect(() => {
    let isSubscribed = true;
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };

      (async () => {
        await post<PaginationRequest, any>(`/project/${project?.id}/areaForeman`,
          paginationQuery,
          {},
          {}).then(result => {
          if (isSubscribed) {
            setInitialValues(result.values);
            dispatch({
              type: "SET_STATE",
              payload: () => ({
                ...result,
                values: result.values.map((x: Areaforeman) => ({
                  ...x,
                  isMarked: false
                }))
              })
            });
          }
        })
      })();
    } finally {
      setIsDirty(false);
      setIsLoading(false);
    }
    return () => {
      isSubscribed = false
    };
  }, [state?.pageSize, state?.currentPage])

  const addNewItem = () => {
    const newId = prevNewId - 1;
    const newItem: Areaforeman = {
      id: newId,
      projectId: project ? project.id : 0,
      code: "",
      foremanText: "",
      name: "",
      isAreaOrSystem: 1,
      isActive: false,
      state: "new",
      hasError: true
    };

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
  }

  const change = useCallback((areaForeman: Areaforeman) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        return handleRowChange(
          prevValues,
          areaForeman,
          setIsDirty);
      }
    });
  }, []);

  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: Areaforeman) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u)
        .filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<AreaforemanProps>>((n: Areaforeman) => ({
        referenceId: n.id,
        props: {
          code: n.code,
          foremanText: n.foremanText,
          name: n.name,
          isAreaOrSystem: n.isAreaOrSystem,
          isActive: n.isActive,
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted")
      .map((x: Areaforeman) => x.id);

    const body = {
      newItems,
      patchedItems,
      deletedItems
    }

    try {
      setIsLoading(true);
      const updateAreasResult = await post<PatchRequest<AreaforemanProps>, PatchResponse<Areaforeman>>(`project/${project!.id}/areaForeman/patch`, body);
      refreshVisibleAreaForemans(updateAreasResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the area foreman list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the area foreman list"});
      }
    } finally {
      setIsLoading(false);
    }
  }

  const copy = useCallback((source: Areaforeman) => {
    const newId = prevNewId - 1;
    setPrevNewId(newId)

    const target: Areaforeman = {
      id: newId,
      projectId: project ? project.id : 0,
      code: source.code,
      foremanText: source.foremanText,
      name: source.name,
      isAreaOrSystem: source.isAreaOrSystem,
      isActive: source.isActive,
      state: "new",
      hasError: source.hasError
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setIsDirty(true);
  }, [prevNewId])

  const refreshVisibleAreaForemans = (updates: PatchResponse<Areaforeman>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: Areaforeman[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  /* Validators */

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"CBS / Areas foreman"}
        save={{
          action: update,
          claim: ProjectClaims.project.areaForeman.write.all,
          disabled: hasErrors || !isDirty
        }}
        add={{
          action: addNewItem,
          claim: ProjectClaims.project.areaForeman.write.all,
        }}/>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CBS ID</TableCell>
            <TableCell>Areas Name</TableCell>
            <TableCell>Areas Foreman</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Active</TableCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map((areaForeman: Areaforeman, index: number) => (
                <AreaForemanRowItem
                  key={index}
                  areaForeman={areaForeman}
                  change={change}
                  copy={copy}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      {/*<Pagination state={state} dispatch={dispatch} isDirty={isDirty}/>*/}
    </>
  )
}
export default AreaForeman;
