import {createStyles, makeStyles, withStyles} from '@material-ui/core/styles';
import {TableRow} from "@material-ui/core";
import {vectLightExtension} from "../../../shared/theme";

// TODO This file is used multiple places. Extract them into one file (helper styles or something)

const filterItemDefault = {
  padding: "1em 1em",
  maxWidth: "20rem",
  width: "100%"
}

export const useStyles = makeStyles(() => ({
  root: {
    border: "none",
  },
  cell: {
    verticalAlign: "top",
    border: "none"
  },
  filterWrapper: {
    maxWidth: "40rem",
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalFilterWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  filterItem: {
    ...filterItemDefault
  },
  filterItem10: {
    ...filterItemDefault,
    maxWidth: "10rem"
  },
  filterItem30: {
    ...filterItemDefault,
    maxWidth: "30rem"
  }
}));

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: vectLightExtension.palette.custom.oddRowColor
      },
    },
  }),
)(TableRow);
