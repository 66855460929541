import React, {FC, useContext, useEffect, useState} from 'react';
import {ProductionWeek} from "../../../shared/interfaces/productionWeek.interface";
import {ChartLine} from "../../../shared/interfaces/common.interface";
import LineChart from "./LineChart";
import {AlertContext} from "../../../shared/context/AlertContext";

interface ViewConnectionsProps {
  productionWeeks: ProductionWeek[]
}

const ViewConnections: FC<ViewConnectionsProps> = ({productionWeeks}) => {
  const [lines, setLines] = useState<ChartLine[]>([])
  const {setAlert} = useContext(AlertContext);

  useEffect(() => {
    const plannedConecions: ChartLine = {
      color: "blue", points: [], name: "P " +
        "Connections"
    }

    const regConnections: ChartLine = {
      color: "pink",
      points: [],
      name: "Reg Connections"
    }


    const start = 0;//project?.plannedCableRoutingStart !== undefined ? parseInt(project?.plannedCableRoutingStart?.replace('-', '')) : 0
    const end = 0;//project?.plannedCableRoutingFinish !== undefined ? parseInt(project?.plannedCableRoutingFinish?.replace('-', '')) : 0
    setAlert({type: "info", text: "This window does not show anything until the routing programme is implemented."});
    productionWeeks.forEach(ele => {
      const codeNoDash = ele.code.replace('-', '');
      const parsedCode = parseInt(codeNoDash)
      if (parsedCode >= start && parsedCode <= end) {
        plannedConecions.points.push({
          x: codeNoDash,
          y: ele.plannedCablesConnected !== undefined ? ele.plannedCablesConnected : 0
        })
        regConnections.points.push({
          x: codeNoDash,
          y: ele.actualCablesConnected !== undefined ? ele.actualCablesConnected : 0
        })

      }
    })
    setLines([
      plannedConecions,
      regConnections
    ])
  }, [])
  return (lines.length > 0 ? <LineChart lines={lines} name={"Connection" +
    " Status"}/> : null
  )
};


export default ViewConnections;
