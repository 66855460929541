import React, {FC} from "react";
import {IBundleRegister} from "../../../shared/interfaces/bundle.interface";
import {TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {Option} from "../../shared/inputs/VectSelect";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {ProjectClaims} from "../../../shared/claims";
import {StyledTableRow} from "../tag/Tags.styles";
import {VectTableRowSelect} from "../../shared/inputs/VectTableRowSelect";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {VectDateTimeField} from "../../shared/inputs/VectDateTimeField";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";
import {VectContainer} from "../../shared/layout/VectContainer";
import {CopyButton} from "../../shared/table/CopyButton";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {getRelevantDp3Option, getRelevantPowerSignalOption, getRelevantProductionStatusOption} from "./options";

type BundleRowItemProps = {
  bundle: IBundleRegister,
  change: (value: IBundleRegister) => void,
  copy: (value: IBundleRegister) => void,
  foremanOptions: Option[] | null,
  systemOptions: Option[] | null,
  prodRespOptions: Option[] | null,
  dp3: Option[] | null,
  powSig: Option[] | null,
  prodStatOps: Option[] | null,
}

const BundleRowItem: FC<BundleRowItemProps> = ({
  bundle,
  change,
  copy,
  foremanOptions,
  systemOptions,
  prodRespOptions,
  dp3,
  powSig,
  prodStatOps
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: bundle.code ? undefined : "A Bundle code is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: IBundleRegister): boolean => {
      return !newState.code;
    }

    handleCellChange(
      change,
      hasError,
      bundle,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...bundle,
      state: itemState,
    })
  };

  const onCopy = () => {
    copy(bundle);
  }

  const style = bundle.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  const cutBackgroundColor = bundle.cut === "Yes"
    ? vectLightExtension.palette.custom.status.allOk
    : bundle.cut === "No" ? vectLightExtension.palette.custom.status.notOkay : "none"

  const pulledBackgroundColor = bundle.pulled === "Yes"
    ? vectLightExtension.palette.custom.status.allOk
    : bundle.pulled === "No" ? vectLightExtension.palette.custom.status.notOkay : "none"

  const productionStatus = getRelevantProductionStatusOption(bundle.productionStatus)?.value;
  const dp3Val = getRelevantDp3Option(bundle.dp3Zone)?.value;
  const powerSignal = getRelevantPowerSignalOption(bundle.bundleType)?.value;

  return (
    <StyledTableRow style={style}>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.routing.external}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={bundle.code}
          change={onCellChange}
          variant="standard"
          required/>
        <VectTableRowSelect
          claim={ProjectClaims.project.routing.external}
          fieldName={"productionStatus"}
          value={productionStatus}
          change={onCellChange}
          options={prodStatOps}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.routing.external}
          fieldName="extendedInformation"
          value={bundle.extendedInformation}
          change={onCellChange}
          variant="standard"
          fullWidth/>
        <VectTextField
          claim={ProjectClaims.project.routing.external}
          fieldName="name"
          value={bundle.name}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.routing.external}
          fieldName={"bundleType"}
          value={powerSignal}
          change={onCellChange}
          options={powSig}/>
        <VectCheckbox
          claim={ProjectClaims.project.routing.external}
          fieldName={"doPrintId"}
          checked={bundle.doPrintId}
          change={onCellChange}
          color="primary"
          size={"small"}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.routing.external}
          fieldName={"systemId"}
          value={bundle.systemId}
          change={onCellChange}
          options={systemOptions}/>
        <VectTableRowSelect
          claim={ProjectClaims.project.routing.external}
          fieldName={"dp3Zone"}
          value={dp3Val}
          change={onCellChange}
          options={dp3}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.production.coordinator}
          fieldName={"areaForemanId"}
          value={bundle.areaForemanId}
          change={onCellChange}
          options={foremanOptions}/>
        <VectTableRowSelect
          claim={ProjectClaims.project.routing.external}
          fieldName={"cableProductionResponsibleId"}
          value={bundle.cableProductionResponsibleId}
          change={onCellChange}
          options={prodRespOptions}/>
      </TableCellInput>
      <TableCellInput padding="none" className={cellClasses.cell}>
        <VectCheckbox
          claim={ProjectClaims.project.routing.external}
          fieldName={"hasSpecialRequirements"}
          checked={bundle.hasSpecialRequirements}
          change={onCellChange}
          color="primary"
          size={"small"}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectDateTimeField
          claim={ProjectClaims.project.routing.coordinator}
          fieldName={"releasedDate"}
          value={bundle.releasedDate || null}
          change={onCellChange}/>
        <VectDateTimeField
          claim={ProjectClaims.project.routing.coordinator}
          fieldName={"printedDate"}
          value={bundle.printedDate || null}
          change={onCellChange}/>
        <VectDateTimeField
          claim={ProjectClaims.project.routing.coordinator}
          fieldName={"labelledDate"}
          value={bundle.labelledDate || null}
          change={onCellChange}/>
      </TableCellInput>
      <TableCellInput
        style={{padding: 0}}
        className={cellClasses.cell}
      >
        <div
          style={{
          backgroundColor: cutBackgroundColor,
          textAlign: "center"
        }}>
          <VectTableReadOnlyText
            value={bundle.cut || ""}
          />
        </div>
        <VectTableReadOnlyText value={""} />
        <VectTableReadOnlyText value={""} />
      </TableCellInput>
      <TableCellInput
        style={{padding: 0}}
        className={cellClasses.cell}
      >
        <div
          style={{
            backgroundColor: pulledBackgroundColor,
            textAlign: "center"
          }}>
          <VectTableReadOnlyText
            value={bundle.pulled || ""}
          />
        </div>
        <VectTableReadOnlyText value={""} />
        <VectTableReadOnlyText value={""} />
      </TableCellInput>

      <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
        <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.routing.external}>
            <CopyButton onClick={onCopy}/>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: "center"}} claim={ProjectClaims.project.routing.coordinator}>
            <DeleteButton change={onDelete} itemState={bundle.state}/>
        </VectContainer>

        {/* Edited mark */}
          <ModifiedIcon itemState={bundle.state}/>
      </div>
      </TableCellInput>
    </StyledTableRow>
  );
}

export default BundleRowItem;
