import {Area} from "./area.interface";
import {SystemListItem} from "./system.interface";

export interface AreaForemanStatusInformation {
  bundleTotals: AreaForemanBundleTotals
  bundleCableTotals: AreaForemanCableTotals,
  systemCableTotals: AreaForemanCableTotals,
  foremanFreePull: number,
  systemFreePull: number,
  areas: Area[],
  systems: SystemListItem[],
  tagTotals: AreaForemanTagTotals
}

export interface AreaForemanBundleTotals {
  bundleTotal: number,
  bundleCutTotal: number,
  bundlePulledTotal: number,
  cutPercent: number,
  pulledPercent: number,
}

export interface AreaForemanCableTotals {
  numberOfCables: number,
  numberOfCablesCut: number,
  numberOfCablesFixed: number,
  cableMeters: number,
  cableMetersCut: number,
  cableMetersFixed: number,
  cableCutPercentage: number,
  cableFixedPercentage: number,
  metersCutPercentage: number,
  metersFixedPercentage: number,
  cablesConnectedTo: number,
  cablesConnectedFrom: number,
  cablesConnectedTotal: number,
  connectedToPercentage: number,
  connectedFromPercentage: number,
  connectedTotalPercentage: number,
}

export interface AreaForemanTagTotals {
  totalTags: number,
  totalTagsForeman: number,
  totalTagsMounted: number,
  totalTagsMountedForeman: number,
  totalPercentage: number,
  mountedPercentage: number,
}

export const initialTotals: AreaForemanStatusInformation = {
  bundleTotals : {
    bundleTotal: 0,
    bundleCutTotal: 0,
    bundlePulledTotal: 0,
    cutPercent: 0,
    pulledPercent: 0,
  },
  bundleCableTotals : {
    numberOfCables: 0,
    numberOfCablesCut: 0,
    numberOfCablesFixed: 0,
    cableMeters: 0,
    cableMetersCut: 0,
    cableMetersFixed: 0,
    cableCutPercentage: 0,
    cableFixedPercentage: 0,
    metersCutPercentage: 0,
    metersFixedPercentage: 0,
    cablesConnectedTo: 0,
    cablesConnectedFrom: 0,
    cablesConnectedTotal: 0,
    connectedToPercentage: 0,
    connectedFromPercentage: 0,
    connectedTotalPercentage: 0,
  },
  systemCableTotals : {
    numberOfCables: 0,
    numberOfCablesCut: 0,
    numberOfCablesFixed: 0,
    cableMeters: 0,
    cableMetersCut: 0,
    cableMetersFixed: 0,
    cableCutPercentage: 0,
    cableFixedPercentage: 0,
    metersCutPercentage: 0,
    metersFixedPercentage: 0,
    cablesConnectedTo: 0,
    cablesConnectedFrom: 0,
    cablesConnectedTotal: 0,
    connectedToPercentage: 0,
    connectedFromPercentage: 0,
    connectedTotalPercentage: 0,
  },
  foremanFreePull: 0,
  systemFreePull: 0,
  areas: [],
  systems: [],
  tagTotals: {
    totalTags: 0,
    totalTagsForeman: 0,
    totalTagsMounted: 0,
    totalTagsMountedForeman: 0,
    totalPercentage: 0,
    mountedPercentage: 0,
  }
}
