import React from "react";
import {Button} from "@material-ui/core";
import {FileCopySharp} from "@material-ui/icons";

export const CopyButton = ({onClick} :{
  onClick: () => void}): JSX.Element => {

  return (
    <Button onClick={onClick}>
      <FileCopySharp />
    </Button>
  )
}
