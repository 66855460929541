import {createMuiTheme, createStyles, makeStyles, TableCell, TableRow, Theme, withStyles} from "@material-ui/core";
import {vectLightExtension} from "../../../shared/theme";

export const tableTheme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    fontSize: 12
  }
});

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: vectLightExtension.palette.custom.oddRowColor
      },
    },
  }),
)(TableRow);

export const TableCellAutoComplete = withStyles(() =>
  createStyles({
    root: {
      padding: "5px"
    },
  }),
)(TableCell);

export const TableCellHeader = withStyles(() =>
  createStyles({
    root: {
      padding: "5px"
    },
  }),
)(TableCell);

export const TableCellInput = withStyles(() =>
  createStyles({
    root: {
        padding: "8px"
    },
  }),
)(TableCell);


export const StyledFooterRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.action.hover,
      '&:first-of-type': {
        borderTop: `2px solid ${theme.palette.divider}`
      },
    },
  }),
)(TableRow);

export const tableCellStyles = makeStyles(() =>
  createStyles({
    root: {
      border: "none"
    },
    cell: {
      verticalAlign: "top",
      border: "none",
    }
  }),
);

export const tableStyles = makeStyles(() => createStyles({
  tableRowWrapper: {
    borderBottom: "1px solid rgba(81, 81, 81, 1)",
    display: "flex",
    flexDirection: "row"
  },
  tableHeaderColumnWrapper: {
    display: "flex",
    flexDirection: "column",
    "& th": {
      borderBottom: "none"
    },
    "& .MuiTableCell-head": {
      padding: "0 16px"
    }
  },
  tableHeaderColumnWrapperWithSpace: {
    display: "flex",
    flexDirection: "column",
    "& th": {
      borderBottom: "none"
    },
    "& .MuiTableCell-head": {
      padding: "0 16px"
    },
    justifyContent: "space-between"
  }
}));

// TODO Might want to move this part to another file in another location (more of a utility set of styles)
export const generalStyles = makeStyles(() => createStyles({
  xFlex: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  yFlex: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  yFlexWSpace: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  spaceRight: {
    marginRight: 6
  },
  filterWrapper: {
    maxWidth: 500,
    display: "flex",
    flexWrap: "wrap",
  },
  medFilterWrapper: {
    maxWidth: 700,
    display: "flex",
    flexWrap: "wrap"
  },
  largeFilterWrapper: {
    maxWidth: 1000,
    display: "flex",
    flexWrap: "wrap",
  },
  filterItem: {
    padding: "1em 1em",
    maxWidth: 200,
    width: "100%"
  },
  largeFilterItem: {
    maxWidth: 700,
    width: "100%",
    padding: "1em"
  },
  medFilterItem: {
    maxWidth: 400,
    width: "100%",
    padding: "1em"
  },
  checkboxFilterItem: {
    alignSelf: "center",
    padding: "1em 1em",
    maxWidth: 150,
    width: "100%",
    textAlign: "center",
    paddingRight: 25,
  },
  vCenteredfilterBtn: {
    maxWidth: 150,
    width: "100%",
    display: "flex",
    "& button": {
      alignSelf: "center"
    }
  },
  vCenteredFilterItem: {
    padding: "1em 1em",
    maxWidth: 200,
    width: "100%",
    alignSelf: "center"
  }
}))
