import React, {FC, useMemo} from "react";
import {Button, Checkbox, InputAdornment, InputLabel,} from "@material-ui/core";
import {BatchUpdateCableProps} from "../../../shared/interfaces/cable.interface";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {CableType} from "../../../shared/interfaces/cableType.interface";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {getYearWeekFormat, validateYearWeekFormat} from "../../../shared/helpers/date";
import {ProjectClaims} from "../../../shared/claims";
import {VectContainer} from "../../shared/layout/VectContainer";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {TodaySharp} from "@material-ui/icons";
import {dp3Options} from "../bundle-register/options";
import {footerStyles} from "../../../shared/styles/footerStyles";
import {mapAreaForemanOptions, mapCableTypeOptions} from "../../../shared/helpers/metadata";

interface BatchUpdateBundleAdminProps {
  areDependenciesLoaded: boolean;
  hasErrors: boolean;
  batchUpdateCables: BatchUpdateCableProps;
  cableClassOptions: Option[] | null;
  cableTypes: CableType[] | null;
  areaForemen: Areaforeman[] | null;
  change: (value: boolean | string | number | undefined | null, field?: string) => void;
  selectAllVisibleCables: () => void;
  updateSelectedBatch: () => void;
  updateAllBatch: () => void;
}

const BatchUpdateBundleAdmin: FC<BatchUpdateBundleAdminProps> = ({
  areDependenciesLoaded,
  hasErrors,
  batchUpdateCables,
  cableClassOptions,
  cableTypes,
  areaForemen,
  change,
  selectAllVisibleCables,
  updateSelectedBatch,
  updateAllBatch
 }) => {
  const classNames = footerStyles();
  const areaForemanOptions = useMemo(() => mapAreaForemanOptions(areaForemen), [areaForemen]);
  const cableTypeOptions = useMemo(() => mapCableTypeOptions(cableTypes), [cableTypes]);

  const errors = {
    cutWeek: validateYearWeekFormat(batchUpdateCables.cutWeek).message,
    pulledWeek: validateYearWeekFormat(batchUpdateCables.pulledWeek).message
  };

  const setCutWeek = () => {
    change(getYearWeekFormat(new Date()), "cutWeek");
  }

  const setPulledWeek = () => {
    change(getYearWeekFormat(new Date()), "pulledWeek");
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <VectContainer claim={ProjectClaims.project.engineering.external} className={classNames.controls}>
          <VectSelect options={cableTypeOptions}
                      value={batchUpdateCables.cableTypeId}
                      fieldName="cableTypeId"
                      change={change}
                      label="Cable Type"
                      placeholder={"Cable Type"}
                      isClearable
          />
          <VectSelect options={cableClassOptions}
                      value={batchUpdateCables.cableClassId}
                      fieldName="cableClassId"
                      change={change}
                      label="Cable Class"
                      placeholder={"Cable Class"}
                      isClearable
          />
          <VectSelect options={dp3Options}
                      value={dp3Options.find(x => x.label === batchUpdateCables.dp3Zone)?.value}
                      change={val => {
                        const label = val == null
                          ? null
                          : dp3Options.find(x => x.value === val)!.label;
                        change(label, "dp3Zone")
                      }}
                      label="DP3 Zone"
                      placeholder={"DP3 Zone"}
                      isClearable
          />
        </VectContainer>
        <VectContainer claim={ProjectClaims.project.production.coordinator} className={classNames.controls}>
          <VectSelect options={areaForemanOptions}
                      value={batchUpdateCables.fromConnectionAreaForemanId}
                      fieldName="fromConnectionAreaForemanId"
                      change={change}
                      label="Conn. Resp. From"
                      placeholder={"Conn. Resp. From"}
                      isClearable
          />
          <VectSelect options={areaForemanOptions}
                      value={batchUpdateCables.toConnectionAreaForemanId}
                      fieldName="toConnectionAreaForemanId"
                      change={change}
                      label="Conn. Resp. To"
                      placeholder={"Conn. Resp. To"}
                      isClearable
          />
        </VectContainer>
        <VectContainer claim={ProjectClaims.project.production.external} className={classNames.controls}>
          <VectCheckbox color="primary"
                        id={"connectedFrom"}
                        checked={batchUpdateCables.connectedFrom || false}
                        fieldName="connectedFrom"
                        label="Connected From"
                        change={change}/>
          <VectCheckbox color="primary"
                        id={"connectedTo"}
                        checked={batchUpdateCables.switchFromTo || false}
                        fieldName="switchFromTo"
                        label="Switch From and To"
                        change={change}/>
          <VectCheckbox color="primary"
                        id={"connectedTo"}
                        checked={batchUpdateCables.connectedTo || false}
                        fieldName="connectedTo"
                        label="Connected To"
                        change={change}/>
          <VectTextField value={batchUpdateCables.cutWeek || ""}
                         fieldName="cutWeek"
                         change={change}
                         label="Cut week"
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end" onClick={setCutWeek}>
                               <TodaySharp/>
                             </InputAdornment>
                           )
                         }}
                         error={!!errors.cutWeek}
                         helperText={errors.cutWeek}
          />
          <VectTextField value={batchUpdateCables.pulledWeek || ""}
                         fieldName="pulledWeek"
                         change={change}
                         label="Pulled week"
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end" onClick={() => {
                               if (batchUpdateCables.cutWeek === undefined || batchUpdateCables.cutWeek === null) {
                                 setCutWeek();
                               }
                               setPulledWeek();
                             }}>
                               <TodaySharp/>
                             </InputAdornment>
                           )
                         }}
                         error={!!errors.pulledWeek}
                         helperText={errors.pulledWeek}/>
        </VectContainer>
        <VectContainer claim={ProjectClaims.project.routing.external} className={classNames.controls}>
          <VectCheckbox color="primary"
                        id={"convertEstimated"}
                        checked={batchUpdateCables.convertEstimated || false}
                        fieldName="convertEstimated"
                        label="Conv. est. m. to actual  meter"
                        change={change}/>
          <VectTextField id={"meters"}
                         type={"number"}
                         value={batchUpdateCables.meters || ""}
                         fieldName="meters"
                         change={change}
                         label="Meters"/>
          <VectTextField id={"markPlacement"}
                         type={"text"}
                         value={batchUpdateCables.markPlacement || ""}
                         fieldName="markPlacement"
                         label="Mark placement"
                         change={change}/>
          <VectTextField id={"penetrations"}
                         type={"text"}
                         value={batchUpdateCables.penetrations || ""}
                         fieldName="penetrations"
                         label="Penetrations"
                         change={change}/>

        </VectContainer>
        <VectContainer claim="TODO" className={classNames.controls}>
          <div>
            <InputLabel id={"srtpBatch"}>SRtP Cables</InputLabel>
            <Checkbox color="primary"
                      id={"srtpBatch"}
                      checked={batchUpdateCables.hasSpecialRequirements}
                      onChange={(event => change(event.target.checked, "hasSpecialRequirements"))}/>
          </div>
        </VectContainer>
      </div>
      <div className={classNames.container}>
        <div className={classNames.controls}>
          <Button onClick={selectAllVisibleCables} color={"default"} variant="contained" >Select all visible cables</Button>
          <Button onClick={updateSelectedBatch} color={"primary"} variant="contained" disabled={!areDependenciesLoaded || hasErrors}>Update selected cables</Button>
          <Button onClick={updateAllBatch} color={"primary"} variant="contained" disabled={!areDependenciesLoaded || hasErrors}> Update all cables in the bundle</Button>
        </div>
      </div>
    </div>
  );
}

export default BatchUpdateBundleAdmin;
