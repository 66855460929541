import {TableItemState} from "../interfaces/common.interface";

interface ColumnDefinition {
  Header: string
  accessor: string
  width?: string
}

interface ColumnProps {
  header?: string
  rem?: number
}

interface RowData {
  id: number;
  hasError: boolean,
  state?: TableItemState
}

export const createColumn = (accessor: string, columnProps?: ColumnProps) : ColumnDefinition => {
  return {
    Header: columnProps?.header || "",
    accessor: accessor,
    width: columnProps?.rem ? `${columnProps.rem}rem` : undefined
  }
};

/**
 * Handle state changes in cells
 * @param change change handler for the row. An optional prevState argument can be used to handle before-change events.
 * @param hasError indicator if row has an error
 * @param prevState the changed row
 * @param value value of saved property
 * @param fieldName name of changed property
 */
export const handleCellChange = <T extends RowData>(
  change: (newState: T, prevState: T) => void,
  hasError: (newState: T, prevState: T) => boolean,
  prevState: T,
  value: unknown,
  fieldName?: string
): void => {

  const state = prevState.state === "deleted" && fieldName !== "state"
    ? "deleted"
    : (prevState.state !== "new"
      ? (fieldName !== "isMarked"
          ? "modified"
          : prevState.state
      )
      : prevState.state)

  if(fieldName != null) {
    const newState: T =  {
      ...prevState,
      state,
      [fieldName]: value
    };
    newState.hasError = hasError(newState, prevState);
    change(newState, prevState);
  }
};

/**
 * Handle state changes for rows.
 * @param values values in current state
 * @param rowData the changed row
 * @param setIsDirty function to set the form as dirty
 * @param sideEffects handle side effects in the changed row
 */
export const handleRowChange = <T extends RowData>(
  values: T[] | null,
  rowData: T,
  setIsDirty: (isDirty: boolean) => void,
  sideEffects?: (prevRowData: T, currentRowData: T) => void
): T[] => {

  const prevValues = values || [];

  const prevItemIndex = prevValues.findIndex(x => x.id === rowData.id);
  if (prevItemIndex < 0) {
    return prevValues;
  }

  if(sideEffects) {
    const prevRowData = prevValues[prevItemIndex];
    sideEffects(prevRowData, rowData);
  }

  if (rowData.state != null && ["modified", "deleted", "new"].includes(rowData.state)) {
    setIsDirty(true);
  }

  const newValues = [...prevValues];
  newValues.splice(prevItemIndex, 1, rowData);
  return newValues;
  }

  /**
  * Common table properties on the client that should be excluded from operation mapping
  */
  export const operationFilter = ["/isMarked", "/state", "/hasError"];

