import {AuthContext} from "../context/AuthContext";
import {useContext} from "react";
import {ConfigContext} from "../context/ConfigContext";
import FileSaver from "file-saver";
import {AlertContext} from "../context/AlertContext";
import {Operation} from "fast-json-patch";

interface ProblemDetail {
  type: string;
  title: string;
  status: number;
  detail?: string
  instance?: string;
  /**
   * errors are populated BadRequest responses
   */
  errors?: ProblemDetailErrorsExtension
}

interface ProblemDetailErrorsExtension {
  [x: string]: string[]
}

export class ProblemDetailError extends Error implements ProblemDetail{
  type: string;
  title: string;
  status: number;
  detail?: string;
  instance?: string;
  errors?: ProblemDetailErrorsExtension;

  constructor(problemDetail: ProblemDetail) {
    super(problemDetail.title); // 'Error' breaks prototype chain here
    this.name = 'ProblemDetailError';
    this.type = problemDetail.type;
    this.title = problemDetail.title;
    this.status = problemDetail.status;
    this.detail = problemDetail.detail;
    this.instance = problemDetail.instance;
    this.errors = problemDetail.errors;
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

interface GetOptions {
  headers?: any
  queryParams?: QueryParams
}

export interface QueryParams {
  [x: string]: string | boolean | number | Date | null | undefined
}
interface ApiOperations {
  get: <T>(route: string, options?: (GetOptions | undefined)) => Promise<T>;
  patch: <TResponse>(route: string, body: Operation[], optionalHeaders?: any) => Promise<TResponse>;
  post: <TBody, TResponse extends unknown>(route: string, body: TBody, optionalHeaders?: any, queryParams?: (QueryParams | undefined)) => Promise<TResponse>;
  postFormData: <TResponse extends unknown>(route: string, body: FormData, optionalHeaders?: any, queryParams?: (QueryParams | undefined)) => Promise<TResponse>;
  put: <T>(route: string, body: T, optionalHeaders?: any) => Promise<any>;
  _delete: <T>(route: string, optionalHeaders?: any) => Promise<T>;
  deleteMethod: (route: string, id: string | number) => Promise<any>;
  fileDownload: (filename: string, route: string, options?: (GetOptions | undefined)) => Promise<void>;
}


export const useApi = (): ApiOperations => {

  const {getAccessToken} = useContext(AuthContext);
  const {api, scopes} = useContext(ConfigContext)
  const {setAlert} = useContext(AlertContext)

  const handleErrors = async (res: Response) => {
    const json = async () => {
      try{
        return await res.json();
      }catch(error) {
        return null;
      }
    }

    if (!res.ok) {
      const errorObject = await json();
      if(errorObject && errorObject.type && errorObject.title && errorObject.status) {
        throw new ProblemDetailError(errorObject);
      } else {
        throw new Error(JSON.stringify(res));
      }
    }
    return await json();
  }

  const getUrl = (route: string, queryParams?: QueryParams): string => {

    const baseurl = api.hostname + (route.startsWith("/") ? route : "/".concat(route));
    if(queryParams != null) {
      const queryString = Object.keys(queryParams)
        .filter(x => x && queryParams[x] != null)
        .map(x => {
          const val = queryParams[x];
          return val != null
            ? `${x}=${encodeURIComponent(val instanceof Date ? val.toISOString() : val.toString())}`
            : null;
        })
        .join("&");
      return queryString.length > 0
        ? `${baseurl}?${queryString}`
        : baseurl;
    } else {
      return baseurl;
    }


  }

  const getHeaders = (accessToken?: string, optionalHeaders = {}) => {
    return {
      ...optionalHeaders,
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }
  }

  const get = async<T> (route: string, options?: GetOptions) : Promise<T> => {
    const url = getUrl(route, options?.queryParams)
    const token = await getAccessToken(scopes)

    return await fetch(url, { headers: getHeaders(token, options?.headers)})
      .then(handleErrors)
      .then((data : T) => { return data;})
      .catch(error  => {
        console.error(error);
        throw  error;
      });
  }

  const patch = async <TResponse>(route: string, body: Operation[], optionalHeaders = {}): Promise<TResponse> => {
    const url = getUrl(route)
    const token = await getAccessToken(scopes)
    const postParams = {
      method: 'PATCH',
      headers: getHeaders(token, {
        ...optionalHeaders,
        'Content-Type': 'application/json-patch+json'
      }),
      body: JSON.stringify(body)
    }
    return await fetch(url, postParams)
      .then(handleErrors)
      .then((data: TResponse) => {
        return data;
      }).catch(error => {
        console.error(error)
        throw error;
      })
  }

  const post = async <TBody, TResponse>(route: string, body: TBody, optionalHeaders = {}, queryParams?: QueryParams): Promise<TResponse> => {
    const url = getUrl(route, queryParams)
    const token = await getAccessToken(scopes)
    const postParams = {
      method: 'POST',
      headers: getHeaders(token, {
        ...optionalHeaders,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(body)
    }
    return await fetch(url, postParams)
      .then(handleErrors)
      .then((data: TResponse) => {
        return data;
      }).catch(error => {
        console.error(error)
        throw error;
      })
  }

  const postFormData = async <TResponse>(route: string, body: FormData, optionalHeaders = {}, queryParams?: QueryParams): Promise<TResponse> => {
    const url = getUrl(route, queryParams)
    const token = await getAccessToken(scopes)
    const postParams = {
      method: 'POST',
      headers: getHeaders(token, {
        ...optionalHeaders,
      }),
      body: body
    }
    return await fetch(url, postParams)
      .then(handleErrors)
      .then((data: TResponse) => {
        return data;
      }).catch(error => {
        console.error(error)
        throw error;
      })
  }


  const put = async <T>(route: string, body: T, optionalHeaders = {}) => {
    const url = getUrl(route)
    const token = await getAccessToken(scopes)
    const postParams = {
      method: 'PUT',
      headers: getHeaders(token, {
        ...optionalHeaders,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(body)
    }
    return await fetch(url, postParams)
      .then(handleErrors)
      .then(data => {
        return data;
      }).catch(error => {
        console.error(error);
        throw error;
      })
  }

  const _delete = async<T> (route: string, optionalHeaders = {}) : Promise<T> => {
    const url = getUrl(route)
    const token = await getAccessToken(scopes)
    const deleteParams = {
      method: 'DELETE',
      headers: getHeaders(token, {
        ...optionalHeaders,
        'Content-Type': 'application/json'
      })
    }
    return await fetch(url, deleteParams)
      .then(handleErrors)
      .then(data => {
        return data;
      }).catch(error => {
        console.error(error);
        throw error;
      })
  }

  /**
   * @deprecated Use _delete
   */
  const deleteMethod = async (route: string, id: string | number) => {
    const token = await getAccessToken(scopes)
    const deleteParams = {
      method: "DELETE",
      headers: getHeaders(token)
    };
    const url = getUrl(route)
    return await fetch(url + `/${id}`, deleteParams).then(handleErrors).catch(err => {
      setAlert({type: "error", text: err.toString()})
    })
  }
  const fileDownload = async (filename: string, route: string, options?: GetOptions) => {
    const token = await getAccessToken(scopes)
    const url = getUrl(route, options?.queryParams)
    return await fetch(
      url,
      {
        headers: {"Authorization": `Bearer ${token}`}
      })
      .then(async(res) => {
        if (!res.ok) {
             const json = await res.json();
             throw new Error(json.detail)

        } else {
          return res;
        }
      })
      .then(response => {
        if (response !== undefined) {
         return response.blob()
        }
      })
      .then(blob => {
        if(blob !== undefined) {
          FileSaver.saveAs(blob, filename);
        }
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }
  return {
    get,
    patch,
    post,
    postFormData,
    put,
    _delete,
    deleteMethod,
    fileDownload
  }
}
