import React, {FC, useCallback, useContext, useEffect, useReducer, useState} from "react";
import {CableClass, CableClassProps} from "../../../../shared/interfaces/cableClass.interface";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import {ProblemDetailError, useApi} from "../../../../shared/hooks/useApi";
import {simpleReducer} from "../../../../shared/reducers/simpleReducer";
import {AlertContext} from "../../../../shared/context/AlertContext";
import {VectUnsavedChangesPrompt} from "../../../shared/navigation/VectUnsavedChangesPrompt";
import {LoadingIndicator} from "../../../shared/table/LoadingIndicator";
import {tableTheme} from "../../../shared/table/styles";
import {compare} from "fast-json-patch";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../../shared/interfaces/patchRequest";
import {handleRowChange} from "../../../../shared/helpers/table";
import CableClassRowItem from "./CableClassRowItem";
import {GlobalClaims} from "../../../../shared/claims";

const CableClasses: FC = () => {
  // State
  const [state, dispatch] = useReducer(simpleReducer, {values: []});
  const [initialValues, setInitialValues] = useState<CableClass[] | null>(null);
  // Management
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prevNewId, setPrevNewId] = useState<number>(0)

  const {get, post} = useApi();
  const {setAlert, setSuccess} = useContext(AlertContext);

  useEffect(() => {
    let isSubscribed = true;
    try {
      setIsLoading(true);
      (async () => {
        const result = await get<CableClass[]>(`/cableClass`).then(res => res)
        if (isSubscribed) {
          setInitialValues(result);
          dispatch({
            type: "SET_STATE",
            payload: () => ({
              ...result,
              values: result.map((x: CableClass) => ({
                ...x,
                isMarked: false
              }))
            })
          });
        }
      })();
    } finally {
      setIsLoading(false);
      setIsDirty(false);
    }
    return () => {
      isSubscribed = false
    };
  }, []);

  const addNewItem = () => {
    const newId = prevNewId - 1;
    const newItem: CableClass = {
      id: newId,
      code: "",
      name: "",
      state: "new",
      hasError: true
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
      })
    });
    setPrevNewId(newId)
  }

  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: CableClass) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u).filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<CableClassProps>>((n: CableClass) => ({
        referenceId: n.id,
        props: {
          code: n.code,
          name: n.name
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted" && x.id > 0)
      .map((x: CableClass) => x.id);

    const body = {
      newItems,
      patchedItems,
      deletedItems
    };

    try {
      setIsLoading(true);
      const updateCableClassResult = await post<PatchRequest<CableClassProps>, PatchResponse<CableClass>>
      (`cableClass/patch`, body);
      refreshVisibleCableClasses(updateCableClassResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the cable class list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the cable class list"});
      }
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const change = useCallback((cableClass: CableClass) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        return handleRowChange(
          prevValues,
          cableClass,
          setIsDirty);
      }
    });
  }, []);

  const copy = useCallback((source: CableClass) => {
    const newId = prevNewId - 1;
    setPrevNewId(newId)

    const target: CableClass = {
      id: newId,
      code: "",
      name: source.name,
      state: "new",
      hasError: source.hasError
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
      })
    });
    setIsDirty(true);
  }, [prevNewId])

  const refreshVisibleCableClasses = (updates: PatchResponse<CableClass>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: CableClass[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  return (
    <div>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Cable class"}
        save={{
          action: update,
          disabled: hasErrors || !isDirty,
          claim: GlobalClaims.manage.baseline.readWrite.all
        }}
        add={{
          action: addNewItem,
          claim: GlobalClaims.manage.baseline.readWrite.all
        }}/>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map(cableClass => {
                return (
                  <CableClassRowItem
                    key={cableClass.id}
                    cableClass={cableClass}
                    change={change}
                    copy={copy}
                  />
                );
              })}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </div>
  )
}
export default CableClasses;
