import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import SystemGroups from "./SystemGroup/SystemGroup";
import MainPanel from "../../shared/layout/MainPanel/MainPanel";
import CableTypes from "./cable-types/CableTypes";
import CableClasses from "./cable-class/CableClass";
import VectAppBar, {ApplicationLink} from "../../shared/layout/VectAppBar";
import {GlobalClaims} from "../../../shared/claims";

interface ManageBaselineHomeProps {
  navLinks: ApplicationLink[]
}

const ManageBaselineHome: React.FC<ManageBaselineHomeProps> = ({navLinks}): JSX.Element => {

  const {path, url} = useRouteMatch();
  const sideBarItems: ApplicationLink[] = [
    {claim: GlobalClaims.manage.baseline.readWrite.all, name: "System Groups", route: `${url}/system-groups`},
    {claim: GlobalClaims.manage.baseline.readWrite.all, name: "Cable Types", route: `${url}/cable-types`},
    {claim: GlobalClaims.manage.baseline.readWrite.all, name: "Cable Class", route: `${url}/cable-class`}
  ];

  return (
    <div>
      <VectAppBar header="Manage baseline" sideBarLinks={sideBarItems} navLinks={navLinks}>
        <MainPanel>
          <Switch>
            <Route path={`${path}/system-groups`} component={SystemGroups}/>
            <Route path={`${path}/cable-types`}>
              <CableTypes />
            </Route>
            <Route path={`${path}/cable-class`}>
              <CableClasses/>
            </Route>
            <Route path={path}>
              <Redirect to={`${path}/system-groups`}/>
            </Route>
          </Switch>
        </MainPanel>
      </VectAppBar>
    </div>
  );
}

export default ManageBaselineHome;
