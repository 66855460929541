import React, {useState} from "react";
import {getShowDrawer, setShowDrawer as setShowDrawerLocalStorage} from "../helpers/localStorage";

interface AppBarContext {
  showDrawer: boolean;
  toggleDrawer: (isVisible: boolean) => void
}

export const AppBarContext = React.createContext<AppBarContext>({
  showDrawer: false,
  toggleDrawer: () => {
    return {}
  }
});

const AppBarProvider: React.FC = ({children}) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(getShowDrawer());

  const toggleDrawer = (isVisible: boolean) => {
    setShowDrawer(isVisible);
    setShowDrawerLocalStorage(isVisible);
  }

  return (
    <AppBarContext.Provider value={{showDrawer, toggleDrawer}}>
      {children}
    </AppBarContext.Provider>
  )
}

export default AppBarProvider;
