export interface SystemStatusInformation {
  bundleTotal: SystemBundleTotal,
  cableTotal: SystemCableTotal,
  tagTotal: SystemTagTotal,
  systemFreePull: number
}

export interface SystemBundleTotal {
  bundleTotal: number,
  bundleCutTotal: number,
  bundlePulledTotal: number,
  cutPercent: number,
  fixedPercent: number,
}

export interface SystemCableTotal {
  cableMeters: number,
  cableMetersCut: number,
  cableMetersFixed: number,
  metersCutPercentage: number,
  metersFixedPercentage: number,

  numberOfCables: number,
  numberOfCablesCut: number,
  numberOfCablesFixed: number,
  cableCutPercentage: number,
  cableFixedPercentage: number,

  cablesConnectedTo: number,
  cablesConnectedFrom: number,
  cablesConnectedTotal: number,
  connectedToPercentage: number,
  connectedFromPercentage: number,
  connectedTotalPercentage: number,
}

export interface SystemTagTotal {
  totalTags: number,
  totalTagsSystem: number,
  totalTagsMounted: number,
  totalTagsMountedSystem: number,
  totalPercentage: number,
  mountedPercentage: number,
}

export const initialTotals: SystemStatusInformation = {
  bundleTotal : {
    bundleTotal: 0,
    bundleCutTotal: 0,
    bundlePulledTotal: 0,
    cutPercent: 0.00,
    fixedPercent: 0.00,
  },
  cableTotal: {
    numberOfCables: 0,
    numberOfCablesCut: 0,
    numberOfCablesFixed: 0,
    cableMeters: 0,
    cableMetersCut: 0,
    cableMetersFixed: 0,
    cableCutPercentage: 0.00,
    cableFixedPercentage: 0.00,
    metersCutPercentage: 0.00,
    metersFixedPercentage: 0.00,
    cablesConnectedTo: 0,
    cablesConnectedFrom: 0,
    cablesConnectedTotal: 0,
    connectedToPercentage: 0.00,
    connectedFromPercentage: 0.00,
    connectedTotalPercentage: 0.00,
  },
  tagTotal: {
    totalTags: 0,
    totalTagsSystem: 0,
    totalTagsMounted: 0,
    totalTagsMountedSystem: 0,
    totalPercentage: 0.00,
    mountedPercentage: 0.00,
  },
  systemFreePull: 0
}
