import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {Cancel, DeleteForeverSharp} from "@material-ui/icons";
import {DeleteConfirmationModal} from "../../shared/Feedback/DeleteConfirmationModal";
import {GlobalRole, GrantResult} from "../../../shared/interfaces/user.interface";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {RoleForm, RoleFormFields} from "./RoleForm";
import {Typography} from "@material-ui/core";
import {useApi} from "../../../shared/hooks/useApi";
import {useHistory, useParams} from "react-router-dom";
import {useModals} from "../../../shared/context/ModalContext";

interface RouteParams {
  roleId?: string
}

const EditRole: FC = () => {
  const [assignedGrants, setAssignedGrants] = useState<string[]>([]);
  const [availableGrants, setAvailableGrants] = useState<GrantResult[]>([]);
  const [fields, setFields] = useState<RoleFormFields | null>(null);
  const [globalRoles, setGlobalRoles] = useState<Option[]>([])
  const [roleName, setRoleName] = useState<string | null>(null);
  const [showDeleteRole, setShowDeleteRow] = useState(false);
  const [transferRoleId, setTransferRoleId] = useState<number | null>(null);
  const history = useHistory();
  const {get, put, post} = useApi()
  const {openModal, closeModal} = useModals();
  const {roleId} = useParams<RouteParams>();
  const {setAlert, setError} = useContext(AlertContext);

  useEffect(() => {
    let isSubScribed = true

    get<GrantResult[]>("roles/global-grants")
      .then(res => {
        if (isSubScribed) {
          setAvailableGrants(res);
          get<GlobalRole>(`roles/global/${roleId}`).then(
            (resRole) => {
              if (isSubScribed) {
                setAssignedGrants(resRole.claims);
                setRoleName(resRole.roleName);
              }
            }
          );
        }
      });

    get<GlobalRole[]>("roles/global")
      .then(res => {
        if (isSubScribed) {
          setGlobalRoles(res
            .filter(role => ""+role.id !== roleId)
            .map(role => ({value: role.id, label: role.roleName})));
        }
      });

    return () => {
      isSubScribed = false
    };
  }, []);

  const onChange = (fields: RoleFormFields) => {
    setFields(fields);
  }

  const showDeleteDialog = () => {
    roleId != null && transferRoleId != null &&  openModal && openModal(DeleteConfirmationModal, {
      description: "Users will be transferred to the new group. Permissions from the deleted group will not follow the user.",
      onDelete: async () => {

        try {
          await post(`roles/global/${roleId}/delete`, {transferToRoleId: transferRoleId});
          history.goBack()
        } catch(error) {
          setError(error);
        } finally {
          if (closeModal) {
            closeModal();
          }
        }
      }
    });
  }

  const showDeleteRoleOption = () => {
    setShowDeleteRow(!showDeleteRole);
  }

  const submit = async () => {
    await put(`roles/global/${roleId}`, fields)
      .then(() => {
        setAlert({
          type: "info",
          text: `${fields?.roleName} is updated.`
        })
      })
      .catch(setError)

  }

  const form = useMemo(() => <RoleForm
      availableGrants={availableGrants}
      initialFields={{
        roleName,
        grants: assignedGrants
      }}
      onChange={onChange}/>,
    [availableGrants, assignedGrants, roleName]);

  const deleteForm = (<div>
    <Typography variant="body1">Choose a group to transfer existing users to:</Typography>
    <div style={{width: "20rem"}}>
      <VectSelect change={setTransferRoleId}
                  value={transferRoleId}
                  placeholder="New role"
                  options={globalRoles}/>
    </div>

  </div>);

  const secondaryActions = showDeleteRole
   ? [{
      label: "Confirm",
      icon: <DeleteForeverSharp/>,
      action: showDeleteDialog,
      disabled: transferRoleId == null
    },
    {
      label: "Cancel",
      icon: <Cancel/>,
      action: showDeleteRoleOption
    }]
    :[{
      label: "Delete role",
      icon: <DeleteForeverSharp/>,
      action: showDeleteRoleOption
    }];


  return (
    <div>
      <PanelHeader text="Edit global role"
                   save={{
                    action: submit,
                    disabled: !(fields && fields.roleName)
                    }}
                    secondaryActions={secondaryActions}/>
      {showDeleteRole ? deleteForm : form}
    </div>
  );
}




export default EditRole;
