import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {DeleteConfirmationModal} from "../../shared/Feedback/DeleteConfirmationModal";
import {DeleteForeverSharp} from "@material-ui/icons";
import {GlobalRole, GrantResult} from "../../../shared/interfaces/user.interface";
import {RoleForm, RoleFormFields} from "./RoleForm";
import {useApi} from "../../../shared/hooks/useApi";
import {useHistory, useParams} from "react-router-dom";
import {useModals} from "../../../shared/context/ModalContext";

interface RouteParams {
  roleId?: string
}

const EditProjectRole: FC = () => {
  const [assignedGrants, setAssignedGrants] = useState<string[]>([]);
  const [availableGrants, setAvailableGrants] = useState<GrantResult[]>([]);
  const [fields, setFields] = useState<RoleFormFields | null>(null);
  const [roleName, setRoleName] = useState<string | null>(null);
  const history = useHistory();
  const {get, put, _delete} = useApi()
  const {openModal, closeModal} = useModals();
  const {roleId} = useParams<RouteParams>();
  const {setAlert, setError} = useContext(AlertContext);

  useEffect(() => {
    let isSubScribed = true

    get<GrantResult[]>("roles/project-grants")
      .then(res => {
        if (isSubScribed) {
          setAvailableGrants(res);
          get<GlobalRole>(`roles/project/${roleId}`).then(
            (resRole) => {
              if (isSubScribed) {
                setAssignedGrants(resRole.claims);
                setRoleName(resRole.roleName);
              }
            }
          );
        }
      });
    return () => {
      isSubScribed = false
    };
  }, []);

  const onChange = (fields: RoleFormFields) => {
    setFields(fields);
  }

  const submit = async () => {
    await put(`roles/project/${roleId}`, fields)
      .then(() => {
        setAlert({
          type: "info",
          text: `${fields?.roleName} is updated.`
        })
      })
      .catch(setError)

  }

  const form = useMemo(() => <RoleForm
      availableGrants={availableGrants}
      initialFields={{
        roleName,
        grants: assignedGrants
      }}
      onChange={onChange}/>,
    [availableGrants, assignedGrants, roleName]);

  const showDeleteDialog = () => {
    roleId != null && openModal && openModal(DeleteConfirmationModal, {
      context: "Users may loose access to required parts of the system.",
      onDelete: async () => {

        try {
          await _delete(`roles/project/${roleId}`);
          history.goBack()
        } catch(error) {
          setError(error);
        } finally {
          if (closeModal) {
            closeModal();
          }
        }
      }
    });
  }


  const secondaryActions =
    [{
      label: "Delete role",
      icon: <DeleteForeverSharp/>,
      action: showDeleteDialog,
    }];

  return (
    <div>
      <PanelHeader text="Edit project role"
                   save={{
                    action: submit,
                    disabled: !(fields && fields.roleName)
                    }}
                    secondaryActions={secondaryActions}/>
      {form}
    </div>
  );
}

export default EditProjectRole;
