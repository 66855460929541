import React, {FunctionComponent, useCallback, useContext, useEffect, useMemo, useReducer, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@material-ui/core";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import Pagination from "../../shared/table/Pagination";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {useStyles} from "./Tags.styles";
import {Tag, TagProps} from "../../../shared/interfaces/tag.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {System, SystemListItem} from "../../../shared/interfaces/system.interface";
import TagRowItem from "./components/TagRowItem";
import {AlertContext} from "../../../shared/context/AlertContext";
import {Modal} from "../../shared/Feedback/Modal";
import {useModals} from "../../../shared/context/ModalContext";
import {tableTheme} from "../../shared/table/styles";
import {compare} from "fast-json-patch";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {handleRowChange} from "../../../shared/helpers/table";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {ProjectClaims} from "../../../shared/claims";
import {Skeleton} from "@material-ui/lab";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {mapAreaForemanOptions, mapAreaOptions, mapSystemOptions} from "../../../shared/helpers/metadata";
import {useClaims} from "../../../shared/hooks/useClaims";

const areaWarningMessage = "If you change the area of a tag in use, you need to have the Engineering External permissions.";
const areaForemanWarningMessage = "If you change the connection responsible of a tag in use, you need to have the Production Coordinator permissions.";
const systemWarningMessage = "If you change the system of a tag in use, Production Coordinator permissions are required to change the connection responsible of affected cables";

interface PatchTagsRequest extends PatchRequest<TagProps> {
  performCableSideEffectsOnSystem: boolean
}

interface PatchTagsResponse extends PatchResponse<Tag> {
  cablesAffectedBySystemSideEffect?: number;
}


const Tags: FunctionComponent = () => {
  /* State */
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<Tag[] | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Data
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [foremans, setForemans] = useState<Areaforeman[] | null>(null);
  const [mvzs, setMvzs] = useState<MainVerticalZone[] | null>(null);
  const [zone3ds, setZone3ds] = useState<Zone3d[] | null>(null);
  // Filtering
  const [tagId, setTagId] = useState<string | undefined>(undefined);
  const [text, setText] = useState<string | undefined>();
  const [selectedSystem, setSelectedSystem] = useState<SystemListItem | null>(null);
  const [selectedAreaForeman, setSelectedAreaForeman] = useState<Areaforeman | null>(null);
  // Management
  const [prevNewId, setPrevNewId] = useState<number>(0);
  const [canAddNewItem, setCanAddNewItem] = useState(false);
  const [reloadList, setReloadList] = useState<boolean>(false);
  const [didAreasChange, setDidAreasChange] = useState<boolean>(false);
  const [didAreaForemanChange, setDidAreaForemanChange] = useState<boolean>(false);
  const [didSystemChange, setDidSystemChange] = useState<boolean>(false);
  /* State End */

  const classes = useStyles();

  const {openModal, closeModal} = useModals();
  const {isValid} = useClaims();
  const {project} = useContext(ProjectContext);
  const {setAlert, setSuccess} = useContext(AlertContext);
  const {get, post, fileDownload} = useApi();

  // Function for downloading the list as pdf file
  const getPdf = async () => {
    const systemId = selectedSystem?.id;
    const afId = selectedAreaForeman?.id;

    await fileDownload("Tags.pdf", `/tag/${project?.id}/pdf`, {
      queryParams: {
        tagId,
        systemId,
        text,
        afId
      }
    });
  }

  // End buttons in "header"
  const excelExport = [
    {
      label: "Export data",
      action: async () => {
        const systemId = selectedSystem?.id;
        const afId = selectedAreaForeman?.id

        await fileDownload("qTagReg.xlsx", `/tag/${project?.id}/exportAsExcel?any=any`, {
          queryParams: {
            tagId,
            systemId,
            text,
            afId
          }
        })
      }
    }
  ]

  // Fetch tags
  useEffect(() => {
    let isSubscribed = true;
    setIsLoading(true);
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };
      (async () => {
        const systemId = selectedSystem?.id;
        const afId = selectedAreaForeman?.id

        await post<PaginationRequest, any>(`/project/${project?.id}/tag`,
          paginationQuery,
          {},
          {
            tagId,
            systemId,
            text,
            afId
          })
          .then(result => {
            if (isSubscribed) {
              setInitialValues(result.values);
              dispatch({
                type: "SET_STATE",
                payload: () => ({
                  ...result,
                  values: result.values.map((x: System) => ({
                    ...x,
                    isMarked: false
                  }))
                })
              });
            }
          });
      })();
    } finally {
      setIsLoading(false);
      setIsDirty(false);
      setReloadList(false);
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    tagId,
    selectedSystem,
    text,
    selectedAreaForeman,
    reloadList,
    state.pageSize,
    state.currentPage
  ]);

  useEffect(() => {
    let isSubscribed = true;
    // Fetch all extra information needed to display information about the tags
    (async () => {
      if (areas == null) {
        const areas = await get<Area[]>(`/project/${project?.id}/area`);
        if (isSubscribed) {
          setAreas(areas);
        }
      }
      if (systems == null) {
        const systems = await get<SystemListItem[]>(`/project/${project?.id}/system`);
        if (isSubscribed) {
          setSystems(systems);
        }
      }
      if (foremans == null) {
        const foremans = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`);
        if (isSubscribed) {
          setForemans(foremans);
        }
      }
      if (mvzs == null) {
        const mvzs = await get<MainVerticalZone[]>(`/project/${project?.id}/mainVerticalZones`);
        if (isSubscribed) {
          setMvzs(mvzs);
        }
      }
      if (zone3ds == null) {
        const zones = await get<Zone3d[]>(`/project/${project?.id}/zone3d`);
        if (isSubscribed) {
          setZone3ds(zones);
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  /*
   * Map Options
   */

  const areaOptions = useMemo((): Option[] | null => mapAreaOptions(areas, {withShortLabel: true}), [areas]);
  const systemOptions = useMemo((): Option[] | null => mapSystemOptions(systems, {withShortLabel: true}), [systems]);
  const foremanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(foremans, {withShortLabel: true}), [foremans]);
  const mvZoneOptions = useMemo((): Option[] | null => {
    if (mvzs == null) {
      return null;
    }

    return mvzs.map(x => ({
      value: x.id,
      label: `${x.code}`
    }));
  }, [mvzs]);
  const zone3dOptions = useMemo((): Option[] | null => {
    if (zone3ds == null) {
      return null;
    }

    return zone3ds.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`
    }));
  }, [zone3ds]);



  // Check if a user can add new item
  useEffect(() => {
    const canAdd = areaOptions && areaOptions.length > 0 &&
      systemOptions && systemOptions.length > 0 &&
      foremanOptions && foremanOptions.length > 0;
    setCanAddNewItem(canAdd === true);
  }, [areaOptions, systemOptions, foremanOptions])

  const addNewItem = () => {
    if (!canAddNewItem || !areDependenciesLoaded) {
      return;
    }

    const activeAreas = areas?.filter(x => x.isActive);
    const activeSystems = systems?.filter(x => x.isActive);
    const activeForemen = foremans?.filter(x => x.isActive);
    const newId = prevNewId - 1;
    const area = activeAreas && activeAreas.length > 0 ? activeAreas[0] : undefined;
    const system = activeSystems && activeSystems.length > 0 ? activeSystems[0] : undefined;
    const foreman = activeForemen && activeForemen.length > 0 ? activeForemen[0] : undefined;

    const newItem: Tag = {
      id: newId,
      projectId: project ? project.id : 0,
      code: "",
      name: "",
      isActive: true,
      areaId: area?.id,
      mainVerticalZoneId: area?.mainVerticalZoneId,
      zone3dId: area?.zone3DId,
      cbs: "",
      systemId: system?.id,
      areaForemanId: foreman?.id,
      fundamentBy: "",
      isFundamentDone: false,
      mountBy: "",
      isMounted: false,
      state: "new",
      hasError: true
    };

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
  };

  const onSave = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: Tag) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u)
        .filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<TagProps>>((n: Tag) => ({
        referenceId: n.id,
        props: {
          code: n.code,
          name: n.name,
          areaId: n.areaId,
          systemId: n.systemId,
          areaForemanId: n.areaForemanId,
          isActive: n.isActive,
          zone3dId: n.zone3dId,
          mainVerticalZoneId: n.mainVerticalZoneId,
          fundamentBy: n.fundamentBy,
          isFundamentDone: n.isFundamentDone,
          mountBy: n.mountBy,
          isMounted: n.isMounted
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted" && x.id > 0)
      .map((x: Tag) => x.id);

    const successDetails: string[] = [];
    if(patchedItems.flatMap(x => x.operations).filter(x => x.path === "/areaId").length > 0) {
      successDetails.push("Areas and corresponding MVZone and 3DZone are updated in Cable list.");
    }

    if(patchedItems.flatMap(x => x.operations).filter(x => x.path === "/areaForemanId").length > 0) {
      successDetails.push("Conn. Resp. are updated in Cable list.");
    }

    const updateAction = async (performCableSideEffectsOnSystem: boolean) => {
      const body: PatchTagsRequest = {
        newItems,
        patchedItems,
        deletedItems,
        performCableSideEffectsOnSystem
      };
      try {
        const updateTagResult = await post<PatchTagsRequest, PatchTagsResponse>(`project/${project!.id}/tag/patch`, body);
        refreshVisibleTags(updateTagResult);
        if(updateTagResult.cablesAffectedBySystemSideEffect != null) {
          successDetails.push(`Cables updated by system change on tags: ${updateTagResult.cablesAffectedBySystemSideEffect}`);
        }
        setSuccess(successDetails);
        setIsDirty(false);
        setDidAreasChange(false);
        setDidAreaForemanChange(false);
        setDidSystemChange(false);

      } catch (error: unknown) {
        if (error instanceof ProblemDetailError || error instanceof Error) {
          setAlert({type: "error", text: "Something failed when updating the tag list", error});
        } else {
          setAlert({type: "error", text: "Something failed horribly when updating the tag list"});
        }
      } finally {
        setIsLoading(false);
      }
    }



    setIsLoading(true);
    if(didSystemChange) {
      const canUpdateAreaForemenOnCables = isValid({claim: ProjectClaims.project.production.coordinator});

      openModal && openModal(Modal, {
        title: "Information",
        description: "Do you want to update all cables attached to tags with changed system with the same connection responsible as the new CBS/Foreman for the system? (Recommended)",
        paragraphs: [
          {head: "Yes",  text: "For all tags with new system, sets the area foreman from system as connection responsible on affected cables."},
          {head: "No", text: "Cables are not updated with area foreman from the new system"}
        ],
        cancelText: "Cancel",
        cancel: () => {
          setIsLoading(false);
          closeModal && closeModal();
        },
        okText: canUpdateAreaForemenOnCables ? "Yes" : undefined,
        ok: canUpdateAreaForemenOnCables ? async () => {
          await updateAction(true);
          closeModal && closeModal();
        } : undefined,
        secondaryActionText: "No",
        secondaryAction: async () => {
          await updateAction(false);
          closeModal && closeModal();
        }
      });
    } else {
      await updateAction(false);
    }
  }

  const change = useCallback((tag: Tag) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        const prevTagValues = prevValues.find(x => x.id === tag.id);

        // Side effects for area
        if (prevTagValues.areaId !== tag.areaId) {
          if (areas !== null) {
            const area = areas?.find((a: Area) => a.id === tag.areaId) as Area;
            tag.zone3dId = area.zone3DId;
            tag.mainVerticalZoneId = area.mainVerticalZoneId

            if(!didAreasChange && !isValid({claim: ProjectClaims.project.engineering.external})) {
              // Set a warning on the first occurrence for users without Engineering External permissions.
              setAlert({type: "warning", text: areaWarningMessage});
            }
            setDidAreasChange(true);
          }
        }

        // Side effect for system
        if (prevTagValues.systemId !== tag.systemId) {
          if (!didSystemChange  && !isValid({claim: ProjectClaims.project.production.coordinator})) {
            // Set a warning on the first occurrence for users without Production Coordinator permissions.
            setAlert({type: "warning", text: systemWarningMessage});
          }
          setDidSystemChange(true);
        }

        // Side effects for area foreman
        if (prevTagValues.areaForemanId !== tag.areaForemanId) {
          if(!didAreaForemanChange && !isValid({claim: ProjectClaims.project.production.coordinator})) {
            // Set a warning on the first occurrence for users without Production Coordinator permissions.
            setAlert({type: "warning", text: areaForemanWarningMessage});
          }
          setDidAreaForemanChange(true);
        }

        return handleRowChange(
          prevValues,
          tag,
          setIsDirty);
      }
    });
  }, [tagId, text, areas, systems, foremans]);

  const copy = useCallback((source: Tag) => {
    const newId = prevNewId - 1;
    setPrevNewId(newId)

    const sourceArea = source.areaId ? areas?.find(x => x.id === source.areaId) : undefined;
    const sourceAreaForeman = source.areaForemanId ? foremans?.find(x => x.id === source.areaForemanId) : undefined;
    const sourceSystem = source.systemId ? systems?.find(x => x.id === source.systemId) : undefined;


    const target: Tag = {
      id: newId,
      projectId: project ? project.id : 0,
      code: source.code,
      name: source.name,
      isActive: source.isActive,
      areaId: sourceArea?.isActive === true ? sourceArea.id : undefined,
      cbs: source.cbs,
      systemId: sourceSystem?.isActive === true ? sourceSystem.id : undefined,
      areaForemanId: sourceAreaForeman?.isActive === true ? sourceAreaForeman.id : undefined,
      fundamentBy: source.fundamentBy,
      isFundamentDone: source.isFundamentDone,
      mountBy: source.mountBy,
      isMounted: source.isMounted,
      state: "new",
      hasError: source.hasError
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setIsDirty(true);
  }, [prevNewId, systems, foremans, areas])

  const refreshVisibleTags = (updates: PatchResponse<Tag>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: Tag[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  /* Validators */

  const areDependenciesLoaded = areaOptions != null
    && systemOptions != null
    && foremanOptions != null
    && zone3dOptions != null
    && mvZoneOptions != null;

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  /* Filters */

  const onTagIdChange = (value: string) => {
    promptIfUnsavedChanges(openModal, isDirty, () => setTagId(value));
  }

  const onTagTextChange = (value: string) => {
    promptIfUnsavedChanges(openModal, isDirty, () => setText(value));
  }

  const systemFilter = useMemo(() => {
    const options = mapSystemOptions(systems)
    return (
      <VectSelect
        id={"systemIdFilter"}
        isClearable={true}
        placeholder={"System ID"}
        value={selectedSystem?.id}
        change={value => {
          const action = () => {
            if (value === null) {
              setSelectedSystem(null);
            } else {
              const system = systems?.find(x => x.id === value);
              if (system) {
                setSelectedSystem(system);
              }
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [systems, selectedSystem, isDirty]);

  const areaForemanFilter = useMemo(() => {
    const options = mapAreaForemanOptions(foremans);
    return (
      <VectSelect
        id={"foremanIdFilter"}
        isClearable={true}
        placeholder={"Conn. Resp"}
        value={selectedAreaForeman?.id}
        change={value => {
          const action = () => {
            if (value === null) {
              setSelectedAreaForeman(null);
            } else {
              const areaForeman = foremans?.find(x => x.id === value);
              if (areaForeman) {
                setSelectedAreaForeman(areaForeman);
              }
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [foremans, selectedAreaForeman, isDirty]);

  const mayAreaChangeLeadToError = didAreasChange && !isValid({claim: ProjectClaims.project.engineering.external});
  const mayAreaForemanChangeLeadToError = didAreaForemanChange && !isValid({claim: ProjectClaims.project.production.coordinator});
  const maySystemChangeLeadToError = didSystemChange && !isValid({claim: ProjectClaims.project.production.coordinator});

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Tag"}
        add={{
          action: addNewItem,
          claim: ProjectClaims.project.tag.write.all,
          disabled: !canAddNewItem || !areDependenciesLoaded
        }}
        save={{
          action: onSave,
          disabled: !areDependenciesLoaded || hasErrors || !isDirty
        }}
        getPdf={{
          action: getPdf
        }}
        excelExports={excelExport}/>

      {/* Header filter */}
      {initialValues ? (
        <div className={classes.filterWrapper}>
          <div className={classes.filterItem10}>
            <VectTextField
              value={tagId}
              change={onTagIdChange}
              label={"Tag ID"}
              debounceDelay={1000}
            />
          </div>
          <div className={classes.filterItem30}>
            <VectTextField
              value={text}
              change={onTagTextChange}
              label={"Tag Name"}
              debounceDelay={1000}
            />
          </div>
          <div className={classes.filterItem}>
            {systemFilter}
          </div>
          <div className={classes.filterItem}>
            {areaForemanFilter}
          </div>
        </div>
      ) : <Skeleton variant="rect" width="40rem" height="5rem"/>}
      { mayAreaChangeLeadToError && <Typography variant="body2">{areaWarningMessage}</Typography>}
      { mayAreaForemanChangeLeadToError && <Typography variant="body2">{areaForemanWarningMessage}</Typography>}
      { maySystemChangeLeadToError && <Typography variant="body2">{systemWarningMessage}</Typography>}
      {/* Tag Table */}
      <Table>
        {/* Tag Table Headers */}
        <TableHead>
          <TableRow>
            <TableCell>Tag ID</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Area</TableCell>
            <TableCell>System ID</TableCell>
            <TableCell>Conn. Resp</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>3D Zone</TableCell>
            <TableCell>MV Zone</TableCell>
            <TableCell>Fund. made by</TableCell>
            <TableCell>Fund. done</TableCell>
            <TableCell>Mount by</TableCell>
            <TableCell>Mounted</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        {/* Table body */}
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map((tag: Tag, index: number) => (
                <TagRowItem
                  key={index}
                  tag={tag}
                  change={change}
                  copy={copy}
                  areaOptions={areaOptions}
                  systemOptions={systemOptions}
                  foremanOptions={foremanOptions}
                  zone3dOptions={zone3dOptions}
                  mvZoneOptions={mvZoneOptions}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch} isDirty={isDirty}/>
    </>
  );
};

export default Tags;
