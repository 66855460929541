import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  filterWrapper: {
    margin: "1em auto",
    width: "100%",
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  filterItem: {
    marginTop: "2em"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  table: {
    width: "100%",
    textAlign: "left",
    padding: "2em"
  },
  loadingIcon: {
    textAlign: "center"
  },
  greyTaint: {
    color: "grey"
  }
}));
