import React, {useEffect, useState} from "react";
import {Order, OrderProps} from "../../../shared/interfaces/cableType.interface";
import {ProjectClaims} from "../../../shared/claims";
import {Skeleton} from "@material-ui/lab";
import {Typography} from "@material-ui/core";
import {VectDateTimeField} from "../../shared/inputs/VectDateTimeField";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {formatDate} from "../../../shared/helpers/date";
import {makeStyles} from "@material-ui/core/styles";

const headerStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "column"
  },
  deliveryDate: {
    width: "10rem",
    marginTop: "10px",
    marginBottom: "10px"
  },
  deliveryLocation: {
    width: "20rem",
    marginTop: "10px",
    marginBottom: "10px"
  }
}));

const itemStyles = makeStyles(() => ({
  item: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline"
  },
  label: {
    width: "8rem"
  }
}));

export const OrderDetailsHeader: React.FC<{
  order: Order | null,
  change: (order: OrderProps) => void
}> = ({
        order,
        change
      }): JSX.Element => {

  const [orderProps, setOrderProps] = useState<OrderProps | null>(null);
  const classes = headerStyles();

  useEffect(() => {
    if (order != null) {
      setOrderProps({
        deliveryDate: order.deliveryDate,
        deliveryLocation: order.deliveryLocation
      })
    }
  }, [order])

  const loadingYesNo = (val: boolean | undefined | null): string | undefined => {
    if (val == null) {
      return undefined;
    }

    return val ? "Yes" : "No";
  }

  const onDateChange = (value: Date | null) => {
    if (order != null) {
      const newState = {
        ...orderProps,
        deliveryDate: value || undefined
      };
      setOrderProps(newState);
      change(newState);
    }
  }

  const onDeliveryLocationChange = (value: string) => {
    if (order != null) {
      const newState = {
        ...orderProps,
        deliveryLocation: value
      };
      setOrderProps(newState);
      change(newState);
    }
  }

  return (
    <div className={classes.header}>
      <HeaderItem label="Created at:" value={formatDate(order?.createdAt)}/>
      <HeaderItem label="Created by:" value={order?.createdByName}/>
      <HeaderItem label="Initial order:" value={loadingYesNo(order?.isInitialOrder)}/>
      <div className={classes.deliveryDate}>
        <VectDateTimeField
          claim={ProjectClaims.project.order.write.all}
          change={onDateChange}
          value={order?.deliveryDate || null}
          label="Delivery date"
          inputVariant="filled"/>
      </div>
      <VectTextField
        className={classes.deliveryLocation}
        claim={ProjectClaims.project.order.write.all}
        change={onDeliveryLocationChange}
        label="Delivery location:"
        value={order?.deliveryLocation}/>
    </div>
  )
}

const HeaderItem: React.FC<{
  label: string;
  value?: string | null
}> = ({
        label,
        value
      }) => {
  const classNames = itemStyles();
  return (
    <div className={classNames.item}>
      <Typography variant="body1" className={classNames.label}>{label}</Typography>
      <Typography variant="body2">{value || <Skeleton width="10rem"/>}</Typography>
    </div>
  );
}
