import {useContext} from "react";
import {UserContext} from "../context/UserContext";
import {ProjectContext} from "../context/ProjectContext";
import {AuthProps} from "../interfaces/auth.interface";

export const useClaims = (): {
  hasClaim: (claim: string, useExactMatch?: boolean) => boolean,
  hasClaims: (claims: string[], useExactMatch?: boolean) => boolean,
  isValid: (authProps: AuthProps) => boolean
} => {
  const{hasClaim: hasGlobalClaim, hasClaims: hasGlobalClaims} = useContext(UserContext);
  const{hasClaim: hasProjectClaim, hasClaims: hasProjectClaims} = useContext(ProjectContext);


  const hasClaim = (claim: string, useExactMatch?: boolean): boolean => {
    return hasProjectClaim(claim, useExactMatch) || hasGlobalClaim(claim, useExactMatch);
  }

  const hasClaims = (claims: string[], useExactMatch?: boolean): boolean => {
    return hasProjectClaims(claims, useExactMatch) || hasGlobalClaims(claims, useExactMatch);
  }

  const isValid = ({claim, claims, useExactMatch}: AuthProps): boolean => {
    return (!claim && !claims)
      || (claim && hasClaim(claim, useExactMatch))
      || (claims && hasClaims(claims, useExactMatch))
      || false;
  }

  return {
    hasClaim,
    hasClaims,
    isValid
  }
}
