import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import Users from "./Users/Users";
import MainPanel from "../../shared/layout/MainPanel/MainPanel";
import {NewUser} from "./Users/NewUser/NewUser";
import EditUser from "./Users/EditUser";
import VectAppBar, {ApplicationLink} from "../../shared/layout/VectAppBar";
import AllRoles from "../roles/AllRoles";
import EditRole from "../roles/EditRole";
import NewRole from "../roles/NewRole";
import {GlobalClaims} from "../../../shared/claims";
import NewProjectRole from "../roles/NewProjectRole";
import EditProjectRole from "../roles/EditProjectRole";

interface ManageUsersHomeProps {
  navLinks: ApplicationLink[]
}

const ManageUsersHome: React.FC<ManageUsersHomeProps> = ({navLinks}): JSX.Element => {
  const {path, url} = useRouteMatch();
  const sideBarLinks: ApplicationLink[] = [
    {claim: GlobalClaims.manage.user.wildcard, useExactMatch: false, name: "Users", route: `${url}/all-users`},
    {claim: GlobalClaims.manage.user.roles.readWrite.all, name: "Roles", route: `${url}/all-roles`}
  ]

  return (
    <VectAppBar header="Manage users" navLinks={navLinks} sideBarLinks={sideBarLinks}>
      <MainPanel>
        <Switch>
          <Route path={`${path}/all-users`} component={Users}/>
          <Route path={`${path}/new-user`} component={NewUser}/>
          <Route path={`${path}/edit/:id`} component={EditUser}/>
          <Route path={`${path}/all-roles`} component={AllRoles}/>
          <Route path={`${path}/global-role/edit/:roleId`} component={EditRole} />
          <Route path={`${path}/global-role/new`} component={NewRole} />
          <Route path={`${path}/project-role/edit/:roleId`} component={EditProjectRole} />
          <Route path={`${path}/project-role/new`} component={NewProjectRole} />
          <Route path={path}>
            <Redirect to={`${path}/all-users`}/>
          </Route>
        </Switch>
      </MainPanel>
    </VectAppBar>
  );
};

export default ManageUsersHome;

