import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {AlertContext} from "../../../../shared/context/AlertContext";
import {useApi} from "../../../../shared/hooks/useApi";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";

import {Project, ProjectMetadata, ProjectProps} from '../../../../shared/interfaces/project.interface';
import {useHistory, useParams} from "react-router-dom";
import {GlobalClaims} from "../../../../shared/claims";
import {Skeleton} from "@material-ui/lab";
import {UsersAssignment} from "../UsersAssignmentTable";
import {ProjectRole, ProjectUser, UserListItem} from "../../../../shared/interfaces/user.interface";
import {DeleteForeverSharp} from "@material-ui/icons";
import {useModals} from "../../../../shared/context/ModalContext";
import {DeleteConfirmationModal} from "../../../shared/Feedback/DeleteConfirmationModal";
import {DeletedProject} from "./DeletedProject";
import {EditProject} from "./EditProject";
import {CommonPaths} from "../../../../shared/urls";
import {compare} from "fast-json-patch";

interface RouteParams {
  projectCode?: string
}

interface ProjectManagementDetails {
  project: Project;
  metadata: ProjectMetadata;
}

const EditProjectPage: FC = () => {
  const [hasError, setHasError] = useState(false);
  const [initialState, setInitialState] = useState<ProjectProps | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [project, setProject] = useState<Project | null>(null);
  const [projectMetadata, setProjectMetadata] = useState<ProjectMetadata | null>(null);
  const [projectPatch, setProjectPatch] = useState<ProjectProps | null>(null);
  const [projectRoles, setProjectRoles] = useState<ProjectRole[] | null>(null);
  const [updatedAssignments, setUpdatedAssignments] = useState<UsersAssignment[] | null>(null);
  const [users, setUsers] = useState<UserListItem[] | null>();
  const history = useHistory();
  const {get, put, _delete, patch} = useApi()
  const {openModal, closeModal} = useModals();
  const {projectCode} = useParams<RouteParams>();
  const {setAlert, setError} = useContext(AlertContext);

  const getUsers = async () => get<UserListItem[]>(
    `/user/list`,
    { queryParams: {isAzureAdAccountEnabled: true, isDeleted: false}})

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      try {
        const userResult = await getUsers();
        const roleResult = await get<ProjectRole[]>('/roles/project');
        let projectResult: ProjectManagementDetails;

        try {
          projectResult = await get<ProjectManagementDetails>(`/manage/project/${projectCode}`);
        } catch (err) {
          if(err.status !== undefined && err.status === 404) {
            history.push(CommonPaths.manage.projects.list);
          } else {
            setError(err);
          }
          return;
        }

        if(isSubscribed) {
          setUsers(userResult);
          setProjectRoles(roleResult);
          setProject(projectResult.project);
          setProjectMetadata(projectResult.metadata)
          setInitialState({...projectResult.project});
        }
      } catch (err) {
        setError(err);
      }
    })();

    get<ProjectManagementDetails>(`/manage/project/${projectCode}`).then(
      (res) => {
        if (isSubscribed) {
          setProject(res.project);
          setProjectMetadata(res.metadata);
          setInitialState({...res.project});
        }
      })
      .catch(error => {
        if(error.status !== undefined && error.status === 404) {
          history.push(CommonPaths.manage.projects.list);
        } else {
          setError(error);
        }
      });
    return () => {
      isSubscribed = false
    };
  }, []);

  const onForceDelete = useCallback(async () => {

    try {
      const res = await _delete<ProjectManagementDetails>(`/project/${projectCode}/force`);
      setProject(res.project);
      setProjectMetadata(res.metadata);
    } catch(error) {
      setError(error);
    }
  }, []);

  const onPropsChange = useCallback((props: ProjectProps, hasError: boolean) => {
    setProjectPatch(props);
    setProject(prevState => {
      if(prevState == null) {
        return null;
      }
      return {
        ...prevState,
        ...props
      };
    })
    setIsDirty(true);
    setHasError(hasError);
  }, []);

  const onUsersChange = useCallback((assignments: UsersAssignment[]) => {
    setUpdatedAssignments(assignments);
    setIsDirty(true);
  }, []);

  const showDeleteDialog = () => {
    project && openModal && openModal(DeleteConfirmationModal, {
      onDelete: async () => {

        try {
          const res = await _delete<ProjectManagementDetails>(`/project/${projectCode}`);
          setProject(res.project);
          setProjectMetadata(res.metadata);
        } catch(error) {
          setError(error);
        } finally {
          if (closeModal) {
            closeModal();
          }
        }
      },
      confirmation: {
        description: "Type the project name to continue",
        challenge: project.name
      }
    });
  }

  const submit = async () => {
    let didPatchProject = false;
    let didUpdateUsers = false;
    let newProjectCode = null;
    let patchProjectError = null;
    let updateUserError = null;

    if(initialState != null && projectPatch != null) {
      const operations = compare(initialState, projectPatch);
      if(operations.length > 0) {
        try {
          const result = await patch<Project>(`project/${projectCode}`, operations);
          if(result.code !== projectCode) {
            newProjectCode = result.code;
          }
          setInitialState({...result});
          didPatchProject = true;
        } catch (err) {
          patchProjectError = err;
        }
      }
    }

    if(project && updatedAssignments) {
      const body = updatedAssignments.flatMap(a => a.roles.map(r => ({userId: a.userId, roleId: r.id})));
      try {
        await put<ProjectUser[]>(`/project/${project?.id}/users`, body);
        setUsers(await getUsers());
        setUpdatedAssignments(null);
        didUpdateUsers = true;
      } catch(err) {
        updateUserError = err;
      }
    }


    if(patchProjectError == null && updateUserError == null) {
      const details = [];
      if(didPatchProject) {
        details.push("The project is updated.");
      }

      if(didUpdateUsers) {
        details.push("Users are updated.");
      }

      setAlert({type: "success", text: "Done!", details});
      setIsDirty(false);
      setHasError(false)
    } else {
      patchProjectError && setError(patchProjectError);
      updateUserError && setError(updateUserError);
    }

    if(newProjectCode != null) {
      history.push(CommonPaths.manage.projects.edit(newProjectCode));
    }
  }

  return (
    <div>
      <PanelHeader
        text={`${project?.code != null ? project.code : ""} ${project?.name != null ? project?.name : ""}`}
        save={{action: submit, disabled: !isDirty || projectMetadata?.deletedAt != null || hasError}}
        secondaryActions={[{
          label: "Delete project",
          claim: GlobalClaims.manage.project.delete.all,
          action: showDeleteDialog,
          icon: <DeleteForeverSharp/>,
          disabled: projectMetadata?.deletedAt != null
        }]}
      />
      {
        projectMetadata == null || project == null || projectRoles == null || users == null
          ? <Skeleton width="20rem" height="1rem" />
          : projectMetadata.deletedAt != null
            ? <DeletedProject projectCode={project.code} metadata={projectMetadata} forceDelete={onForceDelete}/>
            : <EditProject project={project}
                           projectRoles={projectRoles}
                           users={users}
                           onPropsChange={onPropsChange}
                           onUsersChange={onUsersChange}/>
      }
    </div>
  )
};

export default EditProjectPage;
