import React, {FunctionComponent, memo} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../../shared/table/styles";
import {handleCellChange} from "../../../../shared/helpers/table";
import {TableItemState} from "../../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../../shared/theme";
import {GlobalClaims} from "../../../../shared/claims";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {VectContainer} from "../../../shared/layout/VectContainer";
import {DeleteButton} from "../../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../../shared/table/ModifiedIcon";
import {TableCell} from "@material-ui/core";
import {CopyButton} from "../../../shared/table/CopyButton";
import {CableClass} from "../../../../shared/interfaces/cableClass.interface";

type CableClassRowItemProps = {
  cableClass: CableClass;
  change: (value: CableClass) => void;
  copy: (value: CableClass) => void;
};

const CableClassRowItem: FunctionComponent<CableClassRowItemProps> = ({
  cableClass,
  change,
  copy,
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: cableClass.code ? undefined : "A cable class id is required",
    name: cableClass.name ? undefined : "A cable class name is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: CableClass): boolean => {
      return !newState.code || !newState.name;
    }

    handleCellChange(
      change,
      hasError,
      cableClass,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...cableClass,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(cableClass);
  }

  const style = cableClass.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput style={{padding: "1rem"}} className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={cableClass.code}
          change={onCellChange}
          variant="standard"
          fullWidth
          required
        />
      </TableCellInput>
      <TableCellInput style={{padding: "1rem"}} className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          error={!!errors.name}
          helperText={errors.name}
          fieldName="name"
          value={cableClass.name}
          change={onCellChange}
          variant="standard"
          fullWidth
          required
        />
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={GlobalClaims.manage.baseline.readWrite.all}>
          <TableCell style={{display: "flex"}} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCell>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: 'center'}} claim={GlobalClaims.manage.baseline.readWrite.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={cableClass.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={cableClass.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
};

export default memo(CableClassRowItem);
