import React, {FC, useContext, useEffect, useState} from 'react';
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {
  AreaForemanStatusInformation,
  initialTotals
} from "../../../shared/interfaces/areaForemanStatusInformation.interface";
import CbsStatusTotals from "./CbsStatusTotals";
import ScrollableCBSList from "./ScrollableCBSList";
import CBSAreaForeman from "./CBSAreaForeman";
import {useApi} from "../../../shared/hooks/useApi";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import TagTotals from "./TagTotals";
import styles from './cbsForemanStatus.module.css';
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {Paper, Typography} from "@material-ui/core";
import {ExcelButton, PdfButton} from '../../../shared/theme';
import {GridOn, PictureAsPdf} from "@material-ui/icons";

const CbsForemanStatus: FC = () => {
  const [selectedForeman, setSelectedForeman] = useState<Areaforeman | undefined>(undefined);
  const [areaForemanStatusInformation, setAreaForemanStatusInformation] = useState<AreaForemanStatusInformation>(initialTotals)
  const [foremen, setForemen] = useState<Areaforeman[]>([]);

  // Destructuring
  const {get, fileDownload} = useApi();
  const {project} = useContext(ProjectContext)

  // Fetch all area foremen for the given (selected) project
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const result = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`).then(res => res)
      if (isSubscribed) {
        setForemen(result)
      }
    })();
    return () => {
      isSubscribed = false
    };
  }, [])

  // Fetch status CBS / Area Foreman information whenever selected Area Foreman changes
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (selectedForeman?.id !== undefined) {
        const result = await get<AreaForemanStatusInformation | undefined>
        (`/areaForeman/${selectedForeman.id}/getStatus/${project?.id}`).then(res => res)
        if (isSubscribed && result !== undefined) {
          setAreaForemanStatusInformation(result)
        }
      } else {
        setAreaForemanStatusInformation(initialTotals);
      }
    })();
    return () => {
      isSubscribed = false
    };
  }, [selectedForeman?.id])

  // Change foreman "filter"
  const changeForeman = (id: number | undefined | null) => {
    if (id != null) {
      const newForeman = foremen.find(x => x.id === id);
      setSelectedForeman(newForeman);
    } else {
      setSelectedForeman(undefined);
    }
  }

  /*
  Excel & PDF generation
   */

  const getBundleExcel = async () => {
    await fileDownload("expCables_Foreman.xlsx", `/areaForeman/${selectedForeman?.id}/exportAsExcelBundle/${project?.id}`);
  }
  const getSystemExcel = async () => {
    await fileDownload("expCables_ForemSystem.xlsx", `/areaForeman/${selectedForeman?.id}/exportAsExcelSystem/${project?.id}`);
  }
  const getFreePullExcel = async () => {
    await fileDownload("expCables_FreePull.xlsx", `/cableFreePull/${project?.id}/exportAsExcel`);
  }
  const getPdf = async () => {
    await fileDownload("Project Status Foreman.pdf", `/areaForeman/${selectedForeman?.id}/pdf/${project?.id}`);
  }

  return (
    <div>
      <PanelHeader text={"Status information - CBS / Foreman"}/>
      <Paper className={styles.topRow} style={{padding: "10px"}}>
        <CBSAreaForeman areaForemen={foremen} changeForeman={changeForeman} selectedForeman={selectedForeman}/>
        <TagTotals tagTotals={areaForemanStatusInformation.tagTotals}/>
      </Paper>

      <div className={styles.mainRow}>
        <Paper className={styles.mainRowChild} style={{padding: "10px"}}>
          <CbsStatusTotals
            cableTotals={areaForemanStatusInformation?.bundleCableTotals}
            freePullTotals={areaForemanStatusInformation?.foremanFreePull}
            bundleTotals={areaForemanStatusInformation.bundleTotals}
            title={"Bundles"}/>
          <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
            <span>
              <Typography>Export data: </Typography>
              <Typography variant={"subtitle2"}>Excel sheet with filtered data for the info above</Typography>
            </span>
            <ExcelButton disabled={selectedForeman === undefined}
                         variant="contained"
                         onClick={getBundleExcel}
                         startIcon={<GridOn/>}> Export data</ExcelButton>
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
            <span>
              <Typography>Print report: </Typography>
              <Typography variant={"subtitle2"}>Report view of selected CBS</Typography>
            </span>
            <PdfButton disabled={selectedForeman === undefined} onClick={getPdf}
                       variant="contained"
                       startIcon={<PictureAsPdf/>}>Print Report</PdfButton>
          </div>
        </Paper>
        <Paper className={styles.mainRowChild} style={{padding: "10px"}}>
          <CbsStatusTotals
            cableTotals={areaForemanStatusInformation?.systemCableTotals}
            freePullTotals={areaForemanStatusInformation?.systemFreePull}
            title={"Systems"}/>
          <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
            <span>
              <Typography>Export data: </Typography>
              <Typography variant={"subtitle2"}>Excel sheet with filtered data for the info above</Typography>
            </span>
            <ExcelButton disabled={selectedForeman === undefined}
                         variant="contained"
                         onClick={getSystemExcel}
                         startIcon={<GridOn/>}> Export data</ExcelButton>
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
            <span>
              <Typography>Export data: </Typography>
              <Typography variant={"subtitle2"}>Excel -sheet, unfiltered for 'Free Pull'</Typography>
            </span>
            <ExcelButton onClick={getFreePullExcel}
                         variant="contained"
                         startIcon={<GridOn/>}> Export data</ExcelButton>
          </div>
        </Paper>
        <Paper className={styles.listChild} style={{padding: "10px"}}>
          <ScrollableCBSList title={"Areas"}
                             areas={areaForemanStatusInformation.areas}/>
          <ScrollableCBSList title={"System"}
                             systems={areaForemanStatusInformation.systems}/>
        </Paper>
      </div>
    </div>
  )
};

export default CbsForemanStatus;
