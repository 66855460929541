import React, {useContext, useEffect, useState} from "react";
import {AlertContext} from "../../../../shared/context/AlertContext";
import {CommonPaths} from "../../../../shared/urls";
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {DoneSharp, HourglassEmptySharp, UpdateSharp} from "@material-ui/icons";
import {HangfireResponse} from "../../../../shared/interfaces/hangfire.interface";
import {HubConnection} from "@microsoft/signalr";
import {Skeleton} from "@material-ui/lab";
import {VectButton} from "../../../shared/navigation/VectButton";
import {makeStyles} from "@material-ui/core/styles";
import {useApi} from "../../../../shared/hooks/useApi";
import {useHistory} from "react-router-dom";
import {useModals} from "../../../../shared/context/ModalContext";
import {useSignalR} from "../../../../shared/hooks/useSignalR";
import {ProjectProps} from "../../../../shared/interfaces/project.interface";
import {ProjectUser} from "../../../../shared/interfaces/user.interface";

const useStyles = makeStyles(() => ({
  list: {
    width: "40rem"
  },
  listItem: {
    display: "flex",
    marginTop: "5px"
  },
  listIcon: {
    marginRight: "10px"
  }

}))

interface CreateProjectModalProps {
  assignedRoles?: ProjectUser[];
  project: ProjectProps;
  sourceProjectId: number | null;
  open?: boolean;
}

interface NewProjectRequest extends ProjectProps{
  sourceProjectId: number | null;
  assignedRoles?: ProjectUser[];
}

interface ReceiveCreateProjectMessage {
  subscriptionId: string;
  projectStatus: string;
  message?: string;
  isComplete: boolean;
  didFail: boolean;
  project: CreateProjectItem;
  mvZones?: CreateProjectItem;
  penetrations?: CreateProjectItem;
  areaForemen?: CreateProjectItem;
  productionResponsible?: CreateProjectItem;
  zone3ds?: CreateProjectItem;
  areas?: CreateProjectItem;
  systemGroups?: CreateProjectItem;
  systems?: CreateProjectItem;
  tags?: CreateProjectItem;
  cableTypes?: CreateProjectItem;
  bundles?: CreateProjectItem;
  cableFreePull?: CreateProjectItem;
  cables?: CreateProjectItem;

}

interface CreateProjectItem
{
  name: string;
  status: string;
  progress: number;
}

export const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  assignedRoles,
  sourceProjectId,
  project
}): JSX.Element => {

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [message, setMessage] = useState<ReceiveCreateProjectMessage | null>(null);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const classNames = useStyles();
  const history = useHistory();
  const {closeModal} = useModals();
  const {post} = useApi();
  const {projectHub} = useSignalR();
  const {setError} = useContext(AlertContext);

  useEffect(() => {
    setConnection(projectHub());

    return () => {

      connection?.stop();
    }
  }, []);

  useEffect(() => {
    if (connection && subscriptionId) {
      connection.start()
        .then(() => {
          connection.on(
            'ReceiveCreateProjectMessage',
            (res: ReceiveCreateProjectMessage) => {
                if(res.subscriptionId === subscriptionId) {
                  setMessage(res);
                }
              });
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [connection, subscriptionId]);

  useEffect(() => {
    let isSubscribed = true;
    const body = {
      ...project,
      assignedRoles,
      sourceProjectId
    }
    post<NewProjectRequest, HangfireResponse>('/project',body).then(
      (res) => {
        if(isSubscribed) {
          res.subscriptionId && setSubscriptionId(res.subscriptionId);
       }
    }).catch(setError);

    return () => {
      isSubscribed = false;
    }
  }, [])

  const onCancel = () => {
    closeModal && closeModal();
  }

  const onClose = () => {
    history.push({pathname: CommonPaths.manage.projects.edit(project.code)});
    closeModal && closeModal();
  }

  const renderLoadingForm = (): JSX.Element => {
    return (
      <div>
        <Typography variant="body1">Please wait.</Typography>
        <Typography variant="body1">Do not close this window until the process is complete.</Typography>
      </div>
    );
  }

  return (
    <Dialog open={true}
            maxWidth="lg">
      <DialogTitle>{`Creating ${project.code} ${project.name}`}</DialogTitle>
      <DialogContent>
        { message?.isComplete !== true && message?.didFail !== true && renderLoadingForm()}
        {
          message
            ? (
              <div className={classNames.list}>
                <ListItem {...message.project} />
                {message.mvZones && <ListItem {...message.mvZones} />}
                {message.penetrations && <ListItem {...message.penetrations} />}
                {message.areaForemen && <ListItem {...message.areaForemen}/>}
                {message.productionResponsible && <ListItem {...message.productionResponsible}/>}
                {message.zone3ds && <ListItem {...message.zone3ds}/>}
                {message.areas && <ListItem {...message.areas}/>}
                {message.systemGroups && <ListItem {...message.systemGroups}/>}
                {message.systems && <ListItem {...message.systems}/>}
                {message.tags && <ListItem {...message.tags}/>}
                {message.cableTypes && <ListItem {...message.cableTypes}/>}
                {message.bundles && <ListItem {...message.bundles}/>}
                {message.cableFreePull && <ListItem {...message.cableFreePull}/>}
                {message.cables && <ListItem {...message.cables}/>}
                <Typography variant="body1">{`Status: ${message.projectStatus}`}</Typography>
                {message.message && <Typography variant="body1">{message.message}</Typography>}
              </div>
            )
            : <Skeleton width="15rem" height="2rem"/>
        }
      </DialogContent>
      <DialogActions>
        {
          message && message.didFail && <VectButton onClick={onCancel} color="default" >Cancel</VectButton>
        }
        <VectButton onClick={onClose} color="primary" disabled={message == null || !message.isComplete || message.didFail} >Close this window</VectButton>
      </DialogActions>
    </Dialog>
  );
}

const ListItem: React.FC<CreateProjectItem> = ({
  name,
  status,
  progress
}) => {
  const classNames = useStyles();
  const getProgress = (val: number): string => {
    return val > 0 && val < 100 ? `(${progress}%)` : "";
  }

  return (
    <div className={classNames.listItem}>
      <div className={classNames.listIcon}>
        <ListIcon progress={progress} />
      </div>
      <Typography variant="body1">{`${name}: ${status} ${getProgress(progress)}`}</Typography>
    </div>
  );
}

const ListIcon: React.FC<{progress: number}> = ({progress}) => {
  if(progress === 100){
    return <DoneSharp />;
  }
  if(progress > 0) {
    return <UpdateSharp />;
  }

  return <HourglassEmptySharp />;
}
