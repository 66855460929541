import {format, parseISO} from "date-fns";

export const formatDate = (date?: string | Date | null, withTime = false) : string  => {
  if(date) {
    const stringed = String(date);
    return !withTime
      ? format(parseISO(stringed), "dd.MM.yyyy")
      : format(parseISO(stringed), "dd.MM.yyyy HH:mm");
  }
  return "";
}

export const getWeekNumber = (date: Date): [number, number] => {
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(
    ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  return [date.getUTCFullYear(), weekNo];
};

export const getYearWeekFormat = (date= new Date()): string => {
  const result: [number, number] = getWeekNumber(date);
  // If week == 53 return 52 instead
  if (result[1] === 53) {
    result[1] = 52;
  }
  return result[0].toString().substr(-2) + "-" + (result[1] < 10 ? "0" + result[1] : result[1]);
};

export const validateYearWeekFormat = (value: string | null | undefined): {isValid: boolean, message?: string} => {
  if(value == null || value.length === 0){
    return {isValid: true}
  }


  if(value.length !== 5 || value.match(/\d\d-\d\d/) === null) {
    return {isValid: false, message: "Format YY-WW is required."};
  }

  const year = parseInt(value.substr(0, 2));
  if(year < 17) {
    return {isValid: false, message: "Use year number between 17 and 99."}
  }

  const week = parseInt(value.substr(3, 2));
  if(week === 0 || week > 52) {
    return {isValid: false, message: "Use week number between 01 and 52."}
  }

  return {isValid: true}
}
