import React, {FunctionComponent} from "react";
import {
  generalStyles,
  StyledTableRow,
  TableCellInput,
  tableCellStyles,
  tableStyles,
} from "../../../shared/table/styles";
import {CableWithReferenceLabels} from "../../../../shared/interfaces/cable.interface";
import {Option} from "../../../shared/inputs/VectSelect";
import {vectLightExtension} from "../../../../shared/theme";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {ProjectClaims} from "../../../../shared/claims";
import {VectTableRowSelect} from "../../../shared/inputs/VectTableRowSelect";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {CableType} from "../../../../shared/interfaces/cableType.interface";
import {VectTableTextField} from "../../../shared/inputs/VectTableTextField";

type PenetrationListRowItemProps = {
  cable: CableWithReferenceLabels;
  deleteCable: (id: number) => void;
  systemOptions: Option[] | null;
  tagOptions: Option[] | null;
  areaOptions: Option[] | null;
  mvZoneOptions: Option[] | null;
  bundleOptions: Option[] | null;
  zone3dOptions: Option[] | null;
  cableClassTypeOptions: Option[] | null;
  cableType: (cable: CableWithReferenceLabels) => CableType | null;
  cableTypeOptions: Option[] | null;
  areaForemanOptions: Option[] | null;
};

const PenetrationListRowItem: FunctionComponent<PenetrationListRowItemProps> = ({
  cable,
  tagOptions,
  areaOptions,
  mvZoneOptions,
  bundleOptions,
  zone3dOptions,
  cableType,
  cableTypeOptions,
  areaForemanOptions,
}) => {
  const cellClasses = tableCellStyles();
  const classes = tableStyles();
  const general = generalStyles();
  const editPenetrationsClaim = ProjectClaims.project.routing.external

  const errors = {
    cableNumber: cable.cableNumber ? undefined : "A cable number is required"
  }

  const style = cable.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow className={classes.tableRowWrapper} style={style}>
      {/* ! NEW IMPLEMENTATION */}
      <div
        style={{width: "14rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.xFlex}>
            <div className={general.spaceRight}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                error={!!errors.cableNumber}
                helperText={errors.cableNumber}
                fieldName="cableNumber"
                value={cable.cableNumber}
                variant="standard"
                fullWidth
                required
                disabled={true}
              />
            </div>
            <VectTableTextField
              value={cableType(cable)?.signalType}
              disabled={true}
            />
          </div>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.cableTypeId}
            fieldName="cableTypeId"
            options={cableTypeOptions}
            disabled={true}
          />
          <VectTextField
            style={
              cable.releaseForProductionWeek
                ? {backgroundColor: vectLightExtension.palette.custom.status.allOk}
                : cable.releaseForMeasuringWeek
                ? {backgroundColor: vectLightExtension.palette.custom.status.incomplete}
                : {backgroundColor: vectLightExtension.palette.custom.status.notOkay}
            }
            value={cable.code}
            disabled={true}
            variant="standard"
            fullWidth
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "8rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromTagId}
            fieldName="fromTagId"
            options={tagOptions}
            disabled={true}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromAreaId}
            fieldName="fromAreaId"
            options={areaOptions}
            disabled={true}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromMainVerticalZoneId}
            fieldName="fromMainVerticalZoneId"
            options={mvZoneOptions}
            disabled={true}
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "20rem"}}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.yFlexWSpace}>
            <div style={{marginBottom: 29}}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                multiline={true}
                value={cable.fromText}
                fieldName="fromText"
                variant="standard"
                fullWidth
                disabled={true}
              />
            </div>
            <div className={general.xFlex}>
              <div style={{marginLeft: -10, width: "4rem"}}>
                <VectCheckbox
                  claim={ProjectClaims.project.production.external}
                  checked={cable.isConnectedFrom}
                  fieldName="isConnectedFrom"
                  color="primary"
                  size={"small"}
                  disabled={true}
                />
              </div>
              <div style={{width: "7rem"}} className={general.spaceRight}>
                <VectTableRowSelect
                  claim={ProjectClaims.project.production.coordinator}
                  value={cable.fromConnectionAreaForemanId}
                  fieldName={"fromConnectionAreaForemanId"}
                  options={areaForemanOptions}
                  disabled={true}
                />
              </div>
              <div style={{width: "8rem"}} className={general.spaceRight}>
                <VectTextField
                  fieldName={"fromZone3dId"}
                  value={
                    zone3dOptions?.find((x) => x.value === cable.fromZone3dId)
                      ?.selectedLabel
                  }
                  disabled={true}
                  variant="standard"
                  fullWidth
                />
              </div>
              <div style={{marginBottom: 10}}>
                <VectTextField
                  value={cable.cableClassCode}
                  disabled={true}
                  variant="standard"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </TableCellInput>
      </div>
      <div
        style={{width: "8rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toTagId}
            fieldName="toTagId"
            options={tagOptions}
            disabled={true}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toAreaId}
            fieldName="toAreaId"
            options={areaOptions}
            disabled={true}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toMainVerticalZoneId}
            fieldName="toMainVerticalZoneId"
            options={mvZoneOptions}
            disabled={true}
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "20rem"}}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.yFlexWSpace}>
            <div style={{marginBottom: 29}}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                value={cable.toText}
                fieldName="toText"
                multiline={true}
                variant="standard"
                fullWidth
                disabled={true}
              />
            </div>
            <div className={general.xFlex}>
              <div style={{marginLeft: -10, width: "7rem"}}>
                <VectCheckbox
                  claim={ProjectClaims.project.production.external}
                  checked={cable.isConnectedTo}
                  fieldName="isConnectedTo"
                  color="primary"
                  size={"small"}
                  disabled={true}
                />
              </div>
              <div style={{width: "11rem"}} className={general.spaceRight}>
                <VectTableRowSelect
                  claim={ProjectClaims.project.production.coordinator}
                  value={cable.toConnectionAreaForemanId}
                  fieldName="toConnectionAreaForemanId"
                  options={areaForemanOptions}
                  disabled={true}
                />
              </div>
              <VectTextField
                value={
                  zone3dOptions?.find((x) => x.value === cable.toZone3dId)?.selectedLabel}
                disabled={true}
                variant="standard"
                fullWidth
              />
              <VectCheckbox
                claim={"TODO"}
                checked={cable.hasSpecialRequirements}
                fieldName="hasSpecialRequirements"
                color="primary"
                size={"small"}
                disabled={true}
              />
            </div>
          </div>
        </TableCellInput>
      </div>
      <div
        style={{width: "10rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableRowSelect
            claim={ProjectClaims.project.routing.external}
            value={cable.bundleId}
            fieldName="bundleId"
            options={bundleOptions}
            disabled={true}
          />
          <VectTextField
            claim={ProjectClaims.project.engineering.external}
            value={cable.estimatedMeters}
            fieldName="estimatedMeters"
            type="number"
            variant="standard"
            fullWidth
            disabled={true}
          />
          <VectTextField
            claim={ProjectClaims.project.routing.external}
            value={cable.meters}
            fieldName="meters"
            type="number"
            variant="standard"
            fullWidth
            disabled={true}
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "11rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTextField
            fieldName={"bundleText"}
            value={
              bundleOptions!
                .find((x) => x.value === cable.bundleId)
                ?.label.split("| ")[1]
            }
            disabled={true}
            variant="standard"
            fullWidth
          />
          <VectTextField
            claim={ProjectClaims.project.routing.external}
            value={cable.markPlacement}
            fieldName="markPlacement"
            variant="standard"
            fullWidth
            disabled={true}
          />
          <div>
            <VectTextField
              claim={editPenetrationsClaim}
              value={cable.penetrations}
              fieldName="penetrations"
              variant="standard"
              disabled={true}
              // InputProps={penetrationsInputProps}
            />
          </div>
        </TableCellInput>
      </div>
      <div
        style={{width: "6rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTextField
            value={cable.cutWeek}
            disabled={true}
            variant="standard"
            fullWidth
          />
          <VectTextField
            value={cable.pulledWeek}
            disabled={true}
            variant="standard"
            fullWidth
          />
          <VectTextField
            value={cable.dp3Zone}
            disabled={true}
            variant="standard"
            fullWidth
          />
        </TableCellInput>
      </div>
    </StyledTableRow>
  );
};

export default PenetrationListRowItem;
