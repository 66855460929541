import React, {CSSProperties, useEffect, useState} from "react";
import PanelHeader, {Action} from "../../../shared/layout/PanelHeader/PanelHeader";
import {useHistory, useParams} from "react-router-dom";
import {CommonPaths} from "../../../../shared/urls";
import {Project} from "../../../../shared/interfaces/project.interface";
import {useApi} from "../../../../shared/hooks/useApi";
import {Skeleton} from "@material-ui/lab";
import {MuiThemeProvider, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {formatDate} from "../../../../shared/helpers/date";
import {StyledTableRow, tableTheme} from "../../../shared/table/styles";
import {VectTableReadOnlyText} from "../../../shared/inputs/VectTableReadOnlyText";
import {AreaImportLogItem, AreaImportLogResult, useStyles} from "./support";

export const ImportLogAreas: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [areaLog, setAreaLog] = useState<AreaImportLogResult[] | null>(null)
  const {get} = useApi();
  const {projectCode} = useParams<{ projectCode: string }>();
  const history = useHistory();

  useEffect(() => {
    if(projectCode == null) {
      return;
    }

    let isSubscribed = true;
    (async () => {
      const projectResult = await get<Project>(`project/${projectCode}`);
      const areaLogResult = await get<AreaImportLogResult[]>(`manage/project/${projectResult.id}/log/area-import`);
      if(isSubscribed) {
        setProject(projectResult);
        setAreaLog(areaLogResult);
      }
    })();

    return () =>  {
      isSubscribed = false;
    };
  }, [projectCode]);

  const onClose:Action = {
    action: () => history.push(CommonPaths.manage.projects.edit(projectCode))
  }
  return (
    <div>
      {
        project != null
          ? <PanelHeader text={`Area import log for ${project.code || ""} ${project.name || ""}`} close={onClose}/>
          : <Skeleton height="1.5rem" width="15rem"/>
      }
      {
        areaLog && areaLog.map(x => <AreaLogTable key={x.id} {...x}/>)
      }

    </div>
  )
}

const AreaLogTable: React.FC<AreaImportLogResult> = ({
 createdAt,
 userName,
 userEmail,
 areas
}) => {
  const classNames = useStyles();
  return (
    <Paper className={classNames.table}>
      <Typography variant="h6">{formatDate(createdAt, true)}</Typography>
      <Typography variant="subtitle1">{`By ${userName} (${userEmail})`}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Area ID</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Resp. Foreman ID</TableCell>
            <TableCell>Foreman text</TableCell>
            <TableCell>Main Vert. Zone</TableCell>
            <TableCell>3D Zone ID</TableCell>
            <TableCell>3D Zone text</TableCell>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            {areas.map(x => <AreaLogTableRow key={x.id} {...x}/>)}
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </Paper>
  )
}

const AreaLogTableRow: React.FC<AreaImportLogItem> = ({
  code,
  name,
  areaForemanCode,
  areaForemanName,
  mainVerticalZoneCode,
  zone3dCode,
  zone3dName,
  didCreateAreaForeman,
  didCreateMainVerticalZone,
  didCreateZone3d
}) => {
  const newItemStyle: CSSProperties = {
    fontWeight: "bold"
  };

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={code}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={name}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={didCreateAreaForeman ? newItemStyle : undefined} value={areaForemanCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={didCreateAreaForeman ? newItemStyle : undefined} value={areaForemanName}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={didCreateMainVerticalZone ? newItemStyle : undefined} value={mainVerticalZoneCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={didCreateZone3d ? newItemStyle : undefined} value={zone3dCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={didCreateZone3d ? newItemStyle : undefined} value={zone3dName}/>
      </TableCell>
    </StyledTableRow>
  )
}
