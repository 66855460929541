import React, {FC, useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import Pagination from "../../shared/table/Pagination";
import {BundleProps, BundleRegisterProps, IBundleRegister} from "../../../shared/interfaces/bundle.interface";
import BundleRowItem from "./BundleRowItem";
import {ProductionResponsible} from "../../../shared/interfaces/productionResponsible.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {tableTheme} from "../../shared/table/styles";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {useStyles} from "../tag/Tags.styles";
import {useModals} from "../../../shared/context/ModalContext";
import {AlertContext} from "../../../shared/context/AlertContext";
import {compare} from "fast-json-patch";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {handleRowChange} from "../../../shared/helpers/table";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {
  dp3Options,
  getRelevantDp3Option,
  getRelevantPowerSignalOption,
  getRelevantProductionStatusOption,
  productionStatusOptions,
  selectOpts,
  signalTypeOptions
} from "./options";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {prodStatAutoComplete} from "../bundle-status/BundleStatus";
import {mapAreaForemanOptions, mapSystemOptions} from "../../../shared/helpers/metadata";

export type BundleFilter = {
  bundleCode?: string,
  foremanId?: number,
  bundleStatus?: number,
  systemId?: number,
  cableProductionResponsibleId?: number,
  srtpRelated?: number,
  cut?: string,
  pulled?: string
}

const BundleRegister: FC = () => {
  // State
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<IBundleRegister[] | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Data
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  const [productionResponsible, setProductionResponsible] = useState<ProductionResponsible[] | null>(null);
  // Filtering
  const [bundleId, setBundleId] = useState<string>("");
  const [selectedSystem, setSelectedSystem] = useState<SystemListItem | null>(null);
  const [selectedForeman, setSelectedForeman] = useState<Areaforeman | null>(null);
  const [productionResponsibleOpt, setProductionResponsibleOpt] = useState<Option | null>(null);
  const [srtpRelated, setSrtpRelated] = useState<Option | null>(null);
  const [bundleStatus, setBundleStatus] = useState<Option | null>(null);
  // Management
  const [prevNewId, setPrevNewId] = useState<number>(0)

  const classes = useStyles();
  const {openModal} = useModals();
  const {project} = useContext(ProjectContext);
  const {get, post} = useApi();
  const {setAlert, setSuccess} = useContext(AlertContext);

  useEffect(() => {
    let isSubscribed = true;
    setIsLoading(true);
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize
      };

      // Filter
      const filter: BundleFilter = {
        bundleCode: bundleId,
        foremanId: selectedForeman?.id,
        bundleStatus: bundleStatus?.value,
        systemId: selectedSystem?.id,
        cableProductionResponsibleId: productionResponsibleOpt?.value,
        srtpRelated: srtpRelated?.value
      };

      (async () => {
        const requestUrl = `/project/${project?.id}/bundle/list`;
        await post<PaginationRequest, any>(requestUrl, paginationQuery,
          {},
          {
            ...filter
          }).then(result => {
          if (isSubscribed && result !== undefined) {
            setInitialValues(result.values);
            dispatch({
              type: "SET_STATE",
              payload: () => ({
                ...result,
                values: result.values.map((x: IBundleRegister) => ({
                  ...x,
                  isMarked: false
                }))
              })
            });
          }
        })
      })();
    } finally {
      setIsLoading(false);
      setIsDirty(false);
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    state?.pageSize,
    state?.currentPage,
    srtpRelated,
    productionResponsibleOpt,
    bundleStatus,
    selectedForeman,
    bundleId,
    selectedSystem
  ]);

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      await get<SystemListItem[]>(`/project/${project?.id}/system`)
        .then(res => {
          if (isSubscribed) {
            setSystems(res);
          }
        });
      await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`)
        .then(res => {
          if (isSubscribed) {
            setForemen(res);
          }
        });
      await get<ProductionResponsible[]>(`/project/${project?.id}/productionResponsible`)
        .then(res => {
          if (isSubscribed) {
            setProductionResponsible(res);
          }
        });
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  /**
   * Mapping options
   */

  const systemOptions = useMemo((): Option[] | null => mapSystemOptions(systems), [systems]);

  const foremanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(foremen), [foremen]);

  const prodRespOptions = useMemo((): Option[] | null =>
    productionResponsible?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: `${x.code} ${x.name}`
    })) || null, [productionResponsible]);

  const addNewItem = () => {
    const newId = prevNewId - 1;

    const activeAreaForemen = foremen?.filter(x => x.isActive);
    const areaForemanId = activeAreaForemen && activeAreaForemen.length > 0 && activeAreaForemen[0].id || undefined;
    const activeSystems = systems?.filter(x => x.isActive);
    const systemId = activeSystems && activeSystems.length > 0 && activeSystems[0].id || undefined;

    const newItem: IBundleRegister = {
      state: "new",
      code: "",
      name: "",
      extendedInformation: "",
      productionStatus: "Draft - not ready for production",
      bundleType: "P",
      id: newId,
      projectId: project ? project.id : 0,
      areaForemanId,
      systemId,
      cableProductionResponsibleId: prodRespOptions && prodRespOptions.length > 0 ? prodRespOptions[0].value as number : undefined,
      createdIn: 0,
      pulledIn: 0,
      cutIn: 0,
      cut: "No",
      pulled: "No",
      doPrintId: false,
      hasSpecialRequirements: false,
      hasError: true,
    }
    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
  }

  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: IBundleRegister) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u)
        .filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<BundleRegisterProps>>((n: IBundleRegister) => ({
        referenceId: n.id,
        props: {
          code: n.code,
          name: n.name,
          extendedInformation: n.extendedInformation,
          productionStatus: getRelevantProductionStatusOption(n.productionStatus)?.label,
          dp3Zone: getRelevantDp3Option(n.dp3Zone)?.label,
          bundleType: getRelevantPowerSignalOption(n.bundleType)?.selectedLabel,
          areaForemanId: n.areaForemanId,
          systemId: n.systemId,
          cableProductionResponsibleId: n.cableProductionResponsibleId,
          createdIn: n.createdIn,
          pulledIn: n.pulledIn,
          cutIn: n.cutIn,
          doPrintId: n.doPrintId,
          hasSpecialRequirements: n.hasSpecialRequirements,
          labelledDate: n.labelledDate,
          releasedDate: n.releasedDate,
          printedDate: n.printedDate,
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted" && x.id > 0)
      .map((x: Tag) => x.id);

    const body = {
      newItems,
      patchedItems,
      deletedItems
    }

    try {
      setIsLoading(true);
      const updateBundleResult = await post<PatchRequest<BundleProps>, PatchResponse<IBundleRegister>>
      (`project/${project!.id}/bundle`, body);
      refreshVisibleBundleRegisters(updateBundleResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the bundle list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the bundle list"});
      }
    } finally {
      setIsLoading(false);
    }
  }

  const change = useCallback((bundle: IBundleRegister) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        const prevBundle = prevValues.find(x => x.id === bundle.id);
        if (prevBundle.bundleType !== bundle.bundleType) {
          bundle.bundleType = getRelevantPowerSignalOption(bundle.bundleType)?.selectedLabel;
        }
        if (prevBundle.productionStatus !== bundle.productionStatus) {
          bundle.productionStatus = getRelevantProductionStatusOption(bundle.productionStatus)?.label;
        }
        if (prevBundle.dp3Zone !== bundle.dp3Zone) {
          bundle.dp3Zone = getRelevantDp3Option(bundle.dp3Zone)?.label;
        }

        return handleRowChange(
          prevValues,
          bundle,
          setIsDirty);
      }
    });
  }, []);

  const copy = useCallback((source: IBundleRegister) => {
    const newId = prevNewId - 1;
    setPrevNewId(newId)

    const sourceAreaForeman = source.areaForemanId ? foremen?.find(x => x.id === source.areaForemanId && x.isActive) : undefined;
    const sourceSystem = source.systemId ? systems?.find(x => x.id === source.systemId && x.isActive) : undefined;

    const target: IBundleRegister = {
      id: newId,
      projectId: project ? project.id : 0,
      code: source.code,
      name: source.name,
      extendedInformation: source.extendedInformation,
      productionStatus: getRelevantProductionStatusOption(source.productionStatus)?.selectedLabel,
      bundleType: getRelevantPowerSignalOption(source.bundleType)?.label,
      dp3Zone: getRelevantDp3Option(source.dp3Zone)?.label,
      areaForemanId: sourceAreaForeman?.id,
      systemId: sourceSystem?.id,
      cableProductionResponsibleId: source.cableProductionResponsibleId,
      createdIn: source.createdIn,
      pulledIn: source.pulledIn,
      cutIn: source.cutIn,
      doPrintId: source.doPrintId,
      hasSpecialRequirements: source.hasSpecialRequirements,
      state: "new",
      hasError: source.hasError
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setIsDirty(true);
  }, [prevNewId])

  const refreshVisibleBundleRegisters = (updates: PatchResponse<IBundleRegister>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: IBundleRegister[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  /* Validators */

  const areDependenciesLoaded =
    foremen != null &&
    systems != null &&
    productionResponsible != null;

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  /* Filters */

  const onBundleIdChange = (value: string) => {
    promptIfUnsavedChanges(openModal, isDirty, () => setBundleId(value));
  }

  const systemFilter = useMemo(() => {
    const options = mapSystemOptions(systems);
    return (
      <VectSelect
        id={"systemIdFilter"}
        isClearable={true}
        label="System ID"
        placeholder={"System ID"}
        value={selectedSystem?.id}
        change={value => {
          const action = () => {
            if(value != null) {
              const system = systems?.find(x => x.id === value);
              if (system) {
                setSelectedSystem(system);
              }
            } else {
              setSelectedSystem(null);
            }

          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [systems, selectedSystem, isDirty]);

  const areaForemanFilter = useMemo(() => {
    const options = mapAreaForemanOptions(foremen);
    return (
      <VectSelect
        id={"foremanIdFilter"}
        isClearable={true}
        placeholder={"Resp Foreman"}
        label="Resp Foreman"
        value={selectedForeman?.id}
        change={value => {
          const action = () => {
            if(value != null) {
              const foreman = foremen?.find(x => x.id === value);
              if (foreman) {
                setSelectedForeman(foreman);
              }
            } else {
              setSelectedForeman(null);
            }

          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [foremen, selectedForeman, isDirty]);

  const productionResponsibleFilter = useMemo(() => {
    return (
      <VectSelect
        isClearable={true}
        placeholder={"Production responsible"}
        label="Production responsible"
        value={productionResponsibleOpt?.value}
        change={value => {
          const action = () => {
            if(value != null) {
              const choice = prodRespOptions?.find(x => x.value === value);
              if (choice) {
                setProductionResponsibleOpt(choice);
              }
            } else {
              setProductionResponsibleOpt(null)
            }

          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={prodRespOptions}
      />
    );
  }, [productionResponsibleOpt, prodRespOptions, isDirty]);

  const srtpRelatedFilter = useMemo(() => {
    return (
      <VectSelect
        isClearable={true}
        label="SRtP Related"
        placeholder={"SRtP Related"}
        value={srtpRelated?.value}
        change={value => {
          const action = () => {
            if(value != null) {
              const choice = selectOpts?.find(x => x.value === value);
              if (choice) {
                setSrtpRelated(choice);
              }
            } else {
              setSrtpRelated(null);
            }

          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={selectOpts}
      />
    );
  }, [srtpRelated, selectOpts, isDirty]);

  const bundleStatusFilter = useMemo(() => {
    return (
      <VectSelect
        isClearable={true}
        label="Bundle status"
        placeholder={"Bundle status"}
        value={bundleStatus?.value}
        change={value => {
          const action = () => {
            if(value != null) {
              const choice = prodStatAutoComplete?.find(x => x.value === value);
              if (choice) {
                setBundleStatus(choice);
              }
            } else {
              setBundleStatus(null);
            }

          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={prodStatAutoComplete}
      />
    );
  }, [bundleStatus, prodStatAutoComplete, isDirty]);

  return (
    <div>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Bundle Register"}
        add={{
          action: addNewItem,
          disabled: !areDependenciesLoaded
        }}
        save={{
          action: update,
          disabled: !areDependenciesLoaded || hasErrors || !isDirty
        }}/>

      {/* Filter */}
      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
          <div className={classes.filterItem}>
            <VectTextField
              value={bundleId}
              change={onBundleIdChange}
              label={"Bundle ID"}
              debounceDelay={1000}
            />
          </div>
          <div className={classes.filterItem}>
            {areaForemanFilter}
          </div>
          <div className={classes.filterItem}>
            {bundleStatusFilter}
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div className={classes.filterItem}>
            {systemFilter}
          </div>
          <div className={classes.filterItem}>
            {productionResponsibleFilter}
          </div>
          <div className={classes.filterItem}>
            {srtpRelatedFilter}
          </div>
        </div>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: "15rem"}}>
              Bundle ID<br/>
              Bundle Production Status
            </TableCell>
            <TableCell style={{width: "50rem"}}>
              Bundle Text <br/>
              Bundle Extra Info
            </TableCell>
            <TableCell style={{width: "7rem"}}>
              Pow / Sig<br/>
              Print ID
            </TableCell>
            <TableCell style={{width: "12rem"}}>
              System ID<br/>
              DP3 Zone
            </TableCell>
            <TableCell style={{width: "12rem"}}>
              Resp Foreman<br/>
              Prod Resp.
            </TableCell>
            <TableCell padding={"none"} style={{width: "0.5rem"}}>
              SRtP Related
            </TableCell>
            <TableCell style={{width: "14rem"}}>
              Released<br/>
              Printed<br/>
              Labelled
            </TableCell>
            <TableCell style={{width: "1rem"}}>
              Cutted status
            </TableCell>
            <TableCell style={{width: "1rem"}}>
              Fixed status
            </TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state.values.map((bundle: IBundleRegister, index: number) => (
                <BundleRowItem
                  key={index}
                  bundle={bundle}
                  change={change}
                  copy={copy}
                  dp3={dp3Options}
                  powSig={signalTypeOptions}
                  prodStatOps={productionStatusOptions}
                  foremanOptions={foremanOptions}
                  prodRespOptions={prodRespOptions}
                  systemOptions={systemOptions}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch}/>
    </div>
  )
}
export default BundleRegister;
