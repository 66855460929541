/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import React, {useMemo} from "react";
import WindowedSelect from "react-windowed-select";
import {Skeleton} from "@material-ui/lab";
import {useClaims} from "../../../shared/hooks/useClaims";
import {VectTextField} from "./VectTextField";
import {Typography} from "@material-ui/core";

export const selectStyles = {
  menu: (provided: any): any => ({
    ...provided,
    zIndex: 100
  })
};

export const selectStylesWideMenu = (width = "50rem", rightAlign = false) => {


  const localOverrides = (provided: any) => {
    const inheritedOverrides = {...selectStyles.menu(provided)};
    return {
      ...inheritedOverrides,
      position: rightAlign ? "absolute" : inheritedOverrides.position,
      right: rightAlign ? 0 : inheritedOverrides.right
    }
  };

  return {
  ...selectStyles,
    menu: (provided: any) => ({
      ...provided,
      ...localOverrides(provided),
      width,
  })}
}


export interface Option {
  value: number;
  label: string;
  /**
   * If set, overrides label when the select is collapsed.
   */
  selectedLabel?: string;
  /**
    Inactive options are selectable in code for reference with older references, but
   not available for selection in the select menu.

   Unset options are treated as active
   */
  isActive?: boolean
}

/**
 * These props are mainly wrappers for the React-Select props
 * https://react-select.com/props
 */
export interface VectSelectProps extends AuthProps {
  /**
   *
   * @param value     Value of the new selected option. When isClearable is set, value is null when the
   *                  clear option is pressed.
   * @param fieldName Repeats the fieldName prop argument
   */
  change?: (value: number | null, fieldName?: string) => void;
  className?: string;
  classNamePrefix?: string;
  disabled?: boolean;
  /**
   * When used in a table row, you may include fieldName to pipe all changed
   * events to the same callback function.
   */
  fieldName?: string;
  id?: string;
  isClearable?: boolean;
  label?: string;
  value?: number | null;
  options: Option[] | null;
  placeholder?: string;
  styles?: any;
}

export const VectSelect: React.FC<VectSelectProps> = ({
  change,
  claim,
  className,
  classNamePrefix,
  disabled,
  fieldName,
  id,
  isClearable,
  label,
  value: initialValue,
  options: initialOptions,
  placeholder,
  styles,
  useExactMatch,
}) : JSX.Element => {
  const {hasClaim} = useClaims();

  const initialOption = initialOptions?.find(x => x.value === initialValue) || null;
  const options = useMemo((): Option[] | null => {
    return initialOptions?.filter(x => x.isActive !== false) || null;
  }, [initialOptions])

  const onSelectChange = (
    option: Option | null | undefined,
    action: {
      action: "select-option" | "deselect-option"| "remove-value"| "pop-value"| "set-value" | "clear" | "create-option"
    }) => {
    if(option && action.action === "select-option"){
      change && change(option.value, fieldName);
    } else if (action.action === "clear"){
      change && change(null, fieldName);
    }
  }

  const renderSelect = (className?: string):JSX.Element => {
    return (
      <WindowedSelect id={id}
                      className={className}
                      styles={styles || selectStyles}
                      options={options}
                      menuPlacement="auto"
                      onChange={onSelectChange}
                      placeholder={placeholder}
                      value={initialOption}
                      classNamePrefix={classNamePrefix}
                      formatOptionLabel={
                        (option: Option, {context}:{context: "menu" | "value"}) =>
                          context === "menu" ? option.label : option.selectedLabel || option.label
                      }
                      isClearable={isClearable}
                      isDisabled={disabled}/>
    );
  }

  return options != null ? (
    !claim || hasClaim(claim, useExactMatch)
    ? (
        label
          ? (
            <div className={className}>
              <Typography variant="caption">{label}</Typography>
              {renderSelect()}
          </div>
          )
          : renderSelect(className)
      )
    : (
        <VectTextField id={id}
                       claim={claim}
                       className={className}
                       useExactMatch={useExactMatch}
                       value={initialOption?.selectedLabel || initialOption?.label || ""}
                       fullWidth={true}
                       variant="standard"
                       label={label || placeholder}
                       disabled={disabled}

        />
      )

  ) : <Skeleton className={className}><WindowedSelect /></Skeleton>;
}

