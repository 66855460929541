import React, {useCallback, useContext, useEffect, useState,} from "react";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {useApi} from "../../../shared/hooks/useApi";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {UserContext} from "../../../shared/context/UserContext";
import {
  PutSystemGroupRequest,
  SystemGroupsForProjectManagementResult
} from "../../../shared/interfaces/systemGroup.interface";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import Table from "../../shared/table/Table";
import {Cell} from "react-table";
import {useHistory} from 'react-router-dom';

interface SystemGroupStatusEditProps {
  redirectTo: string
}

const SystemGroupStatusEdit: React.FC<SystemGroupStatusEditProps> = ({redirectTo}) => {
  const [groups, setGroups] = useState<SystemGroupsForProjectManagementResult[]>([]);
  const {user} = useContext(UserContext)
  const {project} = useContext(ProjectContext)
  const [reFetchData, setRefetchData] = useState<boolean>(false);
  const history = useHistory();
  const {get, put} = useApi()

  useEffect(() => {
    let isSubscribed = true;

    const  fetchGroups = async() => {
      if(project?.id) {
        const result = await get<SystemGroupsForProjectManagementResult[]>(`/project/${project.id}/systemGroup/manage`);
        if(isSubscribed){
          setGroups(result);
        }
      }
    }
    fetchGroups()
    return () => {
      isSubscribed = false
    };
  }, [project?.id, user.projects.length, reFetchData]);

  const data = React.useMemo(
    () => groups,
    [groups]
  );

  const update = async () => {
    const updatedGroups: PutSystemGroupRequest[] = groups
      .filter(x => x.state === "modified")
      .map(x => ({systemGroupId: x.id, isActive: x.isActive}));
    if(updatedGroups.length > 0) {
      await put(`/project/${project?.id}/systemGroup/manage`, updatedGroups)
        .then((res) => {
          if(res !== undefined)
          {
            setRefetchData(!reFetchData)
          }
        });
      }
    history.push(redirectTo);
  };

  const change= useCallback(
    (value, id: number, field: string) => {
      const item = groups.find(x => x.id === id)
      if(!(item) || item[field as keyof SystemGroupsForProjectManagementResult] === value)
      {
        return;
      }
      const newGroups = groups.map(item => {

        return item.id === id ? {
          ...item,
          [field]: value,
          state: "modified" as TableItemState
        } : item
      });
      setGroups(newGroups)
    },
    [groups]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        type: "number",
        hidden: true
      },
      {
        Header: "Group ID",
        accessor: "code",
        Cell: ({row}: Cell) => (
          row.values.code
        ),
        width:"10rem"

      },
      {
        Header: "Group Text",
        accessor: "name",
        Cell: ({row}: Cell) => (
          row.values.name
        ),
        width:"40rem"

      },
      {
        Header: "Active",
        accessor: "isActive",
        type: "checkbox",
        width: "1rem"
      },
      {
        Header: "Info from Sale",
        columns: [
          {
            Header: "Est. Pcs",
            accessor: "calculatedSoldAmount",
            Cell: ({row}: Cell) => (
              row.values.calculatedSoldAmount
            )
          },
          {
            Header: "Est. Meter",
            accessor: "calculatedSoldMeters",
            Cell: ({row}: Cell) => (
              row.values.calculatedSoldMeters
            )
          }
        ]
      }
    ],
    []
  )

  return (
    <div>
      <PanelHeader text="System Groups" save={{action: update}}/>
      <Table  change={change} columns={columns} data={data} hiddenColumns={["id"]}/>
    </div>
  )
}

export default SystemGroupStatusEdit;
