import React, {FunctionComponent, memo} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {ProjectClaims} from "../../../shared/claims";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {VectContainer} from "../../shared/layout/VectContainer";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {TableCell} from "@material-ui/core";
import {CopyButton} from "../../shared/table/CopyButton";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";

type MainVerticalZoneRowItemProps = {
  mvZone: MainVerticalZone;
  change: (value: MainVerticalZone) => void;
  copy: (value: MainVerticalZone) => void;
};

const MainVerticalZoneRowItem: FunctionComponent<MainVerticalZoneRowItemProps> = ({
  mvZone,
  change,
  copy,
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: mvZone.code ? undefined : "A main vertical zone code is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: MainVerticalZone): boolean => {
      return !newState.code;
    }

    handleCellChange(
      change,
      hasError,
      mvZone,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...mvZone,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(mvZone);
  }

  const style = mvZone.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput style={{padding: "1rem"}} className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.mainVerticalZone.write.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={mvZone.code}
          change={onCellChange}
          variant="standard"
          fullWidth
          required
        />
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.mainVerticalZone.write.all}>
          <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCell>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: 'center'}} claim={ProjectClaims.project.mainVerticalZone.write.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={mvZone.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={mvZone.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
};

export default memo(MainVerticalZoneRowItem);
