import {TableItemState} from "../../../shared/interfaces/common.interface";
import React from "react";
import {Edit} from "@material-ui/icons";

export const ModifiedIcon = ({itemState} :{
  itemState?: TableItemState}): JSX.Element => {


  return (
    <span style={{ alignSelf: "center" }}>
          {itemState &&
          (itemState === "new" || itemState === "modified") ? (
            <Edit />
          ) : null}
        </span>
  );
}
