import React, {useEffect, useState} from "react";
import PanelHeader, {Action} from "../../../shared/layout/PanelHeader/PanelHeader";
import {useHistory, useParams} from "react-router-dom";
import {CommonPaths} from "../../../../shared/urls";
import {Project} from "../../../../shared/interfaces/project.interface";
import {useApi} from "../../../../shared/hooks/useApi";
import {Skeleton} from "@material-ui/lab";
import {MuiThemeProvider, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {formatDate} from "../../../../shared/helpers/date";
import {StyledTableRow, tableTheme} from "../../../shared/table/styles";
import {VectTableReadOnlyText} from "../../../shared/inputs/VectTableReadOnlyText";
import {CableImportLogItem, CableImportLogResult, useStyles} from "./support";

export const ImportLogCables: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [cableLog, setCableLog] = useState<CableImportLogResult[] | null>(null)
  const {get} = useApi();
  const {projectCode} = useParams<{ projectCode: string }>();
  const history = useHistory();

  useEffect(() => {
    if(projectCode == null) {
      return;
    }

    let isSubscribed = true;
    (async () => {
      const projectResult = await get<Project>(`project/${projectCode}`);
      const cableLogResult = await get<CableImportLogResult[]>(`manage/project/${projectResult.id}/log/cable-import`);
      if(isSubscribed) {
        setProject(projectResult);
        setCableLog(cableLogResult);
      }
    })();

    return () =>  {
      isSubscribed = false;
    };
  }, [projectCode]);

  const onClose:Action = {
    action: () => history.push(CommonPaths.manage.projects.edit(projectCode))
  }
  return (
    <div>
      {
        project != null
        ? <PanelHeader text={`Cable import log for ${project.code || ""} ${project.name || ""}`} close={onClose}/>
        : <Skeleton height="1.5rem" width="15rem"/>
      }
      {
        cableLog && cableLog.map(x => <CableLogTable key={x.id} {...x}/>)
      }

    </div>
  )
}

const CableLogTable: React.FC<CableImportLogResult> = ({
  createdAt,
  userName,
  userEmail,
  id,
  cables
}) => {
  const classNames = useStyles();
  return (
    <Paper className={classNames.table}>
      <Typography variant="h6">{formatDate(createdAt, true)}</Typography>
      <Typography variant="subtitle1">{`By ${userName} (${userEmail})`}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>System ID</TableCell>
            <TableCell>Cable Number</TableCell>
            <TableCell>Cable Type ID</TableCell>
            <TableCell>From Tag ID</TableCell>
            <TableCell>From text</TableCell>
            <TableCell>To Tag ID</TableCell>
            <TableCell>To Text</TableCell>
            <TableCell>Meter</TableCell>
            <TableCell>Bundle ID</TableCell>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
              {cables.map(x => <CableLogTableRow key={`${id}${x.cableNumber}`} {...x}/>)}
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </Paper>
  )
}

const CableLogTableRow: React.FC<CableImportLogItem> = ({
  systemCode,
  cableNumber,
  cableTypeCode,
  fromTagCode,
  fromText,
  toTagCode,
  toText,
  meters,
  bundleCode
}) => {

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={systemCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableNumber}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableTypeCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={fromTagCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={fromText}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={toTagCode}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={toText} />
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={meters}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={bundleCode}/>
      </TableCell>
    </StyledTableRow>
  )
}
