import React, {FC, useEffect} from 'react';
import {
  ChartLine, ChartPoint,
} from "../../../shared/interfaces/common.interface";
import {Typography} from "@material-ui/core";
import * as d3 from 'd3';
import ChartLegend from "./ChartLegend";

interface LineChartProps {
  lines: ChartLine[],
  name: string
}

const LineChart: FC<LineChartProps> = ({lines, name}) => {
  useEffect(() => {
    drawChart()
  }, [])

  function drawChart() {
    // Remove the old chart
    d3.select('#container')
      .select('svg')
      .remove();

    const margin = {top: 50, right: 50, bottom: 50, left: 50};

    const findMaxYVal = () => {
      let result: number[] = [];
      lines.forEach(x => {
        const yVals = x.points.map(y => y.y);
        result = result.concat(yVals);
      })
      return result.reduce(function (prev, current) {
        return (prev > current) ? prev : current
      })
    }

    const xScale = d3
      .scaleBand()
      .domain(lines[0].points.map(x => x.x)) // same amount of points in
      // every array so we select the first one
      .range([0, 1500]);

    const yScale = d3
      .scaleLinear()
      .range([500, 0])
      .domain([0, findMaxYVal()]);


    const line = d3
      .line<ChartPoint>()
      .x((d: ChartPoint): any => xScale(d.x))
      .y((d: ChartPoint) => yScale(d.y as number))
      .curve(d3.curveMonotoneX);

    const svg = d3
      .select('#container')
      .append('svg')
      .attr('width', 1500 + margin.left + margin.right)
      .attr('height', 500 + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);


    // Append the X-axis
    svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${500})`)
      .call(d3.axisBottom(xScale).tickValues(xScale.domain().filter(function (d, i) {
        return !(i % 3)
      }))) // show every third value in x axis
      .selectAll("text")
      .attr("transform", "translate(-10,10)rotate(-45)")
      .style("text-anchor", "end")
      .style("font-size", "0.8rem");

    // Append the Y-axis
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale)).selectAll("text")
      .style("font-size", "0.8rem");

    // Append the path going through the data points
    lines.forEach(ele => {
      svg
        .append('path')
        .datum(ele.points)
        .attr('fill', 'none')
        .attr('stroke', ele.color)
        .attr('stroke-width', 2)
        .attr('d', line);
      svg.selectAll("myCircles")
        .data(ele.points)
        .enter()
        .append("circle")
        .attr("fill", ele.color)
        .attr("stroke", "2")
        .attr("cx", function (d) {
          return xScale(d.x) as number
        })
        .attr("cy", function (d) {
          return yScale(d.y)
        })
        .attr("r", 3)
    })


  }

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between"
      }}>
        <Typography variant={"h4"}>{name}</Typography>
        <ChartLegend lines={lines}/>
      </div>
      <div id="container"/>
    </div>
  )
};


export default LineChart;
