import React, {useEffect, useState} from "react";
import {Project, ProjectProps} from "../../../../shared/interfaces/project.interface";
import {VectContainer} from "../../../shared/layout/VectContainer";
import {GlobalClaims} from "../../../../shared/claims";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import {Skeleton} from "@material-ui/lab";
import {UsersAssignment, UsersAssignmentTable} from "../UsersAssignmentTable";
import {ProjectRole, UserListItem} from "../../../../shared/interfaces/user.interface";
import {EditProjectProps} from "./EditProjectProps";
import {Paper} from "@material-ui/core";
import {VectButton} from "../../../shared/navigation/VectButton";
import {makeStyles} from "@material-ui/core/styles";
import {FeaturedPlayListSharp} from "@material-ui/icons";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() => ({
  actionWrapper: {
    margin: "2rem 0 2rem 0",
    padding: "1rem",
    "& > button": {
      margin: "0 0.5rem 0 0.5rem"
    }
  }
}));

export const EditProject: React.FC<{
  onPropsChange: (props: ProjectProps, hasError: boolean) => void,
  onUsersChange: (assignments: UsersAssignment[]) => void,
  project: Project,
  projectRoles: ProjectRole[],
  users: UserListItem[]
}> = ({
  onPropsChange,
  onUsersChange,
  project,
  projectRoles: allProjectRoles,
  users: allUsers
}) => {
  const [initialAssignments, setInitialAssignments] = useState<UsersAssignment[] | null>(null);
  const classNames = useStyles();
  const history = useHistory();

  useEffect(() => {
    if(allUsers && project && allProjectRoles) {
      const assignments = allUsers
        .filter(x => x.projects.findIndex(p => p.projectId === project.id) > -1)
        .map(x => ({
          userId: x.objectId,
          name: x.name,
          roles: x.projects.flatMap(p => {
            if(p.projectId === project.id){
              const role = allProjectRoles.find(pr => pr.id === p.roleId);
              if(role != null) {
                return [role];
              }
            }
            return [];
          }) }));
      setInitialAssignments(assignments);
    }
  }, [allUsers, project, allProjectRoles]);

  const goToAreaImportLog = () => {
    history.push(`${encodeURIComponent(project.code)}/area-import`);
  }

  const goToCableImportLog = () => {
    history.push(`${encodeURIComponent(project.code)}/cable-import`);
  }
  const goToPenetrationImportLog = () => {
    history.push(`${encodeURIComponent(project.code)}/penetration-import`);
  }



  return (
    <div>
      <EditProjectProps project={{...project}} change={onPropsChange} />
      <Paper className={classNames.actionWrapper}>
        <VectButton variant="contained"
                    color="default"
                    onClick={goToCableImportLog}
                    startIcon={<FeaturedPlayListSharp/>} >Go to cable import log</VectButton>
        <VectButton variant="contained"
                    color="default"
                    onClick={goToPenetrationImportLog}
                    startIcon={<FeaturedPlayListSharp/>} >Go to penetration import log</VectButton>
        <VectButton variant="contained"
                    color="default"
                    onClick={goToAreaImportLog}
                    startIcon={<FeaturedPlayListSharp/>} >Go to area import log</VectButton>
      </Paper>
      <VectContainer claim={GlobalClaims.manage.user.project.readWrite.all}>
        {
          project
            ? <PanelHeader text={`Project roles for ${project.name != null ? project.name : ""}`} size="small"/>
            : <Skeleton width="15rem"/>
        }
        {
          allProjectRoles && allUsers && initialAssignments
            ? <UsersAssignmentTable roles={allProjectRoles}
                                    users={allUsers}
                                    initialAssignments={initialAssignments}
                                    onChange={onUsersChange}/>
            : <Skeleton variant="rect" width="15rem" height="5rem"/>
        }
      </VectContainer>
    </div>

  );
}
