import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import PanelHeader, {Action} from "../../shared/layout/PanelHeader/PanelHeader";
import {Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatDate} from "../../../shared/helpers/date";
import {useApi} from "../../../shared/hooks/useApi";
import {Skeleton} from "@material-ui/lab";
import {CaptionedText} from "../../shared/CaptionedText";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {Project, ProjectMetadata, ProjectProps} from "../../../shared/interfaces/project.interface";
import {VectDateTimeField} from "../../shared/inputs/VectDateTimeField";
import {AlertContext} from "../../../shared/context/AlertContext";
import {compare} from "fast-json-patch";

const containerDefaults = {
  padding: "10px"
}
const gridDefaults = {
  ...containerDefaults,
  display: "grid",
  columnGap: "0.2rem",
  rowGap: "1rem",
  justifyItems: "start",
  alignItems: "end",
}

const useStyles = {
  classNames: makeStyles(() => ({
    wrapper: {
      display: "flex",
      flexWrap: "wrap",
      "& > div": {
        marginRight: 10,
        marginBottom: 10
      }
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 10px 10px 0",
      "& > div": {
        marginTop: 5,
        marginBottom: 5
      },
      "& > p": {
        marginTop: 5,
        marginBottom: 5
      }
    }
  })),
  infoContainer: makeStyles(() => ({
    container: {
      ...gridDefaults,
      gridTemplateColumns: "8rem 10rem",
      gridTemplateRows: "2.5rem 2.5rem 2.5rem",
    },
    start: {
      gridColumnStart: 1,
      gridRowStart: 1
    },
    end: {
      gridColumnStart: 2,
      gridRowStart: 1
    },
    cableListManager: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2
    },
    projectSiteHull: {
      gridColumnStart: 1,
      gridRowStart: 3
    },
    projectSiteFinishing: {
      gridColumnStart: 2,
      gridRowStart: 3
    }
  })),
  reportContainer: makeStyles(() => ({
    container: {
      ...gridDefaults,
      gridTemplateColumns: "17rem 10rem",
      gridTemplateRows: "2.5rem 2.5rem 2.5rem 4rem"
    },
    plannedMetersLabel: {
      gridColumnStart: 1,
      gridRowStart: 1
    },
    plannedMetersValue: {
      gridColumnStart: 2,
      gridRowStart: 1,
      justifySelf: "end"
    },
    plannedNumberLabel: {
      gridColumnStart: 1,
      gridRowStart: 2
    },
    plannedNumberValue: {
      gridColumnStart: 2,
      gridRowStart: 2,
      justifySelf: "end"
    },
    reportedUsageLabel: {
      gridColumnStart: 1,
      gridRowStart: 3
    },
    reportedUsageValue: {
      gridColumnStart: 2,
      gridRowStart: 3
    },
    reportedDate: {
      gridColumnStart: 2,
      gridRowStart: 4,
      justifySelf: "end"
    }
  })),
  programmeContainer: makeStyles(() => ({
    container: {
      ...gridDefaults,
      gridTemplateColumns: "20rem 5rem 5rem",
      gridTemplateRows: "repeat(5, 2.5rem)"
    },
    plannedRegisterLabel: {
      gridColumnStart: 1,
      gridRowStart: 1
    },
    plannedRegisterStart: {
      gridColumnStart: 2,
      gridRowStart: 1
    },
    plannedRegisterEnd: {
      gridColumnStart: 3,
      gridRowStart: 1
    },
    plannedReleaseLabel: {
      gridColumnStart: 1,
      gridRowStart: 2
    },
    plannedReleaseStart: {
      gridColumnStart: 2,
      gridRowStart: 2
    },
    plannedReleaseEnd: {
      gridColumnStart: 3,
      gridRowStart: 2
    },
    plannedRoutingLabel: {
      gridColumnStart: 1,
      gridRowStart: 3
    },
    plannedRoutingStart: {
      gridColumnStart: 2,
      gridRowStart: 3
    },
    plannedRoutingEnd: {
      gridColumnStart: 3,
      gridRowStart: 3
    },
    plannedFixingLabel: {
      gridColumnStart: 1,
      gridRowStart: 4
    },
    plannedFixingStart: {
      gridColumnStart: 2,
      gridRowStart: 4
    },
    plannedFixingEnd: {
      gridColumnStart: 3,
      gridRowStart: 4
    },
    plannedConnectingLabel: {
      gridColumnStart: 1,
      gridRowStart: 5
    },
    plannedConnectingStart: {
      gridColumnStart: 2,
      gridRowStart: 5
    },
    plannedConnectingEnd: {
      gridColumnStart: 3,
      gridRowStart: 5
    }
  })),
  copyContainer: makeStyles(() => ({
    container: {
      ...containerDefaults,
      width: "40rem"
    }
  }))
};

const ProjectDashboard: React.FC = (): JSX.Element => {

  const [isDirty, setIsDirty] = useState(false);
  const [project, setProject] = useState<Project | null>(null);
  const [projectMetadata, setProjectMetadata] = useState<ProjectMetadata | null>(null);
  const [projectPatch, setProjectPatch] = useState<ProjectProps | null>(null);
  const {get, patch} = useApi();
  const {setSuccess, setError} = useContext(AlertContext);
  const {project: context} = useContext(ProjectContext);
  const classNames = useStyles.classNames();
  const infoContainer = useStyles.infoContainer();
  const reportContainer = useStyles.reportContainer();
  const programmeContainer = useStyles.programmeContainer();
  const copyContainer = useStyles.copyContainer();

  useEffect(() => {
    let isSubscribed = true;
    if(context)
    {
      (async () => {
        try{
          const result = await get<Project>(`project/${context.code}`);
          const metadataResult = await get<ProjectMetadata>(`project/${context.code}/metadata`);
          if(isSubscribed) {
            setProject(result);
            setProjectMetadata(metadataResult);
          }
        } catch (err) {
          setError(err);
        }
      })();
    }

    return () => {
      isSubscribed = false;
    }

  }, [context])

  useEffect(() => {
    project && setProjectPatch({
      ...project
    })
  }, [project])

  const saveAction = useMemo((): Action => {
    return {
      action: async () => {
        if(context != null && projectPatch != null && project != null) {
          try {
            const initialItem: ProjectProps = {
              ...project
            };
            const operations = compare(initialItem, projectPatch);
            await patch(`project/${context.code}`, operations);
            const result = await get<Project>(`project/${context.code}`);
            setProject(result);
            setSuccess();
            setIsDirty(false);
          } catch (err) {
            setError(err);
          }
        }
      },
      disabled: !isDirty
    }
  }, [project, projectPatch, isDirty, context]);

  const handleDateChange = useCallback((value: Date | null) => {
    setProjectPatch(prevState => {
      return {
        ...prevState!,
        reportedDate: value
      }
    });
    setIsDirty(true);
  }, []);

  const handleUsageChange = useCallback((value: string) => {
    setProjectPatch(prevState => {
      return {
        ...prevState!,
        reportedCableMeters: +value
      }
    });
    setIsDirty(true);
  }, []);

  if(project == null || projectMetadata == null)
  {
    return (
      <div>
        <PanelHeader text=""/>
        <div className={classNames.wrapper}>
          <div className={classNames.container}>
            <Skeleton width="15rem" height="1.5rem"/>
            <Skeleton width="15rem" height="1.5rem"/>
            <Skeleton width="15rem" height="1.5rem"/>
            <Skeleton width="15rem" height="1.5rem"/>
          </div>
          <div className={classNames.container}>
            <Skeleton width="15rem" height="1.5rem"/>
            <Skeleton width="15rem" height="1.5rem"/>
            <Skeleton width="15rem" height="1.5rem"/>
            <Skeleton width="15rem" height="1.5rem"/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <PanelHeader text={`${project.code} ${project.name}`} save={saveAction}/>
      <div className={classNames.wrapper}>
        <Paper className={infoContainer.container}>
          <CaptionedText className={infoContainer.start} label="Project start">{formatDate(project.startDate)}</CaptionedText>
          <CaptionedText className={infoContainer.end} label="Project stop">{formatDate(project.stopDate)}</CaptionedText>
          <CaptionedText className={infoContainer.cableListManager} label="Cable list manager">{project.projectManager}</CaptionedText>
          <CaptionedText className={infoContainer.projectSiteHull} label="Project site 1">{project.projectSiteHull}</CaptionedText>
          <CaptionedText className={infoContainer.projectSiteFinishing} label="Project site 2">{project.projectSiteFinishing}</CaptionedText>
        </Paper>
        <Paper className={reportContainer.container}>
          <Typography className={reportContainer.plannedMetersLabel} variant="body1">Planned total meters (sales):</Typography>
          <Typography className={reportContainer.plannedMetersValue} variant="body1">{projectMetadata.aggregates.calculatedSoldMeters}</Typography>
          <Typography className={reportContainer.plannedNumberLabel} variant="body1">Planned number of cables:</Typography>
          <Typography className={reportContainer.plannedNumberValue} variant="body1">{projectMetadata.aggregates.calculatedSoldAmount}</Typography>
          <Typography className={reportContainer.reportedUsageLabel} variant="body1">Reported usage of cable from store:</Typography>
          <VectTextField
            className={reportContainer.reportedUsageValue}
            value={projectPatch?.reportedCableMeters}
            change={handleUsageChange}
            type="number"/>
          <div className={reportContainer.reportedDate}>
            <VectDateTimeField
              label="Reported date"
              change={handleDateChange}
              inputVariant="filled"
              value={projectPatch?.reportedDate || null}/>
          </div>
        </Paper>
        <Paper className={programmeContainer.container}>
          <Typography className={programmeContainer.plannedRegisterLabel} variant="body1">Planned register cables (yy-ww):</Typography>
          <CaptionedText className={programmeContainer.plannedRegisterStart} label="Start">...</CaptionedText>
          <CaptionedText className={programmeContainer.plannedRegisterEnd} label="Stop">...</CaptionedText>
          <Typography className={programmeContainer.plannedReleaseLabel} variant="body1">Planned release cables (yy-ww):</Typography>
          <Typography className={programmeContainer.plannedReleaseStart} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedReleaseEnd} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedRoutingLabel} variant="body1">Planned meas./routing cables (yy-ww):</Typography>
          <Typography className={programmeContainer.plannedRoutingStart} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedRoutingEnd} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedFixingLabel} variant="body1">Planned fixing cables (yy-ww):</Typography>
          <Typography className={programmeContainer.plannedFixingStart} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedFixingEnd} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedConnectingLabel} variant="body1">Planned connecting cables (yy-ww):</Typography>
          <Typography className={programmeContainer.plannedConnectingStart} variant="body1">...</Typography>
          <Typography className={programmeContainer.plannedConnectingEnd} variant="body1">...</Typography>
        </Paper>
        {
          project.isCopy && (
            <Paper className={copyContainer.container}>
              <Typography variant="body1">This project is a copy of another project.</Typography>
              {
                projectMetadata.copiedFrom && <Typography variant="body2">{`${projectMetadata.copiedFrom.code} ${projectMetadata.copiedFrom.name}`}</Typography>
              }
            </Paper>
          )
        }
      </div>
    </div>
  );
}


export default ProjectDashboard;
