import {SystemListItem} from "../interfaces/system.interface";
import {Option} from "../../components/shared/inputs/VectSelect";
import {Tag} from "../interfaces/tag.interface";
import {Area} from "../interfaces/area.interface";
import {Areaforeman} from "../interfaces/areaforeman.interface";
import {CableTypeListItem} from "../interfaces/cableType.interface";

interface MapAreaForemanProps extends MapProps {
  /**
   * Callback to set a custom label for an area foreman.
   * @param val
   */
  withCustomLabel?: (val: Areaforeman) => string
}

interface MapSystemProps extends MapProps{

  /**
   * Callback to set a custom label for a system.
   * @param val
   */
  withCustomLabel?: (val: SystemListItem) => string
}

interface MapProps {
  /**
   * Shortens the label of the selected option only.
   */
  withShortLabel: boolean
}

export const mapAreaOptions = (
  areas: Area[] | null,
  props: MapProps = {
    withShortLabel: false
  }
): Option[] | null => {

  if(areas == null) {
    return null;
  }

  return areas.map(x => ({
    value: x.id,
    label: `${x.code} ${x.name}`,
    selectedLabel: props.withShortLabel ? x.code : undefined,
    isActive: x.isActive
  }))
};

export const mapAreaForemanOptions = (
  areaForemen: Areaforeman[] | null,
  props: MapAreaForemanProps = {
    withShortLabel: false
  }
): Option[] | null => {

  if(areaForemen == null) {
    return null;
  }

  return areaForemen.map(x => ({
    value: x.id,
    label: props.withCustomLabel?.(x) || `${x.code} ${x.name}`,
    selectedLabel: props.withShortLabel ? x.code : undefined,
    isActive: x.isActive
  }))
};

export const mapCableTypeOptions = (cableTypes: CableTypeListItem[] | null): Option[] | null => {

  if(cableTypes == null) {
    return null;
  }

  return cableTypes.map(x => ({
    value: x.id,
    label: x.code,
    isActive: x.isActive
  }))
};

export const mapSystemOptions = (
  systems: SystemListItem[] | null,
  props: MapSystemProps = {
    withShortLabel: false
  }): Option[] | null => {

  if(systems == null) {
    return null;
  }

  return systems.map(x => ({
    value: x.id,
    label: props.withCustomLabel?.(x) || `${x.code} ${x.name}`,
    selectedLabel: props.withShortLabel ? x.code : undefined,
    isActive: x.isActive
  }));
};

export const mapTagOptions = (
  tags: Tag[] | null,
  props: MapProps = {
    withShortLabel: false
  }
): Option[] | null => {

  if(tags == null) {
    return null;
  }

  return tags.map(x => ({
    value: x.id,
    label: `${x.code} ${x.name}`,
    selectedLabel: props.withShortLabel ? x.code : undefined,
    isActive: x.isActive
  }))
};
