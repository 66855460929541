export const GlobalClaims = {
  manage: {
    wildcard: "Manage.",
    baseline: {
      wildcard: "Manage.Baseline.",
      readWrite: {
        all: "Manage.Baseline.ReadWrite.All"
      }
    },
    project: {
      delete: {
        all: "Manage.Project.Delete.All"
      },
      wildcard: "Manage.Project.",
      readWrite: {
        all: "Manage.Project.ReadWrite.All"
      }
    },
    user: {
      addOrDisable: "Manage.User.AddOrDisable",
      wildcard: "Manage.User.",
      project: {
        readWrite: {
          all: "Manage.User.Projects.ReadWrite.All"
        }
      },
      roles: {
        readWrite: {
          all: "Manage.User.Roles.ReadWrite.All"
        }
      }
    }
  }
}

export const ProjectClaims = {
  project: {
    engineering: {
      coordinator: "Project.Engineering.Coordinator",
      basis: "Project.Engineering.Basis",
      external: "Project.Engineering.External"
    },
    production: {
      coordinator: "Project.Production.Coordinator",
      basis: "Project.Production.Basis",
      external: "Project.Production.External"
    },
    read: "Project.Read",
    routing: {
      coordinator: "Project.Routing.Coordinator",
      basis: "Project.Routing.Basis",
      external: "Project.Routing.External"
    },
    system: {
      write: {
        all: "Project.System.Write.All"
      }
    },
    area: {
      write: {
        all: "Project.Area.Write.All"
      }
    },
    tag: {
      write: {
        all: "Project.Tag.Write.All"
      }
    },
    penetration: {
      write: {
        all: "Project.Penetration.Write.All"
      }
    },
    areaForeman: {
      write: {
        all: "Project.AreaForeman.Write.All"
      }
    },
    cableType: {
      write: {
        all: "Project.CableType.Write.All"
      }
    },
    mainVerticalZone: {
      write: {
        all: "Project.MainVerticalZone.Write.All"
      }
    },
    productionResponsible: {
      write: {
        all: "Project.ProductionResponsible.Write.All"
      }
    },
    order: {
      write: {
        all: "Project.Order.Write.All"
      }
    }
  }
}
