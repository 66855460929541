/**
 * Searches for a given string in an array
 *
 * @param array         Array to search in
 * @param find          Value to search for
 * @param useExactMatch Default true. Set false to return true for values in array that starts with the find argument.
 */
export const findValue = (array: string[], find: string, useExactMatch = true): boolean => {
  return useExactMatch
    ? array.includes(find)
    : array.findIndex(x => x.startsWith(find)) !== -1;
}

/**
 * Searches one array for occurrences of strings in another array
 * @param array         Array to search in
 * @param candidates    Values to search for
 * @param useExactMatch Default true. Set false to return true for values in array that starts with values in the candidate array
 */
export const findValueInArray = (array: string[], candidates: string[], useExactMatch = true): boolean => {
  if(useExactMatch){
    for(const candidate of candidates) {
      if(array.includes(candidate)) {
        return true;
      }
    }
  }else {
    for(const candidate of candidates){
      if(array.findIndex(x => x.startsWith(candidate)) !== -1) {
        return true;
      }
    }
  }

  return false;
}
