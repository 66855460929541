import {TableItemState} from "./common.interface";
import {PatchRequest, PatchResponse} from "./patchRequest";
import {Cable, CableProps} from "./cable.interface";

export interface Bundle {
  areaForemanId?: number
  areaForemanName?: string;
  bundleType?: string;
  cableProductionResponsibleId?: number;
  cableProductionResponsibleName?: string;
  cableProductionResponsibleCode?: string;
  code: string;
  createdDate?: string;
  createdIn: number;
  cut?: string
  cutIn: number;
  doPrintId: boolean;
  dp3Zone?: string;
  extendedInformation?: string;
  hasSpecialRequirements: boolean;
  id: number;
  labelledDate?: string;
  name?: string;
  printedDate?: string;
  productionStatus?: string;
  projectId: number;
  pulled?: string
  pulledIn: number;
  releasedDate?: string;
  status?: string
  systemId?: number;
  systemName?: string;
  /**
   * Number of cables with a length of zero or not set.
   */
  zeroLengthCables: number;
  /**
   * Number of cables with an estimated length of zero or not set.
   */
  zeroLengthCablesEstimated: number;

  //web-only properties
  state?: TableItemState
  isMarked: boolean;
  hasError: boolean
  updateDp3ZoneForAllCables: boolean
}


export interface BundleProps {
  createdDate?: Date;
  labelledDate?: Date;
  printedDate?: Date;
  releasedDate?: Date;
  doPrintId: boolean;
  hasSpecialRequirements: boolean;
  areaForemanId?: number;
  cableProductionResponsibleId?: number;
  code: string;
  bundleType?: string;
  cut?: string;
  dp3Zone?: string;
  extendedInformation?: string;
  name?: string;
  productionStatus?: string;
  pulled?: string;
  status?: string;
  systemId?: number;
}

export interface IBundleRegister {
  id: number;
  projectId: number;
  areaForemanId?: number
  bundleType?: string;
  cableProductionResponsibleId?: number;
  code: string;
  createdDate?: Date;
  createdIn: number;
  cut?: string
  cutIn: number;
  doPrintId: boolean;
  dp3Zone?: string;
  extendedInformation?: string;
  hasSpecialRequirements: boolean;
  labelledDate?: Date;
  name?: string;
  printedDate?: Date;
  productionStatus?: string;
  pulled?: string
  pulledIn: number;
  releasedDate?: Date;
  status?: string
  systemId?: number;
  state?: TableItemState
  hasError: boolean
}

export interface BundleRegisterProps {
  areaForemanId?: number
  bundleType?: string;
  cableProductionResponsibleId?: number;
  code: string;
  createdDate?: Date;
  createdIn: number;
  cut?: string
  cutIn: number;
  doPrintId: boolean;
  dp3Zone?: string;
  extendedInformation?: string;
  hasSpecialRequirements: boolean;
  labelledDate?: Date;
  name?: string;
  printedDate?: Date;
  productionStatus?: string;
  pulled?: string
  pulledIn: number;
  releasedDate?: Date;
  status?: string
  systemId?: number;
}

export interface PatchBundlesRequest extends PatchRequest<BundleProps> {
  cables?: PatchRequest<CableProps>,
  updateDp3ZoneForAllCables: boolean
}

export interface PatchBundlesResponse extends PatchResponse<Bundle> {
  cables: PatchResponse<Cable>
}

export enum Choice {
  YES = 1,
  NO = 2
}
