import React, {useMemo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Avatar, Typography} from "@material-ui/core";

export interface VectAvatarProps {
  name: string;
}

const useStyles = makeStyles(() => ({
  avatar: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  avatarIcon: {
    marginRight: "10px"
  }
}));

const VectAvatar: React.FC<VectAvatarProps> = ({name}): JSX.Element => {
  const classes = useStyles();

  const initials = useMemo((): string => {
    const names = name.split(" ");
    const first = names[0];
    const last = names.length > 1 ? names[names.length - 1] : null;
    return first && last && first.length > 0 && last.length > 0
      ? `${first[0]}${last[0]}`
      : first && first.length > 1
        ? `${first[0]}${first[1]}`
        : first && first.length > 0
          ? `${first[0]}`
          : "";

  }, [name]);


  return (
    <div className={classes.avatar}>
      <Avatar alt={name} className={classes.avatarIcon}>{initials.toUpperCase()}</Avatar>
      <Typography variant="body1">{name}</Typography>
    </div>
  )

}

export default VectAvatar;
