import {Config} from "../interfaces/config.interface";
import React, {createContext, FC, useEffect, useState} from "react";
import {createBrowserHistory} from "history";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import FancySplashScreen from "../../components/shared/layout/FancySplashScreen/FancySplashScreen";

const initialState: Config = {
  msal: {

  },
  api: {
    hostname: ""
  },
  applicationInsights: {
    instrumentationKey: ""
  },
  scopes: {
    vectApi: ""
  }


};


export const ConfigContext =  createContext(initialState);

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

const ConfigProvider: FC  = ({children})=> {
 const [config, setConfig] = useState<Config>(initialState);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
   const fetchConfig = async() : Promise<Config> => {
     const fetched =
       await fetch(`${process.env.PUBLIC_URL}/config.json` )
         .then(res => res.json())
         .then((config:Config) => config);
     setConfig(fetched);
     setLoading(false)
     return fetched;
   };

   fetchConfig().then(config => {
     const appInsights = new ApplicationInsights({
       config: {
         instrumentationKey: (process.env.REACT_APP_AI_INSTRUMENTATION_KEY) || (config.applicationInsights && config.applicationInsights.instrumentationKey),
         extensions: [reactPlugin],
         extensionConfig: {
           [reactPlugin.identifier]: { history: browserHistory }
         }
       }
     });
     appInsights.loadAppInsights();
     appInsights.trackPageView();
   });

 }, []);
  return(
      <ConfigContext.Provider value={config}>
        {loading ? <FancySplashScreen /> : children}
      </ConfigContext.Provider>
  )
};
export default ConfigProvider;
