import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import PanelHeader, {Action, NavigationLink} from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {CloseSharp} from "@material-ui/icons";
import {CommonPaths} from "../../../shared/urls";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {OrderListItem} from "../../../shared/interfaces/cableType.interface";
import {ProjectClaims} from "../../../shared/claims";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {ProjectMetadata} from "../../../shared/interfaces/project.interface";
import {StyledTableRow, TableCellHeader, tableTheme} from "../../shared/table/styles";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {VectNavLink} from "../../shared/navigation/VectNavLink";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";
import {formatDate} from "../../../shared/helpers/date";
import {useApi} from "../../../shared/hooks/useApi";

interface OrderLogProps {
  redirectTo: string
}

export const OrderLog: React.FC<OrderLogProps> = ({
                                                    redirectTo
                                                  }): JSX.Element => {

  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<OrderListItem[] | null>(null);
  const [projectMetadata, setProjectMetadata] = useState<ProjectMetadata | null>(null);
  const history = useHistory();
  const {get} = useApi();
  const {project} = useContext(ProjectContext);
  const {setError} = useContext(AlertContext);

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      try {
        const metadata = await get<ProjectMetadata>(`project/${project?.code}/metadata`);
        const orders = await get<OrderListItem[]>(`project/${project?.id}/cableTypes/order/list`)
        if (isSubscribed) {
          setProjectMetadata(metadata);
          setOrders(orders);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false)
      }

    })();


    return () => {
      isSubscribed = false;
    }
  }, []);

  const addAction: Action = {
    label: "New order",
    claim: ProjectClaims.project.order.write.all,
    action: () => {
      if (project != null) {
        history.push(CommonPaths.order.newOrder(project.code));
      }
    }
  }
  const navigationLinks: NavigationLink[] = [
    {
      label: "Close",
      route: redirectTo,
      icon: <CloseSharp/>
    }
  ]

  return (
    <div>
      <PanelHeader text={"Orders"} add={addAction} navigationLinks={navigationLinks}/>
      <VectCheckbox color="primary"
                    label="Initial order is complete:"
                    labelMarginLeft={false}
                    checked={projectMetadata?.isInitialOrderGenerated}/>
      <PanelHeader text={"Log"} inline={true} size="small"/>
      <Typography variant="body1">The initial order is emphasized with bold text.</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader style={{maxWidth: "2rem"}}>Order id</TableCellHeader>
            <TableCellHeader style={{maxWidth: "3rem"}}>Created</TableCellHeader>
            <TableCellHeader style={{maxWidth: "25rem"}}>Created by</TableCellHeader>
            <TableCellHeader style={{maxWidth: "10rem"}}>Delivery date</TableCellHeader>
            <TableCellHeader style={{maxWidth: "25rem"}}>Location</TableCellHeader>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading} useTableCells={true} columns={5}>
              {
                orders?.map(x => (
                  <OrderRow key={x.id} order={x}/>
                ))
              }
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>

      </Table>
    </div>
  )
}

const OrderRow: React.FC<{
  order: OrderListItem;
}> = ({
        order
      }): JSX.Element => {
console.debug(order);
  const text = (val: number | string | undefined): JSX.Element => {
    return order.isInitialOrder
      ? <strong><span>{val}</span></strong>
      : <span>{val}</span>;
  }

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={<VectNavLink name="Order details" route={`orders/details/${order.id}`}>{text(order.id)}</VectNavLink>}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={text(formatDate(order.createdAt))} />
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={text(order.createdByName)} />
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={text(formatDate(order.deliveryDate))} />
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={text(order.deliveryLocation)} />
      </TableCell>
    </StyledTableRow>
  );
}
