const bundleIdKey = "bundleId";
const systemIdKey = "systemId";

export const useSession = (): {
  bundleId: number | null;
  systemId: number | null;
  setBundleId: (bundleId: number | null) => void;
  setSystemId: (systemId: number | null) => void;
} => {
  const getNumber = (key: string): number | null => {
    const persisted = sessionStorage.getItem(key);
    if(persisted == null){
      return null;
    }
    try {
      return parseInt(persisted);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  const setNumber = (key: string, value: number | null): void => {
    if(value === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, ""+value);
    }
  }

  const bundleId = getNumber(bundleIdKey);

  const systemId = getNumber(systemIdKey);

  const setBundleId = (bundleId: number | null) => setNumber(bundleIdKey, bundleId);

  const setSystemId = (systemId: number | null) => setNumber(systemIdKey, systemId);

  return {
    bundleId,
    systemId,
    setBundleId,
    setSystemId
  }

}
