import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {useApi} from "../../../shared/hooks/useApi";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {StyledTableRow, TableCellHeader, TableCellInput, tableCellStyles, tableTheme} from "../../shared/table/styles";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {ProjectClaims} from "../../../shared/claims";
import {useHistory} from "react-router-dom";

interface ProjectCableTypesEditProps {
  redirectTo: string
}

interface ProjectCableTypeUpdate {
  cableTypeId: number;
  isActive: boolean;
}

interface ProjectCableTypeAdminResponse {
  id: number;
  code: string;
  isActive: boolean;
  signalType?: string;
  partNumber?: string;
  cableTypeProjectId?: number;
  cableTypeProjectIsActive: boolean;
}

interface ProjectCableTypeRowItemProps extends ProjectCableTypeAdminResponse {
  cableClassCode?: string;
  setIsActive: (id: number, isActive: boolean) => void;
}




export const ProjectCableTypesEdit: React.FC<ProjectCableTypesEditProps> = ({
  redirectTo
}) => {

  const {get, put} = useApi();
  const {setSuccess, setError } = useContext(AlertContext);
  const {project} = useContext(ProjectContext);
  const [cableTypes, setCableTypes] = useState<ProjectCableTypeAdminResponse[] | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updates, setUpdates] = useState<ProjectCableTypeUpdate[] | null>(null);
  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;

    get<ProjectCableTypeAdminResponse[]>(`project/${project?.id}/cableTypes/admin/list-all`)
      .then(res => {
        if(isSubscribed){
          setCableTypes(res);
          setIsLoading(false);
        }
      })
      .catch(setError);

    return () => {
      isSubscribed = false;
    }
  }, []);

  const availableCableTypes = useMemo((): ProjectCableTypeRowItemProps[] | null => {
    if(cableTypes == null) {
      return null;
    }

    const setIsActive = (cableTypeId: number, isActive: boolean) => {
      setIsDirty(true);
      setUpdates(prevState => {
        const newState = prevState || [];
        const index = newState.findIndex(x => x.cableTypeId === cableTypeId);
        if (index >= 0) {
          newState[index].isActive = isActive;
        } else {
          newState.push({cableTypeId, isActive});
        }
        return newState;
      });
      setCableTypes(prevState => {
        if (prevState == null) {
          return null;
        }

        const newState = [
          ...prevState
        ];

        const existing = newState.find(x => x.id === cableTypeId);
        if (existing) {
          existing.cableTypeProjectIsActive = isActive;
        }

        return newState;
      })
    }

    return cableTypes.filter(x => x.isActive)
      .map(x => ({
        ...x,
        setIsActive
      }));
  },[cableTypes])

  const save = () => {
    if(!updates) {
      setIsDirty(false);
      return;
    }

    setIsLoading(true);
    put<{updates: ProjectCableTypeUpdate[]}>(
      `project/${project?.id}/cableTypes/admin/update-available`,
      {
        updates
      })
      .then(() => {
        setSuccess();
        setIsDirty(false);
        history.push(redirectTo);
      })
      .catch(setError);
  }
  return (
    <div>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader text="Enable or disable cable types" save={{
        action: save,
        disabled: !isDirty
      }}/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Code</TableCellHeader>
            <TableCellHeader>Cable Class</TableCellHeader>
            <TableCellHeader>Int Vard Id</TableCellHeader>
            <TableCellHeader>Signal type</TableCellHeader>
            <TableCellHeader>Active</TableCellHeader>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {
                availableCableTypes?.map(x => (
                  <ProjectCableTypeRowItem key={x.id} {...x} />
                ))
              }
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>

      </Table>
    </div>
  )
}



const ProjectCableTypeRowItem: React.FC<ProjectCableTypeRowItemProps> = ({
  cableClassCode,
  cableTypeProjectIsActive,
  code,
  id,
  partNumber,
  signalType,
  setIsActive
}) => {
  const classes = tableCellStyles();

  const onSetIsActive = useCallback((isActive: boolean) => {
    setIsActive(id, isActive);
  }, [id, setIsActive])

  return (
    <StyledTableRow>
      <TableCell className={classes.cell}>
        <VectTableReadOnlyText value={code} />
      </TableCell>
      <TableCell className={classes.cell}>
        <VectTableReadOnlyText value={cableClassCode}/>
      </TableCell>
      <TableCell className={classes.cell}>
        <VectTableReadOnlyText value={partNumber}/>
      </TableCell>
      <TableCell className={classes.cell}>
        <VectTableReadOnlyText value={signalType}/>
      </TableCell>
      <TableCellInput className={classes.cell}>
        <VectCheckbox claim={ProjectClaims.project.engineering.coordinator}
                      checked={cableTypeProjectIsActive}
                      change={onSetIsActive}
                      color="primary"/>
      </TableCellInput>
    </StyledTableRow>
  )
}
