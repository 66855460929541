import React, {useContext} from "react";
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@material-ui/core";
import {MenuIcon} from "@material-ui/data-grid";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {UserContext} from "../../../shared/context/UserContext";
import clsx from "clsx";
import {ChevronLeft, ChevronRight, ExitToApp} from "@material-ui/icons";
import {AppBarContext} from "../../../shared/context/AppBarContext";
import VectAvatar from "../VectAvatar";
import {AuthContext} from "../../../shared/context/AuthContext";
import flexStyles from "../../../shared/styles/flex.module.css";
import generalStyles from "../../../shared/styles/general.module.css";
import {VectListItem} from "../navigation/VectListItem";
import {GlobalClaims} from "../../../shared/claims";
import {VectNavLink} from "../navigation/VectNavLink";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import {Logo} from "./Logo";

export interface ApplicationLink extends AuthProps{
  name: string,
  route: string
  /**
   * Highlight on exact route match only.
   */
  exact?: boolean

}

interface VectAppBarProps {
  header: string
  sideBarLinks?: ApplicationLink[] | null,
  navLinks?: ApplicationLink[] | null
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarHeader: {
    fontFamily: "Archivo,sans-serif",
    marginLeft: "20px"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // zIndex: theme.zIndex.drawer + 2
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: "1rem"
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  toolbarActiveLink: {
    color: "black !important",
    borderBottom: "2px solid black"
  },
  toolbarHeader: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  toolbarLink: {
    color: theme.palette.text.secondary,
    // marginRight: "10px",
    textDecoration: "none",
    paddingLeft: "5px",
    paddingRight: "5px",
    '&:hover': {
      color: "black"
    },
    '&:active': {
      color: theme.palette.text.secondary
    }
  },
  toolbarNavigation: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginBottom: "1rem"
  }
}));

const VectAppBar: React.FC<VectAppBarProps> = ({
  children,
  header,
  sideBarLinks,
  navLinks
}): JSX.Element => {

  const classes = useStyles();
  const theme = useTheme();
  const {showDrawer, toggleDrawer} = useContext(AppBarContext);
  const {logout} = useContext(AuthContext);
  const {user} = useContext(UserContext);
  const handleDrawerOpen = () => {
    toggleDrawer(true);
  };

  const handleDrawerClose = () => {
    toggleDrawer(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed"
              className={clsx(classes.appBar, {[classes.appBarShift]: showDrawer,})}
              color="primary">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <IconButton color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, showDrawer && classes.hide)}>
              <MenuIcon/>
            </IconButton>
            <Logo/>
            <Typography variant="h6" noWrap className={classes.appBarHeader}>{header}</Typography>
          </div>
          <nav className={classes.toolbarNavigation}>
            {navLinks && (
              navLinks.map(item => (
                <VectNavLink
                        key={item.route}
                        {...item}
                         activeClassName={classes.toolbarActiveLink}
                         className={classes.toolbarLink}
                >
                  <Typography noWrap variant="button">{item.name}</Typography>
                </VectNavLink>
                )
              )
            )
            }
          </nav>
        </Toolbar>
      </AppBar>
      {
        user && (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={showDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <VectAvatar name={user.name}/>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeft/> : <ChevronRight/>}
              </IconButton>
            </div>
            <div className={`${flexStyles.flex} ${flexStyles.column} ${flexStyles.spaceBetween} ${generalStyles.height100}`}>
              <div>
                <Divider/>
                <List dense>
                  {
                    user.activeProject && (
                      <ListItem button component={Link} to={`/project/${user.activeProject.codeUrlEncoded}`}>
                        <ListItemText>{`${user.activeProject.code} ${user.activeProject.name}`}</ListItemText>
                      </ListItem>
                    )
                  }
                  <ListItem button component={Link} to="/projects">
                    <ListItemText>Projects</ListItemText>
                  </ListItem>
                  <VectListItem claim={GlobalClaims.manage.wildcard} useExactMatch={false} component={Link} to="/manage">
                    <ListItemText>Management</ListItemText>
                  </VectListItem>
                </List>
                {sideBarLinks && <Divider/>}
                {
                  sideBarLinks && (
                    <List dense>
                      {
                        sideBarLinks.map(link => (
                          <VectListItem claim={link.claim}
                                        useExactMatch={link.useExactMatch}
                                        key={link.route}
                                        component={Link}
                                        to={link.route}>
                            <ListItemText primary={link.name}/>
                          </VectListItem>
                        ))
                      }
                    </List>
                  )
                }

              </div>
              <div>
                <Divider/>
                <List>
                  <ListItem button onClick={async () => await logout()}>
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Sign out" />
                  </ListItem>
                </List>
              </div>


            </div>

          </Drawer>
        )
      }
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: showDrawer,
        })}
      >
        <div className={classes.drawerHeader}/>
        {children}
      </main>
    </div>

  )
}

export default VectAppBar;
