import React, {FunctionComponent, useEffect, useState} from 'react';
import {useApi} from "../../../../../shared/hooks/useApi";
import {GlobalRole, ProjectRole, RolesAssignment, User} from "../../../../../shared/interfaces/user.interface";

import {useStyles} from './NewUser.styles';

import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {ProjectListItem} from "../../../../../shared/interfaces/project.interface";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PanelHeader from "../../../../shared/layout/PanelHeader/PanelHeader";
import {RolesAssignmentTable} from "../RolesAssignmentTable";
import {Skeleton} from "@material-ui/lab";

export const NewUser: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("");
  const [users, setUsers] = useState<Array<User>>([]);
  const [globalRoles, setGlobalRoles] = useState<GlobalRole[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectListItem[] | null>(null);
  const [globalRoleId, getGlobalRoleId] = useState<number | null>(null);
  const [projectRoles, setProjectRoles] = useState<ProjectRole[] | null>(null);
  const [assignments, setAssignments] = useState<RolesAssignment[]>([]);

  const classes = useStyles();

  const {get, post} = useApi();

  useEffect(() => {
    let isSubscribed = true;
    get<GlobalRole[]>("/roles/global")
      .then(res => {
        if(isSubscribed) {
          setGlobalRoles(res);
        }
      });
    get<ProjectRole[]>('/roles/project')
      .then(res => {
        if (isSubscribed) {
          setProjectRoles(res);
        }
      });
    get<ProjectListItem[]>("/project")
      .then(res => {
        if(isSubscribed) {
          setProjects(res);
        }
      });

    return () => {
      isSubscribed = false
    };
  }, []);

  const searchUsers = () => {
    if (search.trim() !== "") {
      (async () => {
        setLoading(true);
        const fetched = await get<Array<User>>(`/user/search?query=${search}`);
        setUsers(fetched);
        setLoading(false);
      })();
    } else {
      setUsers([]);
    }
  }

  const handleRoleChange = (event: React.ChangeEvent<{ value: any }>) => {
    getGlobalRoleId(parseInt(event.target.value, 10));
  };

  const onProjectsChange = (assignments: RolesAssignment[]) => {
    setAssignments(assignments);
  }

  const handleAddUser = async (user: User) => {
    if(globalRoleId == null && !loading){
      return;
    }
    setLoading(true);

    const projectRoles = [];
    for (const assignment of assignments) {
      for (const role of assignment.roles) {
        projectRoles.push({projectId: assignment.project.id, roleId: role.id});
      }
    }
    const data ={
      email: user.email,
      name: user.name,
      objectId: user.objectId,
      isAzureAdAccountEnabled: user.isAzureAdAccountEnabled,
      roleId: globalRoleId,
      projectRoles: projectRoles
    };
    await post("/user", data)
    searchUsers();

    setLoading(false);
    return;
  }

  return (
    <>
      <PanelHeader text="Add a new user to VECT"/>
      <div className={classes.filterWrapper}>
        <FormControl style={{width: "20rem"}}>
          <InputLabel>Global role</InputLabel>
          {
            globalRoles
              ? (
                <Select onChange={(value) => handleRoleChange(value)}
                        value={globalRoleId || ""}>
                  {globalRoles.map(role => (
                    <MenuItem value={role.id}>{role.roleName}</MenuItem>
                  ))}
                </Select>
              )
              : <Skeleton width="10rem"/>
          }

        </FormControl>

        {globalRoleId && (
          <>
            <PanelHeader text="Project roles for new user" size="small"/>
            {
              projectRoles && projects
                ? <RolesAssignmentTable roles={projectRoles}
                                        projects={projects}
                                        onChange={onProjectsChange}/>
                : <Skeleton variant="rect" width="15rem" height="5rem"/>
            }

            <form onSubmit={(e) => {e.preventDefault(); searchUsers();}} className={classes.filterItem}>
              <Input placeholder="Search User..." value={search} onChange={(e) => setSearch(e.target.value)}/>
              <Button style={{marginLeft: "10px"}} type="submit" variant="contained" onClick={searchUsers}>Search</Button>
            </form>
          </>
        )}
      </div>

      {loading ? (
        <div className={classes.loadingIcon}>
          <CircularProgress/>
        </div>
      ) : null}

      {users.length > 0 ? (
        <>
          <table className={classes.table}>
            <thead>
            <tr>
              <th className={loading ? classes.greyTaint : ""}>
                Name
              </th>
              <th className={loading ? classes.greyTaint : ""}>
                Email
              </th>
              <th>
              </th>
            </tr>
            </thead>
            <tbody>
            {users.map(user => (
              <tr key={user.objectId}>
                <td className={loading ? classes.greyTaint : ""}>
                  {user.name}
                </td>
                <td className={loading ? classes.greyTaint : ""}>
                  {user.email}
                </td>
                <td className={loading ? classes.greyTaint : ""}>
                  <IconButton aria-label="add" disabled={loading} onClick={() => handleAddUser(user)}>
                    <PersonAddIcon/>
                  </IconButton>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
};
