import React from "react";
import {Dialog, DialogActions, DialogContent, Typography} from "@material-ui/core";
import {useModals} from "../../shared/context/ModalContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import {VectButton} from "./navigation/VectButton";
import {OpenModalOptions, StackedModalProps} from "../../shared/interfaces/modal.interface";

// We use this to keep the text in react-router Prompt in sync with this modal.
export const unsavedChangesHeader = "You have unsaved changes";
export const unsavedCancelDescription = "Press Cancel to go back and save your changes before continuing.";
export const unsavedOkDescription = "Press OK to discard your changes and continue."

interface UnsavedChangesModalProps {
  action: () => void;
  cancel?: () => void;
  open?: boolean
}


export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  action,
  cancel
}): JSX.Element =>  {
  const { closeModal } = useModals();

  const onConfirmClick = () => {
    action();
    closeModal && closeModal();
  }

  const onCancelClick = () => {
    cancel && cancel();
    closeModal && closeModal();
  }

  return (
    <Dialog open={true}
            maxWidth="md"
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{unsavedChangesHeader}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{unsavedCancelDescription}</Typography>
        <Typography variant="body1">{unsavedOkDescription}</Typography>
      </DialogContent>
      <DialogActions>
        <VectButton onClick={onCancelClick}>Cancel</VectButton>
        <VectButton onClick={onConfirmClick} color="secondary">OK</VectButton>
      </DialogActions>
    </Dialog>
  );
}

export const promptIfUnsavedChanges = (
  openModal: (<T extends StackedModalProps, P extends T>(component: React.ComponentType<T>, props?: Omit<P, keyof StackedModalProps>, options?: OpenModalOptions) => any) | undefined,
  isDirty: boolean | undefined,
  action: () => void,
  cancel: (() => void) | undefined = undefined):void => {
  if(isDirty){
    openModal && openModal(UnsavedChangesModal, {action, cancel});
  } else {
    action();
  }
}
