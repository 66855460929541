import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {ProjectCableTypes} from "./ProjectCableTypes";
import {ProjectCableTypesEdit} from "./ProjectCableTypesEdit";
import {OrderLog} from "./OrderLog";
import {OrderCreate} from "./OrderCreate";
import {OrderDetails} from "./OrderDetails";


export const ProjectCableTypesPage: React.FC = () => {
  const {path, url} = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/edit`}>
        <ProjectCableTypesEdit redirectTo={url}/>
      </Route>
      <Route path={`${path}/orders/new`}>
        <OrderCreate redirectTo={`${url}/orders`}/>
      </Route>
      <Route path={`${path}/orders/details/:orderId`}>
        <OrderDetails redirectTo={`${url}/orders`}/>
      </Route>
      <Route path={`${path}/orders`}>
        <OrderLog redirectTo={url}/>
      </Route>
      <Route path={path} component={ProjectCableTypes}/>
    </Switch>
  );
}

