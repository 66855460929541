import {v4 as UUID} from "uuid";
import {Field} from "../../../shared/interfaces/scalar";

export interface AreaListValidationResult {
  areas: AreaListValidationItem[];
  errorCount: number;
}

export interface AreaListValidationItem {
  code: Field;
  name: Field;
  areaForemanCode: Field;
  areaForemanName: Field;
  mvZoneCode: Field;
  zone3dCode: Field;
  zone3dName: Field;
  projectId: number;
  areaForemanId?: number;
  mvZoneId?: number;
  zone3dId?: number;
}

export interface ImportAreaRequest {
	areas: ImportAreaProps[];
	newAreaForemen: ImportAreaForemanProps[];
	newMainVerticalZones: ImportMvZoneProps[];
	new3dZones: ImportZone3dProps[];
}
export interface ImportAreaProps {
	code: string;
	name: string;
	areaForemanId?: number;
	areaForemanReference?: string;
	mainVerticalZoneId?: number;
	mainVerticalZoneReference?: string;
	zone3dId?: number;
	zone3dReference?: string;
}
export interface ImportAreaForemanProps {
	areaForemanReference: string;
	code: string;
	name: string;
}
export interface ImportMvZoneProps {
	mvZoneReference: string;
	code: string;
}
export interface ImportZone3dProps {
	zone3dReference: string;
	code: string;
	name: string;
}

export const CreateImportAreaRequest = (items: AreaListValidationItem[]):ImportAreaRequest => {
  const newAreaForemen = items
    .filter(x => x.areaForemanId == null && x.areaForemanCode.value != null)
    .reduce<ImportAreaForemanProps[]>((prev, current) =>{
      const code = current.areaForemanCode.value;
      const name = current.areaForemanName.value;
      if(name != null && prev.findIndex(x => x.code === code) === -1){
        prev.push({
          areaForemanReference: UUID(),
          code,
          name
        });
      }
      return prev;
    },  [] );
  const newMainVerticalZones = items
    .filter(x => x.mvZoneId == null && x.mvZoneCode.value != null)
    .reduce<ImportMvZoneProps[]>((prev, current) =>{
      const code = current.mvZoneCode.value;
      if(prev.findIndex(x => x.code === code) === -1){
        prev.push({
          mvZoneReference: UUID(),
          code
        });
      }
      return prev;
    },  [] );
  const new3dZones = items
    .filter(x => x.zone3dId == null && x.zone3dCode.value != null)
    .reduce<ImportZone3dProps[]>((prev, current) =>{
      const code = current.zone3dCode.value;
      const name = current.zone3dName.value;
      if(name != null && prev.findIndex(x => x.code === code) === -1){
        prev.push({
          zone3dReference: UUID(),
          code,
          name
        });
      }
      return prev;
    },  [] );

  const areas = items.map<ImportAreaProps>(x => ({
    code: x.code.value,
    name: x.name.value,
    areaForemanId: x.areaForemanId,
    mainVerticalZoneId: x.mvZoneId,
    zone3dId: x.zone3dId,
    areaForemanReference: x.areaForemanId != null
      ? undefined
      : newAreaForemen.find(af => af.code === x.areaForemanCode.value)!.areaForemanReference,
    mainVerticalZoneReference: x.mvZoneId != null
      ? undefined
      : x.mvZoneCode.value == null
        ? undefined
        : newMainVerticalZones.find(mvz => mvz.code === x.mvZoneCode.value)!.mvZoneReference,
    zone3dReference: x.zone3dId != null
      ? undefined
      : x.zone3dCode.value == null
        ? undefined
        : new3dZones.find(z3d => z3d.code === x.zone3dCode.value)!.zone3dReference
  }));

  return {
    areas,
    newAreaForemen,
    newMainVerticalZones,
    new3dZones
  };
}
