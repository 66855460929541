import {Typography} from "@material-ui/core";
import React, {CSSProperties, FunctionComponent, useEffect, useState} from "react";
import {formatDate, getYearWeekFormat} from "../../../shared/helpers/date";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  errorLabel: {
    color: "red"
  }
}));

interface VectTableReadOnlyTextProps {
  value?: JSX.Element |  number | string | boolean | Date | null | undefined;
  type?: "text" | "date";
  /**
   * When set, display an error in caption text below the element
   */
  error?: string;
  variant?: 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'srOnly'
    | 'inherit';
  yearWeek?: boolean;
  color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  align?: "inherit" | "left" | "center" | "right" | "justify";
  className?: string;
  component?: string;
  gutterBottom?: boolean;
  display?: "initial" | "block" | "inline";
  noWrap?: boolean;
  paragraph?: boolean;
  style?: CSSProperties;
}

export const VectTableReadOnlyText: FunctionComponent<VectTableReadOnlyTextProps> = ({
 value= "",
 error,
 type = "text",
 yearWeek,
 variant,
 color,
 align,
 className,
 display,
 gutterBottom,
 noWrap,
 paragraph,
 style
}): JSX.Element => {
  const [text, setText] = useState<JSX.Element | number | string | boolean | Date | null | undefined>(null);
  const classNames = useStyles();
  useEffect(() => {
    switch (type) {
      case "date":
        if (yearWeek) {
          setText(getYearWeekFormat(value as Date));
        } else {
          setText(`${formatDate(value as Date)}`);
        }
        break;

      default:
        setText(value);
        break;
    }
  }, []);
  const defaultStyle: CSSProperties = {
    padding: 8
  }

  return (
    <div>
      <Typography
        style={style != null ? {...defaultStyle, ...style} : defaultStyle}
        align={align || "inherit"}
        className={className || ""}
        color={color || "initial"}
        display={display || "initial"}
        gutterBottom={gutterBottom || false}
        noWrap={noWrap || false}
        paragraph={paragraph || false}
        variant={variant || "body1"}>
        {text}
      </Typography>
      {error && <Typography variant="caption" className={classNames.errorLabel}>{error}</Typography>}
    </div>

  );
}
