import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {Divider, List, Paper} from "@material-ui/core";
import {mapAreaForemanOptions, mapAreaOptions, mapSystemOptions, mapTagOptions} from "../../../shared/helpers/metadata";
import {Area} from "../../../shared/interfaces/area.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {QueryParams, useApi} from "../../../shared/hooks/useApi";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {GridOn, PictureAsPdf} from "@material-ui/icons";
import {ExcelButton, PdfButton} from "../../../shared/theme";
import {useFilter} from "../../../shared/hooks/useFilter";
import {Option, selectStylesWideMenu, VectSelect} from "../../shared/inputs/VectSelect";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {AlertContext} from "../../../shared/context/AlertContext";
import {ProjectClaims} from "../../../shared/claims";
import {useModals} from "../../../shared/context/ModalContext";
import {Modal} from "../../shared/Feedback/Modal";
import {useClaims} from "../../../shared/hooks/useClaims";

type ReportType = {
  claim?: string;
  exportType: string[],
  option: Option,
  systemRequired?: boolean;
}

const ExportAndReport: FunctionComponent = () => {
  // Data
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  const [tags, setTags] = useState<Tag[] | null>(null);

  // Filtering
  const [cableExportSystem, setCableExportSystem] = useState<SystemListItem | null>(null);
  const [bundlePrintoutForeman, setBundlePrintoutForeman] = useState<Areaforeman | null>(null);
  const [bundlePrintoutSystem, setBundlePrintoutSystem] = useState<SystemListItem | null>(null);
  const [reportCableUsageSystem, setReportCableUsageSystem] = useState<SystemListItem | null>(null);
  const [reportCableUsageType, setReportCableUsageType] = useState<number | null>(null);
  const [reportCableListTag, setReportCableListTag] = useState<Tag | null>(null);
  const [reportCableListArea, setReportCableListArea] = useState<Area | null>(null);

  const {get, fileDownload} = useApi();
  const {project} = useContext(ProjectContext);
  const {setAlert, setError} = useContext(AlertContext);
  const {openModal, closeModal} = useModals();
  const {isValid} = useClaims();

  useEffect(() => {
    let isSubscribed = true;
    // Fetch all extra information needed to display information about the tags
    (async () => {
      if (areas == null) {
        const areas = await get<Area[]>(`/project/${project?.id}/area`);
        if (isSubscribed) {
          setAreas(areas);
        }
      }
      if (systems == null) {
        const systems = await get<SystemListItem[]>(`/project/${project?.id}/system`);
        if (isSubscribed) {
          setSystems(systems);
        }
      }
      if (foremen == null) {
        const foremen = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`);
        if (isSubscribed) {
          setForemen(foremen);
        }
      }
      if (tags == null) {
        const tags = await get<Tag[]>(`/project/${project?.id}/tag`);
        if (isSubscribed) {
          setTags(tags);
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const cableExportSystemFilter =
    useFilter(
      "cableExportSystemId",
      "System to export",
      cableExportSystem?.id,
      mapSystemOptions(systems),
      (val) => {
        if (val === null) {
          setCableExportSystem(null);
        } else {
          const system = systems?.find(x => x.id === val);
          if (system) {
            setCableExportSystem(system);
          }
        }
      });

  const bundlePrintoutForemanFilter =
    useFilter(
      "bundlePrintoutForemanId",
      "Foreman Area",
      bundlePrintoutForeman?.id,
      mapAreaForemanOptions(foremen),
      (val) => {
        if (val === null) {
          setBundlePrintoutForeman(null);
        } else {
          setBundlePrintoutSystem(null);
          const areaForeman = foremen?.find(x => x.id === val);
          if (areaForeman) {
            setBundlePrintoutForeman(areaForeman);
          }
        }
      });

  const bundlePrintoutSystemFilter =
    useFilter(
      "bundlePrintoutSystemId",
      "System ID",
      bundlePrintoutSystem?.id,
      mapSystemOptions(systems),
      (val) => {
        if (val === null) {
          setBundlePrintoutSystem(null);
        } else {
          setBundlePrintoutForeman(null);
          const system = systems?.find(x => x.id === val);
          if (system) {
            setBundlePrintoutSystem(system);
          }
        }
      });

  const reportCableUsageSystemFilter =
    useFilter(
      "reportCableUsageSystemId",
      "System ID",
      reportCableUsageSystem?.id,
      mapSystemOptions(systems),
      (val) => {
        if (val === null) {
          setReportCableUsageSystem(null);
        } else {
          const system = systems?.find(x => x.id === val);
          if (system) {
            setReportCableUsageSystem(system);
          }
        }
      });

  const reportCableListTagFilter =
    useFilter(
      "reportCableListTagId",
      "Select Tag ID",
      reportCableListTag?.id,
      mapTagOptions(tags),
      (val) => {
        if (val === null) {
          setReportCableListTag(null);
        } else {
          setReportCableListArea(null);
          const tag = tags?.find(x => x.id === val);
          if (tag) {
            setReportCableListTag(tag);
          }
        }
      });

  const reportCableListAreaFilter =
    useFilter(
      "reportCableListAreaId",
      "Select Area ID",
      reportCableListArea?.id,
      mapAreaOptions(areas),
      (val) => {
        if (val === null) {
          setReportCableListArea(null);
        } else {
          setReportCableListTag(null);
          const area = areas?.find(x => x.id === val);
          if (area) {
            setReportCableListArea(area);
          }
        }
      });

  const exportCableList = async () => {
    const systemId = cableExportSystem?.id;
    const sheetName = cableExportSystem === null ? "qxCableListAll.xlsx" : "qxCableListSelected.xlsx";

    await fileDownload(sheetName, `/cable/${project?.id}/exportCableList`, {
      queryParams: {
        systemId
      }
    })
  }

  const downloadReportTypeExcelFile = async (sheetName: string, path: string, queryParams?: QueryParams) => {
    const reportType = reportTypes.find(reportType => reportType.option.value === reportCableUsageType);
    if (reportType?.systemRequired === true && reportCableUsageSystem === null) {
      setAlert({type: "error", text: "System is required"});
      return;
    }

    try{
      await fileDownload(sheetName, `/export/excel/${project?.id}/${path}`, {
        queryParams: queryParams
      });
    } catch(err) {
      setError(err);
    }
  }

  const downloadReportTypePdfFile = async (sheetName: string, path: string, queryParams?: QueryParams) => {
    const reportType = reportTypes.find(reportType => reportType.option.value === reportCableUsageType);
    if (reportType?.systemRequired === true && reportCableUsageSystem === null) {
      setAlert({type: "error", text: "System is required"});
      return;
    }

    try {
      await fileDownload(sheetName, `/export/pdf/${project?.id}/${path}`, {
        queryParams: queryParams
      });
    } catch (err) {
      setError(err);
    }
  }

  const downloadExcelFile = async (sheetName: string, path: string, queryParams?: QueryParams) => {
    await fileDownload(sheetName, `/export/excel/${project?.id}/${path}`, {
      queryParams: queryParams
    });
  }

  const downloadPdfFile = async (sheetName: string, path: string, queryParams?: QueryParams) => {
    await fileDownload(sheetName, `/export/pdf/${project?.id}/${path}`, {
      queryParams: queryParams
    });
  }

  const onBundlePrintoutClick = async (updPrintedDate: boolean) => {
    const fileName = ["Bundle printout"];
    if (bundlePrintoutSystem != null) {
      fileName.push(` System ${bundlePrintoutSystem.code}`);
    }

    if (bundlePrintoutForeman != null) {
      fileName.push(` Area foreman ${bundlePrintoutForeman.code}`);
    }

    fileName.push(".pdf");

    try {
      await fileDownload(fileName.join(""), `/project/${project?.id}/bundle/bundle-list/pdf`, {
        queryParams: {
          systemId: bundlePrintoutSystem != null ? bundlePrintoutSystem.id : undefined,
          areaForemanId: bundlePrintoutForeman != null ? bundlePrintoutForeman.id : undefined,
          updPrintedDate: updPrintedDate
        }
      });
    } catch (err) {
      setError(err);
    }
  }

  const updatePrintedDateModal = () => {
    if (openModal && closeModal) {
      openModal(Modal, {
        title: "Printed Date",
        jsx: <>
          <p style={{paddingTop: 0}}>Do you want to update the Printed Date info to today?</p>
          <small style={{color: "red"}}>PS. You have to be a Routing Coordinator to update printed date.</small>
        </>,
        ok: () => onBundlePrintoutClick(true).then(() => closeModal()),
        okText: "Yes",
        okColor: "secondary",
        okDisabled: !isValid({claim: ProjectClaims.project.routing.coordinator}),
        cancel: () => onBundlePrintoutClick(false).then(() => closeModal()),
        cancelText: "No",
        cancelColor: "primary",
        cancelLoading: true
      });
    }
  }

  return (
    <>
      <PanelHeader text={"Exports and Reports for Cable Database System"}/>
      {/* First row */}
      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Export of cable list */}
        <Paper style={{width: "100%", marginRight: "2rem"}}>
          <div style={{padding: "1rem"}}>
            <h3 style={{marginTop: 0}}>Export of cable list, complete or based on System ID</h3>
            <div style={{marginTop: "1rem"}}>
              {cableExportSystemFilter}
            </div>
            <p>Or click the Export button without any selection for export of full cable list</p>
            <ExcelButton
              onClick={exportCableList}
              startIcon={<GridOn/>}
            >
              Export
            </ExcelButton>
          </div>
        </Paper>
        {/* Report cable usage */}
        <Paper style={{width: "100%",}}>
          <div style={{padding: "1rem"}}>
            <h3 style={{marginTop: 0}}>Report cable usage, Complete, Estimated and/or Free Pull</h3>
            <VectSelect
              label="Select Report Type"
              placeholder={"Select Report Type"}
              isClearable={true}
              value={reportCableUsageType}
              change={setReportCableUsageType}
              options={reportTypes.map(x => x.option)}
              styles={selectStylesWideMenu()}
            />
            <div style={{marginTop: "1rem"}}>
              {reportCableUsageSystemFilter}
            </div>
            <PdfButton
              style={{marginTop: "1rem"}}
              disabled={!reportTypes.find(reportType => reportType.option.value === reportCableUsageType)?.exportType.includes("pdf")}
              onClick={() => downloadReportTypePdfFile(
                `${getFileNameFromReportType(reportCableUsageType)}.pdf`,
                "cableUsage",
                {
                  reportType: reportCableUsageType,
                  systemId: reportCableUsageSystem?.id || null
                })}
              startIcon={<PictureAsPdf/>}>
              Print Report
            </PdfButton>
            <ExcelButton
              style={{marginTop: "1rem"}}
              disabled={!reportTypes.find(reportType => reportType.option.value === reportCableUsageType)?.exportType.includes("excel")}
              onClick={() => downloadReportTypeExcelFile(
                `${getFileNameFromReportType(reportCableUsageType)}.xlsx`,
                "cableUsage",
                {
                  reportType: reportCableUsageType,
                  systemId: reportCableUsageSystem?.id || null
                })
              }
              startIcon={<GridOn/>}>
              Export
            </ExcelButton>
          </div>
        </Paper>
      </div>
      {/* Second row */}
      <div style={{display: "flex", flexDirection: "row", marginTop: "2rem"}}>
        {/* Bundle printouts */}
        <Paper style={{width: "100%", marginRight: "2rem"}}>
          <div style={{padding: "1rem"}}>
            <h3 style={{marginTop: 0}}>Bundle printouts based on Foreman Area or System ID</h3>
            {bundlePrintoutForemanFilter}
            <div style={{marginTop: "1rem"}}>
              {bundlePrintoutSystemFilter}
            </div>
            <div>
              <PdfButton
                style={{marginTop: "1rem"}}
                disabled={(bundlePrintoutSystem === null && bundlePrintoutForeman === null)}
                onClick={updatePrintedDateModal}
                startIcon={<PictureAsPdf/>}>
                Print Report
              </PdfButton>
            </div>
          </div>
        </Paper>
        {/* Report a cable list */}
        <Paper style={{width: "100%"}}>
          <div style={{padding: "1rem"}}>
            <h3 style={{marginTop: 0}}>Report a cable list going To and From a selected Tag or Area</h3>
            {reportCableListTagFilter}
            <div style={{marginTop: "1rem"}}>
              {reportCableListAreaFilter}
            </div>
            <PdfButton
              style={{marginTop: "1rem"}}
              disabled={reportCableListTag === null && reportCableListArea === null}
              onClick={() => downloadPdfFile(reportCableListTag !== null ? "rqCableRegTag.pdf" : "rqCableRegArea.pdf", "cableList", {
                tagId: reportCableListTag?.id,
                areaId: reportCableListArea?.id
              })}
              startIcon={<PictureAsPdf/>}>
              Print Report
            </PdfButton>
            <ExcelButton
              style={{marginTop: "1rem"}}
              disabled={reportCableListTag === null && reportCableListArea === null}
              onClick={() => downloadExcelFile(reportCableListTag !== null ? "rqCableRegTag.xlsx" : "rqCableRegArea.xlsx", "cableList", {
                tagId: reportCableListTag?.id,
                areaId: reportCableListArea?.id
              })}
              startIcon={<GridOn/>}>
              Export
            </ExcelButton>
          </div>
        </Paper>
      </div>
      {/* Third row */}
      <div style={{display: "flex", flexDirection: "row", marginTop: "2rem"}}>
        {/* Export data to excel - unfiltered */}
        <Paper style={{width: "100%", marginRight: "2rem"}}>
          <div style={{padding: "1rem"}}>
            <h3 style={{marginTop: 0}}>Export data to Excel - unfiltered - for future calculations</h3>
            <div style={{marginTop: "1rem"}}>
              <List style={{padding: 0}} dense={true}>
                {exportDataToExcelList.map((item: { text: string, sheetName: string, path: string }, index: number) => (
                  <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                    <p style={{width: "70%"}}>{item.text}</p>
                    <ExcelButton
                      onClick={() => downloadExcelFile(item.sheetName, item.path)}
                      startIcon={<GridOn/>}>
                      Export
                    </ExcelButton>
                  </div>
                ))}
              </List>
              <Divider/>
              <List style={{padding: 0}} dense={true}>
                {secondaryExportDataToExcelList.map((item: { text: string, sheetName: string, path: string }, index: number) => (
                  <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                    <p>{item.text}</p>
                    <ExcelButton
                      onClick={() => downloadExcelFile(item.sheetName, item.path)}
                      startIcon={<GridOn/>}>
                      Export
                    </ExcelButton>
                  </div>
                ))}
              </List>
            </div>
          </div>
        </Paper>
        <div style={{width: "100%"}}/>
      </div>
    </>
  );
};

export default ExportAndReport;

const getFileNameFromReportType = (reportType: number | null) => {
  switch (reportType) {
    case 1:
      return "rqcableTotal";
    case 2:
      return "rqcableTotalFP";
    case 3:
      return "qTotalUsageCableSystem";
    case 4:
      return "rqTempcableTotal";
    case 5:
      return "qTotalUsageCableSystem";
    case 7:
      return "Project Special Req Cables";
    case 8:
      return "Project Full Cable Info";
  }
}

const reportTypes: ReportType[] = [
  {
    exportType: ["pdf", "excel"],
    option: {
      value: 1,
      label: "Usage - Registered length in cable list"
    },
  },
  {
    exportType: ["pdf", "excel"],
    option: {
      value: 2,
      label: "Usage - Registered as Free Pull"
    },
  },
  {
    exportType: ["pdf", "excel"],
    systemRequired: true,
    option: {
      value: 3,
      label: "Usage - System incl. Free Pull (system required)"
    },
  },
  {
    exportType: ["pdf", "excel"],
    option: {
      value: 4,
      label: "Calculated Usage in Cable List"
    },
  },
  {
    exportType: ["pdf", "excel"],
    option: {
      value: 5,
      label: "Total Usage - Cable List + Free Pull"
    },
  },
  {
    exportType: ["excel"],
    option: {
      value: 7,
      label: "Cables marked w/Special req - Export only"
    },
  },
  {
    claim: ProjectClaims.project.read,
    exportType: ["excel"],
    option: {
      value: 8,
      label: "Complete Cable Info - Export only"
    },
  },
]


const exportDataToExcelList = [
  {
    text: "Export cable list w/cabletype and meter associated with system id and bundle id w/resp foreman. Cut / Fixed",
    sheetName: "Project Status from Cable List.xlsx",
    path: "projectStatus"
  },
  {
    text: "Export cable usage - meter and amount - on each system",
    sheetName: "Project Cable Usage Totals for each System.xlsx",
    path: "system/cableUsage"
  },
  {
    text: "Export Free Pulled cable list, with associated system and foreman responsible",
    sheetName: "Project Cable List Free Pull.xlsx",
    path: "cableFreePull"
  },
  // {
  //   text: "Export Tag list (Components) w/associated system and foreman, and mounted status",
  //   sheetName: "Project System List.xlsx",
  //   path: "system/list"
  // },
  {
    text: "Export Bundle list w/associated system and foreman, and cut / fixed status",
    sheetName: "Project Bundle List.xlsx",
    path: "bundle/list"
  },
]

const secondaryExportDataToExcelList = [
  {
    text: "Export system list w/associated system and foreman",
    sheetName: "Project System List.xlsx",
    path: "system/list"
  },
  {
    text: "Export Area list w/associated foreman",
    sheetName: "Project Area List.xlsx",
    path: "area/list"
  },
]
