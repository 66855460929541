import React, {Dispatch} from "react";
import {PaginatedResult} from "../../../shared/interfaces/pagination.interface";
import {Action} from "../../../shared/interfaces/common.interface";
import {
  createStyles,
  FormControl,
  FormHelperText,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  useTheme
} from "@material-ui/core";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {useModals} from "../../../shared/context/ModalContext";
import {promptIfUnsavedChanges} from "../UnsavedChangesModal";

type PaginationProps = {
  state: PaginatedResult
  dispatch: Dispatch<Action>
  isDirty?: boolean
}

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    select: {
      verticalAlign: "baseline"
    }
  }),
);

const Pagination = ({state, dispatch, isDirty}: PaginationProps): JSX.Element => {
  const theme = useTheme();
  const { openModal } = useModals();
  const classes = useStyles1();

  const handleFirstPageButtonClick = () => {
    const action = () => dispatch({type: "GO_TO_PAGE", payload: 1});
    promptIfUnsavedChanges(openModal, isDirty, action);
  };

  const handleBackButtonClick = () => {
    const action = () => dispatch({type:"DECREASE_PAGE"});
    promptIfUnsavedChanges(openModal, isDirty, action);
  };

  const handleNextButtonClick = () => {
    const action = () => dispatch({type:"INCREASE_PAGE"});
    promptIfUnsavedChanges(openModal, isDirty, action);
  };

  const handleLastPageButtonClick = () => {
    const action = () => dispatch({type: "GO_TO_PAGE", payload: state.totalPages });
    promptIfUnsavedChanges(openModal, isDirty, action);
  };

  const handlePageSizeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const action = () => dispatch({type:"SET_PAGE_SIZE", payload: e.target.value});
    promptIfUnsavedChanges(openModal, isDirty, action);

  }

  return(
    <div className={classes.root}>

      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={(state.currentPage - 1) <= 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={state.currentPage + 1 > state.totalPages}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
      <span style={{padding: "1em"}}>
          Page {state.currentPage} of {state.totalPages}
        </span>
      <FormControl className={classes.select}>
        <Select
          labelId="page-size-label"
          id="page-size"

          value={state.pageSize} label="Rows per page"
          onChange={handlePageSizeChange}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>

        <FormHelperText>Rows per page</FormHelperText>
      </FormControl>
    </div>
  )
}

export default Pagination;
