import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ConfigProvider, {reactPlugin} from "./shared/context/ConfigContext";
import AuthContext from "./shared/context/AuthContext";
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {createMuiTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AppBarProvider from "./shared/context/AppBarContext";

export const vectLight = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: "black",
        backgroundColor: "white"
      }
    }
  }
});




ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <ThemeProvider theme={vectLight}>
        <CssBaseline/>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <AuthContext>
              <AppBarProvider>
                <App/>
              </AppBarProvider>
            </AuthContext>
          </AppInsightsContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
