import React, {FC, useContext, useEffect, useReducer, useState,} from "react";
import {PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {useApi} from "../../../shared/hooks/useApi";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import ReadOnlyPaginatedTable from "../../shared/table/ReadOnlyPaginatedTable";
import {Cell} from "react-table";
import {Typography} from "@material-ui/core";
import {Check, Edit} from "@material-ui/icons";
import {UserContext} from "../../../shared/context/UserContext";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import SystemGroupStatusEdit from "./SystemGroupStatusEdit";
import {createColumn} from "../../../shared/helpers/table";
import {StyledFooterRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {ProjectClaims} from "../../../shared/claims";

const SystemGroupStatusBootstrap: FC = () => {
  const {path, url} = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/edit`}>
        <SystemGroupStatusEdit redirectTo={url}/>
      </Route>
      <Route path={path} component={SystemGroupStatus}/>
    </Switch>
  )
}

const SystemGroupStatus: FC = () => {
  const [state, dispatch] = useReducer(tableReducer, {values: [], currentPage: 1, currentPageSize: 0, pageSize: 50, totalPages: 0, totalValues: 0});
  const {user} = useContext(UserContext)
  const {project} = useContext(ProjectContext);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const {post, fileDownload} = useApi();
  const {url} = useRouteMatch()
  useEffect(() => {
    let isSubScribed = true;
    if(user.projects)
    {
      const find = user.projects.find(x => x.project?.id === project?.id)
      if(find)
      {
        setReadOnly(false);
      }
    }
    const  fetchSystemGroupProjects = async() => {
      const paginationQuery: PaginationRequest = {page: state.currentPage, pageSize: state.pageSize}
      const result = await post<PaginationRequest, any>(`/project/${project?.id}/systemGroup`, paginationQuery)
        .then(res => res);
      if(isSubScribed && result !== undefined) {
        dispatch({type: "SET_STATE", payload: () => result});
      }
    }
    fetchSystemGroupProjects()
    return () => {
      isSubScribed = false
    };
  }, [
    state.pageSize,
    state.currentPage,
    project?.id,
    user.projects?.length])

  const data = React.useMemo(
    () => state.values,
    [state.values]
  )

  const totals = React.useMemo(
    () => state.metadata?.totals || {},
    [state.metadata]
  )

  const columns = React.useMemo(
    () => [

      {
        Header: "Group ID",
        columns: [
          createColumn("systemGroupCode", {rem: 4}),
          createColumn("systemGroupName", {rem: 12})
        ]
      },
      {
        Header: "Sub Systems",
        columns: [
          createColumn("subSystemsCount", {header: "Amount", rem: 4}),
          {
            ...createColumn("areSubSystemsFinished", {header: "Finished", rem: 4}),
            Cell: ({row} : Cell) => (
              <span>
            {(row.values.areSubSystemsFinished) ? <Check color="primary" />: ""}
              </span>
            )
          },
          ]
      },
      {
        Header: "Info from Sale",
        columns: [
          createColumn("calculatedSoldAmount", {header: "Est. Pcs", rem: 4}),
          createColumn("calculatedSoldMeters", {header: "Est. Meter", rem: 4})
        ]
      },
      {
        Header: "Expected Release",
        columns: [
          createColumn("expectedRelease", {header: "yy-ww", rem: 4})
        ]
      },
      {
        Header: "Info from CL Writer",
        columns: [
          createColumn("registeredCount", {header: "Reg Pcs", rem: 4}),
          createColumn("releasedCount", {header: "Rel Pcs", rem: 4}),
          {
            ...createColumn("releasedPercent", {header: "% Rel", rem: 4}),
            Cell: ({row}: Cell) => (`${row.values.releasedPercent}%`)
          },
          createColumn("registeredMeters", {header: "Reg Mtr", rem: 4}),
          {
            ...createColumn("measuredPercent", {header: "% Measured", rem: 4}),
            Cell: ({row}: Cell) => (`${row.values.measuredPercent}%`)
          }
        ]
      },
      {
        Header: "Info from Production",
        columns: [
          createColumn("fixedCount", {header: "Pcs Fixed", rem: 4}),
          {
            ...createColumn("fixedPercent", {header: "%", rem: 4}),
            Cell: ({row}: Cell) => (`${row.values.fixedPercent}%`)
          },
          createColumn("fixedMeters", {header: "Meters Fixed", rem: 4}),
          {
            ...createColumn("fixedMetersPercent", {header: "%", rem: 4}),
            Cell: ({row}: Cell) => (`${row.values.fixedMetersPercent}%`)
          },
          createColumn("connectedFromCount", {header: "Conn From", rem: 4}),
          {
            ...createColumn("connectedFromPercent", {header: "%", rem: 4}),
            Cell: ({row}: Cell) => (`${row.values.connectedFromPercent}%`)
          },
          createColumn("connectedToCount", {header: "Conn To", rem: 4}),
          {
            ...createColumn("connectedToPercent", {header: "%", rem: 4}),
            Cell: ({row}: Cell) => (`${row.values.connectedToPercent}%`)
          },
          createColumn("registeredMetersFreePull", {header: "Meters FP", rem: 4})
        ]
      }
    ],
    []
  )

  const navigationLinks = readOnly
    ? undefined
    : [
      {
        label: "Enable/Disable groups",
        route: `${url}/edit`,
        icon: <Edit />,
        claim: ProjectClaims.project.engineering.coordinator
      }
    ];
  const excelExports = [
    {
      label: "Export Group Data",
      action: async () => {await fileDownload("Project System Group.xlsx", `/project/${project?.id}/systemGroup/exportAsExcel/groupData`)}
    },
    {
      label: "Export Finish status",
      action: async () => {await fileDownload("Project System Group Finish.xlsx", `/project/${project?.id}/systemGroup/exportAsExcel/finishStatus`)}
    },
    {
      label: "Export Finish status per system",
      action: async () => {await fileDownload("Project System Group Systems Finish.xlsx", `/project/${project?.id}/systemGroup/exportAsExcel/finishStatusBySystem`)}
    }
  ];
  const classes = tableCellStyles();
  const footer = [
    <StyledFooterRow>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell} />
      <TableCellInput className={classes.cell} />
      <TableCellInput className={classes.cell} />
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">%</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">%</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">%</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">%</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">%</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">%</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography variant="subtitle2">Sum:</Typography></TableCellInput>
    </StyledFooterRow>,
    <StyledFooterRow>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell}><Typography>{totals.calculatedSoldAmount}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.calculatedSoldMeters}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}/>
      <TableCellInput className={classes.cell}><Typography>{totals.registeredCount}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.releasedCount}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{`${totals.releasedPercent}%`}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.registeredMeters}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{`${totals.measuredPercent}%`}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.fixedCount}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{`${totals.fixedPercent}%`}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.fixedMeters}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{`${totals.fixedMetersPercent}`}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.connectedFromCount}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{`${totals.connectedFromPercent}`}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.connectedToCount}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{`${totals.connectedToPercent}`}</Typography></TableCellInput>
      <TableCellInput className={classes.cell}><Typography>{totals.registeredMetersFreePull}</Typography></TableCellInput>
    </StyledFooterRow>

  ]

  return(
    <div>
      <PanelHeader text={"System Groups"} navigationLinks={navigationLinks} excelExports={excelExports}/>
      <ReadOnlyPaginatedTable state={state} dispatch={dispatch} columns={columns} data={data} footer={footer} />
    </div>)
}

export default SystemGroupStatusBootstrap;
