import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

export const ExcelButton = withStyles(() => ({
  root: {
    color: vectLightExtension.palette.custom.excel.primary,
    '&:hover': {
      backgroundColor: vectLightExtension.palette.custom.excel.backgroundColorHighlighted,
    },
  },
}))(Button);

export const PdfButton = withStyles(() => ({
  root: {
    color: vectLightExtension.palette.custom.pdf.primary,
    '&:hover': {
      backgroundColor: vectLightExtension.palette.custom.pdf.backgroundColorHighlighted,
    },
  },
}))(Button);

export const vectLightExtension = {
  palette: {
    custom: {
      deletedRow: "#E1383890",
      excel: {
        primary: "#217346",
        backgroundColorHighlighted: "#E3F0D350"
      },
      oddRowColor: "#E3F2FD",
      pdf: {
        primary: "#B30B00",
        backgroundColorHighlighted: "#F76C6350"
      },
      status: {
        // When changing any of these colors, also update any colors used as ok/not ok/incomplete
        // colors in web-api reports.css
        allOk: "#26ff0050",
        notOkay: "#E1383890",
        incomplete: "#fffa0080"
      }
    }
  }
};

