import React, {FC, useContext, useEffect, useState} from 'react';
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {ProjectTotals} from "../../../shared/interfaces/projectTotals.interface";
import {useApi} from "../../../shared/hooks/useApi";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import EstimatedAndRegisteredTotals from "./EstimatedAndRegisteredTotals";
import {Skeleton} from "@material-ui/lab";
import ProductionStatusOverview from "./ProductionStatusOverview";
import {ProjectClaims} from "../../../shared/claims";
import {Project} from "../../../shared/interfaces/project.interface";

const ProjectStatus: FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const {project: context} = useContext(ProjectContext);
  const [projectTotals, setProjectTotals] = useState<ProjectTotals>()
  const {get, fileDownload} = useApi();

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const projectResult = await get<Project>(`project/${context?.code}`);
      const result = await get<ProjectTotals>(`/project/${projectResult.id}/getTotals` )
      if(isSubscribed) {
        setProjectTotals(result);
        setProject(projectResult)
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [])

  const getPdf = async () => {
    await fileDownload("Project Status Total.pdf", `/project/${project?.id}/getTotalsPdf`, {
    });
  }
  return (
    <>
      <PanelHeader
        text={"Status information - Project"}
        getPdf={{
          action: getPdf,
          claim: ProjectClaims.project.read
        }}/>
      {project !== null && projectTotals !== undefined ? <EstimatedAndRegisteredTotals project={project}
                                     projectTotals={projectTotals}/> : <Skeleton /> }
      { projectTotals !== undefined ? <ProductionStatusOverview projectTotals={projectTotals}/> : <Skeleton /> }

    </>
  )
};


export default ProjectStatus;
