import React, {FunctionComponent, useMemo} from "react";
import {Orientation, Penetration} from "../../../shared/interfaces/penetration.interface";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {Option} from "../../shared/inputs/VectSelect";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {ProjectClaims} from "../../../shared/claims";
import {VectTableRowSelect} from "../../shared/inputs/VectTableRowSelect";
import {VectContainer} from "../../shared/layout/VectContainer";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {TableCell} from "@material-ui/core";
import {CopyButton} from "../../shared/table/CopyButton";

const orientations: Option[] = [
  {
    value: Orientation.HORIZONTAL,
    label: "Horizontal"
  },
  {
    value: Orientation.VERTICAL,
    label: "Vertical"
  }
]

type PenetrationRowReadOnlyItemProps = {
  penetration: Penetration;
  change: (value: Penetration) => void;
  copy: (value: Penetration) => void;
};

const PenetrationRowItem: FunctionComponent<PenetrationRowReadOnlyItemProps> = ({
  penetration,
  change,
  copy
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: penetration.code ? undefined : "A penetration code is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: Penetration): boolean => {
      return !newState.code;
    }

    handleCellChange(
      change,
      hasError,
      penetration,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...penetration,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(penetration);
  }

  const style = penetration.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  const selectedOrientation = useMemo((): number | null => {
    switch (penetration.orientation) {
      case "Horizontal" || Orientation.HORIZONTAL:
        return Orientation.HORIZONTAL;

      case "Vertical" || Orientation.VERTICAL:
        return Orientation.VERTICAL;

      default:
        return null;
    }
  }, [penetration.orientation]);

  return (
    <StyledTableRow style={style}>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.penetration.write.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={penetration.code}
          change={onCellChange}
          variant="standard"/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.penetration.write.all}
          fieldName="name"
          value={penetration.name}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.penetration.write.all}
          fieldName={"orientation"}
          value={selectedOrientation}
          change={onCellChange}
          options={orientations}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.penetration.write.all}
          fieldName="extendedInformationFromA"
          value={penetration.extendedInformationFromA}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.penetration.write.all}
          fieldName="extendedInformationToA"
          value={penetration.extendedInformationToA}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.penetration.write.all}
          fieldName="extendedInformationFromB"
          value={penetration.extendedInformationFromB}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.penetration.write.all}
          fieldName="extendedInformationToB"
          value={penetration.extendedInformationToB}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.penetration.write.all}>
          <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCell>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: 'center'}} claim={ProjectClaims.project.penetration.write.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={penetration.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={penetration.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
};

export default PenetrationRowItem;
