import React, {FC, useEffect, useState} from "react";
import {Skeleton} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {DualSelectList} from "../../shared/inputs/DualSelectList";
import {GrantResult} from "../../../shared/interfaces/user.interface";
import {VectTextField} from "../../shared/inputs/VectTextField";


export interface RoleFormFields {
  roleName: string | null;
  grants: string[];
}

export interface RoleFormProps {
  availableGrants: GrantResult[];
  initialFields: RoleFormFields;
  onChange?: (fields: RoleFormFields) => void;
}

export const RoleForm: FC<RoleFormProps> = ({
  availableGrants,
  initialFields,
  onChange
 }): JSX.Element => {
  const [updatedGrants, setUpdatedGrants] = React.useState<string[] | null>(null);
  const [roleName, setRoleName] = useState("");
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    setRoleName(initialFields.roleName || "");
  }, [initialFields.roleName])

  const onNameChange = (value: string) => {
    setRoleName(value);
    if(onChange) {
      onChange({
        roleName: value,
        grants: updatedGrants || initialFields.grants
      });
    }
  }

  const onGrantsChange = (value: string[]) => {
    setUpdatedGrants(value);
    if(onChange) {
      onChange({
        roleName: roleName,
        grants: value
      });
    }
  }

  return (
    <div>
      {
        roleName == null ? (
            <Skeleton>
              <TextField type={"text"} margin="normal" style={{width: "20rem"}}/>
            </Skeleton>
          )
          : (
            <TextField type={"text"}
                       margin="normal"
                       label={"Role name"}
                       value={roleName}
                       onChange={(e) => onNameChange(e.target.value || "")}
                       style={{width: "20rem"}}/>
          )}
      <PanelHeader text="Assign permissions to this role" size="small"/>
      <VectTextField
                 margin="normal"
                 label={"Search in unassigned permissions"}
                 value={filter}
                 change={setFilter}
                 style={{width: "20rem"}}/>
      <DualSelectList onChange={onGrantsChange}
                      availableItems={availableGrants.map(x => ({
                        id: x.value,
                        primary: x.value,
                        secondary: x.description
                      }))}
                      filter={filter}
                      preAssigned={initialFields.grants}
                      titleAssignedItems={"Assigned permissions"}
                      titleUnassignedItems={"Unassigned permissions"}
      />
  </div>
  );

}
