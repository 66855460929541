import {InputAdornment, TableCell} from "@material-ui/core";
import React, {FunctionComponent, memo, useMemo} from "react";
import {
  generalStyles,
  StyledTableRow,
  TableCellInput,
  tableCellStyles,
  tableStyles,
} from "../../../shared/table/styles";
import {CableWithReferenceLabels} from "../../../../shared/interfaces/cable.interface";
import {EditSharp} from "@material-ui/icons";
import {vectLightExtension} from "../../../../shared/theme";
import {VectContainer} from "../../../shared/layout/VectContainer";
import {ProjectClaims} from "../../../../shared/claims";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {Option} from "../../../shared/inputs/VectSelect";
import {VectTableRowSelect} from "../../../shared/inputs/VectTableRowSelect";
import {Tag} from "../../../../shared/interfaces/tag.interface";
import {Bundle} from "../../../../shared/interfaces/bundle.interface";
import {TableItemState} from "../../../../shared/interfaces/common.interface";
import {DeleteButton} from "../../../shared/table/DeleteButton";
import {useClaims} from "../../../../shared/hooks/useClaims";
import {handleCellChange} from "../../../../shared/helpers/table";
import {ModifiedIcon} from "../../../shared/table/ModifiedIcon";
import {CopyButton} from "../../../shared/table/CopyButton";
import {CableTypeListItem} from "../../../../shared/interfaces/cableType.interface";
import {VectTableTextField} from "../../../shared/inputs/VectTableTextField";
import {mapTagOptions} from "../../../../shared/helpers/metadata";


interface CableRowItemProps{
  value: CableWithReferenceLabels;
  change: (value: CableWithReferenceLabels) => void;
  copy: (value: CableWithReferenceLabels) => void;
  tags: Tag[] | null;
  areaOptions: Option[] | null;
  mvZoneOptions: Option[] | null;
  bundles: Bundle[] | null;
  zone3dOptions: Option[] | null;
  cableClassOptions: Option[] | null;
  cableType: (cable: CableWithReferenceLabels) => CableTypeListItem | null;
  cableTypeOptions: Option[] | null;
  areaForemanOptions: Option[] | null;
  openPenetrationsModal: (cable: CableWithReferenceLabels) => void;
}

const CableRowItem: FunctionComponent<CableRowItemProps> = ({
  value: cable,
  change,
  copy,
  tags,
  areaOptions,
  mvZoneOptions,
  bundles,
  zone3dOptions,
  cableClassOptions,
  cableType,
  cableTypeOptions,
  areaForemanOptions,
  openPenetrationsModal
}) => {

  const {hasClaim} = useClaims();
  const editPenetrationsClaim = ProjectClaims.project.routing.external;

  const tagOptions = useMemo((): Option[] | null => mapTagOptions(tags, {withShortLabel: true}), [tags]);

  const bundleOptions = useMemo(():Option[] | null =>
    bundles?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code,
      splitLabel: x.name
    })) || null, [bundles]);

  const disableAreaSelect = useMemo((): {from: boolean, to: boolean} => {
    if(!tags || !cable) {
      return {
        from: true,
        to: true
      }
    }

    const fromTag = cable.fromTagId ? tags.find(x => x.id === cable.fromTagId) : null;
    const toTag = cable.toTagId ? tags.find(x => x.id === cable.toTagId) : null;

    return {
      from: fromTag?.code !== "000",
      to: toTag?.code !== "000"
    }

  }, [tags, cable]);

  const cellClasses = tableCellStyles();
  const classes = tableStyles();
  const general = generalStyles();
  const selectedBundle = cable.bundleId
    ? bundles?.find(x => x.id === cable.bundleId) || null
    : null;

  const errors = {
    cableNumber: cable.cableNumber ? undefined : "A cable number is required"
  };

  const signalType = cable.cableTypeId != null
    ? cableType(cable)?.signalType || "" : "";
  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {

    const changeHandler = (newState: CableWithReferenceLabels) => {
      change(newState);
    }

    const hasError = (newState: CableWithReferenceLabels): boolean => {
      return newState.cableNumber == null || newState.cableNumber === "";
    }
    
    handleCellChange(
      changeHandler,
      hasError,
      cable,
      value,
      fieldName
    );
  };

  const onCopy = () => {
    copy(cable);
  }

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...cable,
      state: itemState
    })
  };

  const style = cable.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  const penetrationsInputProps = hasClaim(editPenetrationsClaim)
    ? {
      endAdornment: (
        <InputAdornment position="end" onClick={() => openPenetrationsModal(cable)}>
          <EditSharp/>
        </InputAdornment>
      )
    }
    : undefined;

  return (
    <StyledTableRow className={classes.tableRowWrapper} style={style}>
      <div
        style={{ width: "14rem" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.xFlex}>
            <div className={general.spaceRight}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                error={!!errors.cableNumber}
                helperText={errors.cableNumber}
                fieldName="cableNumber"
                value={cable.cableNumber}
                change={onCellChange}
                variant="standard"
                fullWidth
                required
              />
            </div>
            <VectTableTextField
              value={signalType}
              disabled={true}
            />
          </div>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.cableTypeId}
            fieldName="cableTypeId"
            change={onCellChange}
            options={cableTypeOptions}
          />
          <VectTextField
            style={
              cable.releaseForProductionWeek
                ? {backgroundColor: vectLightExtension.palette.custom.status.allOk}
                : cable.releaseForMeasuringWeek
                ? {backgroundColor: vectLightExtension.palette.custom.status.incomplete}
                : {backgroundColor: vectLightExtension.palette.custom.status.notOkay}
            }
            value={cable.code}
            disabled={true}
            variant="standard"
            fullWidth
          />
        </TableCellInput>
      </div>
      <div
        style={{ width: "8rem" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromTagId}
            fieldName="fromTagId"
            change={onCellChange}
            options={tagOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromAreaId}
            fieldName="fromAreaId"
            disabled={disableAreaSelect.from}
            change={onCellChange}
            options={areaOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromMainVerticalZoneId}
            fieldName="fromMainVerticalZoneId"
            disabled={disableAreaSelect.from}
            change={onCellChange}
            options={mvZoneOptions}
          />
        </TableCellInput>
      </div>
      <div
        style={{ width: "20rem" }}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.yFlexWSpace}>
            <div style={{ marginBottom: 29 }}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                multiline={true}
                value={cable.fromText}
                fieldName="fromText"
                change={onCellChange}
                variant="standard"
                fullWidth
              />
            </div>
            <div className={general.xFlex}>
              <div style={{ marginLeft: -10, width: "4rem" }}>
                <VectCheckbox
                  claim={ProjectClaims.project.production.external}
                  checked={cable.isConnectedFrom}
                  fieldName="isConnectedFrom"
                  change={onCellChange}
                  color="primary"
                  size={"small"}
                />
              </div>
              <div style={{ width: "5rem" }} className={general.spaceRight}>
                <VectTableRowSelect
                  claim={ProjectClaims.project.production.coordinator}
                  value={cable.fromConnectionAreaForemanId}
                  fieldName={"fromConnectionAreaForemanId"}
                  change={onCellChange}
                  options={areaForemanOptions}
                />
              </div>
              <div style={{ width: "3rem" }} className={general.spaceRight}>
                <VectTextField
                  value={
                    zone3dOptions?.find((x) => x.value === cable.fromZone3dId)
                      ?.selectedLabel
                  }
                  disabled={true}
                  variant="standard"
                  fullWidth
                />
              </div>
              <div style={{ width: "6rem", marginBottom: 10 }}>
                <VectTableRowSelect claim={ProjectClaims.project.engineering.external}
                                    options={cableClassOptions}
                                    fieldName="cableClassId"
                                    change={onCellChange}
                                    value={cable.cableClassId}
                                    />
              </div>
            </div>
          </div>
        </TableCellInput>
      </div>
      <div
        style={{ width: "8rem" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toTagId}
            fieldName="toTagId"
            change={onCellChange}
            options={tagOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toAreaId}
            fieldName="toAreaId"
            disabled={disableAreaSelect.to}
            change={onCellChange}
            options={areaOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toMainVerticalZoneId}
            fieldName="toMainVerticalZoneId"
            disabled={disableAreaSelect.to}
            change={onCellChange}
            options={mvZoneOptions}
          />
        </TableCellInput>
      </div>
      <div
        style={{ width: "20rem" }}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.yFlexWSpace}>
            <div style={{ marginBottom: 29 }}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                value={cable.toText}
                fieldName="toText"
                multiline={true}
                change={onCellChange}
                variant="standard"
                fullWidth
              />
            </div>
            <div className={general.xFlex}>
              <div style={{ marginLeft: -10, width: "7rem" }}>
                <VectCheckbox
                  claim={ProjectClaims.project.production.external}
                  checked={cable.isConnectedTo}
                  fieldName="isConnectedTo"
                  change={onCellChange}
                  color="primary"
                  size={"small"}
                />
              </div>
              <div style={{ width: "11rem" }} className={general.spaceRight}>
                <VectTableRowSelect
                  claim={ProjectClaims.project.production.coordinator}
                  value={cable.toConnectionAreaForemanId}
                  fieldName="toConnectionAreaForemanId"
                  change={onCellChange}
                  options={areaForemanOptions}
                />
              </div>
              <VectTextField
                value={
                  zone3dOptions?.find((x) => x.value === cable.toZone3dId)?.selectedLabel}
                disabled={true}
                variant="standard"
                fullWidth
              />
              <VectCheckbox
                claim={"TODO"}
                checked={cable.hasSpecialRequirements}
                fieldName="hasSpecialRequirements"
                change={onCellChange}
                color="primary"
                size={"small"}
              />
            </div>
          </div>
        </TableCellInput>
      </div>
      <div
        style={{ width: "10rem" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableRowSelect
            claim={ProjectClaims.project.routing.external}
            value={cable.bundleId}
            fieldName="bundleId"
            change={onCellChange}
            options={bundleOptions}
          />
          <VectTextField
            claim={ProjectClaims.project.engineering.external}
            value={cable.estimatedMeters}
            fieldName="estimatedMeters"
            change={onCellChange}
            type="number"
            variant="standard"
            fullWidth
          />
          <VectTextField
            claim={ProjectClaims.project.routing.external}
            value={cable.meters}
            fieldName="meters"
            change={onCellChange}
            type="number"
            variant="standard"
            fullWidth
          />
        </TableCellInput>
      </div>
      <div
        style={{ width: "11rem" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTextField
            value={selectedBundle != null ? (selectedBundle.name || ""): ""}
            disabled={true}
            variant="standard"
            fullWidth
          />
          <VectTextField
            claim={ProjectClaims.project.routing.external}
            value={cable.markPlacement}
            fieldName="markPlacement"
            change={onCellChange}
            variant="standard"
            fullWidth
          />
          <div>
            <VectTextField
              claim={editPenetrationsClaim}
              value={cable.penetrations}
              fieldName="penetrations"
              change={onCellChange}
              variant="standard"
              InputProps={penetrationsInputProps}
            />


          </div>
        </TableCellInput>
      </div>
      <div
        style={{ width: "6rem" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTextField
            value={cable.cutWeek}
            disabled={true}
            variant="standard"
            fullWidth
          />
          <VectTextField
            value={cable.pulledWeek}
            disabled={true}
            variant="standard"
            fullWidth
          />
          <VectTextField
            value={cable.dp3Zone}
            disabled={true}
            variant="standard"
            fullWidth
          />
        </TableCellInput>
      </div>
      <div
        style={{ width: "3rem", alignSelf: "center" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectCheckbox
            claim={ProjectClaims.project.routing.external}
            checked={cable.isMarked}
            fieldName="isMarked"
            change={onCellChange}
            color="default"
          />
        </TableCellInput>
      </div>
      <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
        <ModifiedIcon itemState={cable.state} />
      </TableCell>
      <div
        style={{ alignSelf: "center" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <VectContainer claim={ProjectClaims.project.engineering.external}>
          <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>

          </TableCell>
        </VectContainer>
      </div>
      <div
        style={{ alignSelf: "center" }}
        className={classes.tableHeaderColumnWrapper}
      >
        <VectContainer claim={ProjectClaims.project.engineering.coordinator}>
          <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={cable.state}/>

          </TableCell>
        </VectContainer>
      </div>

    </StyledTableRow>
    );
};

export default memo(CableRowItem);
