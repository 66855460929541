import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {FC, useCallback, useContext, useEffect, useReducer, useState} from "react";
import OrganizeRowItem from "./OrganizeRowItem";
import {generalStyles, tableStyles, tableTheme} from "../../shared/table/styles";
import {
  BatchUpdateCablesRequest,
  Cable,
  CableWithReferenceLabels,
  refreshVisibleCables
} from "../../../shared/interfaces/cable.interface";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {organizeFilter} from "../../../shared/interfaces/organizeFilter.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {Bundle} from "../../../shared/interfaces/bundle.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import Pagination from "../../shared/table/Pagination";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import OrganizeFilterHeader from "./OrganizeFilterHeader";
import OrganizeFooter from "./OrganizeFooter";
import {useModals} from "../../../shared/context/ModalContext";
import {Modal} from "../../shared/Feedback/Modal";
import {Option} from "../../shared/inputs/VectSelect";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {AlertContext} from "../../../shared/context/AlertContext";
import {PatchResponse} from "../../../shared/interfaces/patchRequest";

const Organize: FC = () => {
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [bundleOptions, setBundleOptions] = useState<Option[] | null>(null);
  const [bundles, setBundles] = useState<Bundle[] | null>(null);
  const [filter, setFilter] = useState<organizeFilter>({})
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mvZones, setMvZones] = useState<MainVerticalZone[] | null>(null);
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [zone3ds, setZone3ds] = useState<Zone3d[] | null>(null);
  const classes = tableStyles();
  const general = generalStyles();
  const {get, post, fileDownload} = useApi();
  const {openModal, closeModal} = useModals();
  const {project} = useContext(ProjectContext);
  const {setAlert, setSuccess} = useContext(AlertContext);

  /* State End */
  const getPdf = async () => {
    await fileDownload("Cable Report on Selected BUNDLE.pdf", `/cable/${project?.id}/organizePdf`, {queryParams: {...filter}});
  }

  // Initial fetching of data to get organize cdata
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      try {
        setIsLoading(true);
        const paginationQuery: PaginationRequest = {
          page: state.currentPage,
          pageSize: state.pageSize,
        };

        await post<PaginationRequest, any>(`/project/${project?.id}/cable/organize`, paginationQuery, {}, {...filter})
          .then((result) => {
            if (isSubscribed && result !== undefined) {
              dispatch({
                type: "SET_STATE",
                payload: () => ({
                  ...result,
                  values: result.values.map((x: CableWithReferenceLabels) => ({
                    ...x,
                    isMarked: false
                  }))
                })
              });
            }
          }
        );
      } finally {
        setIsLoading(false);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [
    filter.bundleId,
    filter.cableCode,
    filter.toText,
    filter.fromText,
    filter.toTagId,
    filter.toAreaId,
    filter.toMainVerticalZoneId,
    filter.to3dZoneId,
    filter.from3dZoneId,
    filter.fromAreaId,
    filter.fromTagId,
    filter.fromMainVerticalZoneId,
    filter.systemId,
    state?.pageSize,
    state?.currentPage
  ]);

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      await get<SystemListItem[]>(`/project/${project?.id}/system`)
        .then(res => {
          if (isSubscribed) {
            setSystems(res);
          }
        })
      await get<Tag[]>(`/project/${project?.id}/tag`)
        .then(res => {
          if (isSubscribed) {
            setTags(res);
          }
        });
      await get<Area[]>(`/project/${project?.id}/area`)
        .then(res => {
          if (isSubscribed) {
            setAreas(res);
          }
        });
      await get<MainVerticalZone[]>(`/project/${project?.id}/mainVerticalZones`)
        .then(res => {
          if (isSubscribed) {
            setMvZones(res);
          }
        });
      await get<Bundle[]>(`/project/${project?.id}/bundle`)
        .then(res => {
          if (isSubscribed) {
            // Update bundle options
            const opts = res.map(x => ({
              value: x.id,
              label: `${x.code} | ${x.status ? x.status : ""} | ${x.name}`,
              selectedLabel: x.code
            }));
            setBundleOptions(opts);

            // Update filter
            const newFilter = {...filter, bundleId: res[0].id}
            setFilter(newFilter);
            setBundles(res);
          }
        });
      await get<Zone3d[]>(`/project/${project?.id}/zone3d`)
        .then(res => {
          if (isSubscribed) {
            setZone3ds(res);
          }
        });
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const change = useCallback((value: boolean, id: number) => {
    const newCables = state.values.map((x) => {
      const item = x as CableWithReferenceLabels;
      return item.id === id ? {
        ...item,
        isMarked: value
      } : item;
    })
    dispatch({type: "SET_VALUES", payload: () => newCables});
  }, [state.values])

  const selectAllVisibleCables = useCallback(() => {
    dispatch({
      type: "SET_VALUES",
      payload: prevValues => {
        if(prevValues == null){
          return prevValues;
        }

        return prevValues.map(x => ({
          ...x,
          isMarked: true
        }));
      }
    });
  }, [])

  const changeFilter = (value: number | string | undefined | null, field?: string) => {
    if(field == null) {
      return;
    }
    const newFilter = {
      ...filter,
      [field]: value
    }
    setFilter(newFilter);
  }

  const onGetBundle = (bundleId?: number | null) : Bundle | null => {
    if(bundleId == null || bundles == null){
      return null;
    }

    const bundle = bundles.find(x => x.id === bundleId);
    return bundle || null;
  }

  const updateSelectedBatch = async (bundleId: number) => {
    if(state == null || state.values == null || project == null || bundles == null) {
      return;
    }

    const sendResult = async(cableIds: number[], bundleId: number) => {
      setIsLoading(true);
      try {
        const request : BatchUpdateCablesRequest = {
          cableIds,
          returnCableIds: cableIds,
          bundleId
        };
        await post<BatchUpdateCablesRequest, PatchResponse<Cable>>(`/project/${project!.id}/cable/batch-update`, request)
          .then(res => {
            refreshVisibleCables(dispatch, res);
            setSuccess();
          })
          .catch((err: ProblemDetailError) => {
            setAlert({type: "error", text: "Something failed when moving cables to a new bundle", error: err});
          });
      } finally {
        setIsLoading(false);
      }
    }

    const renderUpdateCablesPrompt = async (cableIds: number[], bundle: Bundle) => {
      if (openModal) {
        openModal(Modal, {
          title: "Information",
          description:
            `You are about to move ${cableIds.length} cables to bundle ${bundle.code}. Proceed? `,
          ok: () => {
            if (closeModal) {
              closeModal();
            }
            sendResult(cableIds, bundle.id);
          },
          okText: "Ok",
          cancelText: "Cancel"
        });
      }
    }
    const cableIds: number[] = state.values
      .filter(x => x.isMarked)
      .map(x => x.id);
    const selectedBundle = bundles.find(x => x.id === bundleId);
    if(!selectedBundle) {
      setAlert({type: "error", text: "I could not determine the correct bundle key to move cables to."});
      return;
    }

    if (selectedBundle?.status === 'C') {
      if (openModal) {
        openModal(Modal, {
          title: "Warning!",
          description:
            "The bundle you have selected, are already cutted." +
            "If the cable you are moving to this bundle are not cutted," +
            "the whole bundle will change status to NOT cutted." +
            "The Bundle Status will be shown as R (Released) in Combo Boxes",
          ok: () => {
            if (closeModal) {
              closeModal();
            }
            renderUpdateCablesPrompt(cableIds, selectedBundle);
          },
          okText: "Ok",
          cancelText: "Cancel"

        });
      }
    } else if (selectedBundle?.status === 'P') {
      if (openModal) {
        openModal(Modal, {
          title: "Warning!",
          description:
            "The bundle you have selected, are already cutted and pulled." +
            "If the cable you are moving to this bundle are not cutted or" +
            " pulled," +
            "the whole bundle will change status to NOT cutted or pulled." +
            "The Bundle Status will be shown as R (Released) or C (Cutted) in" +
            " Combo Boxes",
          ok: () => {
            if (closeModal) {
              closeModal();
            }
            renderUpdateCablesPrompt(cableIds, selectedBundle);
          },
          okText: "Ok",
          cancelText: "Cancel"
        });
      }
    } else {
      renderUpdateCablesPrompt(cableIds, selectedBundle);
    }
  }

  return (
    <>
      <PanelHeader text={"Organize / Move Cables to Bundles"} getPdf={{action: getPdf}}/>
      <OrganizeFilterHeader
        filter={filter}
        change={changeFilter}
        systems={systems}
        tags={tags}
        areas={areas}
        mvZones={mvZones}
        bundles={bundles}
        zone3ds={zone3ds}
      />

      <Table>
        <TableHead>
          <TableRow className={classes.tableRowWrapper}>
            <div
              style={{width: "14rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <div className={general.xFlex}>
                <div className={general.yFlex}>
                  <TableCell>Cable nr:</TableCell>
                  <TableCell>Cable Type:</TableCell>
                  <TableCell>Cable ID:</TableCell>
                </div>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>From Tag:</TableCell>
              <TableCell>From Area:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>From Text:</TableCell>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>To Tag:</TableCell>
              <TableCell>To Area:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>To Text:</TableCell>
              </div>
            </div>
            <div
              style={{width: "10rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Bundle ID:</TableCell>
              <TableCell>Meter:</TableCell>
            </div>
            <div
              style={{width: "11rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Bundle Text:</TableCell>
              <TableCell>Mark Placement Info:</TableCell>
              <TableCell>Penetrations to use:</TableCell>
            </div>
            <div
              style={{width: "6rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Cut:</TableCell>
              <TableCell>Fixed:</TableCell>
              <TableCell>Count:{state.totalValues}</TableCell>
            </div>
            <div
              style={{width: "4rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Marked:</TableCell>
            </div>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state.values.map((x) => {
                const item = x as CableWithReferenceLabels;
                return (
                  <OrganizeRowItem key={item.id} cable={item} change={change} getBundle={onGetBundle}/>
                );
              })}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch}/>
      <OrganizeFooter bundleOptions={bundleOptions}
                      updateSelectedBatch={updateSelectedBatch}
                      selectAllVisibleCables={selectAllVisibleCables}/>
    </>
  );
}

export default Organize;
