import {BrowserRouter as Router} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {InformationCard} from "../../InformationCard/InformationCard";

interface Info {[index: string] : {value?: string}}

const FancySplashScreen = (): JSX.Element => <Router><FancySplashScreenDisplay /></Router>;

const FancySplashScreenDisplay = () => {
  // Handle authentication errors from redirection URL
  const [error, setError] = useState<Info | undefined>(undefined);

  useEffect(()=> {
    if(window.location.hash) {
      const info: Info = {};
      window.location.hash.split('&').forEach(hashFragment => {
        const pair = hashFragment.split('=');
        info[pair[0]] = {value: pair.length > 1 ? pair[1] : undefined};
      });
      if(info['#error']) {
        setError(info)
      }
    }
  }, [error]);




    if(error){
      const content = error["error_description"]?.value?.startsWith("AADSTS50105")
        ? "AADSTS50105: The signed in user is not assigned to a role for the application."
        : error["error_description"]?.value;
      const whatNow = error["error_description"]?.value?.startsWith("AADSTS50105")
        ? "Please contact Vard IT."
        : undefined;
      return (
        <InformationCard {...{
          context: "We could not log you in.",
          header: "Authentication failure",
          subTitle: error["#error"].value,
          content: content,
          whatNow: whatNow
        }} />
      )
    }




  const imageUrl = `${process.env.PUBLIC_URL}/splashscreentest.jpg`;
  return <div style={{height: '100vh'}}>
    <img style={{ height: '100vh', width: '100%' }} src={imageUrl}/>
  </div>
}

export default FancySplashScreen;
