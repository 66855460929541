import {Typography} from "@material-ui/core";
import React, {FunctionComponent} from "react";
import {StyledTableRow, TableCellInput, tableStyles} from "../../shared/table/styles";
import {AreaForemanForProjectResult} from "../../../shared/interfaces/AreaForemanForProjectResult";

type CbsStatusRowItemProps = {
  areaForeman: AreaForemanForProjectResult;
};

const CbsStatusRowItem: FunctionComponent<CbsStatusRowItemProps> = ({
  areaForeman,
}) => {
  const classes = tableStyles();

  return (
    <StyledTableRow style={{ border: "none" }} className={classes.tableRowWrapper}>
      <TableCellInput style={{ width: "5rem" }}>
        <Typography>{areaForeman.areaForemanCode}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "20rem" }}>
        <Typography>{areaForeman.areaForemanText}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "10rem" }}>
        <Typography>{areaForeman.areaForemanName}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "4rem" }}>
        <Typography>{areaForeman.type}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.registeredCount}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.releasedCount}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.releasedPercent}%</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.registeredMeters}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.measuredPercent}%</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.fixedPieces}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "5rem" }}>
        <Typography>{areaForeman.fixedPercent}%</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.fixedMeters}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "5rem" }}>
        <Typography>{areaForeman.fixedMetersPercent}%</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "9rem" }}>
        <Typography>{areaForeman.connectedFromCount}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "5rem" }}>
        <Typography>{areaForeman.connectedFromPercent}%</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "9rem" }}>
        <Typography>{areaForeman.connectedToCount}</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "5rem" }}>
        <Typography>{areaForeman.connectedToPercent}%</Typography>
      </TableCellInput>
      <TableCellInput style={{ width: "7rem" }}>
        <Typography>{areaForeman.registeredMetersFreePull}</Typography>
      </TableCellInput>
    </StyledTableRow>
  );
};

export default CbsStatusRowItem;
