import React, {FunctionComponent, useContext, useEffect, useMemo, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";

import {Paper, TextField, Typography} from "@material-ui/core";
import {useApi} from "../../../shared/hooks/useApi";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {generalStyles} from "../../shared/table/styles";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import SystemStatusTotals from "./components/SystemStatusTotals";
import {initialTotals, SystemStatusInformation,} from "../../../shared/interfaces/systemStatusInformation";
import TagTotals from "./components/TagTotals";
import {ExcelButton, PdfButton} from "../../../shared/theme";
import {GridOn, PictureAsPdf} from "@material-ui/icons";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {useModals} from "../../../shared/context/ModalContext";
import {Skeleton} from "@material-ui/lab";
import {mapSystemOptions} from "../../../shared/helpers/metadata";

const SystemStatus: FunctionComponent = () => {
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  const [systemStatusInformation, setSystemStatusInformation] = useState<SystemStatusInformation>(initialTotals);
  const [selectedSystem, setSelectedSystem] = useState<SystemListItem | null>(null);

  const general = generalStyles();
  const {project} = useContext(ProjectContext);
  const {openModal} = useModals();
  const {get, fileDownload} = useApi();

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (selectedSystem !== null) {
        const result = await get<SystemStatusInformation>(
          `/system/${selectedSystem?.id}/getStatus/${project?.id}`
        ).then((res) => {
          return res;
        });
        if (isSubscribed) {
          setSystemStatusInformation(result);
        } else {
          setSystemStatusInformation(initialTotals);
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [selectedSystem]);

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (systems == null) {
        const systems = await get<SystemListItem[]>(`/project/${project?.id}/system`);
        if (isSubscribed) {
          setSystems(systems);
        }
      }
      if (foremen == null) {
        const data = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`);
        if (isSubscribed) {
          setForemen(data);
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  /*
   * Map options
   */
  const systemOptions = useMemo((): Option[] | null => mapSystemOptions(systems), [systems]);

  const getCablesSystemExcel = async () => {
    await fileDownload("expCables_System.xlsx", `/system/${selectedSystem?.id}/exportAsExcelSystem/${project?.id}`);
  }

  const getFreePullExcel = async () => {
    await fileDownload("expCables_FreePull.xlsx", `/cableFreePull/${project?.id}/exportAsExcel`);
  }

  const getPdf = async () => {
    await fileDownload("Project Status System.pdf", `/system/${selectedSystem?.id}/pdf/${project?.id}`);
  }

  const systemFilter = useMemo(() => {
    return (
      <VectSelect
        placeholder={"To Conn. Resp."}
        value={selectedSystem?.id}
        change={value => {
          const action = () => {
            const system = systems?.find(x => x.id === value);
            if (system) {
              setSelectedSystem(system);
            }
          };
          promptIfUnsavedChanges(openModal, false, action);
        }}
        options={systemOptions}
      />
    );
  }, [systems, selectedSystem])

  const systemForeman = useMemo(() => {
    // Get foreman info
    const foreman = foremen?.find(
      (x: Areaforeman) => x.id === selectedSystem?.areaForemanId
    );
    return ({
      code: foreman?.code || "",
      name: foreman?.foremanText || "",
    });
  }, [selectedSystem])

  return (
    <>
      <PanelHeader text={"Status Information - System"}/>
      {systemOptions != null ? (
        <Paper className={general.largeFilterWrapper}>
          {/* First Column */}
          <div style={{width: "100%"}} className={general.yFlex}>
            <div className={general.largeFilterItem}>
              {systemFilter}
            </div>
            <div style={{marginTop: -15}} className={general.xFlex}>
              <div className={general.filterItem}>
                <TextField
                  label="Foreman ID"
                  value={systemForeman?.code || ""}
                />
              </div>
              <div className={general.largeFilterItem}>
                <TextField
                  style={{width: "100%", maxWidth: 472}}
                  label="Name"
                  value={systemForeman?.name || ""}
                />
              </div>
            </div>
          </div>
        </Paper>
      ) : (
        <Skeleton variant="rect" width="40rem" height="5rem"/>
      )}

      <main className={general.xFlex}>
        <Paper style={{width: "100%", maxWidth: 700, marginTop: "1rem", padding: "10px"}}>
          <SystemStatusTotals
            cableTotals={systemStatusInformation?.cableTotal}
            bundleTotals={systemStatusInformation?.bundleTotal}
            freePullTotals={systemStatusInformation?.systemFreePull}
          />
        </Paper>
        <Paper
          style={{
            width: "100%",
            maxWidth: 700,
            marginLeft: "3em",
            padding: "10px",
            marginTop: "1rem"
          }}
        >
          <TagTotals tagTotals={systemStatusInformation?.tagTotal}/>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: 300}}>
            <div>
              {/* Excel Export Section */}
              <div
                style={{
                  marginTop: "2em",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography>Export data: </Typography>
                  <Typography variant={"subtitle2"}>
                    Excel sheet with filtered data for the info above
                  </Typography>
                </div>
                <ExcelButton
                  style={{alignSelf: "center"}}
                  variant="contained"
                  disabled={selectedSystem === null}
                  onClick={getCablesSystemExcel}
                  startIcon={<GridOn/>}
                >
                  Export data
                </ExcelButton>
              </div>
              <div
                style={{
                  marginTop: "1em",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography>Export data: </Typography>
                  <Typography variant={"subtitle2"}>
                    Excel - sheet, filtered for 'Free Pull'
                  </Typography>
                </div>
                <ExcelButton
                  style={{alignSelf: "center"}}
                  variant="contained"
                  disabled={selectedSystem === null}
                  onClick={getFreePullExcel}
                  startIcon={<GridOn/>}
                >
                  Export data
                </ExcelButton>
              </div>

            </div>
            <div>
              {/* Print Report Section */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography>Preview/Print Report</Typography>
                  <Typography variant={"subtitle2"}>
                    Report view of selected CBS
                  </Typography>
                </div>
                <PdfButton
                  disabled={selectedSystem === null}
                  variant="contained"
                  onClick={getPdf}
                  startIcon={<PictureAsPdf/>}>
                  Print Report
                </PdfButton>
              </div>
            </div>
          </div>
        </Paper>
      </main>
    </>
  );
};

export default SystemStatus;
