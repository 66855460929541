import React, {FC, useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {Area, AreaProps} from "../../../shared/interfaces/area.interface";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import AreaRowItem from "./AreaRowItem";
import Pagination from "../../shared/table/Pagination";
import {tableTheme} from "../../shared/table/styles";
import {Option} from "../../shared/inputs/VectSelect";
import {handleRowChange} from "../../../shared/helpers/table";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {useModals} from "../../../shared/context/ModalContext";
import {compare} from "fast-json-patch";
import {AlertContext} from "../../../shared/context/AlertContext";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {ProjectClaims} from "../../../shared/claims";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {useStyles} from "../tag/Tags.styles";
import {Skeleton} from "@material-ui/lab";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {mapAreaForemanOptions} from "../../../shared/helpers/metadata";
import {getValidFileName} from "../../../shared/validators";
import {CloudUploadSharp, GridOn} from "@material-ui/icons";
import {AreaImportModal} from "./AreaImportModal";


const Areas: FC = () => {
  /* State */
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<Area[] | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Data
  const [foremans, setForemans] = useState<Areaforeman[] | null>(null);
  const [mvzs, setMvzs] = useState<MainVerticalZone[] | null>(null);
  const [zone3ds, setZone3ds] = useState<Zone3d[] | null>(null);
  // Filtering
  const [areaId, setAreaId] = useState<string>("");
  //Management
  const [prevNewId, setPrevNewId] = useState<number>(0);
  /* State End */

  const classes = useStyles();
  const {openModal} = useModals();
  const {get, post, fileDownload} = useApi();
  const {project} = useContext(ProjectContext);
  const {setAlert, setSuccess, setError} = useContext(AlertContext);

  const refreshPage = useCallback(async (isSubscribed = true) => {
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };

      const result = await post<PaginationRequest, any>(
        `/project/${project?.id}/area`,
        paginationQuery,
        {},
        {areaId});

      if (isSubscribed) {
        setInitialValues(result.values);
        dispatch({
          type: "SET_STATE",
          payload: () => ({
            ...result,
            values: result.values.map((x: Area) => ({
              ...x,
              isMarked: false
            }))
          })
        });
      }

    } finally {
      setIsDirty(false);
      setIsLoading(false);
    }
  }, [areaId, state?.currentPage, state?.pageSize])

  const loadDependencies = useCallback(async (isSubscribed = true) => {
    try {
      const areaForemanResult = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`);
      const mvZoneResult = await get<MainVerticalZone[]>(`/project/${project?.id}/mainVerticalZones`);
      const zone3dResult = await get<Zone3d[]>(`/project/${project?.id}/zone3d`);
      if(isSubscribed) {
        setForemans(areaForemanResult);
        setMvzs(mvZoneResult);
        setZone3ds(zone3dResult);
      }
    } catch (err) {
      setError(err);
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    (async () => await loadDependencies(isSubscribed))();

    return () => {
      isSubscribed = false;
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true;
    (async () => await refreshPage(isSubscribed))();

    return () => {
      isSubscribed = false;
    };
  }, [
    areaId,
    state?.pageSize,
    state?.currentPage
  ]);


  /*
   * Map Options
   */

  const foremanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(foremans), [foremans]);

  const mvZoneOptions = useMemo((): Option[] | null => {
    if (mvzs == null) {
      return null;
    }

    return mvzs.map(x => ({
      value: x.id,
      label: `${x.code}`
    }));
  }, [mvzs]);

  const zone3dOptions = useMemo((): Option[] | null => {
    if (zone3ds == null) {
      return null;
    }

    return zone3ds.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`
    }));
  }, [zone3ds]);

  const addNewItem = () => {
    if (!areDependenciesLoaded) {
      return;
    }

    const newId = prevNewId - 1;
    const activeForemen = foremans!.filter(x => x.isActive);
    if(activeForemen.length === 0) {
      setAlert({type: "warning", text: "You need to set at least one foreman as active before you can add an area"});
      return;
    }

    if(zone3ds == null || zone3ds.length === 0) {
      setAlert({type: "warning", text: "You need to add at least one 3D zone before you can add an area"});
      return;
    }

    const foremanId = activeForemen[0].id;
    const zone3dId = zone3ds.find(x => x.code === "000") || zone3ds[0];

    const newItem: Area = {
      id: newId,
      projectId: project ? project.id : 0,
      code: "",
      name: "",
      isActive: false,
      areaForemanId: foremanId,
      zone3DId: zone3dId!.id,
      mainVerticalZoneId: undefined,
      state: "new",
      hasError: false
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
  }

  const change = useCallback((area: Area) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        return handleRowChange(
          prevValues,
          area,
          setIsDirty);
      }
    });
  }, [areaId]);

  const exportTemplate = useCallback(async () => {
    if(project == null) {
      return;
    }
    const fileName = getValidFileName(`Area import template for  ${project.code} ${project.name}.xlsx`);
    await fileDownload(fileName, `project/${project.id}/area/import-template`);

  }, [project]);

  const openAreaImport = useCallback(async () => {
    if(project == null) {
      return;
    }

    openModal && openModal(AreaImportModal, {
      project: project,
      onClosed: () =>{
        try {
          setIsLoading(true);
          refreshPage();
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false)
        }
      }
    })
  }, [project, openModal]);

  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: Area) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u)
        .filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<AreaProps>>((n: Area) => ({
        referenceId: n.id,
        props: {
          code: n.code,
          name: n.name,
          areaForemanId: n.areaForemanId,
          isActive: n.isActive,
          mainVerticalZoneId: n.mainVerticalZoneId,
          shortInfoA: n.shortInfoA,
          shortInfoB: n.shortInfoB,
          zone3DId: n.zone3DId
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted" && x.id > 0)
      .map((x: Area) => x.id);

    const body = {
      newItems,
      patchedItems,
      deletedItems
    }

    try {
      setIsLoading(true);
      const updateAreasResult = await post<PatchRequest<AreaProps>, PatchResponse<Area>>(`project/${project!.id}/area/patch`, body);
      refreshVisibleAreas(updateAreasResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the area list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the area list"});
      }
    } finally {
      setIsLoading(false);
    }
  }

  const copy = useCallback((source: Area) => {

    const newId = prevNewId - 1;
    setPrevNewId(newId)

    const activeForemen = foremans?.filter(x => x.isActive);
    if(activeForemen == null || activeForemen.length === 0) {
      return;
    }

    const areaForeman = activeForemen.find(x => x.id === source.areaForemanId);
    const areaForemanId = areaForeman != null ? areaForeman.id : activeForemen[0].id;


    const target: Area = {
      id: newId,
      projectId: project ? project.id : 0,
      code: source.code,
      name: source.name,
      isActive: source.isActive,
      areaForemanId,
      zone3DId: source.zone3DId,
      mainVerticalZoneId: source.mainVerticalZoneId,
      state: "new",
      hasError: source.hasError
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setIsDirty(true);
  }, [prevNewId])

  const refreshVisibleAreas = (updates: PatchResponse<Area>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: Area[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  /* Validators */

  const areDependenciesLoaded = foremanOptions != null
    && zone3dOptions != null
    && mvZoneOptions != null;

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  /* Filters */

  const onAreaIdChange = (value: string) => {
    promptIfUnsavedChanges(openModal, isDirty, () => setAreaId(value));
  }

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Area"}
        add={{
          action: addNewItem,
          claim: ProjectClaims.project.area.write.all,
          disabled: !areDependenciesLoaded
        }}
        save={{
          action: update,
          disabled: !areDependenciesLoaded || hasErrors || !isDirty
        }}
        secondaryActions={[
          {
            icon: <GridOn/>,
            action: exportTemplate,
            label: "Download import template",
            color: "default"
          },
          {
            icon: <CloudUploadSharp />,
            action: openAreaImport,
            label: "Import areas",
            color: "default",
            claim: ProjectClaims.project.area.write.all,
          }
          ]}
      />

      {/* Header filter */}
      {initialValues ? (
        <div className={classes.filterItem}>
          <VectTextField
            value={areaId}
            change={onAreaIdChange}
            label={"Area ID"}
            debounceDelay={1000}
          />
        </div>
      ) : <Skeleton variant="rect" width="40rem" height="5rem"/>}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Area ID</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Resp. Foreman</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Main Vert. Zone</TableCell>
            <TableCell>3D Zone ID</TableCell>
            <TableCell>Short info A</TableCell>
            <TableCell>Short info B</TableCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map((area: Area, index: number) => (
                <AreaRowItem
                  key={index}
                  area={area}
                  change={change}
                  copy={copy}
                  foremanOptions={foremanOptions}
                  zone3dOptions={zone3dOptions}
                  mvzOptions={mvZoneOptions}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch} isDirty={isDirty}/>
    </>
  );
}

export default Areas;
