import React, {createContext, FC, useState} from "react";
import {Alert, initialState} from "../interfaces/alert.interface";
import {ProblemDetailError} from "../hooks/useApi";

type AlertContextType = {
  alert: Alert;
  setAlert: (alert: Alert) => void;
  setSuccess: (details?: string[]) => void;
  setError: (error: ProblemDetailError | Error) => void;
}

const initial: AlertContextType = {
  alert: initialState,
  setAlert: () => {return {}},
  setSuccess: () => {return {}},
  setError: () => {return {}}
}


export const AlertContext = createContext<AlertContextType>(initial);

const AlertProvider: FC = ({children}) => {
  const [alert, setAlert] = useState<Alert>(initialState)

  const setError = (error: ProblemDetailError | Error) => {
    setAlert({
      type: "error",
      text: "Oh, dear, something went wrong...",
      error
    });
  }

  const setSuccess = (details?: string[]) => {
    setAlert({type: "success", text: "Done!", details});
  };

  return(
    <AlertContext.Provider value={{alert, setAlert, setSuccess, setError}} >
      {children}
    </AlertContext.Provider>

  );
};

export default AlertProvider;
