import React from "react";
import {Prompt} from "react-router-dom";
import {unsavedCancelDescription, unsavedChangesHeader, unsavedOkDescription} from "../UnsavedChangesModal";

interface VectUnsavedChangesPromptProps {
  isDirty: boolean
}

export const VectUnsavedChangesPrompt: React.FC<VectUnsavedChangesPromptProps> = ({
  isDirty
}): JSX.Element => {
  const message = `${unsavedChangesHeader}.\n\n${unsavedCancelDescription}\n${unsavedOkDescription}`;
  return <Prompt when={isDirty} message={message} />
}
