import React, {useEffect, useState} from "react";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import {CreateProjectModal} from "./CreateProjectModal";
import {GlobalClaims} from "../../../../shared/claims";
import {Option, VectSelect} from "../../../shared/inputs/VectSelect";
import {ProjectListItem, ProjectProps} from "../../../../shared/interfaces/project.interface";
import {ProjectRole, UserListItem} from "../../../../shared/interfaces/user.interface";
import {Skeleton} from "@material-ui/lab";
import {Typography} from "@material-ui/core";
import {UsersAssignment, UsersAssignmentTable} from "../UsersAssignmentTable";
import {VectContainer} from "../../../shared/layout/VectContainer";
import {makeStyles} from "@material-ui/core/styles";
import {useApi} from "../../../../shared/hooks/useApi";
import {useClaims} from "../../../../shared/hooks/useClaims";
import {useModals} from "../../../../shared/context/ModalContext";
import {EditProjectProps} from "../EditProject/EditProjectProps";

const useStyles = makeStyles(() => ({
  newProjectContainer:  {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem"
  },
  buttonContainer: {
    display: "flex",
  },
  sourceProjectSelect: {
    width: "20rem",
    marginTop: "15px",
    marginBottom: "10px"
  }
}))

const NewProject = (): JSX.Element => {
  const [allProjectRoles, setAllProjectRoles] = useState<ProjectRole[] | null>(null);
  const [allUsers, setAllUsers] = React.useState<UserListItem[] | null>(null);
  const [hasError, setHasError] = useState(false);
  const [projectProps, setProjectProps] = useState<ProjectProps>({code: "", name: "", acceptZeroMeterBundleOverride: false});
  const [sourceProjects, setSourceProjects] = useState<Option[] | null>(null);
  const [sourceProjectId, setSourceProjectId] = useState<number | null>(null);
  const [updatedAssignments, setUpdatedAssignments] = useState<UsersAssignment[] | null>(null);
  const classNames = useStyles();
  const {get}  = useApi();
  const {isValid} = useClaims();
  const {openModal} = useModals();

  useEffect(() => {
    let isSubscribed = true;
    if (isValid({claim: GlobalClaims.manage.user.project.readWrite.all})) {
      get<UserListItem[]>(
        `/user/list`,
        {
          queryParams: {
            isAzureAdAccountEnabled: true,
            isDeleted: false
          }
        })
        .then(res => {
          if (isSubscribed) {
            setAllUsers(res);
          }
        });

      get<ProjectRole[]>('/roles/project')
        .then(res => {
          if (isSubscribed) {
            setAllProjectRoles(res);
          }
        });

      get<ProjectListItem[]>("/project")
        .then(res => {
          if(isSubscribed) {
            setSourceProjects(res.map(x => ({value: x.id, label: `${x.code} ${x.name}`})))
          }
        })
    }
    return () => {
      isSubscribed = false
    };
  }, []);

  const onProjectChange = (val: ProjectProps, hasError: boolean) => {
    setProjectProps(val);
    setHasError(hasError);
  }

  const onUsersChange = (assignments: UsersAssignment[]) => {
    setUpdatedAssignments(assignments);
  };

  const submit = () => {
    if (hasError) {
      return;
    }
    const assignedRoles = updatedAssignments != null
      ? updatedAssignments.flatMap(a => a.roles.map(r => ({userId: a.userId, roleId: r.id})))
      : undefined;

    openModal && openModal(CreateProjectModal, {project: projectProps, sourceProjectId, assignedRoles});
  };

  return (
    <div>
      <PanelHeader text={"New Project"} save={
        {
          action: submit,
          disabled: !isValid({claim: GlobalClaims.manage.project.readWrite.all})
        }}/>
      <form className={classNames.newProjectContainer}>
        <EditProjectProps project={projectProps} change={onProjectChange} newProject/>
        <VectSelect className={classNames.sourceProjectSelect}
                    change={setSourceProjectId}
                    options={sourceProjects}
                    value={sourceProjectId}
                    isClearable
                    label="Copy project from"
                    placeholder="Select a project"/>
        <Typography variant="body1">Select a project to copy cables from that project to this vessel.</Typography>
        <Typography variant="body1">Leave the select empty to create a blank project vessel.</Typography>
        <VectContainer claim={GlobalClaims.manage.user.project.readWrite.all}>
          <PanelHeader text={`Project roles for the new project`} size="small" inline/>
          {
            allProjectRoles && allUsers
              ? <UsersAssignmentTable roles={allProjectRoles}
                                      users={allUsers}
                                      onChange={onUsersChange}/>
              : <Skeleton variant="rect" width="15rem" height="5rem"/>
          }
        </VectContainer>
      </form>

    </div>
  );
};


export default NewProject;
