import React, {FunctionComponent, useState,} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useModals} from "../../../../shared/context/ModalContext";
import {CableWithReferenceLabels,} from "../../../../shared/interfaces/cable.interface";
import {VectTextField} from "../../../shared/inputs/VectTextField";


interface SetPenetrationsModalProps {
  cable: CableWithReferenceLabels;
  change: (value: string) => void;
  open?: boolean;
}

export const SetPenetrationsModal: FunctionComponent<SetPenetrationsModalProps> = ({
  cable,
  change,
}) => {
  const [value, setValue] = useState(cable.penetrations || "");
  const { closeModal } = useModals();

  const onCancel = () => {
    closeModal && closeModal();
  }

  const onSubmit = () => {
    change(value);
    if (closeModal) {
      closeModal();
    }
  };


  return (
    <div>
      <Dialog
        open={true}
        maxWidth="sm"
        fullWidth={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Set penetrations for {cable.code}
        </DialogTitle>
        <DialogContent>
          <VectTextField
            value={value}
            change={setValue}
            multiline
            variant="outlined"
            style={{width: "100%", height: "100%", marginTop: 10, marginBottom: 20}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="secondary">Cancel</Button>
          <Button onClick={onSubmit} color="primary">Submit selection</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
