import React, {FC} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {Option} from "../../shared/inputs/VectSelect";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {ProjectClaims} from "../../../shared/claims";
import {VectTableRowSelect} from "../../shared/inputs/VectTableRowSelect";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {VectContainer} from "../../shared/layout/VectContainer";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {CopyButton} from "../../shared/table/CopyButton";

const options: Option[] = [
  {
    value: 1,
    label: "Area CBS/Foreman"
  },
  {
    value: 2,
    label: "System CBS/Foreman"
  },
  {
    value: 3,
    label: "Other"
  }
]

interface AreaRowItemProps {
  areaForeman: Areaforeman;
  change: (value: Areaforeman) => void;
  copy: (value: Areaforeman) => void;
}

const AreaRowItem: FC<AreaRowItemProps> = ({
 areaForeman,
 change,
 copy
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: areaForeman.code ? undefined : "An area foreman code is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: Areaforeman): boolean => {
      return !newState.code;
    }

    handleCellChange(
      change,
      hasError,
      areaForeman,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...areaForeman,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(areaForeman);
  }

  const style = areaForeman.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput style={{width: "7.5%"}} className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.areaForeman.write.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={areaForeman.code}
          change={onCellChange}
          variant="standard"/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.areaForeman.write.all}
          fieldName="foremanText"
          value={areaForeman.foremanText}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.areaForeman.write.all}
          fieldName="name"
          value={areaForeman.name}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.areaForeman.write.all}
          fieldName={"isAreaOrSystem"}
          value={areaForeman.isAreaOrSystem}
          change={onCellChange}
          options={options}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectCheckbox
          claim={ProjectClaims.project.areaForeman.write.all}
          fieldName={"isActive"}
          checked={areaForeman.isActive}
          change={onCellChange}
          color="primary"
          size={"small"}
        />
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.areaForeman.write.all}>
          <TableCellInput style={{ display: "flex" }} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCellInput>
        </VectContainer>

        {/* Delete */}
        <VectContainer claim={ProjectClaims.project.areaForeman.write.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={areaForeman.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={areaForeman.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
}

export default AreaRowItem;
