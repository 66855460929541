import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import {ConfigContext} from "../context/ConfigContext";

interface useSignalRHooks {
  projectHub: () => HubConnection
}

export const useSignalR = (): useSignalRHooks => {

  const {getAccessToken} = useContext(AuthContext);
  const {api, scopes} = useContext(ConfigContext)

  const projectHub = () :HubConnection => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${api.hostname}/hubs/project`, {
        accessTokenFactory: () => getAccessToken(scopes)
      })
      .withAutomaticReconnect()
      .build();
    return newConnection;
  }

  return {
    projectHub
  };
}
