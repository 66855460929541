import React, {FC, useEffect, useMemo} from "react";
import {Checkbox, TextField} from "@material-ui/core";
import {Column, Row} from "react-table";
import useDebounce from "../../../shared/hooks/useDebounce";

type EditableCellTypes = {
  value: number | string | boolean | undefined
  row: Row,
  column: Column,
  change: (value: number | string | boolean, id:number, field: string, cellState: string) => void,
}

const EditableCell: FC<EditableCellTypes> = ({
                        value: initialValue,
                        row: { values },
                        column: { id, type },
                        change, // This is a custom function that we supplied to
                        // our table instance
                      }: any) => { //TODO: type
  const [value, setValue] = React.useState(initialValue)
  const debouncedValue = useDebounce<number | string | boolean | undefined>(value, 750)
  useEffect(() => {
    change(value, values.id, id, values.state)
  }, [debouncedValue])
  const onChange = (e: any) => {
    let val = type === "checkbox" ? e.target.checked :e.target.value;
    if(type === "number")
    {
      val = parseInt(val)
    }
    setValue(val)
  }
  // We'll only update the external data when the input is blurred

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  return useMemo(() => {
    return type === "checkbox" ?  <Checkbox key={`${values.id}}`} size={"small"} checked={value} onChange={onChange} color="primary"/> : <TextField key={`${values.id}`} fullWidth type={type} size={"small"} value={value} onChange={onChange}  />
  },  [value])
}
export default EditableCell;
