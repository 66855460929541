import {AuthTextFieldProps, VectTextField} from "./VectTextField";
import React from "react";

export const VectTableTextField: React.FC<AuthTextFieldProps> = (props) => {
  const textFieldProps : AuthTextFieldProps = {
    ...props,
    fullWidth: true,
    variant: "standard"
  }

  return <VectTextField {...textFieldProps} />
}
