import React, {FC, useContext, useEffect, useState} from 'react';
import {ProductionWeek} from "../../../shared/interfaces/productionWeek.interface";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {useApi} from "../../../shared/hooks/useApi";
import ViewRegisteredAndFixed from "./ViewRegisteredAndFixed";
import ViewRegisteredAndFixedMeters from "./ViewRegAndFixedMeters";
import ViewConnections from './ViewConnections';
import {VectButton} from "../../shared/navigation/VectButton";
import {ProjectClaims} from "../../../shared/claims";


const ChartView: FC = () => {
  const [productionWeek, setProductionWeek] = useState<ProductionWeek[]>([]);
  const {project} = useContext(ProjectContext)
  const [view, setView] = useState<number>(0)
  const {get} = useApi()

  useEffect(() => {
    let isSubscribed = true;
    const fetchStatVals = async () => {
      if (project?.id) {
        const result = await get<ProductionWeek[]>(`/project/${project.id}/productionWeek`).then(res => res);
        if (isSubscribed) {
          setProductionWeek(result)
        }
      }
    }
    fetchStatVals()
    return () => {
      isSubscribed = false
    };
  }, [project?.id])

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-evenly",
        paddingBottom: "2rem"
      }}>
        <VectButton
          claim={ProjectClaims.project.read}
          disabled={productionWeek.length === 0}
          onClick={() => setView(1)}
        >
          View Reg and Fix Pcs
        </VectButton>
        <VectButton
          claim={ProjectClaims.project.read}
          disabled={productionWeek.length === 0}
          onClick={() => setView(2)}
        >
          View Reg and Fix Meters
        </VectButton>
        <VectButton
          claim={ProjectClaims.project.read}
          disabled={productionWeek.length === 0}
          onClick={() => setView(3)}
        >
          View Connections
        </VectButton>
      </div>
      {view === 1 && <ViewRegisteredAndFixed productionWeeks={productionWeek}/>}
      {view === 2 && <ViewRegisteredAndFixedMeters productionWeeks={productionWeek}/>}
      {view === 3 && <ViewConnections productionWeeks={productionWeek}/>}
    </>
  )
};


export default ChartView;
