import React, {FC} from "react";
import {CableType} from "../../../shared/interfaces/cableType.interface";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";


interface CableTypeRowItemProps {
  cableType: CableType;
}

export const ProjectCableTypeRowItem: FC<CableTypeRowItemProps> = ({
   cableType
 }) => {
  const cellClasses = tableCellStyles();


  return (
    <StyledTableRow >
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.code}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.signalType}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.typeIdLogistics}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.minimumOrderQuantity}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.deliveryTimeWeeks}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.copperWeightGramsPerMeter}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.weightGramsPerMeter}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.outerDimension}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.roxType}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.estimatedHoursPerPieceConnecting}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableType.estimatedHoursPerMetersPulling}/>
      </TableCellInput>
    </StyledTableRow>
  )
}
