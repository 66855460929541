import React, {FunctionComponent, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useModals} from "../../../shared/context/ModalContext";
import {LinearProgress, LinearProgressProps, PropTypes, Typography} from "@material-ui/core";
import {VectTextField} from "../inputs/VectTextField";

export type DialogProps = {
  cancel?: () => void;
  cancelColor?: PropTypes.Color;
  cancelText?: string;
  confirmation?: DialogConfirmationChallenge;
  description?: string;
  context?: string;
  /**
   * When detailed context is needed, use paragraphs
   */
  paragraphs?: {head: string, text: string }[];
  jsx?: JSX.Element;
  ok?: () => void;
  okColor?: PropTypes.Color
  okText?: string;
  okDisabled?: boolean;
  progressColor?: LinearProgressProps["color"]
  title: string;
  open?: boolean;
  secondaryAction?: () => void;
  secondaryActionText?: string;
  cancelLoading?: boolean;
};

export interface DialogConfirmationChallenge {
  description: string;
  challenge: string;
}

export const Modal: FunctionComponent<DialogProps> = ({
  cancel,
  cancelColor = "secondary",
  cancelText,
  confirmation,
  description,
  context,
  jsx,
  ok,
  okColor = "primary",
  okText,
  okDisabled,
  paragraphs,
  progressColor = "primary",
  title,
  secondaryAction,
  secondaryActionText,
  cancelLoading
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSecondaryAction, setIsLoadingSecondaryAction] = useState(false);
  const [isCancelLoading, setCancelLoading] = useState<boolean>(false);
  const [challengeResponse, setChallengeResponse] = useState("")
  const {closeModal} = useModals();

  const onCancelClicked = async () => {
    if (cancel != null) {
      setCancelLoading(true);
      await cancel();
      setCancelLoading(false);
    } else {
      closeModal && closeModal();
    }
  }

  const onOkClicked = async () => {
    if (ok != null) {
      setIsLoading(true);
      await ok()
      setIsLoading(false);
    } else {
      closeModal && closeModal();
    }
  }

  const onSecondaryActionClicked = async () => {
    if (secondaryAction != null) {
      setIsLoadingSecondaryAction(true);
      await secondaryAction()
      setIsLoadingSecondaryAction(false);
    } else {
      closeModal && closeModal();
    }
  }

  const renderBusyIndicator = (): JSX.Element => {
    return (
      <>
        {cancelLoading && (
          <div>
            <Button color={cancelColor}
                    variant="contained"
                    disabled={true}>
              {cancelText}
            </Button>
            {isCancelLoading && <LinearProgress color={progressColor}/>}
          </div>
        )}
        {secondaryActionText && (
          <div>
            <Button color={okColor}
                    variant="contained"
                    disabled={true}>
              {secondaryActionText}
            </Button>
            {isLoadingSecondaryAction && <LinearProgress color={progressColor}/>}
          </div>
        )}
        <div>
          <Button color={okColor}
                  variant="contained"
                  disabled={true}>
            {okText}
          </Button>
          {isLoading && <LinearProgress color={progressColor}/>}
        </div>
      </>
    );
  }

  const renderChallenge = (): JSX.Element | null => {
    if (!confirmation) {
      return null;
    }

    return (
      <div>
        <DialogContentText>
          {confirmation.description}
        </DialogContentText>
        <VectTextField value={challengeResponse}
                       change={setChallengeResponse}
                       fullWidth={true}/>
      </div>
    );
  }

  const renderChoices = (): JSX.Element => {
    return (
      <>
        {cancelText ? (
          <Button onClick={onCancelClicked} color={cancelColor} variant="contained" autoFocus>
            {cancelText}
          </Button>
        ) : null}
        {secondaryActionText ? (
          <Button onClick={onSecondaryActionClicked}
                  color={okColor}
                  variant="contained"
                  disabled={confirmation != null ? confirmation.challenge !== challengeResponse : false}>
            {secondaryActionText}
          </Button>
        ) : null}
        {okText ? (
          <Button onClick={onOkClicked}
                  color={okColor}
                  variant="contained"
                  disabled={okDisabled != null ? okDisabled : (confirmation != null ? confirmation.challenge !== challengeResponse : false)}>
            {okText}
          </Button>
        ) : null}
      </>
    )
  }

  const renderDescription = (): JSX.Element => {
    return (
      <div>
        {description && <DialogContentText id="alert-dialog-description">{description}</DialogContentText>}
        {context && <DialogContentText id="alert-dialog-description">{context}</DialogContentText>}
        {paragraphs && (
          <DialogContentText id="alert-dialog-description">
            {paragraphs.map(p => (
              <>
                <Typography variant="body1">{p.head}</Typography>
                <Typography variant="body2" gutterBottom>{p.text}</Typography>
              </>
            ) )}
          </DialogContentText>
        )}
      </div>

    );
  }

  const renderJsx = (): JSX.Element | null => {
    return jsx ? (
      <>{jsx}</>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {renderJsx()}
          {renderDescription()}
          {renderChallenge()}
        </DialogContent>
        <DialogActions>
          {isLoading || isLoadingSecondaryAction || isCancelLoading ? renderBusyIndicator() : renderChoices()}
        </DialogActions>
      </Dialog>
    </div>
  );
};
