import React, {FunctionComponent, memo} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {ProjectClaims} from "../../../shared/claims";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {VectContainer} from "../../shared/layout/VectContainer";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {TableCell} from "@material-ui/core";
import {CopyButton} from "../../shared/table/CopyButton";
import {ProductionResponsible} from "../../../shared/interfaces/productionResponsible.interface";

type ProductionResponsibleRowItemProps = {
  productionResponsible: ProductionResponsible;
  change: (value: ProductionResponsible) => void;
  copy: (value: ProductionResponsible) => void;
};

const ProductionResponsibleRowItem: FunctionComponent<ProductionResponsibleRowItemProps> = ({
  productionResponsible,
  change,
  copy,
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: productionResponsible.code ? undefined : "A production responsible code is required",
    name: productionResponsible.name ? undefined : "A production responsible name is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: ProductionResponsible): boolean => {
      return !newState.code || !newState.name;
    }

    handleCellChange(
      change,
      hasError,
      productionResponsible,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...productionResponsible,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(productionResponsible);
  }

  const style = productionResponsible.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput style={{padding: "1rem"}} className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.productionResponsible.write.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={productionResponsible.code}
          change={onCellChange}
          variant="standard"
          fullWidth
          required
        />
      </TableCellInput>
      <TableCellInput style={{padding: "1rem"}} className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.productionResponsible.write.all}
          error={!!errors.name}
          helperText={errors.name}
          fieldName="name"
          value={productionResponsible.name}
          change={onCellChange}
          variant="standard"
          fullWidth
          required
        />
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.productionResponsible.write.all}>
          <TableCell style={{display: "flex"}} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCell>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: 'center'}} claim={ProjectClaims.project.productionResponsible.write.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={productionResponsible.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={productionResponsible.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
};

export default memo(ProductionResponsibleRowItem);
