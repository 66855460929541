import React, { createContext, useContext, useMemo, useState } from "react";
import {
  ModalStackProps,
  ModalStackValue,
  StackedModal,
} from "../interfaces/modal.interface";

export const ModalStackContext = createContext<ModalStackValue>({} as any);

export function ModalStack({
  children,
  renderModals: ModalsComponent = Modals,
}: ModalStackProps): JSX.Element {
  const [stack, setStack] = useState<StackedModal[]>([]);

  const value = useMemo<ModalStackValue>(() => {
    function pop(amount = 1) {
      return setStack((prev) => [...prev].slice(0, prev.length - amount));
    }

    function dismissAll() {
      setStack([]);
    }

    function dismiss(amount?: number) {
      if (stack.length === 1) {
        dismissAll();
      } else {
        pop(amount);
      }
    }

    return {
      stack,
      openModal: (component, props, options) => {
        setStack((prev) => {
          let newStack = [...prev];

          if (options?.replace) {
            newStack = stack.slice(0, stack.length - 1);
          }

          return [...newStack, { component, props } as StackedModal];
        });
      },
      closeModal: () => dismiss(1),
      closeModals: dismiss,
      closeAllModals: dismissAll,
    };
  }, [stack]);

  return (
    <ModalStackContext.Provider value={value}>
      {children}
      <ModalsComponent {...value} />
    </ModalStackContext.Provider>
  );
}

function Modals({ stack }: ModalStackValue) {
  return (
    <>
      {stack.map((modal, index) => {
        return (
          <modal.component
            key={index}
            open={modal === stack[stack.length - 1]}
            {...modal.props}
          />
        );
      })}
    </>
  );
}

export function useModals(): ModalStackValue {
  return useContext(ModalStackContext)
}
