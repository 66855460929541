import React, {FC, useContext, useEffect, useState} from 'react';
import {ProductionWeek} from "../../../shared/interfaces/productionWeek.interface";
import {ChartLine} from "../../../shared/interfaces/common.interface";
import LineChart from "./LineChart";
import {AlertContext} from "../../../shared/context/AlertContext";

interface ViewRegisteredAndFixedMetersProps {
  productionWeeks: ProductionWeek[]
}

const ViewRegisteredAndFixedMeters: FC<ViewRegisteredAndFixedMetersProps> = ({productionWeeks}) => {
  const [lines, setLines] = useState<ChartLine[]>([])
  const {setAlert} = useContext(AlertContext);

  useEffect(() => {
    const plannedRegMeters: ChartLine = {
      color: "blue", points: [], name: "Planned" +
        " Reg Meters"
    }
    const plannedFixedMeters: ChartLine = {
      color: "yellow",
      points: [],
      name: "Planned Fixed Meters"
    }
    const regMeters: ChartLine = {
      color: "pink",
      points: [],
      name: "Planned Fix Cables"
    }
    const fixedMeters: ChartLine = {
      color: "teal",
      points: [],
      name: "Registered Cables"
    }

    const start = 0;//project?.plannedCableRegistrationStart !== undefined ? parseInt(project?.plannedCableRegistrationStart?.replace('-', '')) : 0
    const end = 0;//project?.plannedCableFixingFinish !== undefined ? parseInt(project?.plannedCableFixingFinish?.replace('-', '')) : 0
    setAlert({type: "info", text: "This window does not show anything until the engineering, routing, and production programme is implemented."});
    productionWeeks.forEach(ele => {
      const codeNoDash = ele.code.replace('-', '');
      const parsedCode = parseInt(codeNoDash)
      if(parsedCode >= start && parsedCode <= end) {
        plannedRegMeters.points.push({
          x: codeNoDash,
          y: ele.plannedCableRegistrationMeters !== undefined ? ele.plannedCableRegistrationMeters : 0
        })
        plannedFixedMeters.points.push({
          x: codeNoDash,
          y: ele.plannedCableFixedMeters !== undefined ? ele.plannedCableFixedMeters : 0
        })
        regMeters.points.push({
          x: codeNoDash,
          y: ele.actualCableRegistrationMeters !== undefined ? ele.actualCableRegistrationMeters : 0
        })
        fixedMeters.points.push({
          x: codeNoDash,
          y: ele.actualCableFixedMeters !== undefined ? ele.actualCableFixedMeters : 0
        })
      }
    })
    setLines([
      plannedRegMeters,
      regMeters,
      plannedFixedMeters,
      fixedMeters,
    ])
  }, [])
  return ( lines.length > 0 ? <LineChart lines={lines} name={"Registered and" +
    " fixed meters"} /> : null
  )
};


export default ViewRegisteredAndFixedMeters;
