import React, {FC} from 'react';
import {ProjectTotals} from "../../../shared/interfaces/projectTotals.interface";
import {Paper, Table, TableBody, TableHead, TableRow, Typography} from "@material-ui/core";
import {TableCellInput} from '../../shared/table/styles';

interface ProductionStatusOverviewProps {
  projectTotals: ProjectTotals
}

const ProductionStatusOverview: FC<ProductionStatusOverviewProps> = ({projectTotals}) => {
  return (
    <Paper style={{padding: "10px"}}>
    <Typography variant={"h6"}>Production Status overview:</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellInput />
            <TableCellInput>Total: </TableCellInput>
            <TableCellInput >Draft - not ready for production: </TableCellInput>
            <TableCellInput />
            <TableCellInput>Ready but on HOLD: </TableCellInput>
            <TableCellInput />
            <TableCellInput>Ready for measuring: </TableCellInput>
            <TableCellInput />
            <TableCellInput>Measured ready f. checkout:</TableCellInput>
            <TableCellInput />
            <TableCellInput>Checked - ready for production:</TableCellInput>
            <TableCellInput />
            <TableCellInput style={{borderLeft: "2px solid"}}>Cutted:</TableCellInput>
            <TableCellInput />
            <TableCellInput>Fixed:</TableCellInput>
            <TableCellInput />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellInput>Meter of cable reg</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.totalCableMeters}</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableMetersDraft}</TableCellInput> <TableCellInput> {projectTotals.cableTotals.cableMetersDraftPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableMetersHold} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.cableMetersHoldPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableMetersReady} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.cableMetersReadyPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableMetersMeasured} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.cableMetersMeasuredPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableMetersChecked}</TableCellInput> <TableCellInput> {projectTotals.cableTotals.cableMetersCheckedPercentage}%</TableCellInput>
            <TableCellInput style={{borderLeft: "2px solid"}}> {projectTotals.cableTotals.cableMetersCut} </TableCellInput> <TableCellInput> {projectTotals.cableTotals.cableMetersCutPercentage}% </TableCellInput>
            <TableCellInput>{projectTotals.cableTotals.cableMetersPulled} </TableCellInput>   <TableCellInput>{projectTotals.cableTotals.cableMetersPulledPercentage}%</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>No of cable reg</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.totalCable}</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableDraft}</TableCellInput> <TableCellInput> {projectTotals.cableTotals.cableDraftPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableHold} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.cableHoldPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableReady} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.cableReadyPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableMeasured} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.cableMeasuredPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.cableChecked}</TableCellInput> <TableCellInput> {projectTotals.cableTotals.cableCheckedPercentage}%</TableCellInput>
            <TableCellInput style={{borderLeft: "2px solid"}}> {projectTotals.cableTotals.cableCut} </TableCellInput> <TableCellInput> {projectTotals.cableTotals.cableCutPercentage}% </TableCellInput>
            <TableCellInput>{projectTotals.cableTotals.cablePulled} </TableCellInput>   <TableCellInput>{projectTotals.cableTotals.cablePulledPercentage}%</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>No of bundle reg</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.bundleTotal}</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.bundleDraft}</TableCellInput> <TableCellInput> {projectTotals.cableTotals.bundleDraftPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.bundleHold} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.bundleHoldPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.bundleReady} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.bundleReadyPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.bundleMeasured} </TableCellInput> <TableCellInput>{projectTotals.cableTotals.bundleMeasuredPercentage}%</TableCellInput>
            <TableCellInput> {projectTotals.cableTotals.bundleChecked}</TableCellInput> <TableCellInput> {projectTotals.cableTotals.bundleCheckedPercentage}%</TableCellInput>
            <TableCellInput style={{borderLeft: "2px solid"}}> {projectTotals.cableTotals.bundleCut} </TableCellInput> <TableCellInput> {projectTotals.cableTotals.bundleCutPercentage}% </TableCellInput>
            <TableCellInput>{projectTotals.cableTotals.bundlePulled} </TableCellInput>   <TableCellInput>{projectTotals.cableTotals.bundlePulledPercentage}%</TableCellInput>
          </TableRow>
        </TableBody>
      </Table>

    </Paper>
  )
};


export default ProductionStatusOverview;
