import React, {FC} from 'react';
import {CableListItem} from "../../../../shared/interfaces/cable.interface";
import {Tag} from "../../../../shared/interfaces/tag.interface";
import {InputLabel, Typography} from "@material-ui/core";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {gridStyles} from "../modals/BatchUpdateTagsModal.styles";

interface CableRowModalProps {
  cable: CableListItem;
  newFromTag?: Tag;
  newToTag?: Tag;
  prevFromTag?: Tag;
  prevToTag?: Tag;
  fromTextChange: (value: string) => void;
  toTextChange: (value: string) => void;
}

const helperText = "Please revise."

const CableRowModal: FC<CableRowModalProps> = ({
   cable,
  newFromTag,
  newToTag,
  prevFromTag,
  prevToTag,
  fromTextChange,
  toTextChange
 }) => {
  const grid = gridStyles();

  const reviseFromText = newFromTag && cable.fromText !== (prevFromTag ? prevFromTag.name : newFromTag.name);
  const reviseToText = newToTag && cable.toText !== (prevToTag ? prevToTag.name : newToTag.name);

  return (
    <div className={grid.grid} style={{marginBottom: 15}}>
      <Typography className={grid.cableNumber}>{cable.cableNumber}</Typography>
      <Typography className={grid.cableType}>{cable.cableTypeCode}</Typography>
      <Typography className={grid.cableCode}>{cable.code}</Typography>
      <InputLabel className={grid.newFromTagLabel} id={"fromTag"}>New Tag Id</InputLabel>
      <Typography className={grid.newFromTagCode}
                  id={"fromTag"}>
        {newFromTag !== null ? newFromTag?.code : cable.fromTagCode}
      </Typography>
      <Typography className={grid.newFromAreaCode}>{cable.fromAreaCode}</Typography>
      <InputLabel className={grid.newFromTextLabel}
                  id={"fromText"}>
        Original From Text - must be revised/checked if <span style={{color: "#e13838"}}>RED</span>
      </InputLabel>
      <VectTextField className={grid.newFromText}
                     id={"fromText"}
                     fullWidth
                     change={fromTextChange}
                     value={cable.fromText}
                     multiline={true}
                     disabled={!newFromTag}
                     error={reviseFromText}
                     helperText={reviseFromText ? helperText : undefined}/>
      <InputLabel className={grid.newToTagLabel} id={"toTag"}>New Tag Id</InputLabel>
      <Typography className={grid.newToTagCode}
                  id={"toTag"}>
        {newToTag !== null ? newToTag?.code : cable.toTagCode}
      </Typography>
      <Typography className={grid.newToAreaCode}>{cable.toAreaCode}</Typography>
      <InputLabel className={grid.newToTextLabel}
                  id={"toText"}>
        Original To Text - must be revised/checked if <span style={{color: "#e13838"}}>RED</span>
      </InputLabel>
      <VectTextField className={grid.newToText} id={"toText"}
                     fullWidth
                     change={toTextChange}
                     value={cable.toText}
                     multiline={true}
                     disabled={!newToTag}
                     error={reviseToText}
                     helperText={reviseToText ? helperText : undefined}/>
    </div>
  )

}

export default CableRowModal
