/**
 * Used for in-app URL redirection
 */
export const CommonPaths = {
  manage: {
    projects: {
      edit: (projectCode: string): string => `/manage/projects/edit/${projectCode}`,
      newProject: "/manage/projects/new-project",
      list: "/manage/projects/all-projects"
    },
    users: {
      editGlobalRole: (roleId: number): string => `/manage/users/global-role/edit/${roleId}`,
      editProjectRole: (roleId: number): string => `/manage/users/project-role/edit/${roleId}`,
      newGlobalRole: "/manage/users/global-role/new",
      newProjectRole: "/manage/users/project-role/new",
      newUser: "/manage/users/new-user"
    }
  },
  order: {
    details: (projectCode: string, orderId: number): string => `/project/${projectCode}/cable-types/orders/details/${orderId}`,
    newOrder:  (projectCode: string): string => `/project/${projectCode}/cable-types/orders/new`
  },
  project: {
    list: "/projects"
  }
};
