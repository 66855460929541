import {Field} from "../../../shared/interfaces/scalar";
import React from "react";
import {StyledTableRow} from "../../shared/table/styles";
import {TableCell} from "@material-ui/core";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";

export interface PenetrationListValidationResult {
	penetrations: PenetrationListValidationItem[];
	errorCount: number;
}
export interface PenetrationListValidationItem {
	projectId: number;
	code: Field;
	name: Field;
	orientation: Field;
}

export const PenetrationImportRowItem: React.FC<PenetrationListValidationItem> = ({
  code,
  name,
  orientation
}) => {

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={code.value} error={code.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={name.value} error={name.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={orientation.value} error={orientation.error}/>
      </TableCell>
    </StyledTableRow>
  )
}
