import {
  FilledInputProps,
  InputBaseComponentProps,
  InputProps,
  OutlinedInputProps,
  PropTypes,
  TextField
} from "@material-ui/core";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import React, {ChangeEvent, CSSProperties, RefObject, useEffect, useState} from "react";
import {useClaims} from "../../../shared/hooks/useClaims";
import useDebounce from "../../../shared/hooks/useDebounce";

export interface AuthTextFieldProps extends AuthProps {
  /**
   * In conjunction with type="number", disable input of negative values
   */
  allowNegativeNumbers?: boolean
  change?: (value: string, fieldName?: string, hasError?: boolean) => void;
  className?: string;
  debounceDelay?: number
  disabled?: boolean;
  error?: boolean;
  fieldName?: string;
  fullWidth?: boolean;
  /**
   * Helper text on errors
   */
  helperText?: string;
  id?: string;
  inputProps?: InputBaseComponentProps;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  label?: string;
  margin?: PropTypes.Margin;
  multiline?: boolean;
  ref?: RefObject<any>;
  required?: boolean;
  size?: "small" | "medium";
  style?: CSSProperties;
  type?: string;
  value?: number | string | boolean | Date | null;
  variant?: "filled" | "standard" | "outlined";
  /**
   * Display the
   */
  yearWeek?: boolean;
}

export const VectTextField: React.FC<AuthTextFieldProps> = ({
  allowNegativeNumbers = false,
  children,
  claim,
  claims,
  useExactMatch,
  change,
  className,
  debounceDelay = 350,
  disabled,
  error,
  fieldName,
  fullWidth,
  helperText,
  id,
  inputProps,
  InputProps,
  label,
  margin,
  multiline,
  ref,
  required,
  size= "small",
  style,
  type,
  value: initialValue = "",
  variant = "filled"
}): JSX.Element => {


  const{isValid} = useClaims();
  const [value, setValue] = useState<number | string | boolean | Date | null | undefined>(initialValue);
  const debouncedValue = useDebounce(value, debounceDelay)

  useEffect(() => {
    if(change && debouncedValue !== initialValue) {
      change(`${value}`, fieldName);
    }
  }, [debouncedValue])

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);


  const textFieldReadonlyProps = {
    className,
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    inputProps,
    InputProps,
    label,
    margin,
    multiline,
    required,
    size,
    style,
    type,
    value,
    InputLabelProps: { shrink: true }
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if(type === "number" && !allowNegativeNumbers) {
      setValue(Math.abs(+event.target.value));
    }
    else {
      setValue(event.target.value);
    }

  }

  if(isValid({claim, claims, useExactMatch})) {
    const textFieldProps = Object.assign(
      {},
      {
        ...textFieldReadonlyProps,
        min: type === "number" && !allowNegativeNumbers ? 0 : undefined,
        ref,
        onChange,
        variant
      });
    return <TextField {...textFieldProps}>{children}</TextField>;
  } else {
    return <TextField {...textFieldReadonlyProps} disabled variant="standard">{children}</TextField>;
  }
}
