import React, {FunctionComponent, useContext, useEffect, useMemo, useState,} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useModals} from "../../../../shared/context/ModalContext";
import {ProblemDetailError, useApi} from "../../../../shared/hooks/useApi";
import {generalStyles} from "../../../shared/table/styles";
import {Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography,} from "@material-ui/core";
import {Modal} from "../../../shared/Feedback/Modal";
import {Area} from "../../../../shared/interfaces/area.interface";
import {Tag} from "../../../../shared/interfaces/tag.interface";
import {AlertContext} from "../../../../shared/context/AlertContext";
import {Cable, CableProps} from "../../../../shared/interfaces/cable.interface";
import {SystemListItem} from "../../../../shared/interfaces/system.interface";
import {MainVerticalZone} from "../../../../shared/interfaces/mainVerticalZone.interface";
import {Bundle} from "../../../../shared/interfaces/bundle.interface";
import {Zone3d} from "../../../../shared/interfaces/zone3d.interface";
import {CableTypeListItem} from "../../../../shared/interfaces/cableType.interface";
import {Areaforeman} from "../../../../shared/interfaces/areaforeman.interface";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {makeStyles} from "@material-ui/core/styles";
import {Option, VectSelect} from "../../../shared/inputs/VectSelect";
import {ProjectClaims} from "../../../../shared/claims";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../../shared/interfaces/patchRequest";
import {mapAreaOptions, mapCableTypeOptions, mapTagOptions} from "../../../../shared/helpers/metadata";
import {CableClass} from "../../../../shared/interfaces/cableClass.interface";

interface CreateSeriesModalProps {
  projectId: number;
  system: SystemListItem;
  tags: Tag[];
  areas: Area[];
  mvZones: MainVerticalZone[];
  bundles: Bundle[];
  zones3d: Zone3d[];
  cableTypes: CableTypeListItem[];
  cableClasses: CableClass[];
  foremen: Areaforeman[];
  open?: boolean;
  refreshList: () => void;
}

export const CreateSeriesModal: FunctionComponent<CreateSeriesModalProps> = ({
  projectId,
  system,
  tags,
  areas,
  mvZones,
  bundles,
  zones3d,
  cableTypes,
  cableClasses,
  foremen,
  refreshList,
}) => {
  /** State **/
  // Data
  const [seriesType, setSeriesType] = useState<string>("standard");
  /** Standard Series Type State **/
  const [prefix, setPrefix] = useState<string>("");
  const [startVal, setStartVal] = useState<string>("");
  const [endVal, setEndVal] = useState<string>("");
  /** End of Standard Series Type State **/
  /** Standard Series Type State **/
  const [itemId, setItemId] = useState<string>("");
  const [phaseId, setPhaseId] = useState<string>("");
  const [numbOfCores, setNumbOfCores] = useState<number>(0);
  /** End of Standard Series Type State **/
  const [calculatedSeriesStart, setCalculatedSeriesStart] = useState<string>(
    ""
  );
  const [calculatedSeriesStop, setCalculatedSeriesStop] = useState<string>("");
  const [srtp, setSrtp] = useState<boolean>(false);
  const [cableType, setCableType] = useState<number | null>();
  const [cableClass, setCableClass] = useState<number | null>();

  const [fromTag, setFromTag] = useState<number | null>();
  const [toTag, setToTag] = useState<number | null>();
  const [fromArea, setFromArea] = useState<number | null>();
  const [toArea, setToArea] = useState<number | null>();
  const [fromConnResp, setFromConnResp] = useState<number | null>();
  const [toConnResp, setToConnResp] = useState<number | null>();
  const [fromZone3d, setFromZone3d] = useState<number | null>();
  const [toZone3d, setToZone3d] = useState<number | null>();
  const [fromMvz, setFromMvz] = useState<number | null>();
  const [toMvz, setToMvz] = useState<number | null>();
  const [bundle, setBundle] = useState<number | null>();

  /** Locks **/
  const [fromAreaLocked, setFromAreaLocked] = useState<boolean>(false);
  const [toAreaLocked, setToAreaLocked] = useState<boolean>(false);
  const [fromConnRespLocked, setFromConnRespLocked] = useState<boolean>(false);
  const [toConnRespLocked, setToConnRespLocked] = useState<boolean>(false);
  const [fromZone3dLocked, setFromZone3dLocked] = useState<boolean>(false);
  const [toZone3dLocked, setToZone3dLocked] = useState<boolean>(false);
  const [fromMvzLocked, setFromMvzLocked] = useState<boolean>(false);
  const [toMvzLocked, setToMvzLocked] = useState<boolean>(false);
  /** End of State **/

  const classes = generalStyles();
  const useStyles = makeStyles(() => ({
    selectionList: {
      display: "flex",
      flexDirection: "column",
      "& > div": {
        marginTop: 5,
        marginBottom: 5,
      },
      "& > p": {
        marginTop: 5,
        marginBottom: 5,
      }
    },
    selectionListHeader: {
      marginTop: 10
    }
  }));
  const styles = useStyles();

  const { setAlert } = useContext(AlertContext);
  const { closeModal, openModal } = useModals();
  const { post } = useApi();

  const mapCode = ({id, code}: {id: number, code: string}): Option => ({value: id, label: code});
  const mapCodeName = ({id, code, name}: {id: number, code: string, name?: string}): Option => ({value: id, label: `${code} ${name || ""}`});
  const tagOptions = useMemo((): Option[] | null => mapTagOptions(tags), [tags]);
  const areaOptions = useMemo(():Option[] | null => mapAreaOptions(areas), [areas]);
  const mvZoneOptions = useMemo(():Option[] => mvZones.map(mapCode), [mvZones]);
  const zone3dOptions = useMemo(():Option[] => zones3d.map(mapCodeName), [zones3d]);
  const cableClassOptions = useMemo(():Option[] => cableClasses.map(mapCodeName), [cableClasses]);
  const bundleOptions = useMemo(():Option[] => bundles.map(mapCodeName), [bundles]);
  const cableTypeOptions = useMemo(():Option[] | null => mapCableTypeOptions(cableTypes), [cableTypes]);
  const foremanOptions = useMemo(():Option[] => foremen.map(mapCodeName), [foremen]);

  useEffect(() => {
    const defaultBundle = bundles.find(x => x.code === "000");
    const defaultConnectionResponsible = foremen.find(x => x.code === "000");

    setFromConnResp(defaultConnectionResponsible?.id);
    setToConnResp(defaultConnectionResponsible?.id);
    setBundle(defaultBundle?.id);
  }, []);

  useEffect(() => {
    if (seriesType === "standard") {
      setCalculatedSeriesStart(system.code + "." + prefix + startVal);
      setCalculatedSeriesStop(system.code + "." + prefix + endVal);
    } else {
      setCalculatedSeriesStart(
        system.code +
          "." +
          (itemId !== "" ? itemId + "." : "") +
          (phaseId !== "" ? phaseId : "") +
          (numbOfCores > 0 ? "01" : "")
      );
      setCalculatedSeriesStop(
        system.code +
          "." +
          (itemId !== "" ? itemId + "." : "") +
          (phaseId !== "" ? phaseId : "") +
          (numbOfCores > 0 && numbOfCores < 10
            ? "0" + numbOfCores
            : numbOfCores)
      );
    }
  }, [
    seriesType,
    system,
    prefix,
    startVal,
    endVal,
    itemId,
    phaseId,
    numbOfCores,
  ]);

  const openInformationModal = () => {
    if (openModal && closeModal) {
      openModal(Modal, {
        title: "Creating series (naming convention)",
        jsx: (
          <>
            <p>Creating of series need special attention!</p>
            <p>
              Using SFI 111 and serie start 200 - stop 300, will create a serie
              starting at 111.200 to 111.300
            </p>
            <p>
              If you are using e.g. WD as prefix, the serie would be like
              111.WD200 to 111.WD300
            </p>
            <p>
              You have to use 3 character and include leading zeros like 001.
            </p>
            <p>
              BE 100% SURE YOU ARE NOT CREATING DUPLICATES WITH SERIE CREATION!
            </p>
          </>
        ),
        cancelText: "close",
      });
    }
  };

  const get3dzone = (tagId?: number | null, areaId?: number | null): Zone3d | undefined => {
    const zone3dId = areaId != null
      ? areas.find(x => x.id === areaId)?.zone3DId
      : tagId != null
        ? tags.find(x => x.id === tagId)?.zone3dId
        : undefined;
    return zone3dId != null ? zones3d.find(x => x.id === zone3dId) : undefined;
  }

  const handleSeriesTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSeriesType((event.target as HTMLInputElement).value);
  };

  const handleFromTagChange = (fromTagId: number | null): void => {
    const tag = tags.find((x: Tag) => x.id === fromTagId);
    if (tag?.code !== "000") {
      setFromAreaLocked(true);
      setFromConnRespLocked(true);
      setFromZone3dLocked(true);
      setFromMvzLocked(true);
    } else {
      setFromAreaLocked(false);
      setFromConnRespLocked(false);
      setFromZone3dLocked(false);
      setFromMvzLocked(false);
      setFromZone3d(zones3d.find(z => z.code === "000")?.id);
    }
    setFromTag(fromTagId);
    if (tag?.areaId) {
      handleFromAreaChange(tag?.areaId || -1);
      const zone3d = get3dzone(tag?.id, tag?.areaId);
      if (zone3d) {
        setFromZone3d(zone3d.id);
      }
    }
    if (tag?.areaForemanId) {
      setFromConnResp(tag?.areaForemanId);
    }
  };

  const handleToTagChange = (toTagId: number | null): void => {
    const tag = tags.find((x: Tag) => x.id === toTagId);
    if (tag?.code !== "000") {
      setToAreaLocked(true);
      setToConnRespLocked(true);
      setToZone3dLocked(true);
      setToMvzLocked(true);
    } else {
      setToAreaLocked(false);
      setToConnRespLocked(false);
      setToZone3dLocked(false);
      setToMvzLocked(false);
      setToZone3d(zones3d.find(z => z.code === "000")?.id);
    }
    setToTag(toTagId);
    if (tag?.areaId) {
      handleToAreaChange(tag?.areaId || -1);
      const zone3d = get3dzone(tag?.id, tag?.areaId);
      if (zone3d) {
        setToZone3d(zone3d.id);
      }
    }
    if (tag?.areaForemanId) {
      setToConnResp(tag?.areaForemanId);
    }
  };

  const handleFromAreaChange = (fromAreaId: number | null): void => {
    if (fromAreaId && fromAreaId >= 0) {
      const area = areas.find((x: Area) => x.id === fromAreaId);
      setFromArea(fromAreaId);
      const zone3d = get3dzone(null, fromAreaId);
      if (zone3d) {
        setFromZone3d(zone3d.id);
      }
      setFromMvz(mvZoneOptions.find(x => x.value === area?.mainVerticalZoneId)?.value);
    }
  };

  const handleToAreaChange = (toAreaId: number | null): void => {
    const area = areas.find((x: Area) => x.id === toAreaId);
    setToArea(toAreaId);
    const zone3d = get3dzone(null, toAreaId);
    if (zone3d) {
      setToZone3d(zone3d.id);
    }
    setToMvz(mvZoneOptions.find(x => x.value === area?.mainVerticalZoneId)?.value);
  };

  const runPreFormSubmitValidation = (): boolean => {
    if (cableType === undefined) {
      setAlert({
        type: "error",
        text: "You need to select a Cable type for the serie.",
      });
      return false;
    }
    if (fromTag === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select From Tag, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (toTag === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select To Tag, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (fromArea === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select From Area, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (toArea === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select To Area, 000 - or another key for creating a serie.",
      });
      return false;
    }
    if (fromZone3d === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select From 3d Zone, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (toZone3d === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select To 3d Zone, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (fromMvz === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select From MV Zone, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (toMvz === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select To MV Zone, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (fromConnResp === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select Conn. Resp in the From-end, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (toConnResp === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select Conn. Resp in the To-end, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (bundle === undefined) {
      setAlert({
        type: "error",
        text:
          "You need to select a Bundle ID, 000 - or another key, for creating a serie.",
      });
      return false;
    }
    if (seriesType === "standard") {
      if (startVal.trim() === "") {
        setAlert({
          type: "error",
          text: "You need to specify a Start value.",
        });
        return false;
      }
      if (endVal.trim() === "") {
        setAlert({
          type: "error",
          text: "You need to specify a End value.",
        });
        return false;
      }
      if (parseInt(startVal, 10) > parseInt(endVal, 10)) {
        setAlert({
          type: "error",
          text: "The end value cannot be smaller then the start value.",
        });
        return false;
      }
    } else {
      if (itemId.trim() === "") {
        setAlert({
          type: "error",
          text: "You need to specify an item key.",
        });
        return false;
      }
      if (phaseId.trim() === "") {
        setAlert({
          type: "error",
          text: "You need to specify a phase key.",
        });
        return false;
      }
      if (numbOfCores <= 0) {
        setAlert({
          type: "error",
          text: "You need to specify a valid number of cores.",
        });
        return false;
      }
    }

    return true;
  };

  const createSeries = () => {
    if (runPreFormSubmitValidation()) {
      const newItems: PatchRequestNewItem<CableProps>[] = [];
      let newId = -1;
      if (seriesType === "standard") {
        // Remove leading 0s
        const startValParsed = parseInt(startVal, 10);
        const endValParsed = parseInt(endVal, 10);

        for (let i = startValParsed; i <= endValParsed; i++) {
          newItems.push({
            referenceId: newId,
            props: {
              code:
                system.code +
                "." +
                prefix +
                (i < 10 ? "00" + i : i < 100 ? "0" + i : i),
              cableNumber: prefix + (i < 10 ? "00" + i : i < 100 ? "0" + i : i),
              systemId: system.id,
              cableTypeId: cableType || undefined,
              cableClassId: cableClass || undefined,
              fromTagId: fromTag || undefined,
              toTagId: toTag || undefined,
              fromAreaId: fromArea || undefined,
              toAreaId: toArea || undefined,
              fromText: tags.find(x => x.id === fromTag)?.name,
              toText: tags.find(x => x.id === toTag)?.name,
              bundleId: bundle || undefined,
              dp3Zone: undefined,
              isConnectedFrom: false,
              isConnectedTo: false,
              fromMainVerticalZoneId: fromMvz || undefined,
              toMainVerticalZoneId: toMvz || undefined,
              fromZone3dId: fromZone3d || undefined,
              toZone3dId: toZone3d || undefined,
              hasSpecialRequirements: srtp,
              fromConnectionAreaForemanId: fromConnResp || undefined,
              toConnectionAreaForemanId: toConnResp || undefined,
              meters: 0,
              estimatedMeters: 0
            }
          });
          newId--;
        }
      } else {
        for (let i = 1; i <= numbOfCores; i++) {
          newItems.push({
            referenceId: newId,
            props: {
              code:
                system.code +
                "." +
                itemId +
                "." +
                phaseId +
                (i > 0 && i < 10 ? "0" + i : i),
              cableNumber:
                itemId + "." + phaseId + (i > 0 && i < 10 ? "0" + i : i),
              systemId: system.id,
              cableTypeId: cableType || undefined,
              cableClassId: cableClass || undefined,
              fromTagId: fromTag || undefined,
              toTagId: toTag || undefined,
              fromAreaId: fromArea || undefined,
              toAreaId: toArea || undefined,
              fromText: tags.find(x => x.id === fromTag)?.name,
              toText: tags.find(x => x.id === toTag)?.name,
              bundleId: bundle || undefined,
              dp3Zone: undefined,
              isConnectedFrom: false,
              isConnectedTo: false,
              fromMainVerticalZoneId: fromMvz || undefined,
              toMainVerticalZoneId: toMvz || undefined,
              fromZone3dId: fromZone3d || undefined,
              toZone3dId: toZone3d || undefined,
              hasSpecialRequirements: srtp,
              fromConnectionAreaForemanId: fromConnResp || undefined,
              toConnectionAreaForemanId: toConnResp || undefined,
              meters: 0,
              estimatedMeters: 0
            }
          });
          newId--;
        }
      }

      const body = {
        newItems
      }
      post<PatchRequest<CableProps>, PatchResponse<Cable>>(`project/${projectId}/cable/patch`, body)
        .then(() => {
          refreshList();
          if (closeModal) {
            closeModal();
          }
      })
        .catch((err: ProblemDetailError) => {
          setAlert({type: "error", text: "Something failed when creating the cable serie", error: err});
        });
    }
  };

  const inputStringNumbers = (numbers: string, callback: () => void) => {
    const reg = new RegExp("^\\d+$");
    if (numbers.trim() === "") {
      callback();
    } else {
      if (reg.test(numbers)) {
        callback();
      }
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        maxWidth={"md"}
        fullWidth={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          Creating Series of Cables
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0}}>
          <div>
            {/* Selected System */}
            <div style={{ display: "flex", padding: "0 1em" }}>
              <Typography variant="h6">{`Selected system: ${system.code} ${system.name}`}</Typography>
            </div>
            {/* Radio Selector */}
            <div
              style={{ padding: 0, paddingLeft: "1em" }}
              className={classes.largeFilterItem}
            >
              <div style={{ border: "1px solid white" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="cableSeries"
                    name="cableSeries"
                    value={seriesType}
                    onChange={handleSeriesTypeChange}
                  >
                    <FormControlLabel
                      value={"standard"}
                      control={<Radio color="primary" />}
                      label="Series with standard auto increasing Cable ID"
                    />
                    <FormControlLabel
                      value={"singleCore"}
                      control={<Radio color="primary"/>}
                      label="Series for single core cables with phase label and auto incr. Cable ID"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {/* 1st row */}
            <div style={{ paddingLeft: "1em" }} className={classes.xFlex}>
              {seriesType === "standard" ? (
                <>
                  <div
                    style={{ padding: 0, paddingRight: "1em" }}
                    className={classes.filterItem}
                  >
                    <VectTextField
                      claim={ProjectClaims.project.engineering.external}
                      value={prefix}
                      change={setPrefix}
                      label={"Enter prefix"}
                    />
                  </div>
                  <div
                    style={{ padding: 0, paddingRight: "1em" }}
                    className={classes.filterItem}
                  >
                    <VectTextField
                      claim={ProjectClaims.project.engineering.external}
                      value={startVal}
                      change={(e) =>
                        inputStringNumbers(e, () =>
                          setStartVal(e)
                        )
                      }
                      label={"Start Value"}
                    />
                  </div>
                  <div style={{ padding: 0 }} className={classes.filterItem}>
                    <VectTextField
                      claim={ProjectClaims.project.engineering.external}
                      value={endVal}
                      change={(e) =>
                        inputStringNumbers(e, () =>
                          setEndVal(e)
                        )
                      }
                      label={"End Value"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{ padding: 0, paddingRight: "1em" }}
                    className={classes.filterItem}
                  >
                    <VectTextField
                      claim={ProjectClaims.project.engineering.external}
                      value={itemId}
                      change={setItemId}
                      label={"Item ID"}
                    />
                  </div>
                  <div
                    style={{ padding: 0, paddingRight: "1em" }}
                    className={classes.filterItem}
                  >
                    <VectTextField
                      claim={ProjectClaims.project.engineering.external}
                      value={phaseId}
                      change={setPhaseId}
                      label={"Phase ID"}
                    />
                  </div>
                  <div
                    style={{ padding: 0, paddingRight: "1em" }}
                    className={classes.filterItem}
                  >
                    <VectTextField
                      claim={ProjectClaims.project.engineering.external}
                      value={numbOfCores}
                      type={"number"}
                      change={(e) => setNumbOfCores(parseInt(e))}
                      label={"No. of Cores"}
                    />
                  </div>
                </>
              )}
            </div>
            {/* Calculated row */}
            <div className={classes.xFlex}>
              <div
                style={{ paddingBottom: 0 }}
                className={classes.medFilterItem}
              >
                <TextField
                  value={calculatedSeriesStart}
                  disabled={true}
                  label={"Calculated series start"}
                />
              </div>
              <div
                style={{ paddingBottom: 0 }}
                className={classes.medFilterItem}
              >
                <TextField
                  value={calculatedSeriesStop}
                  disabled={true}
                  label={"Calculated series stop"}
                />
              </div>

              <div
                style={{ marginTop: 16, paddingBottom: 0 }}
                className={classes.filterItem}
              >
                <VectCheckbox
                  claim={ProjectClaims.project.engineering.external}
                  color="primary"
                  checked={srtp}
                  change={() => setSrtp(!srtp)}
                  label="Special Req/SRtP"
                />
              </div>
            </div>

            <div className={styles.selectionList}>
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"cableTypes"}
                placeholder={"Cable Type"}
                label={"Cable Type"}
                change={setCableType}
                value={cableType}
                options={cableTypeOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"cableClassIdPicker"}
                placeholder={"Cable Class"}
                label={"Cable Class"}
                change={setCableClass}
                value={cableClass}
                options={cableClassOptions}
                isClearable={true}
              />
              <Divider style={{marginTop: 10, marginBottom: 3}}/>
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"fromTagAutoComplete"}
                placeholder={"From Tag"}
                label={"From Tag"}
                value={fromTag}
                change={handleFromTagChange}
                options={tagOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"fromAreaIdPicker"}
                placeholder={"From AreaID"}
                label={"From AreaID"}
                disabled={fromAreaLocked}
                value={fromArea}
                change={handleFromAreaChange}
                options={areaOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"fromZone3dIdPicker"}
                placeholder={"From 3d Zone"}
                label={"From 3d Zone"}
                disabled={fromZone3dLocked}
                value={fromZone3d}
                change={setFromZone3d}
                options={zone3dOptions}
                isClearable={true}
              />
              <VectSelect
                claim={ProjectClaims.project.production.coordinator}
                id={"fromConnRespIdPicker"}
                placeholder={"From Conn Resp."}
                label={"From Conn Resp."}
                disabled={fromConnRespLocked}
                value={fromConnResp}
                change={setFromConnResp}
                options={foremanOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"fromMvzIdPicked"}
                placeholder={"From MV Zone"}
                label={"From MV Zone"}
                disabled={fromMvzLocked}
                value={fromMvz}
                change={setFromMvz}
                options={mvZoneOptions}
                isClearable={true}
              />
              <Divider style={{marginTop: 10, marginBottom: 3}}/>
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"toTagAutoComplete"}
                placeholder={"To Tag"}
                label={"To Tag"}
                value={toTag}
                change={handleToTagChange}
                options={tagOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"toAreaIdPicker"}
                placeholder={"To Area"}
                label={"To Area"}
                disabled={toAreaLocked}
                value={toArea}
                change={handleToAreaChange}
                options={areaOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"toZone3dIdPicker"}
                placeholder={"To 3d Zone"}
                label={"To 3d Zone"}
                disabled={toZone3dLocked}
                value={toZone3d}
                change={setToZone3d}
                options={zone3dOptions}
                isClearable={true}
              />
              <VectSelect
                claim={ProjectClaims.project.production.coordinator}
                id={"toConnRespIdPicker"}
                placeholder={"To Conn Resp."}
                label={"To Conn Resp."}
                disabled={toConnRespLocked}
                value={toConnResp}
                change={setToConnResp}
                options={foremanOptions}
              />
              <VectSelect
                claim={ProjectClaims.project.engineering.external}
                id={"toMvzIdPicked"}
                placeholder={"To MV Zone"}
                label={"To MV Zone"}
                disabled={toMvzLocked}
                value={toMvz}
                change={setToMvz}
                options={mvZoneOptions}
                isClearable={true}
              />
              <Divider style={{marginTop: 10, marginBottom: 3}}/>
              <VectSelect
                claim={ProjectClaims.project.routing.external}
                id={"bundleIdPicker"}
                placeholder={"Bundle ID"}
                label={"Bundle ID"}
                value={bundle}
                change={setBundle}
                options={bundleOptions}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={openInformationModal} color="default" variant="contained">
            INFORMATION
          </Button>

          <Button onClick={closeModal} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={() => createSeries()} color="primary" variant="contained" autoFocus>
            Create series
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
