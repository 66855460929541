import React, {FunctionComponent, useCallback, useContext, useEffect, useMemo, useReducer, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {FormControlLabel, MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow,} from "@material-ui/core";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import Pagination from "../../shared/table/Pagination";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {CableTypeListItem} from "../../../shared/interfaces/cableType.interface";
import {generalStyles, tableStyles, tableTheme,} from "../../shared/table/styles";
import {
  BatchUpdateCableProps,
  BatchUpdateCablesRequest,
  Cable,
  CableListItem,
  CableProps,
  CableWithReferenceLabels,
  handleSideEffects,
  refreshVisibleCables,
  TagTextOverride
} from "../../../shared/interfaces/cable.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import CableRowItem from "./components/CableRowItem";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {Bundle} from "../../../shared/interfaces/bundle.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import {CableClass} from "../../../shared/interfaces/cableClass.interface";
import {useModals} from "../../../shared/context/ModalContext";
import BatchUpdateCablesFooter from "./components/BatchUpdateCablesFooter";
import {BatchUpdateTagsModal} from "./modals/BatchUpdateTagsModal";
import {CreateSeriesModal} from "./modals/CreateSeriesModal";
import {AlertContext} from "../../../shared/context/AlertContext";
import {ProjectClaims} from "../../../shared/claims";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {Skeleton} from "@material-ui/lab";
import {VectButton} from "../../shared/navigation/VectButton";
import {compare} from 'fast-json-patch';
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {getYearWeekFormat} from "../../../shared/helpers/date";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {SetPenetrationsModal} from "./modals/SetPenetrationsModal";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {handleRowChange, operationFilter} from "../../../shared/helpers/table";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {useClaims} from "../../../shared/hooks/useClaims";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {useSession} from "../../../shared/hooks/useSession";
import {
  mapAreaForemanOptions,
  mapAreaOptions,
  mapCableTypeOptions,
  mapSystemOptions
} from "../../../shared/helpers/metadata";
import {CloudUploadSharp, GridOn} from "@material-ui/icons";
import {getValidFileName} from "../../../shared/validators";
import {CableImportModal} from "./modals/CableImportModal";

const Cables: FunctionComponent = () => {
  // Pagination
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  // Data
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [bundles, setBundles] = useState<Bundle[] | null>(null);
  const [cableClasses, setCableClasses] = useState<CableClass[] | null>(null);
  const [cableTypes, setCableTypes] = useState<CableTypeListItem[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  const [initialValues, setInitialValues] = useState<CableWithReferenceLabels[] | null>(null)
  const [mvZones, setMvZones] = useState<MainVerticalZone[] | null>(null);
  const [systemUpdate, setSystemUpdate] = useState<SystemListItem | null>(null);
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [zones3d, setZones3d] = useState<Zone3d[] | null>(null);
  // Filtering
  const [selectedSystem, setSelectedSystem] = useState<SystemListItem | null>(null);
  // Management
  const [batchUpdateCables, setBatchUpdateCables] = useState<BatchUpdateCableProps>({});
  const [didChange, setDidChange] = useState(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [prevNewId, setPrevNewId] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);
  const [reloadList, setReloadList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllMarked, setAllMarked] = useState<boolean>(false);

  const classes = tableStyles();
  const general = generalStyles();

  const {get, post, put, fileDownload} = useApi();
  const {hasClaim} = useClaims();
  const {project} = useContext(ProjectContext);
  const {setAlert, setSuccess} = useContext(AlertContext);
  const {openModal, closeModal} = useModals();
  const {systemId, setSystemId} = useSession();

  useEffect(() => {
    let isSubscribed = true;
    if (initialLoad) {
      get<SystemListItem[]>(`/project/${project?.id}/system`)
        .then(res => {
          if (isSubscribed) {
            setSystems(res);
            const activeSystems = res?.filter(x => x.isActive);
            const defaultSystem = activeSystems?.find(x => x.id === systemId);
            if (defaultSystem) {
              setSelectedSystem(defaultSystem);
            } else if (activeSystems.length > 0) {
              setSelectedSystem(activeSystems[0]);
            }
          }
        });
      get<Tag[]>(`/project/${project?.id}/tag`)
        .then(res => {
          if (isSubscribed) {
            setTags(res);
          }
        });
      get<Area[]>(`/project/${project?.id}/area`)
        .then(res => {
          if (isSubscribed) {
            setAreas(res);
          }
        });
      get<MainVerticalZone[]>(`/project/${project?.id}/mainVerticalZones`)
        .then(res => {
          if (isSubscribed) {
            setMvZones(res);
          }
        });
      get<Bundle[]>(`/project/${project?.id}/bundle`)
        .then(res => {
          if (isSubscribed) {
            setBundles(res);
          }
        });
      get<Zone3d[]>(`/project/${project?.id}/zone3d`)
        .then(res => {
          if (isSubscribed) {
            setZones3d(res);
          }
        });
      get<CableClass[]>(`/cableClass`)
        .then(res => {
          if (isSubscribed) {
            setCableClasses(res);
          }
        });
      get<CableTypeListItem[]>(`/project/${project?.id}/cableTypes/select-options`)
        .then(res => {
          if (isSubscribed) {
            setCableTypes(res);
          }
        });
      get<Areaforeman[]>(`/project/${project?.id}/areaForeman`)
        .then(res => {
          if (isSubscribed) {
            setForemen(res);
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    setSystemUpdate(selectedSystem)
    setSystemId(selectedSystem != null ? selectedSystem.id : null);
  }, [selectedSystem]);

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (selectedSystem) {
        const paginationQuery: PaginationRequest = {
          page: state.currentPage,
          pageSize: state.pageSize,
        };
        const requestUri = `/project/${project?.id}/cable/list?any=any&systemId=${selectedSystem.id}`;
        setIsLoading(true);
        try {
          const result = await post<PaginationRequest, any>(requestUri, paginationQuery)
            .then((res) => {
              setInitialValues(res.values);
              return res
            });

          if (isSubscribed && result !== undefined) {
            dispatch({
              type: "SET_STATE",
              payload: () => ({
                ...result,
                values: result.values.map((x: CableWithReferenceLabels) => ({
                  ...x,
                  isMarked: false
                }))
              })
            });
            if (!initialLoad) {
              setReload(!reload);
            }
            setInitialLoad(false);
          }
        } finally {
          setIsLoading(false);
        }
      }
      setDidChange(false);
    })();

    return () => {
      isSubscribed = false;
    };
  }, [
    selectedSystem,
    reloadList,
    state?.pageSize,
    state?.currentPage,
  ]);

  const areaOptions = useMemo((): Option[] | null => mapAreaOptions(areas, {withShortLabel: true}), [areas]);

  const cableClassOptions = useMemo((): Option[] | null =>
    cableClasses?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [cableClasses]);


  const cableTypeOptions = useMemo((): Option[] | null => mapCableTypeOptions(cableTypes), [cableTypes]);

  const foremanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(foremen, {withShortLabel: true}), [foremen]);

  const mvZoneOptions = useMemo((): Option[] | null =>
    mvZones?.map(x => ({
      value: x.id,
      label: x.code
    })) || null, [mvZones]);

  const zone3dOptions = useMemo((): Option[] | null =>
    zones3d?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [zones3d]);

  const areDependenciesLoaded =
    areas != null &&
    bundles != null &&
    cableClasses != null &&
    cableTypes != null &&
    foremen != null &&
    mvZones != null &&
    systems != null &&
    tags != null &&
    zones3d != null;

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  const change = useCallback((cable: CableWithReferenceLabels) => {
    const sideEffects = (
      prevCable: CableWithReferenceLabels,
      currentCable: CableWithReferenceLabels,
    ): void => {
        handleSideEffects(prevCable, currentCable, selectedSystem!, areas!, tags!);
    }

    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        return handleRowChange(
          prevValues,
          cable,
          setDidChange,
          sideEffects);
      }
    });
  }, [selectedSystem, tags, areas]);

  const copy = useCallback((source: CableWithReferenceLabels) => {
    const newId = prevNewId - 1;
    const activeCableTypes = cableTypes?.filter(x => x.isActive);
    setPrevNewId(newId);

    const terminalErrors: string[] = [];
    const defaultValuesInformation: string[] = [];
    const fromTag = tags?.find(x => x.id === source.fromTagId && x.isActive);
    const toTag = tags?.find(x => x.id === source.toTagId && x.isActive);
    const bundle = hasClaim(ProjectClaims.project.routing.external)
      ? bundles?.find(x => x.id === source.bundleId)
      : bundles?.find(x => x.code === "000");
    const cableType = hasClaim(ProjectClaims.project.engineering.external)
      ? activeCableTypes?.find(x => x.id === source.cableTypeId)
      : (activeCableTypes && activeCableTypes.length > 0 && activeCableTypes[0] || undefined);

    if(!fromTag){
      terminalErrors.push("The cable you are copying has no from tag set or the from tag is not marked as active.");
    }
    
    if(!toTag){
      terminalErrors.push("The cable you are copying has no to tag set or the to tag is not marked as active.");
    }
    
    if(!bundle){
      terminalErrors.push(
        hasClaim(ProjectClaims.project.routing.external)
          ? "The cable you are copying has no bundle set."
          : "A default bundle  000 does not exist.");
    }

    if(!cableType){
      terminalErrors.push(
        hasClaim(ProjectClaims.project.engineering.external)
          ? "The cable you are copying has no cable type set or the cable type is marked as inactive."
          : "A default cable type does not exist."
      );
    }
    
    if(terminalErrors.length > 0){
      setAlert({
        type: "error",
        text: "I could not make a copy of this cable:",
        details: terminalErrors});
      return;
    }

    const cableNumber = undefined;
    const code = hasClaim(ProjectClaims.project.engineering.external) ? source.code : "";
    const connectedFromWeek = hasClaim(ProjectClaims.project.production.external) ? source.connectedFromWeek : undefined;
    const connectedToWeek = hasClaim(ProjectClaims.project.production.external) ? source.connectedToWeek : undefined;
    const estimatedMeters = hasClaim(ProjectClaims.project.engineering.external) ? source.estimatedMeters : undefined;
    const fromAreaId = hasClaim(ProjectClaims.project.engineering.external) ? source.fromAreaId : fromTag!.areaId;
    const fromConnectionAreaForemanId = hasClaim(ProjectClaims.project.production.coordinator) ? source.fromConnectionAreaForemanId : fromTag!.areaForemanId;
    const fromMainVerticalZoneId = hasClaim(ProjectClaims.project.engineering.external) ? source.fromMainVerticalZoneId : fromTag!.mainVerticalZoneId;
    const fromZone3dId = hasClaim(ProjectClaims.project.engineering.external) ? source.fromZone3dId : fromTag!.zone3dId;
    const isConnectedFrom = hasClaim(ProjectClaims.project.production.external) ? source.isConnectedFrom : false;
    const isConnectedTo = hasClaim(ProjectClaims.project.production.external) ? source.isConnectedTo : false;
    const markPlacement = hasClaim(ProjectClaims.project.routing.external) ? source.markPlacement : undefined;
    const meters = hasClaim(ProjectClaims.project.routing.external) ? source.meters : undefined;
    const penetrations = hasClaim(ProjectClaims.project.routing.external) ? source.penetrations : undefined;
    const toAreaId = hasClaim(ProjectClaims.project.engineering.external) ? source.toAreaId : toTag!.areaId;
    const toConnectionAreaForemanId = hasClaim(ProjectClaims.project.production.coordinator) ? source.toConnectionAreaForemanId : toTag!.areaForemanId;
    const toMainVerticalZoneId = hasClaim(ProjectClaims.project.engineering.external) ? source.toMainVerticalZoneId : toTag!.mainVerticalZoneId;
    const toZone3dId = hasClaim(ProjectClaims.project.engineering.external) ? source.toZone3dId : toTag!.zone3dId;

    if(source.bundleId !== bundle!.id) {
      defaultValuesInformation.push(`Bundle is reset to default ${bundle!.code}.`);
    }

    if(cableType != null && source.cableTypeId !== cableType.id)
    {
      defaultValuesInformation.push(`Cable type is reset to default ${cableType!.code}.`);
    }

    if(source.connectedFromWeek !== connectedFromWeek)
    {
      defaultValuesInformation.push("Connected from week is reset.");
    }

    if(source.connectedToWeek !== connectedToWeek)
    {
      defaultValuesInformation.push("Connected to week is reset.");
    }

    if(source.estimatedMeters !== estimatedMeters)
    {
      defaultValuesInformation.push("Estimated meters is reset.");
    }

    if(source.fromTagId !== fromTag!.id || source.toTagId !== toTag!.id){
      defaultValuesInformation.push(`Tag, area, main vertical zone, and 3d zone is reset.`);
    }

    if(source.toConnectionAreaForemanId !== toConnectionAreaForemanId || source.fromConnectionAreaForemanId !== fromConnectionAreaForemanId) {
      defaultValuesInformation.push("Connection responsible is reset.");
    }

    if(source.isConnectedTo !== isConnectedTo || source.isConnectedFrom !== isConnectedFrom)
    {
      defaultValuesInformation.push("Connection is reset.");
    }

    if(source.markPlacement !== markPlacement)
    {
      defaultValuesInformation.push("Mark placement is reset.");
    }

    if(source.meters !== meters)
    {
      defaultValuesInformation.push("Meters is reset.");
    }

    if(source.penetrations !== penetrations)
    {
      defaultValuesInformation.push("Penetrations is reset.");
    }

    if(source.fromTagId !== fromTag!.id) {
      defaultValuesInformation.push("From tag is reset.");
    }

    if(source.toTagId !== toTag!.id) {
      defaultValuesInformation.push("To tag is reset.")
    }


    if(defaultValuesInformation.length > 0) {
      setAlert({
        type: "warning",
        text: "Additional permissions are required to set some fields and they are set to default:",
        details: defaultValuesInformation});
    }
    
    const target : CableWithReferenceLabels = {
      id: newId,
      code,
      state: "new",
      hasError: true,
      isMarked: false,
      bundleId: bundle!.id,
      cableClassCode: source.cableClassCode,
      cableDate: new Date(),
      cableNumber,
      cableTrayExtraMeters: source.cableTrayExtraMeters,
      cableTrayMeters: source.cableTrayMeters,
      cableTypeId: cableType!.id,
      connectedFromWeek,
      connectedToWeek,
      cutWeek: source.cutWeek,
      dp3Zone: source.dp3Zone,
      estimatedMeters: estimatedMeters || 0,
      fromAreaId,
      fromConnectionAreaForemanId,
      fromMainVerticalZoneId,
      fromTagId: fromTag!.id,
      fromText: source.fromText,
      fromZone3dId,
      hasSpecialRequirements: source.hasSpecialRequirements,
      isConnectedFrom,
      isConnectedTo,
      markPlacement,
      meters: meters || 0,
      penetrations,
      projectId: project!.id,
      registrationWeek: getYearWeekFormat(),
      systemId: source.systemId,
      toAreaId: toAreaId,
      toConnectionAreaForemanId,
      toMainVerticalZoneId,
      toTagId: toTag!.id,
      toText: source.toText,
      toZone3dId
    };

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setDidChange(true);

  }, [prevNewId, tags, bundles, cableTypes]);

  const exportTemplate = useCallback(async () => {
    if(project == null || selectedSystem == null) {
      return;
    }
    const fileName = getValidFileName(`Cable import template for ${selectedSystem.code} ${selectedSystem.name} on ${project.code} ${project.name}.xlsx`);
    await fileDownload(fileName, `project/${project.id}/system/${selectedSystem.id}/cables/import-template`);

  }, [project, selectedSystem])

  const getCableType = useCallback((cable: CableWithReferenceLabels): CableTypeListItem | null => {
    if (cableTypes == null || cable.cableTypeId == null) {
      return null;
    }

    const cableType = cableTypes.find(x => x.id === cable.cableTypeId);
    return cableType || null;
  }, [cableTypes])

  const openCableImport = useCallback(async () => {
    if(project == null || selectedSystem == null) {
      return;
    }

    openModal && openModal(CableImportModal, {
      project: project,
      system: selectedSystem,
      onClosed: refreshList
    })

  }, [project, selectedSystem, openModal, reloadList])

  const openPenetrationsModal = useCallback((cable: CableWithReferenceLabels) => {
    if (openModal) {

      const onPenetrationsChange = (value: string) => {
        change({
          ...cable,
          penetrations: value ? value : undefined,
          state: cable.state === undefined ? "modified" : cable.state
        });
      }

      openModal(SetPenetrationsModal, {
        cable: cable,
        change: onPenetrationsChange,
      });
    }
  }, []);

  const changeBatch = useCallback((value: number | boolean | string | undefined | null, field?: string) => {
    if (field != null) {
      setBatchUpdateCables((prevState) => {
        return {
          ...prevState,
          [field]: value,
        }
      });
    }
  }, []);

  const selectAllVisibleCables = useCallback(() => {
    setAllMarked(true);
    dispatch({
      type: "SET_VALUES",
      payload: prevValues => {
        if(prevValues == null){
          return prevValues;
        }

        return prevValues.map(x => ({
          ...x,
          isMarked: true
        }));
      }
    });
  }, []);

  const clearAllVisibleCables = useCallback(() => {
    setAllMarked(false);
    dispatch({
      type: "SET_VALUES",
      payload: prevValues => {
        if(prevValues == null){
          return prevValues;
        }

        return prevValues.map(x => ({
          ...x,
          isMarked: false
        }));
      }
    });
  }, []);

  const systemFilter = useMemo(() => {

    const options = mapSystemOptions(systems);
    return (
      <VectSelect
        id={"systemIdFilter"}
        label="System ID"
        placeholder={"System ID"}
        value={selectedSystem?.id}
        change={value => {
          const action = () => {
            const system = systems?.find(x => x.id === value);
            if (system) {
              setSelectedSystem(system);
            }
          };
          promptIfUnsavedChanges(openModal, didChange, action);
        }}
        options={options}
      />
    );
  }, [systems, selectedSystem, didChange]);

  const addNewItem = () => {
    if (!areDependenciesLoaded || !selectedSystem) {
      return;
    }

    const activeAreas = areas?.filter(x => x.isActive);
    const activeAreaForemen = foremen?.filter(x => x.isActive);
    const activeCableTypes = cableTypes?.filter(x => x.isActive);
    const activeTags = tags?.filter(x => x.isActive);


    const newId = prevNewId - 1;
    const tag = activeTags && activeTags.length > 0 && activeTags[0] || undefined;
    const tagId = tag?.id || undefined;
    const text = tag?.name || undefined;
    const areaId = tag != null ? tag.areaId : (activeAreas && activeAreas.length > 0 && activeAreas[0] || undefined)?.id;
    const foreman = activeAreaForemen && activeAreaForemen.length > 0 && activeAreaForemen[0] || undefined;
    const foremanId = foreman != null ? foreman.id : (activeAreaForemen && activeAreaForemen.length > 0 && activeAreaForemen[0] || undefined)?.id;
    const zone3dId = (zones3d && zones3d.find(x => x.code === "000")?.id) || undefined;
    const bundleId = (bundles && bundles.find(x => x.code === "000")?.id) || undefined;
    const cableTypeId = (activeCableTypes && activeCableTypes.length > 0 && activeCableTypes[0].id) || undefined;
    const newItem: CableWithReferenceLabels = {
      id: newId,
      projectId: project ? project.id : 0,
      code: `${selectedSystem.code}.`,
      cableTypeId,
      cableDate: new Date(),
      systemId: selectedSystem.id,
      fromTagId: tagId,
      toTagId: tagId,
      fromText: text,
      toText: text,
      fromAreaId: areaId,
      toAreaId: areaId,
      fromConnectionAreaForemanId: foremanId,
      toConnectionAreaForemanId: foremanId,
      fromZone3dId: zone3dId,
      toZone3dId: zone3dId,
      meters: 0,
      estimatedMeters: 0,
      bundleId,
      isMarked: false,
      isConnectedFrom: false,
      isConnectedTo: false,
      hasSpecialRequirements: false,
      registrationWeek: getYearWeekFormat(),
      state: "new",
      hasError: true // since cable number is not set the default is true
    };
    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
    setDidChange(true);
  };

  const getPdf = async () => {
    if (selectedSystem) {
      await fileDownload("Cable Report on Selected SYSTEM.pdf", `/cable/${project?.id}/pdf/${selectedSystem.id}`);
    }
  }

  const onChangeFinished = (isChecked: boolean) => {
    setDidChange(true);
    if (systemUpdate) {
      setSystemUpdate((prevState => {
        if (prevState == null) {
          return null;
        }

        return {
          ...prevState,
          isFinished: isChecked
        };
      }));
    }
  }

  const onChangeShortComment = (comment: string) => {
    setDidChange(true);
    if (systemUpdate) {
      setSystemUpdate(prevState => {
        if (prevState == null) {
          return null;
        }

        return {
          ...prevState,
          drawingPlacement: comment
        };
      });
    }
  }

  const openCreateSeriesModal = () => {
    if (
      project &&
      openModal &&
      closeModal &&
      areDependenciesLoaded &&
      selectedSystem
    ) {

      const action = () => openModal(CreateSeriesModal, {
        projectId: project.id,
        system: selectedSystem,
        tags: tags!,
        areas: areas!,
        mvZones: mvZones!,
        bundles: bundles!,
        zones3d: zones3d!,
        cableTypes: cableTypes!,
        cableClasses: cableClasses!,
        foremen: foremen!,
        refreshList: refreshList,
      });
      promptIfUnsavedChanges(openModal, didChange, action);
    }
  };

  const refreshList = () => {
    setReloadList(!reloadList);
  };

  const update = async () => {
    setIsLoading(true);
    try{
      const updates = state.values.filter(x => x.state === "modified");
      const patchedItems = updates.map((u: CableWithReferenceLabels) => {
        const initialItem = initialValues && initialValues.find(x => x.id === u.id);
        //Remove the web-only properties before comparing
        const operations = compare(initialItem!, u).filter(op => !operationFilter.includes(op.path));

        return {referenceId: u.id, operations};
      });
      const newItems = state.values
        .filter(x => x.state === "new")
        .map<PatchRequestNewItem<CableProps>>((n: CableWithReferenceLabels) => ({
          referenceId: n.id,
          props: {
            bundleId: n.bundleId,
            systemId: n.systemId!,
            cableDate: n.cableDate,
            cableNumber: n.cableNumber,
            cableTrayExtraMeters: n.cableTrayExtraMeters,
            cableTrayMeters: n.cableTrayMeters,
            cableTypeId: n.cableTypeId,
            code: n.code,
            connectedFromWeek: n.connectedFromWeek,
            connectedToWeek: n.connectedToWeek,
            cutWeek: n.cutWeek,
            dp3Zone: n.dp3Zone,
            estimatedMeters: n.estimatedMeters,
            fromAreaId: n.fromAreaId,
            fromConnectionAreaForemanId: n.fromConnectionAreaForemanId,
            fromMainVerticalZoneId: n.fromMainVerticalZoneId,
            fromTagId: n.fromTagId,
            fromText: n.fromText,
            fromZone3dId: n.fromZone3dId,
            hasSpecialRequirements: n.hasSpecialRequirements,
            isConnectedFrom: n.isConnectedFrom,
            isConnectedTo: n.isConnectedTo,
            localId: n.localId,
            markPlacement: n.markPlacement,
            meterRegistrationInWeek: n.meterRegistrationInWeek,
            meters: n.meters,
            penetrations: n.penetrations,
            pulledWeek: n.pulledWeek,
            registrationWeek: n.registrationWeek,
            releaseForMeasuringWeek: n.releaseForMeasuringWeek,
            releaseForProductionWeek: n.releaseForProductionWeek,
            toAreaId: n.toAreaId,
            toConnectionAreaForemanId: n.toConnectionAreaForemanId,
            toMainVerticalZoneId: n.toMainVerticalZoneId,
            toTagId: n.toTagId,
            toText: n.toText,
            toZone3dId: n.toZone3dId
          }
        }));
      const deletedItems = state.values
        .filter(x => x.state === "deleted")
        .map((x: CableWithReferenceLabels) => x.id);

      const body = {
        newItems,
        patchedItems,
        deletedItems
      };

      try {
        const updateCableResult = await post<PatchRequest<CableProps>, PatchResponse<Cable>>(`project/${project!.id}/cable/patch`, body);
        refreshVisibleCables(dispatch, updateCableResult, setInitialValues);
        setSuccess();
        setDidChange(false);

        if (selectedSystem &&
          systemUpdate &&
          (selectedSystem.drawingPlacement !== systemUpdate.drawingPlacement ||
            selectedSystem.isFinished !== systemUpdate.isFinished)) {
          try {
            await put(
              `/system/${selectedSystem.id}/updateProps`,
              {
                finished: systemUpdate.isFinished,
                shortText: systemUpdate.drawingPlacement
              });

            setSystems(prevState => {
              if (prevState == null) {
                return null;
              }
              const itemIndex = prevState.findIndex(x => x.id === selectedSystem.id);
              if (itemIndex !== -1) {
                const newState = [...prevState];
                newState.splice(itemIndex, 1, {
                  ...prevState[itemIndex],
                  isFinished: systemUpdate.isFinished,
                  drawingPlacement: systemUpdate.drawingPlacement
                });
                return newState;
              } else {
                return prevState;
              }

            });
          } catch (e: unknown) {
            if (e instanceof ProblemDetailError || e instanceof Error) {
              setAlert({type: "error", text: "I could not update the system properties", error: e});
            } else {
              setAlert({type: "error", text: "I could not update the system properties"});
            }
          }
        }

      } catch (error: unknown) {
        if(error instanceof ProblemDetailError || error instanceof Error) {
          setAlert({type: "error", text: "Something failed when updating the cable list", error});
        } else {
          setAlert({type: "error", text: "Something failed horribly when updating the cable list"});
        }
      }
    } finally {
      setIsLoading(false);
      setDidChange(false);
    }
  }

  /**
   * @param cables      Cables included in the update
   * @param refreshList Id of the cables that should be updated after a successful update.
   */
  const updateBatch = async (cables: CableListItem[], refreshList: number[]) => {
    if (!areDependenciesLoaded || !project) {
      return;
    }

    const updateHandler = async (
      tagTextOverrides: TagTextOverride[] | undefined = undefined,
      fromText: string | undefined = undefined,
      toText: string | undefined = undefined): Promise<void> => {

      setIsLoading(true);
      try {
        const request: BatchUpdateCablesRequest = {
          ...batchUpdateCables,
          cableIds: cables.map(x => x.id),
          returnCableIds: refreshList,
          fromText,
          toText,
          tagTextOverrides,
          estimatedMeters: batchUpdateCables.estimatedMeters || undefined,
          meters: batchUpdateCables.meters || undefined,
          markPlacement: batchUpdateCables.markPlacement || undefined,
          penetrations: batchUpdateCables.penetrations || undefined
        }
        await post<BatchUpdateCablesRequest, PatchResponse<Cable>>(`/project/${project!.id}/cable/batch-update`, request)
          .then(res => {
            refreshVisibleCables(dispatch, res, setInitialValues);
            setBatchUpdateCables({});
            setSuccess();
          })
          .catch((err: ProblemDetailError) => {
            setAlert({type: "error", text: "Something failed when updating the cable list", error: err});
          });
      } finally {
        setIsLoading(false);
        setDidChange(false);
      }
    }


    if (openModal && (batchUpdateCables.fromTagId || batchUpdateCables.toTagId)) {
      openModal(BatchUpdateTagsModal, {
        cables: cables,
        tags: tags!,
        fromTagId: batchUpdateCables.fromTagId,
        toTagId: batchUpdateCables.toTagId,
        update: updateHandler
      })
    } else {
      await updateHandler();
    }
  }

  const updateSelectedBatch = () => {
    if (!selectedSystem) {
      return;
    }
    const markedCableIds = state.values
      .filter((x: CableWithReferenceLabels) => x.isMarked)
      .map((x: CableWithReferenceLabels) => x.id);

    post<number[], any>(`/project/${project?.id}/system/${selectedSystem.id}/cables/selection`, markedCableIds)
      .then(res => updateBatch(res, markedCableIds))

      .catch((err: ProblemDetailError) => {
        setAlert({type: "error", text: "I failed to read the selected cables in this system", error: err});
      });
  };

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={didChange}/>
      <PanelHeader
        text={"Registrating Cables"}
        add={{
          action: addNewItem,
          claim: ProjectClaims.project.engineering.external,
          disabled: !areDependenciesLoaded
        }}
        save={{
          action: update,
          disabled: !areDependenciesLoaded || hasErrors || !didChange
        }}
        secondaryActions={[
          {
            icon: <GridOn/>,
            action: exportTemplate,
            label: "Download import template",
            color: "default"
          },
          {
            icon: <CloudUploadSharp />,
            action: openCableImport,
            label: "Import cables",
            color: "default",
            claim: ProjectClaims.project.engineering.external,
          }
        ]}
        getPdf={{action: getPdf}}
      />

      {systems ? (
        <div className={general.largeFilterWrapper}>
          <div className={general.largeFilterItem}>
            {systemFilter}
          </div>

          <div style={{display: "flex"}} className={general.medFilterItem}>
            <VectTextField
              claims={[
                ProjectClaims.project.engineering.external,
                ProjectClaims.project.system.write.all
              ]}
              style={{alignSelf: "center", width: "100%"}}
              value={systemUpdate?.drawingPlacement}
              change={onChangeShortComment}
              label={"Short comment"}
            />
          </div>
          <div className={general.checkboxFilterItem}>
            <FormControlLabel
              label="Finished"
              labelPlacement="start"
              control={
                <VectCheckbox
                  claims={[
                    ProjectClaims.project.engineering.external,
                    ProjectClaims.project.system.write.all
                  ]}
                  color="primary"
                  checked={systemUpdate?.isFinished || false}
                  change={onChangeFinished}
                />
              }
            />
          </div>
          <div className={general.vCenteredfilterBtn}>
            <VectButton claim={ProjectClaims.project.engineering.external}
                        color="primary"
                        variant="contained"
                        onClick={openCreateSeriesModal}>
              Create Series
            </VectButton>
          </div>
        </div>
      ) : <Skeleton variant={"rect"} width={"40rem"} height={"5rem"}/>}

      {/* Tag Table Headers */}
      <Table>
        <TableHead>
          <TableRow className={classes.tableRowWrapper}>
            <div
              style={{width: "14rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <div className={general.xFlex}>
                <div className={general.yFlex}>
                  <TableCell>Cable nr:</TableCell>
                  <TableCell>Cable Type:</TableCell>
                  <TableCell>Cable ID:</TableCell>
                </div>
                <div>
                  <TableCell>Signal Type:</TableCell>
                </div>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>From Tag:</TableCell>
              <TableCell>From Area:</TableCell>
              <TableCell>From MVZone:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>From Text:</TableCell>
              </div>
              <div>
                <TableCell>From:</TableCell>
                <TableCell>Conn Resp:</TableCell>
                <TableCell>From 3D Zone:</TableCell>
                <TableCell>Class Type:</TableCell>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>To Tag:</TableCell>
              <TableCell>To Area:</TableCell>
              <TableCell>To MVZone:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>To Text:</TableCell>
              </div>
              <div>
                <TableCell>To:</TableCell>
                <TableCell>Conn Resp:</TableCell>
                <TableCell>To 3D Zone:</TableCell>
                <TableCell>SRtP or Special Req.:</TableCell>
              </div>
            </div>
            <div
              style={{width: "10rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Bundle ID:</TableCell>
              <TableCell>Calculated Meter:</TableCell>
              <TableCell>Meter:</TableCell>
            </div>
            <div
              style={{width: "11rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Bundle Text:</TableCell>
              <TableCell>Mark Placement Info:</TableCell>
              <TableCell>Penetrations to use:</TableCell>
            </div>
            <div
              style={{width: "6rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Cut:</TableCell>
              <TableCell>Fixed:</TableCell>
              <TableCell>DP Zone:</TableCell>
            </div>
            <div
              style={{width: "4rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Marked:</TableCell>
            </div>
            <div
              style={{textAlign: "center"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </div>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {areDependenciesLoaded
                ?
                state.values.map((x: CableWithReferenceLabels) => {
                  return (
                    <CableRowItem
                      key={x.id}
                      value={x}
                      change={change}
                      copy={copy}
                      tags={tags}
                      areaOptions={areaOptions}
                      mvZoneOptions={mvZoneOptions}
                      bundles={bundles}
                      zone3dOptions={zone3dOptions}
                      cableClassOptions={cableClassOptions}
                      cableType={getCableType}
                      cableTypeOptions={cableTypeOptions}
                      areaForemanOptions={foremanOptions}
                      openPenetrationsModal={openPenetrationsModal}
                    />
                  );
                })
                : null}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch} isDirty={didChange}/>
      <BatchUpdateCablesFooter areDependenciesLoaded={areDependenciesLoaded}
                               isAllMarked={isAllMarked}
                               hasErrors={hasErrors || didChange}
                               batchUpdateCables={batchUpdateCables}
                               tags={tags}
                               areas={areas}
                               bundles={bundles}
                               cableClasses={cableClasses}
                               cableTypes={cableTypes}
                               areaForemen={foremen}
                               change={changeBatch}
                               selectAllVisibleCables={selectAllVisibleCables}
                               clearAllVisibleCables={clearAllVisibleCables}
                               updateSelectedBatch={updateSelectedBatch}/>
    </>
  );
};


export default Cables;
