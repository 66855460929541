import React from "react";
import {ApplicationLink} from "../layout/VectAppBar";
import {NavLink} from "react-router-dom";
import {useClaims} from "../../../shared/hooks/useClaims";

interface VectNavLinkProps extends ApplicationLink {
  activeClassName?: string;
  className?: string;
}

export const VectNavLink: React.FC<VectNavLinkProps> = ({
  children,
  claim,
  claims,
  useExactMatch,
  route,
  exact,
  activeClassName,
  className
}): JSX.Element => {
  const{isValid} = useClaims();
  const props = {
    key: route,
    to: route,
    exact,
    activeClassName,
    className
  }

  return isValid({claim, claims, useExactMatch})
    ? <NavLink {...props}>{children}</NavLink>
    : <></>;

}
