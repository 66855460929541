import React from "react";
import {useTable} from "react-table";
import './table.module.css';
import {MuiThemeProvider, Table, TableBody, TableFooter, TableHead, TableRow,} from "@material-ui/core";
import EditableCell from "./EditableCell";
import {StyledTableRow, TableCellHeader, TableCellInput, tableCellStyles, tableTheme} from "./styles";
import {TableProps} from "./tableProps";

const defaultColumn = {
  Cell: EditableCell,
}

const RegularTable= (
  {
    change,
    columns,
    data,
    hiddenColumns,
    footer
  }: TableProps): JSX.Element  => {
  const classes = tableCellStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
      columns,
      data,
      defaultColumn,
      change,
      initialState: {
        hiddenColumns: hiddenColumns || []
      }
    }
  )
  // Render the UI for your table

  return (
    <>
      <Table {...getTableProps()}>
        <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>

            {headerGroup.headers.map(column => (
              <TableCellHeader style={{width: column.width}} {
                    ...column.getHeaderProps()}>{column.render('Header')} </TableCellHeader>
            ))}
          </TableRow>
        ))}
        </TableHead>
        <MuiThemeProvider theme={tableTheme} >
        <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <StyledTableRow  {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <TableCellInput className={classes.root} {...cell.getCellProps()}>{cell.render('Cell')}</TableCellInput>
              })}
              <td>
              </td>
            </StyledTableRow>
          )
        })}
        </TableBody>
        {footer && (<TableFooter>{footer}</TableFooter>)}
        </MuiThemeProvider>
      </Table>
    </>
  )
}
export default RegularTable
