import React, {FunctionComponent, useCallback, useContext, useEffect, useReducer, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {MuiThemeProvider, Table, TableBody, TableHead, TableRow,} from "@material-ui/core";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import Pagination from "../../shared/table/Pagination";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Orientation, Penetration, PenetrationProps} from "../../../shared/interfaces/penetration.interface";
import PenetrationRowItem from "./PenetrationRowItem";
import {initialPaginated, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {TableCellHeader, tableTheme} from "../../shared/table/styles";
import {handleRowChange} from "../../../shared/helpers/table";
import {PatchRequest, PatchRequestNewItem, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {compare} from "fast-json-patch";
import {AlertContext} from "../../../shared/context/AlertContext";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {useModals} from "../../../shared/context/ModalContext";
import {Skeleton} from "@material-ui/lab";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {useStyles} from "../tag/Tags.styles";
import {getValidFileName} from "../../../shared/validators";
import {CloudUploadSharp, GridOn} from "@material-ui/icons";
import {ProjectClaims} from "../../../shared/claims";
import {PenetrationImportModal} from "./PenetrationImportModal";

const Penetrations: FunctionComponent = () => {
  /* State */
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<Penetration[] | null>(null);
  // Management
  const [penetrationId, setPenetrationId] = useState<string | undefined>(undefined);
  const [prevNewId, setPrevNewId] = useState<number>(0);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  /* State End */

  const classes = useStyles();
  const {openModal} = useModals();
  const {project} = useContext(ProjectContext);
  const {setAlert, setSuccess} = useContext(AlertContext);
  const {post, fileDownload} = useApi();

  const refreshPage = useCallback(async (isSubscribed = true) => {
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };
      await post<PaginationRequest, any>(`/project/${project?.id}/penetration`,
        paginationQuery,
        {},
        {penetrationId}).then(result => {
        if (isSubscribed) {
          setInitialValues(result.values);
          dispatch({
            type: "SET_STATE",
            payload: () => ({
              ...result,
              values: result.values.map((x: Penetration) => ({
                ...x,
                isMarked: false
              }))
            })
          });
        }
      })
    } finally {
      setIsDirty(false);
      setIsLoading(false);
    }
  }, [state, penetrationId, state?.pageSize, state?.currentPage]);

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      await refreshPage(isSubscribed);
    })();
    return () => {
      isSubscribed = false;
    };
  }, [penetrationId, state?.pageSize, state?.currentPage]);

  const addNewItem = () => {
    const newId = prevNewId - 1;
    const newItem: Penetration = {
      id: newId,
      projectId: project ? project.id : 0,
      code: "",
      name: "",
      orientation: "",
      extendedInformationToA: "",
      extendedInformationToB: "",
      extendedInformationFromA: "",
      extendedInformationFromB: "",
      state: "new",
      hasError: true
    };

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          newItem,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setPrevNewId(newId);
  };

  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: Penetration) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u)
        .filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const newItems = state.values
      .filter(x => x.state === "new")
      .map<PatchRequestNewItem<PenetrationProps>>((n: Penetration) => ({
        referenceId: n.id,
        props: {
          code: n.code,
          name: n.name,
          orientation: n.orientation,
          extendedInformationToA: n.extendedInformationToA,
          extendedInformationToB: n.extendedInformationToB,
          extendedInformationFromA: n.extendedInformationFromA,
          extendedInformationFromB: n.extendedInformationFromB,
        }
      }));

    const deletedItems = state.values
      .filter(x => x.state === "deleted")
      .map((x: Penetration) => x.id);

    const body = {
      newItems,
      patchedItems,
      deletedItems
    }

    try {
      setIsLoading(true);
      const updatedPenetrationsResult = await post<PatchRequest<PenetrationProps>, PatchResponse<Penetration>>(`project/${project!.id}/penetration/patch`, body);
      refreshVisiblePenetrations(updatedPenetrationsResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the penetrations list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the penetrations list"});
      }
    } finally {
      setIsLoading(false);
    }
  }


  const copy = useCallback((source: Penetration) => {
    const newId = prevNewId - 1;
    setPrevNewId(newId)

    const target: Penetration = {
      id: newId,
      projectId: project ? project.id : 0,
      code: source.code,
      name: source.name,
      orientation: source.orientation,
      extendedInformationToA: source.extendedInformationToA,
      extendedInformationToB: source.extendedInformationToB,
      extendedInformationFromA: source.extendedInformationFromA,
      extendedInformationFromB: source.extendedInformationFromB,
      state: "new",
      hasError: source.hasError
    }

    dispatch({
      type: "SET_STATE",
      payload: (prevState) => ({
        ...prevState,
        values: [
          target,
          ...prevState.values
        ],
        totalValues: prevState.totalValues + 1
      })
    });
    setIsDirty(true);
  }, [prevNewId])

  const change = useCallback((penetration: Penetration) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        const prevPenetrationValues = prevValues.find(x => x.id === penetration.id);
        if (prevPenetrationValues.orientation !== penetration.orientation) {
          penetration.orientation = (() => {
            switch (String(penetration.orientation)) {
              case String(Orientation.HORIZONTAL):
                return "Horizontal";

              case String(Orientation.VERTICAL):
                return "Vertical";

              default:
                return "";
            }
          })();
        }

        return handleRowChange(
          prevValues,
          penetration,
          setIsDirty);
      }
    });
  }, [penetrationId]);

  const exportTemplate = useCallback(async () => {
    if(project == null) {
      return;
    }
    const fileName = getValidFileName(`Penetration import template for  ${project.code} ${project.name}.xlsx`);
    await fileDownload(fileName, `project/${project.id}/penetration/import-template`);

  }, [project])

  const openPenetrationImport = useCallback(async () => {
    if(project == null) {
      return;
    }

    openModal && openModal(PenetrationImportModal, {
      project: project,
      onClosed: refreshPage
    })
  }, [project, openModal]);

  const refreshVisiblePenetrations = (updates: PatchResponse<Penetration>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: Penetration[]) => {
        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  /* Validators */

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  /* Filters */

  const onPenetrationIdChange = (value: string) => {
    promptIfUnsavedChanges(openModal, isDirty, () => setPenetrationId(value));
  }

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Penetrations"}
        add={{action: addNewItem}}
        save={{action: update, disabled: hasErrors || !isDirty}}
        secondaryActions={[
          {
            icon: <GridOn/>,
            action: exportTemplate,
            label: "Download import template",
            color: "default"
          },
          {
            icon: <CloudUploadSharp />,
            action: openPenetrationImport,
            label: "Import penetrations",
            color: "default",
            claim: ProjectClaims.project.penetration.write.all,
          }
        ]}
      />

      {/* Header filter */}
      {initialValues ? (
        <div className={classes.filterItem}>
          <VectTextField
            value={penetrationId}
            change={onPenetrationIdChange}
            label={"Penetration ID"}
            debounceDelay={1000}
          />
        </div>
      ) : <Skeleton variant="rect" width="40rem" height="5rem"/>}

      {/* Penetration Table Headers */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Penetration ID</TableCellHeader>
            <TableCellHeader style={{width: "25rem"}}>Text</TableCellHeader>
            <TableCellHeader style={{width: "9rem"}}>Orientation</TableCellHeader>
            <TableCellHeader>From Info A</TableCellHeader>
            <TableCellHeader>To Info A</TableCellHeader>
            <TableCellHeader>From Info B</TableCellHeader>
            <TableCellHeader>To info B</TableCellHeader>
            <TableCellHeader/>
            <TableCellHeader/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map((penetration: Penetration, index: number) => (
                <PenetrationRowItem
                  key={index}
                  penetration={penetration}
                  change={change}
                  copy={copy}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch} isDirty={isDirty}/>
    </>
  );
};
export default Penetrations;
