import React from "react";
import {useTable} from "react-table";
import Pagination from "./Pagination";
import './table.module.css';
import {MuiThemeProvider, Table, TableBody, TableCell, TableFooter, TableHead, TableRow,} from "@material-ui/core";
import EditableCell from "./EditableCell";
import {StyledTableRow, tableCellStyles, tableTheme} from "./styles";
import {PaginatedTableProps} from "./tableProps";


const defaultColumn = {
  Cell: EditableCell,
}

const PaginatedTable= (
  {
    state,
    dispatch,
    change,
    columns,
    data,
    children,
    click,
    hiddenColumns,
    footer}: PaginatedTableProps): JSX.Element  => {
  const classes = tableCellStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns || []
      },
      defaultColumn,
      change,
    },
  )
  return (
    <>
      <Table {...getTableProps()}>
        <TableHead >
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>

            {headerGroup.headers.map(column => (
              <TableCell style={{width: column.width}} {
                    ...column.getHeaderProps()}>{column.render('Header')} </TableCell>
            ))}
          </TableRow>
        ))}
        </TableHead>
        <MuiThemeProvider theme={tableTheme} >
        <TableBody {...getTableBodyProps()}>
        {rows.map((row, ) => {

          prepareRow(row)
          return (
            <StyledTableRow  {...row.getRowProps()} onClick={() => {
              click !== undefined ? click(row): {}}} >
              {row.cells.map((cell) => {

                return <TableCell className={classes.root} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
              })}
            </StyledTableRow>
          )
        })}
        </TableBody>
          {footer && (<TableFooter>{footer}</TableFooter>)}
        </MuiThemeProvider>
      </Table>
      {children}
      <Pagination state={state} dispatch={dispatch} />
    </>
  )
}
export default PaginatedTable
