import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export const DialogActionsFinished: React.FC<{close: () => void}> = ({close}) => {
  return (
    <DialogActions>
      <Button onClick={close} color="primary" variant="contained">
        Close
      </Button>
    </DialogActions>
  )
}
