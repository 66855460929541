import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  table: {
    margin: "1rem 0 1rem 0",
    padding: "12px"
  }
}));

interface ImportLogResult {
  id: number;
  projectId: number;
  userId: number;
  userObjectId?: string;
  userName?: string;
  userEmail?: string;
  createdAt: string;
}

export interface AreaImportLogResult extends ImportLogResult{
	areas: AreaImportLogItem[];
}

export interface AreaImportLogItem {
	id: number;
	code: string;
	name: string;
	areaForemanCode: string;
	areaForemanName?: string;
	mainVerticalZoneCode?: string;
	zone3dCode?: string;
	zone3dName?: string;
	didCreateAreaForeman: boolean;
	didCreateMainVerticalZone: boolean;
	didCreateZone3d: boolean;
}

export interface CableImportLogResult extends ImportLogResult{
  cables: CableImportLogItem[];
}

export interface CableImportLogItem {
  systemCode: string;
  cableNumber: string;
  cableTypeCode: string;
  fromTagCode: string;
  fromText: string;
  toTagCode: string;
  toText: string;
  meters?: number;
  bundleCode?: string;
}

export interface PenetrationImportLogResult extends ImportLogResult{
  penetrations: PenetrationImportLogItem[];
}

export interface PenetrationImportLogItem {
  code: string;
  name: string;
  orientation?: string;
}
