import React from "react";
import {Typography} from "@material-ui/core";

interface CaptionedTextProps {
  className?: string,
  label: string,
}

export const CaptionedText : React.FC<CaptionedTextProps> = ({
  children,
  className,
  label
}):JSX.Element => {
  return (
    <div className={className}>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="body1">{children || ""}</Typography>
    </div>
  );
}
