import React, {useCallback, useContext, useEffect, useState} from "react";
import {ProjectUserContext} from "../../../shared/interfaces/project.interface";
import {AlertContext} from "../../../shared/context/AlertContext";
import {useModals} from "../../../shared/context/ModalContext";
import {useApi} from "../../../shared/hooks/useApi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {FileInput} from "../../shared/inputs/FileInput";
import {Alert} from "@material-ui/lab";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {tableTheme} from "../../shared/table/styles";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {DialogActionsFinished} from "../../shared/import-modal/DialogActionsFinished";
import {DialogActionsReady} from "../../shared/import-modal/DialogActionsReady";
import {PenetrationImportRowItem, PenetrationListValidationResult} from "./PenetrationImportRowItem";
import {PenetrationProps} from "../../../shared/interfaces/penetration.interface";

export const PenetrationImportModal: React.FC<{
  project: ProjectUserContext,
  onClosed: () => void,
  open?: boolean
}> = ({
  project,
  onClosed
}) => {
  const [isFinished, setIsFinished] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [preview, setPreview] = useState<PenetrationListValidationResult | null>(null);
  const {setError, setSuccess} = useContext(AlertContext);
  const { closeModal } = useModals();
  const { post, postFormData } = useApi();

  useEffect(() => {
    const isValid = preview != null && preview.penetrations && preview.penetrations.length > 0 && preview.errorCount === 0;
    setIsValid(isValid);
  }, [preview])

  const onCancel = useCallback(() => {
    closeModal && closeModal();
  }, [closeModal]);

  const onClose = useCallback(() => {
    onClosed();
    closeModal && closeModal();
  }, [closeModal]);

  const onFileChange = useCallback(async (file: File) => {
    const formData = new FormData();
    formData.append("penetrationList", file, file.name);
    try {
      setIsValidating(true);
      setPreview(null);
      const result = await postFormData<PenetrationListValidationResult>(`project/${project.id}/penetration/import-template/process-excel-file`, formData);
      setPreview(result);
    } catch(err) {
      setError(err);
    } finally {
      setIsValidating(false);
    }
  }, []);

  const importPenetrations = useCallback(async () => {
    if(preview == null){
      return;
    }

    try {
      const body: {penetrations: PenetrationProps[]} = {
        penetrations: preview.penetrations.map(x => ({
          code: x.code.value,
          name: x.name.value,
          orientation: x.orientation.value
        }))
      }

      await post(`project/${project.id}/penetration/import`, body);
      setIsFinished(true);
      const count = body.penetrations.length;
      const successDetails = [
        `${count} ${(count === 1 ? "penetration" : "penetrations")} was successfully imported.`,
        "You may close this dialog now."
      ]
      setSuccess(successDetails);
    } catch(err) {
      setError(err);
    }
  }, [preview])

  return (
    <div>
      <Dialog
        open={true}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          Import penetrations
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0}}>
          <FileInput fileChange={onFileChange} accept=".xlsx" />
          <hr/>
          {preview && preview.errorCount > 0 && (
            <Alert severity="error" style={{margin: "1rem 0 1rem 0"}}>
              {`This penetration list has ${preview.errorCount} ${(preview.errorCount === 1 ? "error" : "errors")}.`}
            </Alert>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Penetration ID</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Orientation</TableCell>
              </TableRow>
            </TableHead>
            <MuiThemeProvider theme={tableTheme}>
              <TableBody>
                <LoadingIndicator isLoading={isValidating} columns={3} useTableCells>
                  {preview
                    ? preview.penetrations.map(x => <PenetrationImportRowItem {...x}/>)
                    : null}
                </LoadingIndicator>
              </TableBody>
            </MuiThemeProvider>
          </Table>
        </DialogContent>
        {isFinished
          ? <DialogActionsFinished close={onClose}/>
          : <DialogActionsReady importData={importPenetrations} importLabel="Import penetrations" enableImport={isValid} close={onCancel}/>}
      </Dialog>
    </div>
  );
}
