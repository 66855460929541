export interface PaginatedResult {
  values: any[],
  currentPage: number,
  currentPageSize: number,
  pageSize: number | unknown,
  totalPages: number,
  totalValues: number
  metadata?: any
}

export interface PaginationRequest {
  page: number,
  pageSize: number | unknown
}

export const initialPaginated = {

    values: [],
    currentPage: 1,
    currentPageSize: 0,
    pageSize: 50,
    totalPages: 0,
    totalValues: 0,

}
