import React, {useMemo} from "react";
import {TableCell} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {StyledTableRow} from "./styles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    "& > span": {
      marginLeft: "5px",
      marginRight: "5px"
    }
  }
}))

interface LoadingIndicatorProps {
  isLoading?: boolean;
  component?: JSX.Element,
  rows?: number,
  columns?: number,
  width?: string | number
  height?: string | number
  /**
   * When false, uses a single table cell and generates all column skeletons inside
   * that cell. This is used to maintain backward compatibility.
   *
   * When true, each skeleton is created in its own table cell.
   * Use this option whenever possible.
   */
  useTableCells?: boolean
}


export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isLoading = true,
  children,
  component,
  rows= 2,
  columns= 4,
  width= "5rem",
  height = "1rem",
  useTableCells = false
}): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | any | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined => {
  const classNames = useStyles();


  const indicatorAsSkeleton = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    for (let i = 0; i < columns; i++) {
      result.push(<Skeleton key={`li-skeleton-${i}`} variant="rect" height={height} width={width}/>)
    }
    return result;
  }

  const indicatorAsTableCell = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    for (let i = 0; i < columns; i++) {
      result.push(
        <TableCell key={`li-skeleton-${i}`}>
          <Skeleton variant="rect" height={height} width={width}/>
        </TableCell>
      )
    }
    return result;
  }

  const singleTableCellRow = (index: number) => {
    return (
      <StyledTableRow key={`li-row-${index}`}>
        <TableCell className={classNames.cell}>
          {indicatorAsSkeleton()}
        </TableCell>
      </StyledTableRow>
    );
  };

  const multipleTableCellRow = (index: number) => {
    return (
    <StyledTableRow key={`li-row-${index}`}>
      {indicatorAsTableCell()}
    </StyledTableRow>);
  }

  const rowElements = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    for (let i = 0; i < rows; i++) {
      result.push(useTableCells ? multipleTableCellRow(i) : singleTableCellRow(i));
    }
    return result;
  };

  const skeleton = useMemo(() => rowElements(), [rows, columns]);

  if (isLoading) {
    if (component) {
      return component
    } else {
      return skeleton;
    }
  } else {
    return children;
  }
}
