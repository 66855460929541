import React, {FC, memo} from "react";
import {Button, Checkbox, InputLabel, Typography} from "@material-ui/core";
import {ProjectClaims} from "../../../../shared/claims";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {VectContainer} from "../../../shared/layout/VectContainer";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {VectSelect} from "../../../shared/inputs/VectSelect";
import {Tag} from "../../../../shared/interfaces/tag.interface";
import {Area} from "../../../../shared/interfaces/area.interface";
import {CableClass} from "../../../../shared/interfaces/cableClass.interface";
import {Bundle} from "../../../../shared/interfaces/bundle.interface";
import {CableTypeListItem} from "../../../../shared/interfaces/cableType.interface";
import {Areaforeman} from "../../../../shared/interfaces/areaforeman.interface";
import {BatchUpdateCableProps} from "../../../../shared/interfaces/cable.interface";
import {dp3Options} from "../../bundle-register/options";
import {footerStyles} from "../../../../shared/styles/footerStyles";
import {
  mapAreaForemanOptions,
  mapAreaOptions,
  mapCableTypeOptions,
  mapTagOptions
} from "../../../../shared/helpers/metadata";

interface BatchUpdateCablesProps {
  areDependenciesLoaded: boolean;
  isAllMarked: boolean;
  hasErrors: boolean;
  batchUpdateCables: BatchUpdateCableProps;
  tags: Tag[] | null;
  areas: Area[] | null;
  bundles: Bundle[] | null;
  cableClasses: CableClass[] | null;
  cableTypes: CableTypeListItem[] | null;
  areaForemen: Areaforeman[] | null;
  change: (value: boolean | string | number | undefined | null, field?: string) => void;
  selectAllVisibleCables: () => void;
  clearAllVisibleCables: () => void;
  updateSelectedBatch: () => void;
}

const BatchUpdateCablesFooter: FC<BatchUpdateCablesProps> = ({
  isAllMarked,
  areaForemen,
  areas,
  batchUpdateCables,
  bundles,
  cableTypes,
  change,
  selectAllVisibleCables,
  clearAllVisibleCables,
  tags,
  updateSelectedBatch,
  areDependenciesLoaded,
  cableClasses,
  hasErrors
 }) => {

  const classNames = footerStyles();

  const tagOptions = mapTagOptions(tags);
  const areaOptions = mapAreaOptions(areas);
  const bundleOptions = bundles?.map(x => ({value: x.id, label: `${x.code} ${x.name}`})) || null;
  const cableClassOptions = cableClasses?.map(x => ({value: x.id, label: `${x.code} ${x.name}`})) || null;
  const cableTypeOptions = mapCableTypeOptions(cableTypes);
  const areaForemanOptions = mapAreaForemanOptions(areaForemen);


  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <VectContainer claim={ProjectClaims.project.engineering.external} className={classNames.controls}>
          <Typography variant="body1">
            Updating of From / To Tag must be done separately from other updates.
          </Typography>
          <VectSelect options={tagOptions}
                      value={batchUpdateCables.fromTagId}
                      fieldName="fromTagId"
                      change={change}
                      label="From Tag"
                      placeholder={"From Tag"}
                      isClearable
          />
          <VectSelect options={tagOptions}
                      value={batchUpdateCables.toTagId}
                      fieldName="toTagId"
                      change={change}
                      label="To Tag"
                      placeholder={"To Tag"}
                      isClearable
          />
          <VectSelect options={areaOptions}
                      value={batchUpdateCables.fromAreaId}
                      fieldName="fromAreaId"
                      change={change}
                      label="From Area"
                      placeholder={"From Area"}
                      isClearable
          />
          <VectSelect options={areaOptions}
                      value={batchUpdateCables.toAreaId}
                      fieldName="toAreaId"
                      change={change}
                      label="To Area"
                      placeholder={"To Area"}
                      isClearable
          />
          <VectSelect options={cableTypeOptions}
                      value={batchUpdateCables.cableTypeId}
                      fieldName="cableTypeId"
                      change={change}
                      label="Cable Type"
                      placeholder={"Cable Type"}
                      isClearable
          />
          <VectSelect options={cableClassOptions}
                      value={batchUpdateCables.cableClassId}
                      fieldName="cableClassId"
                      change={change}
                      label="Cable Class"
                      placeholder={"Cable Class"}
                      isClearable
          />
          <VectSelect options={dp3Options}
                      value={dp3Options.find(x => x.label === batchUpdateCables.dp3Zone)?.value}
                      change={val => {
                        const label = val == null
                          ? null
                          : dp3Options.find(x => x.value === val)!.label;
                        change(label, "dp3Zone")
                      }}
                      label="DP3 Zone"
                      placeholder={"DP3 Zone"}
                      isClearable
          />
          <div>
            <VectTextField id={"estimatedMeters"}
                           type={"number"}
                           value={batchUpdateCables.estimatedMeters || ""}
                           fieldName="estimatedMeters"
                           change={change}
                           label="Calculated Meter"
            />
          </div>
        </VectContainer>
        <VectContainer claim={ProjectClaims.project.engineering.basis} className={classNames.controls}>
          <div>
            <VectCheckbox
              color="primary"
              id={"releaseForMeasuringWeek"}
              label="Release for Routing"
              checked={batchUpdateCables.releaseForMeasuringWeek || false}
              fieldName="releaseForMeasuringWeek"
              change={change}/>
          </div>
        </VectContainer>
        <VectContainer claim={ProjectClaims.project.routing.external} className={classNames.controls}>
          <VectSelect options={bundleOptions}
                      value={batchUpdateCables.bundleId}
                      fieldName="bundleId"
                      change={change}
                      label="Bundle ID"
                      placeholder={"Bundle ID"}
                      isClearable
          />
          <VectCheckbox color="primary"
                        id={"convertEstimated"}
                        checked={batchUpdateCables.convertEstimated || false}
                        fieldName="convertEstimated"
                        label="Conv. est. m. to actual  meter"
                        change={change}/>
          <VectTextField id={"meters"}
                         type={"number"}
                         value={batchUpdateCables.meters || ""}
                         fieldName="meters"
                         change={change}
                         label="Meters"/>
          <VectTextField id={"markPlacement"}
                         type={"text"}
                         value={batchUpdateCables.markPlacement || ""}
                         fieldName="markPlacement"
                         label="Mark placement"
                         change={change}/>
          <VectTextField id={"penetrations"}
                         type={"text"}
                         value={batchUpdateCables.penetrations || ""}
                         fieldName="penetrations"
                         label="Penetrations"
                         change={change}/>

        </VectContainer>
        <VectContainer claim={ProjectClaims.project.production.coordinator} className={classNames.controls}>
          <VectSelect options={areaForemanOptions}
                      value={batchUpdateCables.fromConnectionAreaForemanId}
                      fieldName="fromConnectionAreaForemanId"
                      change={change}
                      label="Conn. Resp. From"
                      placeholder={"Conn. Resp. From"}
                      isClearable
          />
          <VectSelect options={areaForemanOptions}
                      value={batchUpdateCables.toConnectionAreaForemanId}
                      fieldName="toConnectionAreaForemanId"
                      change={change}
                      label="Conn. Resp. To"
                      placeholder={"Conn. Resp. To"}
                      isClearable
          />
        </VectContainer>
        <VectContainer claim={ProjectClaims.project.production.external} className={classNames.controls}>
          <VectCheckbox color="primary"
                        id={"connectedFrom"}
                        checked={batchUpdateCables.connectedFrom || false}
                        fieldName="connectedFrom"
                        label="Connected From"
                        change={change}/>
          <VectCheckbox color="primary"
                        id={"connectedTo"}
                        checked={batchUpdateCables.switchFromTo || false}
                        fieldName="switchFromTo"
                        label="Switch From and To"
                        change={change}/>
          <VectCheckbox color="primary"
                        id={"connectedTo"}
                        checked={batchUpdateCables.connectedTo || false}
                        fieldName="connectedTo"
                        label="Connected To"
                        change={change}/>
        </VectContainer>
        <VectContainer claim="TODO" className={classNames.controls}>
          <div>
            <InputLabel id={"srtpBatch"}>SRtP Cables</InputLabel>
            <Checkbox color="primary"
                      id={"srtpBatch"}
                      checked={batchUpdateCables.hasSpecialRequirements}
                      onChange={(event => change(event.target.checked, "hasSpecialRequirements"))}/>
          </div>
        </VectContainer>

      </div>
      <div className={classNames.container}>
        <VectContainer  className={classNames.controls}
                        claims={[
                          ProjectClaims.project.engineering.external,
                          ProjectClaims.project.engineering.basis,
                          ProjectClaims.project.routing.external,
                          ProjectClaims.project.production.coordinator,
                          ProjectClaims.project.production.external
                        ]}>
          {isAllMarked ? (
            <Button onClick={clearAllVisibleCables} color={"default"} variant="contained" >Clear all</Button>
          ) : (
            <Button onClick={selectAllVisibleCables} color={"default"} variant="contained" >Select all</Button>
          )}
          <Button onClick={() => updateSelectedBatch()} color={"primary"} variant="contained" disabled={!areDependenciesLoaded || hasErrors}>Update selected cables</Button>
        </VectContainer>
      </div>
    </div>
  );
}

export default memo(BatchUpdateCablesFooter);
