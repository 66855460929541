import {TableCell, TextField} from "@material-ui/core";
import React, {FunctionComponent} from "react";
import {CableWithReferenceLabels} from "../../../shared/interfaces/cable.interface";
import {generalStyles, StyledTableRow, tableCellStyles, tableStyles} from "../../shared/table/styles";

import {formatDate} from "../../../shared/helpers/date";
import {vectLightExtension} from "../../../shared/theme";
import {ProjectClaims} from "../../../shared/claims";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {Bundle} from "../../../shared/interfaces/bundle.interface";

type CableRowItemProps = {
  cable: CableWithReferenceLabels;
  change: (
    value: boolean,
    id: number,
  ) => void,
  getBundle: (bundleId?: number | null) => Bundle | null
};

const OrganizeRowItem: FunctionComponent<CableRowItemProps> = ({
  cable,
  change,
  getBundle
}) => {
  const cellClasses = tableCellStyles();
  const classes = tableStyles();
  const general = generalStyles();
  const bundle = getBundle(cable.bundleId);
  const handleCheckboxValueMappingOnChange = (value: boolean) => {
    change(value, cable.id);
  }

  return (
    <StyledTableRow className={classes.tableRowWrapper}>
      <span
        style={{width: "14rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <span className={general.yFlex}>
            <TextField value={cable.cableNumber} disabled fullWidth/>
            <TextField value={cable.cableTypeCode} disabled fullWidth/>
            <TextField
              style={
                cable.releaseForProductionWeek
                  ? {backgroundColor: vectLightExtension.palette.custom.status.allOk}
                  : cable.releaseForMeasuringWeek
                  ? {backgroundColor: vectLightExtension.palette.custom.status.incomplete}
                  : {backgroundColor: vectLightExtension.palette.custom.status.notOkay}
              }
              value={cable.code}
              disabled fullWidth
            />
          </span>
        </TableCell>
      </span>
      <span
        style={{width: "8rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <TextField value={cable.fromTagCode} disabled fullWidth/>
          <TextField value={cable.fromAreaCode} disabled fullWidth/>
          <TextField value={cable.fromMainVerticalZoneCode} disabled fullWidth/>
        </TableCell>
      </span>
      <span
        style={{width: "20rem"}}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCell className={cellClasses.cell}>
          <span className={general.yFlexWSpace}>
            <span style={{marginBottom: 29}}>
              <TextField value={cable.fromText} disabled fullWidth
                         multiline/>
            </span>
            <span className={general.xFlex}
                  style={{justifyContent: 'space-between'}}>
              <span style={{width: "4rem"}}>
                <TextField value={cable.from3dZoneCode} disabled fullWidth/>
              </span>
              <span style={{width: "4rem"}}>
                <TextField value={cable.releaseForProductionWeek} disabled
                />
              </span>
              <span style={{width: "4rem"}}>
                <TextField value={cable.releaseForMeasuringWeek} disabled
                />
              </span>
            </span>
          </span>
        </TableCell>
      </span>
      <span
        style={{width: "8rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <TextField value={cable.toTagCode} disabled fullWidth/>
          <TextField value={cable.toAreaCode} disabled fullWidth/>
          <TextField value={cable.toMainVerticalZoneCode} disabled fullWidth/>
        </TableCell>
      </span>
      <span
        style={{width: "20rem"}}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCell className={cellClasses.cell}>
          <span className={general.yFlexWSpace}>
            <span style={{marginBottom: 29}}>
              <TextField value={cable.toText} disabled fullWidth multiline/>
            </span>
            <span className={general.xFlex}
                  style={{justifyContent: 'space-between'}}>
              <span style={{width: "7rem"}}>
                <TextField value={cable.to3dZoneCode} disabled fullWidth/>
              </span>
              <span style={{width: "5rem"}} className={general.spaceRight}>
                <TextField value={formatDate(cable.cableDate)}
                           disabled fullWidth/>
              </span>
            </span>
          </span>
        </TableCell>
      </span>
      <span
        style={{width: "10rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <TextField value={bundle?.code} disabled fullWidth/>
          <TextField value={cable.meters} disabled fullWidth/>

        </TableCell>
      </span>
      <span
        style={{width: "11rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <TextField value={bundle?.name} disabled fullWidth/>
          <TextField value={cable.markPlacement} disabled fullWidth/>
          <span>
            <TextField value={cable.penetrations} disabled fullWidth/>
          </span>
        </TableCell>
      </span>
      <span
        style={{width: "6rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <TextField value={cable.cutWeek} disabled fullWidth/>
          <TextField value={cable.pulledWeek} disabled fullWidth/>
        </TableCell>
      </span>
      <span
        style={{width: "3rem", alignSelf: "center"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCell className={cellClasses.cell}>
          <VectCheckbox
            claim={ProjectClaims.project.routing.external}
            checked={cable.isMarked}
            fieldName="isMarked"
            change={handleCheckboxValueMappingOnChange}
            color="primary"
            size={"small"}
          />
        </TableCell>
      </span>
    </StyledTableRow>
  );
};

export default OrganizeRowItem;
