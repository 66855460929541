import React, {useContext, useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {ProjectMetadata} from "../../../../shared/interfaces/project.interface";
import {formatDate} from "../../../../shared/helpers/date";
import {DeletedProjectLog, ReceiveDeleteProjectMessage} from "./DeletedProjectLog";
import {VectButton} from "../../../shared/navigation/VectButton";
import {GlobalClaims} from "../../../../shared/claims";
import {HubConnection} from "@microsoft/signalr";
import {AlertContext} from "../../../../shared/context/AlertContext";
import {useSignalR} from "../../../../shared/hooks/useSignalR";
import {makeStyles} from "@material-ui/core/styles";
import {Alert, AlertTitle} from "@material-ui/lab";

export const deletedProjectMessage = "This project is in the process of being deleted.";

const styles = makeStyles(() => ({
  retryButton: {
    marginRight: "1rem"
  },
  deletedBy: {
    margin: "0.5rem 0 0.5rem 0"
  },
  alertContainer: {
    margin: "1rem 0 1rem 0",
    padding: "0.5rem"
  }
}))

export const DeletedProject: React.FC<{
  projectCode: string,
  metadata: ProjectMetadata,
  forceDelete: () => void
}> = ({
  projectCode,
  metadata,
  forceDelete
 }) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [messages, setMessages] = useState<ReceiveDeleteProjectMessage[]>([]);
  const {setError} = useContext(AlertContext);
  const {projectHub} = useSignalR();

  const groupName = `project_delete_${projectCode}`;
  const classNames = styles();

  useEffect(() => {
    setConnection(projectHub());

    return () => {
      connection?.stop();
      setConnection(null);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(() => {
          connection
            .invoke("JoinGroup", groupName)
            .then(() => {
              connection.on(
                'ReceiveDeleteProjectMessage',
                (res: ReceiveDeleteProjectMessage) => {
                  setMessages(prevState => {
                    return [res, ...prevState];
                  });
                });
            })
            .catch(setError);
        })
        .catch(setError);
    }
  }, [connection, projectCode]);

  return(
    <div>
      <Typography variant="h6">{deletedProjectMessage}</Typography>
      <Typography variant="body1">The project will be visible in this part of the management section while the cleanup is in progress.</Typography>
      <Typography variant="body1">It is not possible to cancel the cleanup process..</Typography>
      <Typography variant="body2" className={classNames.deletedBy}>{`Deleted by ${metadata.deletedBy?.name} (${metadata.deletedBy?.email}) on ${formatDate(metadata.deletedAt, true)}`}</Typography>
      {
        metadata.deleteError && (
          <Alert className={classNames.alertContainer}
                 severity="error"
                 action={
                   <VectButton claim={GlobalClaims.manage.project.delete.all}
                               className={classNames.retryButton}
                               variant="contained"
                               color="inherit"
                               size="small"
                               onClick={forceDelete}>Retry</VectButton>
                 }
                 >
            <AlertTitle>Something failed when deleting this project:</AlertTitle>
            {`${formatDate(metadata.deleteError.timestamp, true)}: ${metadata.deleteError.message}`}
          </Alert>
        )
      }
      <DeletedProjectLog messages={messages} />
    </div>
  );

}
