import {FilledInputProps, InputProps, OutlinedInputProps, PropTypes} from "@material-ui/core";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import React, {CSSProperties, RefObject, useEffect, useState} from "react";
import {useClaims} from "../../../shared/hooks/useClaims";
import useDebounce from "../../../shared/hooks/useDebounce";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";

export interface AuthTextFieldProps extends AuthProps {
  change?: (value: Date | null, fieldName?: string, hasError?: boolean) => void;
  className?: string;
  debounceDelay?: number
  disabled?: boolean;
  error?: boolean;
  fieldName?: string;
  fullWidth?: boolean;
  /**
   * Helper text on errors
   */
  helperText?: string;
  id?: string;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>
  inputVariant?: "filled" | "standard" | "outlined";
  label?: string;
  margin?: PropTypes.Margin;
  ref?: RefObject<any>;
  required?: boolean;
  style?: CSSProperties;
  format?: string;
  value?: Date | null;
}

export const VectDateTimeField: React.FC<AuthTextFieldProps> = ({
  claim,
  claims,
  useExactMatch,
  change,
  debounceDelay = 350,
  disabled,
  error,
  fieldName,
  fullWidth,
  helperText,
  id,
  InputProps,
  inputVariant,
  label,
  required,
  style,
  value: initialValue,
}): JSX.Element => {
  const{isValid} = useClaims();
  const [value, setValue] = useState<Date | null | undefined>(initialValue);
  const debouncedValue = useDebounce(value, debounceDelay)

  useEffect(() => {
    if(value !== undefined && change && debouncedValue !== initialValue) {
      change(value, fieldName);
    }
  }, [debouncedValue])

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (date: MaterialUiPickersDate) => {
    setValue(date);
  }

  const isDisabled = disabled ? disabled : !isValid({claim, claims, useExactMatch});

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{marginTop: -2, ...style}}
        disableToolbar
        id={id}
        fullWidth={fullWidth || false}
        helperText={helperText}
        error={error}
        disabled={isDisabled}
        variant="inline"
        format="dd.MM.yyyy"
        label={label || ""}
        margin="normal"
        value={value}
        required={required}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputLabelProps = {{shrink: true}}
        InputProps={InputProps}
        inputVariant={isDisabled ? "standard" : inputVariant}
      />
    </MuiPickersUtilsProvider>
  );
}
