import {TableItemState} from "./common.interface";

export enum Orientation {
  HORIZONTAL = 1,
  VERTICAL = 2,
}

export interface Penetration {
  id: number,
  projectId: number,
  code: string,
  name: string,
  orientation?: string,
  extendedInformationToA?: string,
  extendedInformationToB?: string,
  extendedInformationFromA?: string,
  extendedInformationFromB?: string,

  hasError: boolean,
  state?: TableItemState,
}

/**
 * Updatable props for Penetration
 */
export interface PenetrationProps {
  code: string,
  name: string,
  orientation?: string,
  extendedInformationToA?: string,
  extendedInformationToB?: string,
  extendedInformationFromA?: string,
  extendedInformationFromB?: string
}
