import React, {FC} from 'react';
import {Area} from "../../../shared/interfaces/area.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Paper, Typography} from "@material-ui/core";
import styles from './cbsForemanStatus.module.css'
interface ScrollableCBSListProps{
  title: string,
  areas?: Area[]
  systems?: SystemListItem[]
}

const ScrollableCBSList: FC<ScrollableCBSListProps> = ({areas, title, systems}) => {
  return (
    <div>
      <Typography variant="subtitle1">{title} covered by this CBS / Foreman</Typography>
      <Paper elevation={2}>
        <div className={styles.scrollableList}>
          {areas && areas.map(ele => {
            return <span>{ele.code} {ele.name}</span>
          })}
          {systems && systems.map(ele => {
            return <span>{ele.code} {ele.name}</span>
          })}
        </div>
      </Paper>

    </div>
  )
};


export default ScrollableCBSList;
