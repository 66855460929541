import AreaForeman from "../cbs-area-foreman/AreaForeman";
import Areas from "../area/Areas";
import BundleAdmin from "../Bundle-Admin/BundleAdmin";
import BundleRegister from "../bundle-register/BundleRegister";
import BundleStatus from "../bundle-status/BundleStatus";
import Cables from "../cable/Cables";
import CbsForemanStatus from "../cbsForemanStatus/CbsForemanStatus";
import CbsStatus from "../cbs-status/CbsStatus";
import ChartView from "../chart/ChartView";
import ConnectionAdmin from "../connection-admin/ConnectionAdmin";
import FreePullCables from "../free-pull-cable/FreePullCables";
import MainPanel from "../../shared/layout/MainPanel/MainPanel";
import MainVerticalZones from "../main-vertical-zones/MainVerticalZones";
import Organize from "../organize/Organize";
import PenetrationList from "../penetration-list/PenetrationList";
import Penetrations from "../penetration/Penetration";
import ProductionResponsibles from "../production-responsible/ProductionResponsibles";
import ProjectDashboard from "./ProjectDashboard";
import ProjectStatus from "../ProjectStatus/ProjectStatus";
import React, {useContext} from "react";
import Spinner from "../../shared/Spinner/Spinner";
import SystemGroupStatus from "../system-group-status/SystemGroupStatus";
import SystemStatus from "../system-status/SystemStatus";
import Systems from "../system/Systems";
import Tags from "../tag/Tags";
import ThreeDZones from "../3d-zones/3DZones";
import VectAppBar, {ApplicationLink} from "../../shared/layout/VectAppBar";
import styles from "./ProjectHome.module.css"
import {VectContainer} from "../../shared/layout/VectContainer";
import {ProjectClaims} from "../../../shared/claims";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {ProjectCableTypesPage} from "../cable-types/ProjectCableTypesPage";
import CableClassReadOnly from "../cable-class/CableClassReadOnly";
import {ProtectedRoute} from "../../shared/layout/ProtectedRoute";
import ExportAndReport from "../export-and-report/ExportAndReport";

const ProjectHomeBootstrap = (): JSX.Element => {
  const {isLoading, project} = useContext(ProjectContext);
  const {url} = useRouteMatch();
  const navLinks: ApplicationLink[] = [
    {claim: ProjectClaims.project.read, name: "Home", route: url, exact: true},
    {claim: ProjectClaims.project.read, name: "Registration Cables", route: `${url}/cable`},
    {claim: ProjectClaims.project.read, name: "Bundle Admin", route: `${url}/bundles`},
    {claim: ProjectClaims.project.read, name: "Free Pull Cables", route: `${url}/free-pull-cable`},
    {claim: ProjectClaims.project.read, name: "Connection Admin", route: `${url}/connection`},
    {claim: ProjectClaims.project.read, name: "Penetrations", route: `${url}/penetration-list`},
    {claim: ProjectClaims.project.read, name: "Organize", route: `${url}/organize`},
    {claim: ProjectClaims.project.read, name: "Chart View", route: `${url}/chart`},
    {claim: ProjectClaims.project.read, name: "P-St", route: `${url}/project-status`},
    {
      claims: [
        ProjectClaims.project.engineering.coordinator,
        ProjectClaims.project.routing.coordinator,
        ProjectClaims.project.production.coordinator
      ],
      name: "S-St",
      route: `${url}/system-status`},
    {claim: ProjectClaims.project.read, name: "CBS / Foreman Status", route: `${url}/cbs-foreman-status`},
    {claim: ProjectClaims.project.read, name: "Exports and Reports for Cable Db System", route: `${url}/export-and-report`}
  ];
  const sideBarLinks: ApplicationLink[] = [
    {claim: ProjectClaims.project.read, name: "System", route: `${url}/system`},
    {claim: ProjectClaims.project.read, name: "System Group Status", route: `${url}/system-group-status`},
    {claim: ProjectClaims.project.read, name: "Tag", route: `${url}/tag`},
    {claim: ProjectClaims.project.read, name: "Areas", route: `${url}/area`},
    {claim: ProjectClaims.project.read, name: "3D Zones", route: `${url}/3d-zones`},
    {claim: ProjectClaims.project.read, name: "CBS / Area Foreman", route: `${url}/cbs-area-foreman`},
    {claim: ProjectClaims.project.read, name: "CBS Status", route: `${url}/cbs-status`},
    {claim: ProjectClaims.project.read, name: "Bundle Register", route: `${url}/bundle-register`},
    {claim: ProjectClaims.project.read, name: "Bundle Status", route: `${url}/bundle-status`},
    {claim: ProjectClaims.project.read, name: "Production Responsible", route: `${url}/production-responsible`},
    {claim: ProjectClaims.project.read, name: "Cable Class", route: `${url}/cable-class`},
    {claim: ProjectClaims.project.read, name: "Cable Types", route: `${url}/cable-types`},
    {claim: ProjectClaims.project.read, name: "Main Vertical Zones", route: `${url}/main-vertical-zones`},
    {claim: ProjectClaims.project.read, name: "Penetrations", route: `${url}/penetrations`}
  ];

  const header = isLoading || !project
    ? ""
    : `${project.code} ${project.name}`;

  return (
    <VectAppBar header={header} navLinks={navLinks} sideBarLinks={project && sideBarLinks}>
      <VectContainer claim={ProjectClaims.project.read}>
        {isLoading || !project ? <ProjectLoading/> : <ProjectHome/>}
      </VectContainer>
    </VectAppBar>
  );
};

const ProjectHome: React.FC = (): JSX.Element => {
  const {path} = useRouteMatch();
  return (
    <MainPanel>
      <Switch>
        <Route path={`${path}/system`} component={Systems}/>
        <Route path={`${path}/system-group-status`} component={SystemGroupStatus}/>
        <Route path={`${path}/tag`} component={Tags}/>
        <Route path={`${path}/area`} component={Areas}/>
        <Route path={`${path}/3d-zones`} component={ThreeDZones}/>
        <Route path={`${path}/cbs-area-foreman`} component={AreaForeman}/>
        <Route path={`${path}/cbs-status`} component={CbsStatus}/>
        <Route path={`${path}/bundles`} component={BundleAdmin}/>
        <Route path={`${path}/bundle-register`} component={BundleRegister}/>
        <Route path={`${path}/bundle-status`} component={BundleStatus}/>
        <Route path={`${path}/production-responsible`} component={ProductionResponsibles}/>
        <Route path={`${path}/organize`} component={Organize}/>
        <Route path={`${path}/cable-class`} component={CableClassReadOnly}/>
        <Route path={`${path}/cable-types`} component={ProjectCableTypesPage}/>
        <Route path={`${path}/main-vertical-zones`} component={MainVerticalZones}/>
        <Route path={`${path}/penetrations`} component={Penetrations}/>
        <Route path={`${path}/free-pull-cable`} component={FreePullCables}/>
        <Route path={`${path}/cable`} component={Cables}/>
        <Route path={`${path}/penetration-list`} component={PenetrationList}/>
        <Route path={`${path}/cbs-foreman-status`} component={CbsForemanStatus}/>
        <Route path={`${path}/chart`} component={ChartView}/>
        <Route path={`${path}/connection`} component={ConnectionAdmin}/>
        <ProtectedRoute path={`${path}/system-status`}
                        claims={[
          ProjectClaims.project.engineering.coordinator,
          ProjectClaims.project.routing.coordinator,
          ProjectClaims.project.production.coordinator
        ]}>
          <SystemStatus/>
        </ProtectedRoute>
        <Route path={`${path}/project-status`} component={ProjectStatus}/>
        <Route path={`${path}/export-and-report`} component={ExportAndReport}/>
        <Route path={path} component={ProjectDashboard}/>
      </Switch>
    </MainPanel>
  );
};

const ProjectLoading = (): JSX.Element => {
  return (
    <MainPanel>
      <div className={styles.loadingContainer}>
        <Spinner/>
        <div className={styles.loadingText}>Loading project...</div>
      </div>
    </MainPanel>
  )
};

export default ProjectHomeBootstrap;
