import React, {CSSProperties, ReactNode} from "react";
import {Button, PropTypes} from "@material-ui/core";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import {useClaims} from "../../../shared/hooks/useClaims";

interface VectButtonProps extends AuthProps{
  autoFocus?: boolean;
  className?: string;
  color?: (PropTypes.Color & string) | PropTypes.Color;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  disabled?: boolean;
  onClick?: () => void;
  size?: "small" | "medium" | "large"
  showIfClaimMissing?: boolean
  startIcon?: ReactNode;
  style?: CSSProperties;
  to?: string;
  variant?: "text" | "outlined" | "contained"
}

export const VectButton: React.FC<VectButtonProps> = (
  {
    autoFocus,
    children,
    claim,
    claims,
    className,
    color,
    component,
    disabled = false,
    onClick,
    showIfClaimMissing = true,
    size,
    startIcon,
    to,
    style,
    useExactMatch,
    variant
  }): JSX.Element => {
  const{isValid} = useClaims();

  const readOnlyProps = {
    className,
    disabled,
    startIcon,
    color,
    size,
    variant
  }

  if(isValid({claim, claims, useExactMatch})) {
    const props = {
      ...readOnlyProps,
      autoFocus,
      onClick,
      component,
      to
    }
    return <Button style={style} {...props} >{children}</Button>
  } else if (showIfClaimMissing === true) {
    return <Button style={style} {...readOnlyProps} disabled>{children}</Button>
  } else {
    return <></>;
  }
}
