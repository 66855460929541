import React, {ElementType} from "react";
import {ListItem} from "@material-ui/core";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import {useClaims} from "../../../shared/hooks/useClaims";

interface VectListItemProps extends AuthProps {
  /**
   * Component used to render the list item
   */
  component?: ElementType;
  /**
   * For navigation list items, the destination route
   */
  to?: string;
}

export const VectListItem: React.FC<VectListItemProps> = ({
  children,
  claim,
  useExactMatch,
  component,
  to
}): JSX.Element => {
  const{hasClaim} = useClaims();
  const props = {
    component,
    to
  }

  return !claim || hasClaim(claim, useExactMatch)
    ? <ListItem button {...props}>{children}</ListItem>
    : <></>;

}
