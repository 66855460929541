import React, {FC} from "react";
import {CableClass} from "../../../shared/interfaces/cableClass.interface";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";

export const CableClassReadOnlyRowItem: FC<{cableClass: CableClass}> = ({
   cableClass,
 }) => {
  const cellClasses = tableCellStyles();

  return (
    <StyledTableRow >
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableClass.code}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText value={cableClass.name}/>
      </TableCellInput>
    </StyledTableRow>
  )
}
