import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: "10px 16px"
  },
  listWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8
    }
  }
}));
