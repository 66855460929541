import {ProblemDetailError} from "../hooks/useApi";

export interface Alert {
  type: AlertType,
  text: string,
  details?: string[],
  error?: ProblemDetailError | Error
}
export type AlertType = "info" | "error" | "success" | "warning" | undefined;


export const initialState: Alert = {
  type: undefined,
  text: ""
};

