import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProblemDetailError, useApi} from "../../../shared/hooks/useApi";
import Pagination from "../../shared/table/Pagination";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {initialPaginated, PaginatedResult, PaginationRequest,} from "../../../shared/interfaces/pagination.interface";
import {tableTheme,} from "../../shared/table/styles";
import {CableWithReferenceLabels} from "../../../shared/interfaces/cable.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import ConnectionAdminRowItem from "./components/ConnectionAdminRowItem";
import {CableConnectionFilter, CableConnectionProps} from "../../../shared/interfaces/cableConnectionFilter.interface";
import {getYearWeekFormat} from "../../../shared/helpers/date";
import {AlertContext} from "../../../shared/context/AlertContext";
import {useModals} from "../../../shared/context/ModalContext";
import {Modal} from "../../shared/Feedback/Modal";
import {Option, selectStyles, selectStylesWideMenu, VectSelect} from "../../shared/inputs/VectSelect";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {handleRowChange} from "../../../shared/helpers/table";
import {compare} from "fast-json-patch";
import {PatchRequest, PatchResponse} from "../../../shared/interfaces/patchRequest";
import {Skeleton} from "@material-ui/lab";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {makeStyles} from "@material-ui/core/styles";
import {footerStyles} from "../../../shared/styles/footerStyles";
import {VectContainer} from "../../shared/layout/VectContainer";
import {ProjectClaims} from "../../../shared/claims";
import {mapAreaForemanOptions, mapAreaOptions, mapSystemOptions, mapTagOptions} from "../../../shared/helpers/metadata";

const gridStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "12rem 12rem 12rem 20rem",
    gridTemplateRows: "repeat(6, auto)",
    columnGap: "20px",
    rowGap: "10px"
  },
  systemId: {
    gridColumnStart: 1,
    gridColumnEnd: "span 3",
    gridRowStart: 1
  },
  fromToTagId: {
    gridColumnStart: 1,
    gridColumnEnd: "span 3",
    gridRowStart: 2
  },
  reports: {
    gridColumnStart: 4,
    gridRowStart: 1,
    gridRowEnd: "span 3"
  },
  fromTagId: {
    gridColumnStart: 1,
    gridRowStart: 3
  },
  fromAreaId: {
    gridColumnStart: 1,
    gridRowStart: 4
  },
  from3dZoneId: {
    gridColumnStart: 1,
    gridRowStart: 5
  },
  fromAreaForemanId: {
    gridColumnStart: 1,
    gridRowStart: 6
  },
  toTagId: {
    gridColumnStart: 2,
    gridRowStart: 3
  },
  toAreaId: {
    gridColumnStart: 2,
    gridRowStart: 4
  },
  to3dZoneId: {
    gridColumnStart: 2,
    gridRowStart: 5
  },
  toAreaForemanId: {
    gridColumnStart: 2,
    gridRowStart: 6
  },
  yearWeek: {
    gridColumnStart: 3,
    gridRowStart: 6,
    alignSelf: "end"
  }
}));

const ConnectionAdmin: FunctionComponent = () => {
  // State
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [initialValues, setInitialValues] = useState<CableWithReferenceLabels[] | null>(null);
  const [week, setWeek] = useState<string>(getYearWeekFormat(new Date()));
  const [pdfExportChoice, setPdfExportChoice] = useState<number>(1);
  // Options
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [zone3ds, setZone3ds] = useState<Zone3d[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  // Filtering
  const [filter, setFilter] = useState<CableConnectionFilter>({});
  // Management
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshList, setRefreshList] = useState<boolean>(true);

  const grid = gridStyles();
  const footer = footerStyles();

  const {openModal, closeModal} = useModals();
  const {setAlert, setSuccess} = useContext(AlertContext);
  const {project} = useContext(ProjectContext);
  const {get, post, fileDownload} = useApi();

  const getPdf = async () => {
    if (
      filter.systemId === undefined &&
      filter.fromTagId === undefined &&
      filter.toTagId === undefined &&
      filter.fromAreaId === undefined &&
      filter.toAreaId === undefined &&
      filter.from3dZoneId === undefined &&
      filter.to3dZoneId === undefined &&
      filter.fromConnRespId === undefined &&
      filter.toConnRespId === undefined &&
      filter.fromToTagId === undefined
    ) {
      setAlert({
        type: "error",
        text: "Needs to set at least one filter item",
      });
    } else {
      await fileDownload(
        "Cable Report on cable CONNECTIONS.pdf",
        `/cable/${project?.id}/connections/pdf/${pdfExportChoice}`,
        {
          queryParams: {...filter},
        }
      );
    }
  };

  const excelExport = [
    {
      label: "Export data",
      action: async () => {
        await fileDownload(
          "qCableRegConnExpA.xlsx",
          `/cable/${project?.id}/exportAsExcel`
        );
      },
    },
  ];

  useEffect(() => {
    let isSubscribed = true;
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };

      (async () => {
        post<PaginationRequest, PaginatedResult>(
          `/project/${project?.id}/cable/connections`,
          paginationQuery,
          {},
          {...filter})
          .then(result => {
            if (isSubscribed) {
              setInitialValues(result.values);
              dispatch({
                type: "SET_STATE",
                payload: () => ({
                  ...result,
                  values: result.values.map((x: CableWithReferenceLabels) => ({
                    ...x,
                    isMarked: false
                  }))
                })
              });
            }
          });
      })();
    } finally {
      setIsLoading(false);
      setIsDirty(false)
    }
    return () => {
      isSubscribed = false
    };
  }, [
    filter.systemId,
    filter.fromTagId,
    filter.toTagId,
    filter.fromAreaId,
    filter.toAreaId,
    filter.from3dZoneId,
    filter.to3dZoneId,
    filter.fromConnRespId,
    filter.toConnRespId,
    filter.fromToTagId,
    refreshList,
    state?.pageSize,
    state?.currentPage,
  ]);

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      get<Tag[]>(`/project/${project?.id}/tag`)
        .then(res => {
          if (isSubscribed) {
            setTags(res);
          }
        });
      get<Area[]>(`/project/${project?.id}/area`)
        .then(res => {
          if (isSubscribed) {
            setAreas(res);
          }
        });
      get<Zone3d[]>(`/project/${project?.id}/zone3d`)
        .then(res => {
          if (isSubscribed) {
            setZone3ds(res);
          }
        });
      get<Areaforeman[]>(`/project/${project?.id}/areaForeman`)
        .then(res => {
          if (isSubscribed) {
            setForemen(res);
          }
        });
      get<SystemListItem[]>(`/project/${project?.id}/system`)
        .then(res => {
          if (isSubscribed) {
            setSystems(res);
          }
        })
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const systemOptions = useMemo((): Option[] | null => {

      return mapSystemOptions(systems, {
        withCustomLabel: x => {
          const systemForeman = x.areaForemanId && foremen?.find(f => f.id === x.areaForemanId);
          return systemForeman
            ? `${x.code} ${x.name}: ${systemForeman.name}`
            : `${x.code} ${x.name}`;

        },
        withShortLabel: false
      });
    }
  , [systems, foremen]);

  const areaOptions = useMemo((): Option[] | null => mapAreaOptions(areas, {withShortLabel: true}), [areas]);
  const foremanOptions = useMemo((): Option[] | null => mapAreaForemanOptions(foremen, {withShortLabel: true}), [foremen]);

  const zone3dOptions = useMemo((): Option[] | null =>
    zone3ds?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [zone3ds]);

  const tagOptions = useMemo((): Option[] | null => mapTagOptions(tags, {withShortLabel: true}), [tags]);
  const tagOptionsFromTo = useMemo((): Option[] | null => mapTagOptions(tags), [tags]);


  const update = async () => {
    const updates = state.values.filter(x => x.state === "modified");
    const patchedItems = updates.map((u: CableWithReferenceLabels) => {
      const initialItem = initialValues && initialValues.find(x => x.id === u.id);
      //Remove the web-only properties before comparing
      const operations = compare(initialItem!, u).filter(op => !["/isMarked", "/state", "/hasError"].includes(op.path));
      return {referenceId: u.id, operations};
    });

    const body = {
      patchedItems,
    };

    try {
      setIsLoading(true);
      const updateCableConnectionsResult = await post<PatchRequest<CableConnectionProps>, PatchResponse<CableWithReferenceLabels>>
      (`project/${project!.id}/cable`, body);
      refreshVisibleCableConnections(updateCableConnectionsResult);
      setSuccess();
      setIsDirty(false);
    } catch (error: unknown) {
      if (error instanceof ProblemDetailError || error instanceof Error) {
        setAlert({type: "error", text: "Something failed when updating the cable connection list", error});
      } else {
        setAlert({type: "error", text: "Something failed horribly when updating the cable connection list"});
      }
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const change = useCallback((cable: CableWithReferenceLabels) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues) => {
        const prevCable = prevValues.find(x => x.id === cable.id);
        if (cable.state !== "new") {
          if (prevCable.isConnectedFrom !== cable.isConnectedFrom) {
            if (cable.isConnectedFrom) {
              cable.connectedFromWeek = week;
            } else {
              cable.connectedFromWeek = undefined;
            }
          }
          if (prevCable.isConnectedTo !== cable.isConnectedTo) {
            if (cable.isConnectedTo) {
              cable.connectedToWeek = week;
            } else {
              cable.connectedToWeek = undefined;
            }
          }
        }

        return handleRowChange(
          prevValues,
          cable,
          setIsDirty);
      }
    });
  }, []);

  const updateAllConnected = async (type: string) => {
    await post(
      `/cable/${project?.id}/connections/batchUpdate/${type}/${week}`,
      {},
      {},
      {...filter}
    )
      .then((res) => {
        if (res !== undefined) {
          setRefreshList(!refreshList);
        }
      })
      .catch(() => {
        setAlert({
          type: "error",
          text: `Failed to update all ${type} in selection to connected.`,
        });
      });

    if (closeModal) {
      closeModal();
    }
  };

  /* Validators */

  const areDependenciesLoaded =
    areas != null &&
    foremen != null &&
    systems != null &&
    tags != null &&
    zone3ds != null;

  const hasErrors = state?.values == null
    ? false
    : state.values.findIndex(x => x.hasError === true && x.state !== "deleted") >= 0;

  /* Filters */

  const changeFilter = (value: number | null, field: string) => {
    const newFilter = {
      ...filter,
      ["fromToTagId"]: null,
      ["systemId"]: null,
      [field]: value,
    };
    setFilter(newFilter);
  };

  const changeConditionalFilter = (
    value: number | null,
    field: string
  ) => {
    let newFilter: CableConnectionFilter = {};
    switch (field) {
      case "systemId":
        newFilter = {
          systemId: value
        };
        break;

      case "fromToTagId":
        newFilter = {
          fromToTagId: value
        };
        break;

      case "fromTagId":
        newFilter = {
          ...filter,
          fromToTagId: null,
          systemId: null,
          fromTagId: value,
        };
        break;

      case "toTagId":
        newFilter = {
          ...filter,
          fromToTagId: null,
          systemId: null,
          toTagId: value,
        };
        break;

      default:
        break;
    }

    setFilter(newFilter);
  };

  const refreshVisibleCableConnections = (updates: PatchResponse<CableWithReferenceLabels>) => {
    dispatch({
      type: "SET_VALUES",
      payload: (prevValues: CableWithReferenceLabels[]) => {

        const newValues = prevValues.filter(x => x.state !== "deleted");
        updates.updatedItems.forEach(refresh => {
          const index = newValues.findIndex(x => x.id === refresh.referenceId);
          newValues[index] = {
            ...newValues[index],
            ...refresh.item,
            state: undefined
          }
        });
        setInitialValues(newValues);
        return newValues;
      }
    });
  }

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Connection Admin"}
        save={{
          action: update,
          disabled: hasErrors || !isDirty
        }}
        getPdf={{action: getPdf}}
        excelExports={excelExport}
      />

      {areDependenciesLoaded ? (
        <div className={grid.container}>
          <VectSelect
            className={grid.systemId}
            label="System ID"
            placeholder={"System ID"}
            isClearable={true}
            value={filter.systemId}
            change={value => {
              const action = () => {
                changeConditionalFilter(value, "systemId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={systemOptions}
            styles={{
              ...selectStyles,
              menu: (provided: any) => ({
                ...provided,
                width: "50rem"
              })
            }}
          />
          <VectSelect
            className={grid.fromToTagId}
            label="From / To Tag ID"
            placeholder={"From / To Tag ID"}
            isClearable={true}
            value={filter.fromToTagId}
            change={value => {
              const action = () => {
                changeConditionalFilter(value, "fromToTagId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={tagOptionsFromTo}
          />
          <div className={grid.reports}>
            <FormControl style={{width: "24rem"}}>
              <InputLabel id="pdf-export-picker">
                Type of reporting
              </InputLabel>
              <Select
                labelId="pdf-export-picker"
                id="demo-simple-select"
                value={pdfExportChoice}
                onChange={(e: ChangeEvent<{ value: unknown }>) =>
                  setPdfExportChoice(e.target.value as number)
                }
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            </FormControl>
            <ol style={{padding: "0 1em"}}>
              <li>Report ALL cables based on criteria</li>
              <li>
                Report ONLY cables based on criteria AND are not connected
                in From and To
              </li>
            </ol>
          </div>
          <VectSelect
            className={grid.fromTagId}
            label="From Tag ID"
            placeholder={"From Tag ID"}
            isClearable={true}
            value={filter.fromTagId}
            change={value => {
              const action = () => {
                changeConditionalFilter(value, "fromTagId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={tagOptions}
            styles={selectStylesWideMenu()}
          />
          <VectSelect
            className={grid.fromAreaId}
            label="From Area"
            placeholder={"From Area"}
            isClearable={true}
            value={filter.fromAreaId}
            change={value => {
              const action = () => {
                changeFilter(value, "fromAreaId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={areaOptions}
            styles={selectStylesWideMenu("30rem")}
          />
          <VectSelect
            className={grid.from3dZoneId}
            label="From 3D Zone"
            placeholder={"From 3D Zone"}
            isClearable={true}
            value={filter.from3dZoneId}
            change={value => {
              const action = () => {
                changeFilter(value, "from3dZoneId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={zone3dOptions}
            styles={selectStylesWideMenu()}
          />
          <VectSelect
            className={grid.fromAreaForemanId}
            label="From Conn. Resp."
            placeholder={"From Conn. Resp."}
            isClearable={true}
            value={filter.fromConnRespId}
            change={value => {
              const action = () => {
                changeFilter(value, "fromConnRespId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={foremanOptions}
            styles={selectStylesWideMenu("30rem")}
          />
          <VectSelect
            className={grid.toTagId}
            label="To Tag ID"
            placeholder={"To Tag ID"}
            isClearable={true}
            value={filter.toTagId}
            change={value => {
              const action = () => {
                changeConditionalFilter(value, "toTagId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={tagOptions}
            styles={selectStylesWideMenu()}
          />
          <VectSelect
            className={grid.toAreaId}
            label="To Area"
            placeholder={"To Area"}
            isClearable={true}
            value={filter.toAreaId}
            change={value => {
              const action = () => {
                changeFilter(value, "toAreaId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={areaOptions}
            styles={selectStylesWideMenu("30rem")}
          />
          <VectSelect
            className={grid.to3dZoneId}
            label="To 3D Zone"
            placeholder={"To 3D Zone"}
            isClearable={true}
            value={filter.to3dZoneId}
            change={value => {
              const action = () => {
                changeFilter(value, "to3dZoneId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={zone3dOptions}
            styles={selectStylesWideMenu()}
          />
          <VectSelect
            className={grid.toAreaForemanId}
            label="To Conn. Resp."
            placeholder={"To Conn. Resp."}
            isClearable={true}
            value={filter.toConnRespId}
            change={value => {
              const action = () => {
                changeFilter(value, "toConnRespId")
              };
              promptIfUnsavedChanges(openModal, isDirty, action);
            }}
            options={foremanOptions}
            styles={selectStylesWideMenu("30rem")}
          />
          <VectTextField
            className={grid.yearWeek}
            value={week}
            change={setWeek}
          />
        </div>
      ) : <Skeleton variant={"rect"} width={"40rem"} height={"5rem"}/>}

      {/* Tag Table Headers */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Cable ID <br/>
              Cable Type
            </TableCell>
            <TableCell>
              From Tag ID <br/>
              To Tag ID
            </TableCell>
            <TableCell>
              From Text <br/>
              To Text
            </TableCell>
            <TableCell>
              Conn. Resp. From <br/>
              Conn. Resp. To
            </TableCell>
            <TableCell>
              From Area <br/>
              To Area
            </TableCell>
            <TableCell>
              From 3D Zone <br/>
              To 3D Zone
            </TableCell>
            <TableCell>
              Conn. From <br/>
              Conn. To
            </TableCell>
            {/* Extra actions */}
            <TableCell/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state.values.map((item: CableWithReferenceLabels, index: number) => (
                <ConnectionAdminRowItem
                  key={index}
                  cable={item}
                  change={change}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch}/>
      {areDependenciesLoaded ? (
        <div className={footer.wrapper}>
          <div className={footer.container}/>
          <div className={footer.container}>
            <VectContainer claim={ProjectClaims.project.production.external} className={footer.controls}>
              <Button
                style={{width: "100%", marginBottom: "1em"}}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (openModal) {
                    openModal(Modal, {
                      title: "Warning",
                      description: "Are you sure you wish to update ALL from in selection to connected?",
                      ok: () => updateAllConnected("from"),
                      okText: "Yes",
                      cancelText: "No"
                    })
                  }
                }}
              >
                Update all FROM in selection to Connected
              </Button>
              <Button
                style={{width: "100%"}}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (openModal) {
                    openModal(Modal, {
                      title: "Warning",
                      description: "Are you sure you wish to update ALL to in selection to connected?",
                      ok: () => updateAllConnected("to"),
                      okText: "Yes",
                      cancelText: "No"
                    })
                  }
                }}
              >
                Update all To in selection to Connected
              </Button>
            </VectContainer>
          </div>

        </div>
      ) : (
        <Skeleton variant={"rect"} width={"40rem"} height={"5rem"}/>
      )}
    </>
  );
};

export default ConnectionAdmin;
