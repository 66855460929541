import React, {FC, useContext, useEffect, useMemo, useReducer, useState,} from "react";
import {initialPaginated, PaginatedResult, PaginationRequest} from "../../../shared/interfaces/pagination.interface";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {useApi} from "../../../shared/hooks/useApi";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {BundleFilter} from "../bundle-register/BundleRegister";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Bundle, Choice} from "../../../shared/interfaces/bundle.interface";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {tableTheme} from "../../shared/table/styles";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import Pagination from "../../shared/table/Pagination";
import BundleStatusRowItem from "./BundleStatusRowItem";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {useModals} from "../../../shared/context/ModalContext";
import {useStyles} from "../tag/Tags.styles";
import {mapAreaForemanOptions, mapSystemOptions} from "../../../shared/helpers/metadata";

const selectOpts: Option[] = [
  {
    label: "Yes",
    value: Choice.YES,
  }, {
    label: "No",
    value: Choice.NO
  }
]

export const prodStatAutoComplete: Option[] = [
  {
    label: "Draft - not ready for production",
    value: 1,
  },
  {
    label: "Ready - but on HOLD",
    value: 2,
  },
  {
    label: "Ready for measuring",
    value: 3,
  },
  {
    label: "Measured - ready for checkout",
    value: 4,
  },
  {
    label: "Checked - ready for production",
    value: 5,
  }
];

const BundleStatus: FC = () => {
  // State
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Supporting data
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  // Filtering
  const [bundleId, setBundleId] = useState<string>("");
  const [selectedSystem, setSelectedSystem] = useState<SystemListItem | null>(null);
  const [selectedForeman, setSelectedForeman] = useState<Areaforeman | null>(null);
  const [reportedCut, setReportedCut] = useState<Option | null>(null);
  const [reportedFixed, setReportedFixed] = useState<Option | null>(null);
  const [bundleStatus, setBundleStatus] = useState<Option | null>(null);

  const classes = useStyles();
  const {openModal} = useModals();
  const {project} = useContext(ProjectContext);
  const {get, fileDownload} = useApi();
  const {post} = useApi();

  const getPdf = async () => {
    await fileDownload("Bundles.pdf", `/bundle/${project?.id}/pdf`, {queryParams: {
        bundleCode: bundleId,
        foremanId: selectedForeman?.id,
        bundleStatus: bundleStatus?.value,
        systemId: selectedSystem?.id,
        cableProductionResponsibleId: undefined,
        srtpRelated: undefined,
        cut: reportedCut?.label,
        pulled: reportedFixed?.label
      }});
  }

  const excelExport = [
    {
      label: "Export data",
      action: async () => {
        await fileDownload("q2_BundleProd.xlsx", `/bundle/${project?.id}/exportAsExcel`, {queryParams: {
            bundleCode: bundleId,
            foremanId: selectedForeman?.id,
            bundleStatus: bundleStatus?.value,
            systemId: selectedSystem?.id,
            cableProductionResponsibleId: undefined,
            srtpRelated: undefined,
            cut: reportedCut?.label,
            pulled: reportedFixed?.label
          }})
      }
    }
  ]

  useEffect(() => {
    let isSubscribed = true;

    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize
      }

      // Filter
      const filter: BundleFilter = {
        bundleCode: bundleId,
        foremanId: selectedForeman?.id,
        bundleStatus: bundleStatus?.value,
        systemId: selectedSystem?.id,
        cableProductionResponsibleId: undefined,
        srtpRelated: undefined,
        cut: reportedCut?.label,
        pulled: reportedFixed?.label
      }

      post<PaginationRequest, PaginatedResult>(
        `/project/${project?.id}/bundle/list`,
        paginationQuery,
        {},
        {...filter})
        .then(result => {
          if (isSubscribed) {
            dispatch({
              type: "SET_STATE",
              payload: () => ({
                ...result,
                values: result.values.map((x: Bundle) => ({
                  ...x,
                  isMarked: false
                }))
              })
            });
          }
        });
    } finally {
      setIsLoading(false);
      setIsDirty(false)
    }
    return () => {
      isSubscribed = false
    };
  }, [
    state.pageSize,
    state.currentPage,
    reportedFixed,
    reportedCut,
    bundleStatus,
    selectedForeman,
    bundleId,
    selectedSystem]);

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const foremanResult = await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`);
      if (isSubscribed) {
        setForemen(foremanResult)
      }

      const systemsResult = await get<SystemListItem[]>(`/project/${project?.id}/system`);
      if (isSubscribed) {
        setSystems(systemsResult)
      }
    })();
    return () => {
      isSubscribed = false
    };
  }, [])

  /* Filters */

  const onBundleIdChange = (value: string) => {
    promptIfUnsavedChanges(openModal, isDirty, () => setBundleId(value));
  }

  const systemFilter = useMemo(() => {
    const options = mapSystemOptions(systems);
    return (
      <VectSelect
        id={"systemIdFilter"}
        placeholder={"System ID"}
        value={selectedSystem?.id}
        change={value => {
          const action = () => {
            const system = systems?.find(x => x.id === value);
            if (system) {
              setSelectedSystem(system);
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [systems, selectedSystem, isDirty]);

  const areaForemanFilter = useMemo(() => {
    const options = mapAreaForemanOptions(foremen);
    return (
      <VectSelect
        id={"foremanIdFilter"}
        placeholder={"Resp Foreman"}
        value={selectedForeman?.id}
        change={value => {
          const action = () => {
            const foreman = foremen?.find(x => x.id === value);
            if (foreman) {
              setSelectedForeman(foreman);
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [foremen, selectedForeman, isDirty]);

  const reportedCutFilter = useMemo(() => {
    return (
      <VectSelect
        placeholder={"Reported Cut"}
        value={reportedCut?.value}
        change={value => {
          const action = () => {
            const choice = selectOpts?.find(x => x.value === value);
            if (choice) {
              setReportedCut(choice);
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={selectOpts}
      />
    );
  }, [reportedCut, isDirty]);

  const reportedFixedFilter = useMemo(() => {
    return (
      <VectSelect
        placeholder={"Reported Fixed"}
        value={reportedFixed?.value}
        change={value => {
          const action = () => {
            const choice = selectOpts?.find(x => x.value === value);
            if (choice) {
              setReportedFixed(choice);
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={selectOpts}
      />
    );
  }, [reportedFixed, isDirty]);

  const bundleStatusFilter = useMemo(() => {
    return (
      <VectSelect
        placeholder={"Bundle status"}
        value={bundleStatus?.value}
        change={value => {
          const action = () => {
            const choice = prodStatAutoComplete?.find(x => x.value === value);
            if (choice) {
              setBundleStatus(choice);
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={prodStatAutoComplete}
      />
    );
  }, [bundleStatus, isDirty]);

  return (
    <>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader text={"Bundle Status"} getPdf={{action: getPdf}}
                   excelExports={excelExport}/>

      {/* Filter */}
      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div className={classes.filterItem}>
            <VectTextField
              value={bundleId}
              change={onBundleIdChange}
              label={"Bundle ID"}
              debounceDelay={1000}
            />
          </div>
          <div className={classes.filterItem}>
            {areaForemanFilter}
          </div>
          <div className={classes.filterItem}>
            {bundleStatusFilter}
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div className={classes.filterItem}>
            {systemFilter}
          </div>
          <div className={classes.filterItem}>
            {reportedCutFilter}
          </div>
          <div className={classes.filterItem}>
            {reportedFixedFilter}
          </div>
        </div>
      </div>

      {/* Systems Table Headers */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Bundle ID</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Production Status</TableCell>
            <TableCell>Released</TableCell>
            <TableCell>Prt. Bundle</TableCell>
            <TableCell>Prt. Label</TableCell>
            <TableCell>Cut</TableCell>
            <TableCell>Fixed</TableCell>
            <TableCell>Prod. Resp.</TableCell>
            <TableCell>Text</TableCell>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map(bundle => (
                <BundleStatusRowItem
                  key={bundle.id}
                  bundle={bundle}
                />
              ))}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch}/>
    </>
  );
}
export default BundleStatus;
