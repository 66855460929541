import React, {FunctionComponent, useCallback, useContext, useEffect, useState,} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useModals} from "../../../../shared/context/ModalContext";
import {useApi} from "../../../../shared/hooks/useApi";
import {SystemListItem} from "../../../../shared/interfaces/system.interface";
import {ProjectUserContext} from "../../../../shared/interfaces/project.interface";
import {AlertContext} from "../../../../shared/context/AlertContext";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {FileInput} from "../../../shared/inputs/FileInput";
import {tableTheme} from "../../../shared/table/styles";
import {LoadingIndicator} from "../../../shared/table/LoadingIndicator";
import {Alert} from "@material-ui/lab";
import {CableImportRowItem, CableListValidationResult} from "./CableImportRowItem";
import {CableProps} from "../../../../shared/interfaces/cable.interface";
import {useClaims} from "../../../../shared/hooks/useClaims";
import {ProjectClaims} from "../../../../shared/claims";
import {DialogActionsFinished} from "../../../shared/import-modal/DialogActionsFinished";
import {DialogActionsReady} from "../../../shared/import-modal/DialogActionsReady";


export const CableImportModal: FunctionComponent<{
  project: ProjectUserContext,
  system: SystemListItem,
  onClosed: () => void,
  open?: boolean
}> = ({
  project,
  system,
  onClosed,
}) => {
  const [requiresAdditionalPermissions, setRequiresAdditionalPermissions] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [preview, setPreview] = useState<CableListValidationResult | null>(null);
  const {setError, setSuccess} = useContext(AlertContext);
  const { closeModal } = useModals();
  const { post, postFormData } = useApi();
  const hasRoutingExternal = useClaims().isValid({claim: ProjectClaims.project.routing.external});

  useEffect(() => {
    const isValid = preview != null && preview.cables && preview.cables.length > 0 && preview.errorCount === 0;
    setIsValid(isValid);
    if(preview != null) {
      // Check if permissions in addition to engineering external is needed.
      if(preview.cables.filter(x => parseInt(x.meters.value) > 0).length > 0){
        if(!hasRoutingExternal) {
          setRequiresAdditionalPermissions(true);
        }
      } else if(preview.cables.filter(x => x.bundleCode.value !== "000").length > 0) {
        if(!hasRoutingExternal) {
          setRequiresAdditionalPermissions(true);
        }
      }
    } else {
      setRequiresAdditionalPermissions(false);
    }

  }, [preview])

  const onCancel = useCallback(() => {
    closeModal && closeModal();
  }, [closeModal]);

  const onClose = useCallback(() => {
    onClosed();
    closeModal && closeModal();
  }, [closeModal]);

  const onFileChange = useCallback(async (file: File) => {
    const formData = new FormData();
    formData.append("cableList", file, file.name);
    try {
      setIsValidating(true);
      setPreview(null);
      const result = await postFormData<CableListValidationResult>(`project/${project.id}/system/${system.id}/cables/import-template/process-excel-file`, formData);
      setPreview(result);
    } catch(err) {
      setError(err);
    } finally {
      setIsValidating(false);
    }
  }, []);

  const importCables = useCallback(async () => {
    if(preview == null){
      return;
    }

    try {
      const body: {cables: CableProps[]} = {
        cables: preview.cables.map(x => ({
          code: x.code.value,
          cableNumber: x.cableNumber,
          bundleId: x.bundleId,
          estimatedMeters: 0,
          fromAreaId: x.fromAreaId,
          fromText: x.fromText,
          fromConnectionAreaForemanId: x.fromConnectionAreaForemanId,
          fromTagId: x.fromTagId,
          fromZone3dId: x.fromZone3dId,
          fromMainVerticalZoneId: x.fromMainVerticalZoneId,
          toAreaId: x.toAreaId,
          toText: x.toText,
          toConnectionAreaForemanId: x.toConnectionAreaForemanId,
          toTagId: x.toTagId,
          toZone3dId: x.toZone3dId,
          toMainVerticalZoneId: x.toMainVerticalZoneId,
          meters: parseInt(x.meters.value),
          systemId: x.systemId,
          cableTypeId: x.cableTypeId,
          isConnectedFrom: false,
          isConnectedTo: false,
          hasSpecialRequirements: false
        }))
      }

      await post(`project/${project.id}/system/${system.id}/cables/import`, body);
      setIsFinished(true);
      const count = body.cables.length;
      const successDetails = [
        `${count} ${(count === 1 ? "cable" : "cables")} was successfully imported.`,
        "You may close this dialog now."
      ]
      setSuccess(successDetails);
    } catch(err) {
      setError(err);
    }
  }, [preview])

  return (
    <div>
      <Dialog
        open={true}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          {`Import cables to ${system.code} ${system.name}`}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0}}>
          <FileInput fileChange={onFileChange} accept=".xlsx" />
          <hr/>
          {preview && preview.errorCount > 0 && (
            <Alert severity="error" style={{margin: "1rem 0 1rem 0"}}>
              {`This cable list has ${preview.errorCount} ${(preview.errorCount === 1 ? "error" : "errors")}.`}
            </Alert>
          )}
          {
            requiresAdditionalPermissions && (
              <Alert severity="warning" style={{margin: "1rem 0 1rem 0"}}>
                {`The ${ProjectClaims.project.routing.external} permission is required when importing cables with meters set or a different bundle ID than default "000"`}
              </Alert>
            )
          }
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cable ID</TableCell>
                <TableCell>Cable Type ID</TableCell>
                <TableCell>From Tag ID</TableCell>
                <TableCell>From text</TableCell>
                <TableCell>To Tag ID</TableCell>
                <TableCell>To Text</TableCell>
                <TableCell>Meter</TableCell>
                <TableCell>Bundle ID</TableCell>
              </TableRow>
            </TableHead>
            <MuiThemeProvider theme={tableTheme}>
              <TableBody>
                <LoadingIndicator isLoading={isValidating} columns={8} useTableCells>
                  {preview
                    ? preview.cables.map(x => <CableImportRowItem {...x}/>)
                    : null}
                </LoadingIndicator>
              </TableBody>
            </MuiThemeProvider>
          </Table>
        </DialogContent>
        {isFinished
          ? <DialogActionsFinished close={onClose}/>
          : <DialogActionsReady importData={importCables} importLabel="Import cables" enableImport={isValid && !requiresAdditionalPermissions} close={onCancel}/>}
      </Dialog>
    </div>
  );
};


