import React, {FC, useEffect, useState} from "react";
import {ProjectListItem} from "../../../../shared/interfaces/project.interface";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {useApi} from "../../../../shared/hooks/useApi";
import FolderIcon from "@material-ui/icons/Folder";
import {NavLink, useHistory} from "react-router-dom";
import {useStyles} from "../../../projects/Projects/Projects.styles";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import {CommonPaths} from "../../../../shared/urls";
import {GlobalClaims} from "../../../../shared/claims";
import {useClaims} from "../../../../shared/hooks/useClaims";
import {deletedProjectMessage} from "../EditProject/DeletedProject";

const AllProjects: FC = () => {

  const classes = useStyles();
  const [projects, setProjects] = useState([] as ProjectListItem[]);
  const history = useHistory();
  const {get} = useApi();
  const {isValid} = useClaims();

  useEffect(() => {
    let isSubscribed = true
    get<ProjectListItem[]>("/project", {queryParams: {isDeleted: true}})
      .then(data => {
        if(isSubscribed) {
          setProjects(data)
        }
      });
    return () => {
      isSubscribed = false
    };
  }, [])

  const addProjectAction = {
    action: () => history.push(CommonPaths.manage.projects.newProject),
    disabled: !isValid({claim: GlobalClaims.manage.project.readWrite.all}),
    label: "Create a new project"
  };

  const returnListItem = (project: ProjectListItem) => {
    return (
      <ListItem key={project.id}>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <NavLink color="primary"
                     className={classes.link}
                     to={`/manage/projects/edit/${project.codeUrlEncoded}`}>
              <Typography variant="h6"><Box color="text.primary">{project.code} - {project.name}</Box></Typography>
              {project.isDeleted && <Typography variant="caption">{deletedProjectMessage}</Typography> }
            </NavLink>
          }

        />
      </ListItem>
    )
  }


  return (
    <div>
      <PanelHeader text="All projects" add={addProjectAction}/>
      <List className={classes.listWrapper}>

      {projects.map(project => (
        returnListItem(project)
      ))}
      </List>
    </div>
  )
}

export default AllProjects;
