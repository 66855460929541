import React, {useEffect, useState} from "react";
import PanelHeader, {Action} from "../../../shared/layout/PanelHeader/PanelHeader";
import {useHistory, useParams} from "react-router-dom";
import {CommonPaths} from "../../../../shared/urls";
import {Project} from "../../../../shared/interfaces/project.interface";
import {useApi} from "../../../../shared/hooks/useApi";
import {Skeleton} from "@material-ui/lab";
import {MuiThemeProvider, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {formatDate} from "../../../../shared/helpers/date";
import {StyledTableRow, tableTheme} from "../../../shared/table/styles";
import {VectTableReadOnlyText} from "../../../shared/inputs/VectTableReadOnlyText";
import {PenetrationImportLogItem, PenetrationImportLogResult, useStyles} from "./support";


export const ImportLogPenetrations: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [penetrationLog, setPenetrationLog] = useState<PenetrationImportLogResult[] | null>(null)
  const {get} = useApi();
  const {projectCode} = useParams<{ projectCode: string }>();
  const history = useHistory();

  useEffect(() => {
    if(projectCode == null) {
      return;
    }

    let isSubscribed = true;
    (async () => {
      const projectResult = await get<Project>(`project/${projectCode}`);
      const penetrationLogResult = await get<PenetrationImportLogResult[]>(`manage/project/${projectResult.id}/log/penetration-import`);
      if(isSubscribed) {
        setProject(projectResult);
        setPenetrationLog(penetrationLogResult);
      }
    })();

    return () =>  {
      isSubscribed = false;
    };
  }, [projectCode]);

  const onClose:Action = {
    action: () => history.push(CommonPaths.manage.projects.edit(projectCode))
  }
  return (
    <div>
      {
        project != null
          ? <PanelHeader text={`Penetration import log for ${project.code || ""} ${project.name || ""}`} close={onClose}/>
          : <Skeleton height="1.5rem" width="15rem"/>
      }
      {
        penetrationLog && penetrationLog.map(x => <PenetrationLogTable key={x.id} {...x}/>)
      }

    </div>
  )
}

const PenetrationLogTable: React.FC<PenetrationImportLogResult> = ({
   createdAt,
   userName,
   userEmail,
   id,
   penetrations
 }) => {
  const classNames = useStyles();
  return (
    <Paper className={classNames.table}>
      <Typography variant="h6">{formatDate(createdAt, true)}</Typography>
      <Typography variant="subtitle1">{`By ${userName} (${userEmail})`}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Penetration ID</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Orientation</TableCell>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            {penetrations.map(x => <PenetrationLogTableRow key={`${id}${x.code}`} {...x}/>)}
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </Paper>
  )
}

const PenetrationLogTableRow: React.FC<PenetrationImportLogItem> = ({
  code,
  name,
  orientation
}) => {

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={code}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={name}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={orientation}/>
      </TableCell>
    </StyledTableRow>
  )
}
