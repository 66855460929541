import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import AllProjects from "./AllProjects/AllProjects";
import NewProject from "./NewProject/NewProject";
import MainPanel from "../../shared/layout/MainPanel/MainPanel";
import VectAppBar, {ApplicationLink} from "../../shared/layout/VectAppBar";
import EditProjectPage from "./EditProject/EditProjectPage";
import {ImportLogCables} from "./EditProject/ImportLogCables";
import {ImportLogPenetrations} from "./EditProject/ImportLogPenetrations";
import {ImportLogAreas} from "./EditProject/ImportLogAreas";

interface ManageProjectsHomeProps {
  navLinks: ApplicationLink[]
}

const ManageProjectsHome: React.FC<ManageProjectsHomeProps> = ({navLinks}): JSX.Element => {

  const {path} = useRouteMatch();

  return (
    <VectAppBar header="Manage projects" navLinks={navLinks}>
      <MainPanel>
        <Switch>
          <Route path={`${path}/edit/:projectCode/area-import`} component={ImportLogAreas} />
          <Route path={`${path}/edit/:projectCode/cable-import`} component={ImportLogCables} />
          <Route path={`${path}/edit/:projectCode/penetration-import`} component={ImportLogPenetrations} />
          <Route path={`${path}/edit/:projectCode`} component={EditProjectPage}/>
          <Route path={`${path}/all-projects`} component={AllProjects}/>
          <Route path={`${path}/new-project`} component={NewProject}/>
          <Route path={path}>
            <Redirect to={`${path}/all-projects`}/>
          </Route>
        </Switch>
      </MainPanel>
    </VectAppBar>
  );
}

export default ManageProjectsHome;
