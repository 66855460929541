import React, {useEffect, useState} from "react";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Skeleton} from "@material-ui/lab";
import {DoubleArrow, NavigateBefore, NavigateNext} from "@material-ui/icons";

export interface SelectListItem<T> {
  id: T
  /**
   * Primary text
   */
  primary: string;
  /**
   * Secondary text
   */
  secondary?: string;
}
export interface DualSelectProps<T> {
  /**
   * Available items
   */
  availableItems?: SelectListItem<T>[];
  /**
   * Filter the unassigned items by primary or secondary text
   */
  filter?: string;
  /**
   * Pre-assigned items
   */
  preAssigned?: T[];

  titleAssignedItems?: string;
  titleUnassignedItems?: string;
}
export interface DualSelectListProps<T> extends DualSelectProps<T>{

  /**
   * Returns a list of assigned item ids when the lists change
   */
  onChange: (itemIds: T[]) => void;

}

export const styles = makeStyles(() => ({
  assignUsersContainer: {
    display: "flex"
  },
  transferButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "1rem"
  }
}));

export const listStyles = makeStyles(() => ({
  paper: {
    height: "40rem",
    overflow: "auto",
    minWidth: "30rem"
  }
}));

export const DualSelectList = <T extends (number | string)>({
  availableItems,
  filter,
  preAssigned,
  onChange,
  titleAssignedItems,
  titleUnassignedItems
  }: DualSelectListProps<T>) => {
  const classNames = styles();
  const [assigned, setAssigned] = useState<T[]>([]);
  const [unassigned, setUnassigned] = useState<T[]>([]);
  const [checked, setChecked] = useState<T[]>([]);

  useEffect(() => {
    if(preAssigned) {
      setAssigned(preAssigned);
    }
  }, [preAssigned]);

  useEffect(() => {
    if(availableItems && assigned) {
      setUnassigned(not(availableItems.map(x => x.id), assigned));
    }
  }, [availableItems, assigned]);

  /**
   * Helper method to transfer items between assigned and unassigned
   * @param a Items from a
   * @param b not in b
   */
  const not = (a: T[], b: T[]): T[] => {
    return a.filter(x => b.indexOf(x) === -1);
  }

  /**
   * Handle changes when checkboxes in the transfer list are toggled.
   */
  const handleToggle = (item: SelectListItem<T>) => {
    const currentIndex = checked.indexOf(item.id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(item.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  /**
   * Helper method to determine checked items are in the list
   * @param a List of checked item ids
   * @param b List of item ids
   */
  const intersection = (a: T[], b: T[]) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const unassignedChecked = intersection(checked, unassigned);
  const assignedChecked = intersection(checked, assigned);

  const handleCheckedRight = () => {
    const newAssigned = assigned.concat(unassignedChecked)
    setAssigned(newAssigned);
    setUnassigned(not(unassigned, unassignedChecked));
    setChecked(not(checked, unassignedChecked));
    onChange(newAssigned);
  };
  const handleCheckedLeft = () => {
    const newAssigned = not(assigned, assignedChecked);
    setUnassigned(unassigned.concat(assignedChecked));
    setAssigned(newAssigned);
    setChecked(not(checked, assignedChecked));
    onChange(newAssigned)
  };
  const handleAllLeft = () => {
    const newAssigned: T[] = [];
    setUnassigned((availableItems || []).map(x => x.id));
    setAssigned(newAssigned);
    onChange(newAssigned);

  };

  return (
    <div className={classNames.assignUsersContainer}>
      {
        !availableItems
        ? <Skeleton variant="rect" width="20rem" height="15rem" />
        : <SelectList items={availableItems.filter(x => unassigned.indexOf(x.id) !== -1)}
                      title={titleUnassignedItems}
                      filter={filter}
                      handleToggle={handleToggle}
                      checked={checked}/>
      }
      <div className={classNames.transferButtonsContainer}>
        <IconButton
          onClick={handleCheckedRight}
          disabled={unassignedChecked.length === 0}
          aria-label="move selected right">
          <NavigateNext/>
        </IconButton>
        <IconButton
          onClick={handleCheckedLeft}
          disabled={assignedChecked.length === 0}
          aria-label="move selected left"
        >
          <NavigateBefore/>
        </IconButton>
        <IconButton
          onClick={handleAllLeft}
          disabled={assigned.length === 0}
          aria-label="move all left"
        >
          <DoubleArrow style={{transform: "rotate(180deg)"}}/>
        </IconButton>
      </div>
      {
        !availableItems
          ? <Skeleton variant="rect" width="20rem" height="10rem" />
          : <SelectList items={availableItems.filter(x => assigned.indexOf(x.id) !== -1)}
                        title={titleAssignedItems}
                        handleToggle={handleToggle}
                        checked={checked}/>
      }
    </div>
  );

}
export interface SelectListProps<T>{
  checked: T[];
  filter?: string;
  handleToggle: (item: SelectListItem<T>) => void;
  items: SelectListItem<T>[];
  title?: string;
}

export const SelectList = <T extends (number | string)>({
  checked,
  items,
  filter,
  handleToggle,
  title
}: SelectListProps<T>) => {
  const classNames = listStyles();
  const displayedItems = filter && filter.length > 0
    ? items.filter(x =>
      x.primary.toLowerCase().includes(filter.toLowerCase()) ||
      (x.secondary && x.secondary.toLowerCase().includes(filter.toLowerCase())))
    : items;
  return (
    <Paper elevation={2} className={classNames.paper}>
      <List dense component="div" role="list">
        {title && <ListSubheader>{title}</ListSubheader>}
        {
          displayedItems.map(item => {
            const labelId = `transfer-list-item-${item.id}-label`;
            return (
              <ListItem key={item.id} role="listitem"
                        button
                        onClick={() => handleToggle(item)}>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={checked.indexOf(item.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={item.primary}
                  secondary={item.secondary}
                />
              </ListItem>
            );
          })
        }
        <ListItem/>
      </List>
    </Paper>
  )
};
