import React from "react";

export const Logo: React.FC = (): JSX.Element => {
  return (
    <svg width="105" height="25" viewBox="0 0 136 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.954 0C44.7797 0 43.4547 1.17188 42.1114 3.6111L28.2251 28.7668H35.556L46.9586 7.63581L55.2498 23.4377H44.5073C43.0116 23.4377 42.1852 23.8566 41.2896 25.511L39.5354 28.7668H65.0089L51.8059 3.6111C50.5733 1.26203 49.0637 0 46.954 0Z" fill="#D20000"/>
      <path d="M29.6745 0.466797L18.5027 21.5821L7.33092 0.466797H0L13.9001 25.6493C15.1881 27.9772 16.4438 29.2551 18.5027 29.2551C20.6078 29.2551 21.8173 27.9719 23.1007 25.6493L37.0054 0.466797H29.6745Z" fill="#D20000"/>
      <path d="M91.1334 19.0791C94.7574 18.1512 97.1394 14.7469 97.1394 10.3457C97.1394 2.2644 91.5258 0.466797 86.517 0.466797H70.6318C67.945 0.466797 67.3818 2.57726 67.3818 4.19987V28.7671H73.1432V20.3412H84.9705L89.4715 26.9695C90.3763 28.2421 91.3227 28.7671 92.7492 28.7671H98.5244L94.4711 23.2682C92.9569 21.2797 91.8905 19.795 91.1334 19.0791ZM87.4264 14.715H73.1432V7.62539C73.1432 7.11633 73.374 6.47471 74.1403 6.47471H87.4218C89.7577 6.47471 91.1796 8.18217 91.1796 10.6691C91.1842 12.8644 89.5362 14.715 87.4264 14.715Z" fill="#D20000"/>
      <path d="M117.59 0.466797H105.112C102.42 0.466797 101.862 2.57726 101.862 4.19988V25.0394C101.862 26.662 102.42 28.7725 105.112 28.7725H117.59C125.757 28.7725 130.858 22.7857 130.858 14.6196C130.858 6.45351 125.789 0.466797 117.59 0.466797ZM118.647 22.7539H108.606C107.896 22.7274 107.665 22.1282 107.655 21.6351V7.59889C107.665 7.10574 107.896 6.50654 108.606 6.48003H118.647C122.331 6.48003 125.161 9.95328 125.161 14.6196C125.161 19.2807 122.331 22.7539 118.647 22.7539Z" fill="#D20000"/>
      <path d="M131.103 4.92505V1.77527H130.147V1.12305H132.631V1.77527H131.684V4.92505H131.103ZM135.405 4.92505V2.96837L134.731 4.92505H134.283L133.609 2.96837V4.92505H133.041V1.12305H133.586L134.519 3.83801L135.451 1.12305H135.982V4.92505H135.405Z" fill="#D20000"/>
    </svg>
  )
}
