import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import {AlertContext} from "../../../shared/context/AlertContext";
import {CommonPaths} from "../../../shared/urls";
import {GrantResult} from "../../../shared/interfaces/user.interface";
import {RoleForm, RoleFormFields} from "./RoleForm";
import {Scalar} from "../../../shared/interfaces/scalar";
import {useApi} from "../../../shared/hooks/useApi";
import {useHistory} from "react-router-dom";

const NewProjectRole: FC = () => {
  const [availableGrants, setAvailableGrants] = useState<GrantResult[]>([]);
  const [fields, setFields] = useState<RoleFormFields | null>(null);
  const history = useHistory();
  const {get, post} = useApi()
  const {setAlert, setError} = useContext(AlertContext);

  useEffect(() => {
    let isSubScribed = true;

    get<GrantResult[]>("roles/project-grants")
      .then(res => {
        if (isSubScribed) {
          setAvailableGrants(res);
        }
      });

    return () => {
      isSubScribed = false
    };
  }, []);

  const onChange = (changes: RoleFormFields) => {
    setFields(changes);
  };

  const submit = async () => {
    if(fields){
      await post<RoleFormFields, Scalar<number>>(`roles/project`, fields)
        .then(res => {
          setAlert({
            type: "info",
            text: `${fields?.roleName} was created.`
          });
          history.push({pathname: CommonPaths.manage.users.editProjectRole(res.value)});
        })
        .catch(setError);
    }
  };

  const form = useMemo(() => <RoleForm
      availableGrants={availableGrants}
      initialFields={{
        roleName: "",
        grants: []
      }}
      onChange={onChange}/>,
    [availableGrants]);

  return (
    <div>
      <PanelHeader text="New project role"
                   save={
                     {
                       action: submit,
                       disabled: !(fields && fields.roleName)
                     }
                   }/>
      {form}
    </div>
  );
};


export default NewProjectRole;
