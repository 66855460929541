import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import React, {FC, useContext, useEffect, useState} from 'react';
import {Box, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {CommonPaths} from "../../../shared/urls";
import {GlobalClaims} from "../../../shared/claims";
import {GlobalRole, ProjectRole, RoleResult} from "../../../shared/interfaces/user.interface";
import {Group} from "@material-ui/icons";
import {NavLink, useHistory} from 'react-router-dom';
import {UserContext} from "../../../shared/context/UserContext";
import {useApi} from "../../../shared/hooks/useApi";


const AllRoles: FC = () => {
  const [globalRoles, setGlobalRoles] = useState<GlobalRole[]>([]);
  const [projectRoles, setProjectRoles] = useState<ProjectRole[]>([]);
  const history = useHistory();
  const {get} = useApi();
  const {hasClaim} = useContext(UserContext);

  useEffect(() => {
      let isSubscribed = true;

      get<RoleResult>('/roles').then((data) => {
          if (data !== undefined && isSubscribed) {
            setGlobalRoles(data.globalRoles);
            setProjectRoles(data.projectRoles);
          }
        }
      );


      return () => {
        isSubscribed = false;
      }
    }, []
  );

  const returnListItem = (role: GlobalRole | ProjectRole, roleType: string) => {
    return (
      <ListItem key={role.id}>
        <ListItemAvatar>
          <Group/>
        </ListItemAvatar>
        <ListItemText
          primary={
            <NavLink style={{textDecoration: 'none'}} color="primary"
                     to={`/manage/users/${roleType}/edit/${role.id}`}>
              <Typography variant="h6"><Box
                color="text.primary">{role.roleName}</Box></Typography>
            </NavLink>
          }

        />
      </ListItem>
    )
  };

  const addGlobalRoleAction = {
    action: () => history.push(CommonPaths.manage.users.newGlobalRole),
    disabled: !hasClaim(GlobalClaims.manage.user.roles.readWrite.all),
    label: "Add a new global role"
  };

  const addProjectRoleAction = {
    action: () => history.push(CommonPaths.manage.users.newProjectRole),
    disabled: !hasClaim(GlobalClaims.manage.user.roles.readWrite.all),
    label: "Add a new project role"
  };


  return (
    <div>
      <PanelHeader text="Global roles" add={addGlobalRoleAction}/>
      {
        globalRoles.map(x => returnListItem(x, "global-role"))
      }
      <PanelHeader text="Project roles" add={addProjectRoleAction}/>
      {
        projectRoles.map(x => returnListItem(x, "project-role"))
      }
    </div>
  );
};

export default AllRoles;
