import React, {FunctionComponent, useCallback, useContext, useEffect, useMemo, useReducer, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";

import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow,} from "@material-ui/core";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {useApi} from "../../../shared/hooks/useApi";
import Pagination from "../../shared/table/Pagination";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {initialPaginated, PaginationRequest,} from "../../../shared/interfaces/pagination.interface";
import {CableType} from "../../../shared/interfaces/cableType.interface";
import {generalStyles, tableStyles, tableTheme,} from "../../shared/table/styles";
import {CableWithReferenceLabels} from "../../../shared/interfaces/cable.interface";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {Bundle} from "../../../shared/interfaces/bundle.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import {CableClass} from "../../../shared/interfaces/cableClass.interface";
import PenetrationListRowItem from "./components/PenetrationListRowItem";
import {Penetration} from "../../../shared/interfaces/penetration.interface";
import {CableTray} from "../../../shared/interfaces/cableTray.interface";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {useModals} from "../../../shared/context/ModalContext";
import {Skeleton} from "@material-ui/lab";

const PenetrationList: FunctionComponent = () => {
  /* State */
  const [state, dispatch] = useReducer(tableReducer, initialPaginated);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Data
  const [systems, setSystems] = useState<SystemListItem[] | null>(null);
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [areas, setAreas] = useState<Area[] | null>(null);
  const [mvZones, setMvZones] = useState<MainVerticalZone[] | null>(null);
  const [bundles, setBundles] = useState<Bundle[] | null>(null);
  const [dpZones] = useState<any>([]);
  const [zones3d, setZones3d] = useState<Zone3d[] | null>(null);
  const [cableClassTypes, setCableClassTypes] = useState<CableClass[] | null>(null);
  const [cableTypes, setCableTypes] = useState<CableType[] | null>(null);
  const [foremen, setForemen] = useState<Areaforeman[] | null>(null);
  const [penetrations, setPenetrations] = useState<Penetration[] | null>(null);
  const [cableTrays, setCableTrays] = useState<CableTray[] | null>(null);
  // Filtering
  const [selectedPenetration, setSelectedPenetration] = useState<Penetration | null>(null);
  const [selectedCableTrayId] = useState<number | null>(null);
  // Management
  const [reloadList, setReloadList] = useState<boolean>(false);

  /* State End */
  const classes = tableStyles();
  const general = generalStyles();

  const {project} = useContext(ProjectContext);
  const {openModal} = useModals();
  const {get, post, deleteMethod, fileDownload} = useApi();

  const getPdf = async () => {
    await fileDownload(
      "Cable Report on Selected PENETRATION.pdf",
      `/cable/${project?.id}/penetration/pdf/${selectedPenetration?.id}`
    );
  };

  // Fetch penetrations
  useEffect(() => {
    let isSubscribed = true;
    try {
      const paginationQuery: PaginationRequest = {
        page: state.currentPage,
        pageSize: state.pageSize,
      };
      const selectedPenetrationId = selectedPenetration?.code;

      (async () => {
        const requestUri = `/project/${project?.id}/cable/penetration`;
        await post<PaginationRequest, any>(requestUri, paginationQuery, {}, {
          penetrationId: selectedPenetrationId,
          selectedCableTrayId
        }).then((result) => {
          if (isSubscribed && result !== undefined) {
            dispatch({
              type: "SET_STATE",
              payload: () => ({
                ...result,
                values: result.values.map((x: CableWithReferenceLabels) => ({
                  ...x,
                  isMarked: false
                }))
              })
            });
          }
        });
      })();
    } finally {
      setIsLoading(false);
      setIsDirty(false);
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    selectedPenetration,
    selectedCableTrayId,
    reloadList,
    state?.pageSize,
    state?.currentPage,
  ]);

  // Fetch supporting data
  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      await get<SystemListItem[]>(`/project/${project?.id}/system`)
        .then(res => {
          if (isSubscribed) {
            setSystems(res);
          }
        });
      await get<Tag[]>(`/project/${project?.id}/tag`)
        .then(res => {
          if (isSubscribed) {
            setTags(res);
          }
        });
      await get<Area[]>(`/project/${project?.id}/area`)
        .then(res => {
          if (isSubscribed) {
            setAreas(res);
          }
        });
      await get<MainVerticalZone[]>(`/project/${project?.id}/mainVerticalZones`)
        .then(res => {
          if (isSubscribed) {
            setMvZones(res);
          }
        });
      await get<Bundle[]>(`/project/${project?.id}/bundle`)
        .then(res => {
          if (isSubscribed) {
            setBundles(res);
          }
        });
      await get<Zone3d[]>(`/project/${project?.id}/zone3d`)
        .then(res => {
          if (isSubscribed) {
            setZones3d(res);
          }
        });
      await get<CableClass[]>(`/cableClass`)
        .then(res => {
          if (isSubscribed) {
            setCableClassTypes(res);
          }
        });
      await get<CableType[]>(`/project/${project?.id}/cableTypes/select-options`)
        .then(res => {
          if (isSubscribed) {
            setCableTypes(res);
          }
        });
      await get<Areaforeman[]>(`/project/${project?.id}/areaForeman`)
        .then(res => {
          if (isSubscribed) {
            setForemen(res);
          }
        });
      await get<Penetration[]>(`/project/${project?.id}/penetration`)
        .then(res => {
          if (isSubscribed) {
            setPenetrations(res);
          }
        });
      await get<CableTray[]>(`/project/${project?.id}/cableTrays`)
        .then(res => {
          if (isSubscribed) {
            setCableTrays(res);
          }
        });
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  /**
   * Mapping options
   */

  const bundleOptions = useMemo((): Option[] | null =>
    bundles?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [bundles]);

  const cableTrayOptions = useMemo((): Option[] | null =>
    cableTrays?.map(x => ({
      value: x.id,
      label: `${x.code}`,
      selectedLabel: x.code
    })) || null, [cableTrays]);

  const systemOptions = useMemo((): Option[] | null =>
    systems?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [systems]);

  const tagOptions = useMemo((): Option[] | null =>
    tags?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [tags]);

  const areaOptions = useMemo((): Option[] | null =>
    areas?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [areas]);

  const cableClassTypeOptions = useMemo((): Option[] | null =>
    cableClassTypes?.map(x => ({
      value: x.id,
      label: x.code
    })) || null, [cableClassTypes]);

  const cableTypeOptions = useMemo((): Option[] | null =>
    cableTypes?.map(x => ({
      value: x.id,
      label: x.code
    })) || null, [cableTypes]);


  const foremanOptions = useMemo((): Option[] | null =>
    foremen?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [foremen]);

  const mvZoneOptions = useMemo((): Option[] | null =>
    mvZones?.map(x => ({
      value: x.id,
      label: x.code
    })) || null, [mvZones]);

  const zone3dOptions = useMemo((): Option[] | null =>
    zones3d?.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`,
      selectedLabel: x.code
    })) || null, [zones3d]);

  const getCableType = useCallback((cable: CableWithReferenceLabels): CableType | null => {
    if(cableTypes == null || cable.cableTypeId == null) {
      return null;
    }

    const cableType = cableTypes.find(x => x.id === cable.cableTypeId);
    return cableType || null;
  }, [cableTypes])

  // Oldrim
  const deleteCable = async (id: number) => {
    deleteMethod("/cable", id).then((res) => {
      if (res !== undefined) {
        setReloadList(!reloadList);
      }
    });
  };

  /* Validators */

  const areDependenciesLoaded =
    areas != null &&
    bundles != null &&
    cableClassTypes != null &&
    cableTypes != null &&
    foremen != null &&
    mvZones != null &&
    systems != null &&
    tags != null &&
    zones3d != null &&
    penetrations != null &&
    cableTrays != null &&
    dpZones != null;

  /* Filters */

  const penetrationFilter = useMemo(() => {
    const options = penetrations?.map(x => ({value: x.id, label: `${x.code} ${x.name}`})) || null;
    return (
      <VectSelect
        id={"penetrationIdFilter"}
        isClearable={true}
        label="Penetration ID"
        placeholder={"Select Penetration"}
        value={selectedPenetration?.id}
        change={value => {
          const action = () => {
            if(value === null) {
              setSelectedPenetration(null);
            } else {
              const penetration = penetrations?.find(x => x.id === value);
              if (penetration) {
                setSelectedPenetration(penetration);
              }
            }
          };
          promptIfUnsavedChanges(openModal, isDirty, action);
        }}
        options={options}
      />
    );
  }, [penetrations, selectedPenetration, isDirty]);

  return (
    <>
      <PanelHeader
        text={"Cable list based on selected penetration or cable tray"}
        getPdf={{action: getPdf}}
      />

      {penetrations !== null && cableTrayOptions !== null ? (
        <div className={general.largeFilterWrapper}>
          <div style={{ width: "100%" }} className={general.yFlex}>
            {/* Penetration Filter */}
            <div className={general.xFlex}>
              <div className={general.largeFilterItem}>
                {penetrationFilter}
              </div>
            </div>
            {/* ! NOT IN USE, AND ALSO OUTDATED */}
            {/* Cable Tray Filter */}
            {/*<div className={general.xFlex}>*/}
            {/*  <div className={general.filterItem}>*/}
            {/*    <VirtualizedAutoComplete*/}
            {/*      id={"cableTrayIdFilter"}*/}
            {/*      label={"Select Cable Tray"}*/}
            {/*      initialValue={selectedCableTrayId}*/}
            {/*      change={setSelectedCableTrayId}*/}
            {/*      options={cableTrayOptions}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    style={{ display: "flex" }}*/}
            {/*    className={general.largeFilterItem}*/}
            {/*  >*/}
            {/*    <TextField*/}
            {/*      style={{ width: "100%", alignSelf: "center" }}*/}
            {/*      value={*/}
            {/*        cableTrayOptions.find(*/}
            {/*          (x) => x.value === selectedCableTrayId*/}
            {/*        )?.label*/}
            {/*      }*/}
            {/*      disabled={true}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      ) : <Skeleton variant={"rect"} width={"40rem"} height={"5rem"}/>}

      {/* Tag Table Headers */}
      <Table>
        <TableHead>
          <TableRow className={classes.tableRowWrapper}>
            <div
              style={{width: "14rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <div className={general.xFlex}>
                <div className={general.yFlex}>
                  <TableCell>Cable nr:</TableCell>
                  <TableCell>Cable Type:</TableCell>
                  <TableCell>Cable ID:</TableCell>
                </div>
                <div>
                  <TableCell>Signal Type:</TableCell>
                </div>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>From Tag:</TableCell>
              <TableCell>From Area:</TableCell>
              <TableCell>From MVZone:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>From Text:</TableCell>
              </div>
              <div>
                <TableCell>From:</TableCell>
                <TableCell>Conn Resp:</TableCell>
                <TableCell>From 3D Zone:</TableCell>
                <TableCell>Class Type:</TableCell>
              </div>
            </div>
            <div
              style={{width: "8rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>To Tag:</TableCell>
              <TableCell>To Area:</TableCell>
              <TableCell>To MVZone:</TableCell>
            </div>
            <div
              style={{width: "20rem"}}
              className={classes.tableHeaderColumnWrapperWithSpace}
            >
              <div>
                <TableCell>To Text:</TableCell>
              </div>
              <div>
                <TableCell>To:</TableCell>
                <TableCell>Conn Resp:</TableCell>
                <TableCell>To 3D Zone:</TableCell>
                <TableCell>SRtP or Special Req.:</TableCell>
              </div>
            </div>
            <div
              style={{width: "10rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Bundle ID:</TableCell>
              <TableCell>Calculated Meter:</TableCell>
              <TableCell>Meter:</TableCell>
            </div>
            <div
              style={{width: "11rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Bundle Text:</TableCell>
              <TableCell>Mark Placement Info:</TableCell>
              <TableCell>Penetrations to use:</TableCell>
            </div>
            <div
              style={{width: "6rem"}}
              className={classes.tableHeaderColumnWrapper}
            >
              <TableCell>Cut:</TableCell>
              <TableCell>Fixed:</TableCell>
              <TableCell>DP Zone:</TableCell>
            </div>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {areDependenciesLoaded ? state.values.map((x, index) => {
                  const item = x as CableWithReferenceLabels;
                  return (
                    <PenetrationListRowItem
                      key={index}
                      cable={item}
                      deleteCable={deleteCable}
                      systemOptions={systemOptions}
                      tagOptions={tagOptions}
                      areaOptions={areaOptions}
                      mvZoneOptions={mvZoneOptions}
                      bundleOptions={bundleOptions}
                      zone3dOptions={zone3dOptions}
                      cableClassTypeOptions={cableClassTypeOptions}
                      cableType={getCableType}
                      cableTypeOptions={cableTypeOptions}
                      areaForemanOptions={foremanOptions}
                    />
                  );
                })
                : null}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
      <Pagination state={state} dispatch={dispatch}/>
    </>
  );
};

export default PenetrationList;
