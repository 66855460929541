import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";

import {
  FormControl,
  FormControlLabel,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {useApi} from "../../../shared/hooks/useApi";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {generalStyles, TableCellHeader, tableStyles, tableTheme,} from "../../shared/table/styles";
import CbsStatusRowItem from "./CbsStatusRowItem";
import {
  AreaForemanForProjectResult,
  AreaForemanForProjectWrapper,
} from "../../../shared/interfaces/AreaForemanForProjectResult";
import CbsStatusTableFooter from "./CbsStatusTableFooter";

const CbsStatus: FunctionComponent = () => {
  const [afStatusWrapper, setAfStatusWrapper] = useState<AreaForemanForProjectWrapper>();
  const [optFrameConn, setOptFrameConn] = useState<number>(1);
  const classes = tableStyles();
  const general = generalStyles();
  const { project } = useContext(ProjectContext);
  const { get, fileDownload } = useApi();

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const result = await get<AreaForemanForProjectWrapper | undefined>(
        `/areaForeman/cbsStatus/${project?.id}?optFrameConn=${optFrameConn}`
      ).then((res) => {
        return res;
      });
      if (isSubscribed && result !== undefined) {
        setAfStatusWrapper(result);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [optFrameConn]);

  const excelExport = [
    {
      label: "Export data",
      action: async () => {
        await fileDownload(
          "Project CBS Foreman Status.xlsx",
          `/areaForeman/${project?.id}/exportAsExcel?optFrameConn=${optFrameConn}`
        );
      },
    },
  ];

  const handleOptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value as string;
    setOptFrameConn(parseInt(val));
  };

  return (
    <>
      <PanelHeader text={"CBS Status"} excelExports={excelExport} />

      <div className={general.largeFilterWrapper}>
        <div className={general.largeFilterItem}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="optFrameConn"
              value={optFrameConn}
              onChange={handleOptChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Conn. based on System's CBS"
              />
              <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label="Conn. based on Conn. Resp. for each cable end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <Table>
        <TableHead>
          <TableRow style={{ display: "flex", flexDirection: "row" }}>
            <TableCellHeader style={{ width: "45.5em", border: "none" }} />
            <TableCellHeader
              style={{
                width: "38.5em",
                padding: "4px 0",
                border: "1px solid black",
                textAlign: "center",
                marginRight: "1.5em",
              }}
            >
              <span>Info from CL Writer</span>
            </TableCellHeader>
            <TableCellHeader
              style={{
                width: "64.5em",
                padding: "4px 0",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              <span>Info from CL Writer</span>
            </TableCellHeader>
          </TableRow>
          <TableRow style={{ border: "none" }} className={classes.tableRowWrapper}>
            <TableCellHeader style={{ width: "5rem" }}>
              <span>CBS ID</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "20rem" }}>
              <span>Area Name</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "10rem" }}>
              <span>Area Foreman</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "4rem" }}>
              <span>Type</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>Reg Pcs</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>Rel. Pcs</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>% Released</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>Reg. Mtr</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>% Measured</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>Pcs. Fixed</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "5rem" }}>
              <span>%</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>Meter Fixed</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "5rem" }}>
              <span>%</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "9rem" }}>
              <span>Pcs. Conn From</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "5rem" }}>
              <span>%</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "9rem" }}>
              <span>Pcs. Conn To</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "5rem" }}>
              <span>%</span>
            </TableCellHeader>
            <TableCellHeader style={{ width: "7rem" }}>
              <span>Mtr Free P</span>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            {afStatusWrapper !== undefined
              ? afStatusWrapper?.status.length > 0
                ? afStatusWrapper?.status.map((x, index) => {
                    const item = x as AreaForemanForProjectResult;
                    return (
                      <CbsStatusRowItem
                        key={index}
                        areaForeman={item}
                      />
                    );
                  })
                : null
              : null}
          </TableBody>
          {/* Footer */}
          {afStatusWrapper !== undefined ? (
            <CbsStatusTableFooter totals={afStatusWrapper?.totals} />
          ) : null}
        </MuiThemeProvider>
      </Table>
    </>
  );
};

export default CbsStatus;
