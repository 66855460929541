import React, {useCallback, useContext, useEffect, useState} from "react";
import PanelHeader, {Action, SecondaryAction} from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {CableTypeLineItem} from "../../../shared/interfaces/cableType.interface";
import {CancelSharp} from "@material-ui/icons";
import {CommonPaths} from "../../../shared/urls";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {ProjectClaims} from "../../../shared/claims";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {ProjectMetadata} from "../../../shared/interfaces/project.interface";
import {Scalar} from "../../../shared/interfaces/scalar";
import {StyledTableRow, TableCellHeader, TableCellInput, tableTheme} from "../../shared/table/styles";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";
import {VectTableTextField} from "../../shared/inputs/VectTableTextField";
import {useApi} from "../../../shared/hooks/useApi";
import {useHistory} from "react-router-dom";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";

export interface CreateOrderRequest {
  isInitialOrder: boolean;
  items: {
    cableTypeId: number;
    quantity: number;
  }[];
}

export const OrderCreate: React.FC<{
  redirectTo: string;
}> = ({
  redirectTo
}): JSX.Element => {
  const [cableTypes, setCableTypes] = useState<CableTypeLineItem[] | null>(null);
  const [addedCableTypes, setAddedCableTypes] = useState<CableTypeLineItem[]>([]);
  const [isInitialOrder, setIsInitialOrder] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [projectMetadata, setProjectMetadata] = useState<ProjectMetadata | null>(null);
  const history = useHistory();
  const {get, post} = useApi()
  const {project} = useContext(ProjectContext);
  const {setError} = useContext(AlertContext);

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      try {
        const projectMetadataResponse = await get<ProjectMetadata>(`project/${project?.code}/metadata`);
        const cableTypeResponse = await get<CableTypeLineItem[]>(`project/${project?.id}/cableTypes`);
        if(isSubscribed){
          setProjectMetadata(projectMetadataResponse);
          setIsInitialOrder(!projectMetadataResponse.isInitialOrderGenerated);
          setCableTypes(cableTypeResponse);
        }
      } catch(error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      isSubscribed = false;
    }
  }, []);

  useEffect(() => {
    if(isInitialOrder === true) {
      (async () => {
        setIsLoading(true);
        try {
          const template = await get<{items: {cableTypeId: number, estimatedMeters: number}[]}>(`project/${project?.id}/cableTypes/order/initial-order-template`);
          setCableTypes(prevState => {
            if(prevState == null) {
              return null;
            }

            const newState = [...prevState];
            template.items
              .filter(x => x.estimatedMeters > 0)
              .forEach(x => {
                const cableType = newState.find(cableType => cableType.id === x.cableTypeId);
                if(cableType != null) {
                  cableType.quantity = x.estimatedMeters;
                }
              });

            return newState;
          });
        } catch(error) {
          setError(error);
        } finally {
          setIsLoading(false);
        }


      })();
    }
  }, [isInitialOrder]);

  useEffect(() => {
    setAddedCableTypes(cableTypes == null ? [] : cableTypes.filter(x => x.quantity && x.quantity > 0));
  }, [cableTypes])

  const cancelAction = useCallback(() => {
    history.push(redirectTo);
  }, [redirectTo, history])

  const onChange = useCallback((cableTypeId: number, quantity: number | undefined) => {
    setCableTypes(prevState => {
      if (prevState == null) {
        return null;
      }

      const newState = [...prevState];
      const index = newState.findIndex(x => x.id === cableTypeId);
      if (index !== -1) {
        newState[index].quantity = quantity;
      }

      return newState;
    })
  }, [])

  const saveAction = useCallback(() => {
    if (project == null) {
      return;
    }

    const body: CreateOrderRequest = {
      isInitialOrder: isInitialOrder || false,
      items: addedCableTypes.map(x => ({cableTypeId: x.id, quantity: x.quantity || 0}))
    }

    setIsLoading(true)
    post<CreateOrderRequest, Scalar<number>>(`project/${project.id}/cableTypes/order`, body)
      .then(res => {
        //
        setAddedCableTypes([]);
        history.push(CommonPaths.order.details(project.code, res.value));
      })
      .catch(setError)


  }, [addedCableTypes]);

  const isDirty = addedCableTypes.length > 0;

  const onCancel: SecondaryAction = {
    label: "Cancel",
    action: cancelAction,
    icon: <CancelSharp/>,
    disabled: isLoading
  }

  const onSave: Action = {
    label: "Save order sheet",
    claim: ProjectClaims.project.order.write.all,
    action: saveAction,
    disabled: !isDirty
  }

  return (
    <div>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader text={"New order"} save={onSave} secondaryActions={[onCancel]}/>
      <VectCheckbox label="Set as initial order"
                    labelMarginLeft={false}
                    checked={isInitialOrder}
                    change={setIsInitialOrder}
                    color={"primary"}
                    disabled={projectMetadata?.isInitialOrderGenerated === true}
      />
      {
        projectMetadata?.isInitialOrderGenerated && (
          <Typography variant="body2">The initial order is already created. You can not set this on multiple
            orders</Typography>
        )
      }
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader style={{width: "15rem"}}>
              Description
            </TableCellHeader>
            <TableCellHeader style={{width: "10rem"}}>
              Part number
            </TableCellHeader>
            <TableCellHeader style={{width: "10rem"}}>
              Required [m]
            </TableCellHeader>
            <TableCellHeader>
              Weight [kg/m]
            </TableCellHeader>
            <TableCellHeader>
              External diameter [mm]
            </TableCellHeader>
            <TableCellHeader>
              Pulling rate [h/m]
            </TableCellHeader>
            <TableCellHeader>
              Connection rate [h/end]
            </TableCellHeader>
            <TableCellHeader>
              Delivery time [weeks]
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {
                cableTypes?.map(x => (
                  <CableTypeLineItemRow key={x.id}
                                        cableType={x}
                                        change={onChange}/>
                ))
              }
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </div>
  );
}

interface CableTypeLineItemRowProps {
  cableType: CableTypeLineItem;
  change: (cableTypeId: number, quantity: number | undefined) => void;
}

const CableTypeLineItemRow: React.FC<CableTypeLineItemRowProps> = ({
 cableType,
 change
}): JSX.Element => {

  const onChange = (val: string) => {
    change(cableType.id, +val);
  }

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={cableType.code}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.typeIdLogistics}/>
      </TableCell>
      <TableCellInput>
        <VectTableTextField type="number" value={cableType.quantity} change={onChange}/>
      </TableCellInput>
      <TableCell>
        <VectTableReadOnlyText value={cableType.weightKiloGramsPerMeter}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.outerDimension}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.estimatedHoursPerMetersPulling}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.estimatedHoursPerPieceConnecting}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.deliveryTimeWeeks}/>
      </TableCell>
    </StyledTableRow>
  );
}
