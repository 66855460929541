import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {PublicClientApplication} from '@azure/msal-browser';
import {ConfigContext} from "./ConfigContext";
import {Scope} from "../interfaces/config.interface";

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  getAccessToken: (scopes: Scope) => Promise<string>;
}

interface AuthProviderState {
  isAuthenticated: boolean;
  accessToken: string
}

export interface AuthContextInterface {
  authState: AuthProviderState,
  getAccessToken: (scopes: Scope) => Promise<string>
  logout: () => void;
}

export const AuthContext = createContext<AuthContextInterface>(
  {
    authState: {
      isAuthenticated: false,
      accessToken: ""
    },
    getAccessToken: () => {
      return new Promise<string>(() => "")
    },
    logout: () => {
      return;
    }
  });

const AuthProvider: FC = ({children}) => {
  const config = useContext(ConfigContext)
  const [state, setState] = useState<AuthProviderState>({
    isAuthenticated: false,
    accessToken: ""
  });
  const getPublicClientApplication = async () => new PublicClientApplication(config.msal);


  useEffect(() => {
      let isSubScribed = true;
      // If MSAL already has an account, the user
      // is already logged in
      getPublicClientApplication().then(msal => {
        msal.handleRedirectPromise().then(redirectResponse => {
          if(redirectResponse == null || redirectResponse.account == null){
            // noinspection  JSIgnoredPromiseFromCall
            getAccessToken(config.scopes, true);
          } else {
            if(isSubScribed) {
              setState({
                accessToken: redirectResponse?.accessToken || "",
                isAuthenticated: true
              })
            }
          }
        })
      });

    return () => {
      isSubScribed = false
    };
  }, []);



  const logout = async () => {
    await (await getPublicClientApplication()).logout();
  }

  const getAccessToken = async (scope: Scope, forceRefresh = false): Promise<string> => {
    const msal = await getPublicClientApplication();
    const accounts = msal.getAllAccounts();
    const account = accounts.length > 0 ? accounts[0] : undefined;
    const scopes: string[] = [scope.vectApi];

    if(account){
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      try{
        const silentResult = await msal.acquireTokenSilent({scopes: scopes, forceRefresh: forceRefresh, account: account});
        if(!silentResult) {
          await msal.loginRedirect({scopes: scopes, account: account});
        } else {
          setState({
            ...state,
            isAuthenticated: true,
            accessToken: silentResult.accessToken
          });
          return silentResult.accessToken;
        }
      }catch(error) {
        if(error.name && error.name === "InteractionRequiredAuthError"){
          console.warn("Interactive login is required.");
          await msal.loginRedirect({scopes: scopes, account: account});
        } else {
          console.error(error);
        }
      }
    } else {
      // No account is present, do login from scratch
      await msal.loginRedirect({scopes: scopes});

    }
    return "";
  }

  return (
    <AuthContext.Provider value={{authState: state, getAccessToken, logout}}>
      {children}
    </AuthContext.Provider>
  );


}
export default AuthProvider;
