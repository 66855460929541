import React from "react";
import {Field} from "../../../../shared/interfaces/scalar";
import {StyledTableRow} from "../../../shared/table/styles";
import {TableCell} from "@material-ui/core";
import {VectTableReadOnlyText} from "../../../shared/inputs/VectTableReadOnlyText";

export interface CableListValidationResult {
  cables: CableListValidationItem[];
  errorCount: number;
}

export interface CableListValidationItem {
  bundleCode: Field;
  cableTypeCode: Field;
  code: Field;
  fromTagCode: Field;
  toTagCode: Field;
  meters: Field;
  projectId: number;
  systemId: number;
  bundleId?: number;
  cableTypeId?: number;
  fromAreaId?: number;
  fromConnectionAreaForemanId?: number;
  fromMainVerticalZoneId?: number;
  fromTagId?: number;
  fromZone3dId?: number;
  toAreaId?: number;
  toConnectionAreaForemanId?: number;
  toMainVerticalZoneId?: number;
  toTagId?: number;
  toZone3dId?: number;
  cableNumber?: string;
  fromText?: string;
  toText?: string;
}

export const CableImportRowItem: React.FC<CableListValidationItem> = ({
  code,
  cableTypeCode,
  fromTagCode,
  fromText,
  toTagCode,
  toText,
  meters,
  bundleCode
}) => {
  
  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={code.value} error={code.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableTypeCode.value} error={cableTypeCode.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={fromTagCode.value} error={fromTagCode.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={fromText}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={toTagCode.value} error={toTagCode.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={toText} />
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={meters.value} error={meters.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={bundleCode.value} error={bundleCode.error}/>
      </TableCell>
    </StyledTableRow>
  )
}
