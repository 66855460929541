import {makeStyles} from "@material-ui/core/styles";

export const gridStyles = makeStyles(() => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "7rem 7rem 1fr 7rem 1fr",
    gridTemplateRows: "1rem 1rem auto auto",
    justifyItems: "start",
    columnGap: 10
  },
  cableNumber: {
    gridColumnStart: 1,
    gridRowStart: 2,
    fontSize: "0.8rem"
  },
  cableType: {
    gridColumnStart: 1,
    gridRowStart: 3,
    fontSize: "0.8rem"
  },
  cableCode: {
    gridColumnStart: 1,
    gridRowStart: 4,
    fontSize: "0.8rem"
  },
  newFromTagLabel: {
    gridColumnStart: 2,
    gridRowStart: 1,
    fontSize: "0.8rem"
  },
  newFromTagCode: {
    gridColumnStart: 2,
    gridRowStart: 2,
    fontSize: "0.8rem"
  },
  newFromAreaCode: {
    gridColumnStart: 2,
    gridRowStart: 3,
    fontSize: "0.8rem"
  },
  newFromTextLabel: {
    gridColumnStart: 3,
    gridRowStart: 1,
    fontSize: "0.8rem"
  },
  newFromText: {
    gridColumnStart: 3,
    gridRowStart: 2,
    gridRowEnd: "last-line"
  },
  newToTagLabel: {
    gridColumnStart: 4,
    gridRowStart: 1,
    fontSize: "0.8rem"
  },
  newToTagCode: {
    gridColumnStart: 4,
    gridRowStart: 2,
    fontSize: "0.8rem"
  },
  newToAreaCode: {
    gridColumnStart: 4,
    gridRowStart: 3,
    fontSize: "0.8rem"
  },
  newToTextLabel: {
    gridColumnStart: 5,
    gridRowStart: 1,
    fontSize: "0.8rem"
  },
  newToText: {
    gridColumnStart: 5,
    gridRowStart: 2,
    gridRowEnd: "last-line"
  }
  
  
}));
