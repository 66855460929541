import {selectStyles, VectSelect, VectSelectProps} from "./VectSelect";
import React, {CSSProperties, memo} from "react";


export const tableRowSelectStyles={
  ...selectStyles,
  container: (provided: any): CSSProperties => {
    return {
      ...provided,
      borderBottom: "1px solid rgba(0, 0, 0, 0.5)",

      "&:hover": {
        borderBottomWidth: 2,
        borderBottomColor: "rgba(0, 0, 0, 0.87)"
      }
    };
  },
  control: () => ({
    background: "inherit",
    display: "flex",
    height: "1.55rem",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    width: 16
  }),
  menu: (provided: any) => ({
    ...provided,
    ...selectStyles.menu(provided),
    width: "30rem"
  })
};

const VectTableRowSelect_: React.FC<VectSelectProps> = (props) => {



  const tableSelectProps = {
    ...props,
    styles: props.styles || tableRowSelectStyles,
    placeholder: ""
  };

  return <VectSelect {...tableSelectProps}  />
}
export const VectTableRowSelect = memo(VectTableRowSelect_)
