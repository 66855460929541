import React, {useState} from "react";
import {Button} from "@material-ui/core";
import {Person, PersonOutline} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import {VectButton} from "../../../shared/navigation/VectButton";
import {GlobalClaims} from "../../../../shared/claims";

type UserProps = {
  objectId: string, userAction: (objectId: string) => Promise<void>, isDeleted: boolean
}
const UserButton = ({objectId, userAction, isDeleted}: UserProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleClick = async () => {
    setLoading(true);
    await userAction(objectId).then(() => setLoading(false));
  }
  const text = `${isDeleted ? "Enable" : "Disable"} user`;
  return (
    <span className="button-container">
    {
      loading
        ? <Skeleton><Button startIcon={isDeleted ? <Person/> : <PersonOutline/>}>{text}</Button></Skeleton>
        : (<VectButton claim={GlobalClaims.manage.user.addOrDisable}
                       variant="contained"
                       onClick={() => handleClick()}
                       startIcon={isDeleted ? <Person/> : <PersonOutline/>}>
          {text}
        </VectButton>)
    }
    </span>
  )
}
export default UserButton;
