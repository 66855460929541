import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import PanelHeader, {Action, AdditionalAction} from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {CableTypeLineItem, Order, OrderProps} from "../../../shared/interfaces/cableType.interface";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {OrderDetailsHeader} from "./OrderDetailsHeader";
import {ProjectClaims} from "../../../shared/claims";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {StyledTableRow, TableCellHeader, tableTheme} from "../../shared/table/styles";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";
import {compare} from "fast-json-patch";
import {useApi} from "../../../shared/hooks/useApi";
import {VectUnsavedChangesPrompt} from "../../shared/navigation/VectUnsavedChangesPrompt";


export const OrderDetails: React.FC<{ redirectTo: string }> = ({
                                                                 redirectTo
                                                               }): JSX.Element => {
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lineItems, setLineItems] = useState<CableTypeLineItem[] | null>(null);
  const [order, setOrder] = useState<Order | null>(null);
  const [updates, setUpdates] = useState<OrderProps | null>(null);
  const history = useHistory();
  const {get, patch, fileDownload} = useApi();
  const {orderId} = useParams<{ orderId?: string }>();
  const {project} = useContext(ProjectContext);
  const {setError} = useContext(AlertContext);

  if (!orderId || isNaN(+orderId)) {
    history.push(redirectTo);
  }

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (project?.id && orderId) {

        try {
          const order = await get<Order>(`project/${project.id}/cableTypes/order/details/${orderId}`);
          const cableTypeDetails = await get<CableTypeLineItem[]>(`project/${project?.id}/cableTypes`, {queryParams: {showInactive: true}})
          if(isSubscribed) {
            setLineItems(order.items.map(x => {
              const cableType = cableTypeDetails.find(ct => ct.id === x.cableTypeId);
              return {
                ...cableType!,
                quantity: x.quantity
              };
            }));
            setOrder(order);
          }
        } catch (err) {
          setError(err);
          return;
        }

        setIsLoading(false)
      }
    })();

    return () => {
      isSubscribed = false;
    }
  }, [orderId, project?.id])

  const closeAction = useCallback(() => {
    history.push(redirectTo);
  }, [redirectTo, history])

  const excelAction = useCallback(async () => {
    if(project == null || order == null) {
      return null;
    }

    await fileDownload(
      `Order Sheet ${order.id}.xlsx`,
      `/project/${project.id}/cableTypes/order/details/${order.id}/excel`);
  }, [project, order])

  const onChange = useCallback((order: OrderProps) => {
    setIsDirty(true);
    setUpdates(order);
  }, [])

  const saveAction = useCallback(async () => {
    if (updates == null || order == null) {
      return;
    }
    setIsLoading(true);
    try {
      const initialItem: OrderProps = {
        deliveryLocation: order.deliveryLocation,
        deliveryDate: order.deliveryDate
      }
      const operations = compare(initialItem, updates);
      const result = await patch<Order>(`project/${order.projectId}/cableTypes/order/details/${order.id}`, operations);
      setOrder(result);
      setUpdates(null);
      setIsDirty(false);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false)
    }
  }, [updates])

  const excelExports: AdditionalAction[] = [
    {
      label: "Download order sheet",
      action: excelAction,
      disabled: order == null || project == null
    }
  ]

  const onClose: Action = {
    action: closeAction
  }

  const onSave: Action = {
    label: "Save changes",
    claim: ProjectClaims.project.order.write.all,
    disabled: isLoading || !isDirty,
    action: saveAction
  }

  return (
    <div>
      <VectUnsavedChangesPrompt isDirty={isDirty}/>
      <PanelHeader
        text={"Order details"}
        save={onSave}
        excelExports={excelExports}
        close={onClose}/>
      <OrderDetailsHeader order={order} change={onChange}/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader style={{width: "15rem"}}>
              Description
            </TableCellHeader>
            <TableCellHeader style={{width: "10rem"}}>
              Part number
            </TableCellHeader>
            <TableCellHeader style={{width: "10rem"}}>
              Required [m]
            </TableCellHeader>
            <TableCellHeader>
              Weight [kg/m]
            </TableCellHeader>
            <TableCellHeader>
              External diameter [mm]
            </TableCellHeader>
            <TableCellHeader>
              Pulling rate [h/m]
            </TableCellHeader>
            <TableCellHeader>
              Connection rate [h/end]
            </TableCellHeader>
            <TableCellHeader>
              Delivery time [weeks]
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {
                lineItems?.map(x => (
                  <CableTypeLineItemRow key={x.id} cableType={x}/>
                ))
              }
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </div>
  );
}


const CableTypeLineItemRow: React.FC<{ cableType: CableTypeLineItem }> = ({
                                                                            cableType,
                                                                          }): JSX.Element => {

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={cableType.code}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.typeIdLogistics}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.quantity}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.weightKiloGramsPerMeter}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.outerDimension}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.estimatedHoursPerMetersPulling}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.estimatedHoursPerPieceConnecting}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={cableType.deliveryTimeWeeks}/>
      </TableCell>
    </StyledTableRow>
  );
}

