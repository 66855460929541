import React, {FC, useContext, useEffect, useState} from 'react';
import {
  GlobalRole,
  ProjectRole,
  RolesAssignment,
  User,
  UserProjectRole
} from "../../../../shared/interfaces/user.interface";
import {useApi} from "../../../../shared/hooks/useApi";
import {Select, Typography} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {ProjectListItem,} from "../../../../shared/interfaces/project.interface";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import {Skeleton} from "@material-ui/lab";
import {RolesAssignmentTable} from "./RolesAssignmentTable";
import {Option, VectSelect} from "../../../shared/inputs/VectSelect";
import {useClaims} from "../../../../shared/hooks/useClaims";
import {GlobalClaims} from "../../../../shared/claims";
import {AlertContext} from "../../../../shared/context/AlertContext";
import {DeleteForeverSharp} from "@material-ui/icons";
import {DeleteConfirmationModal} from "../../../shared/Feedback/DeleteConfirmationModal";
import {useModals} from "../../../../shared/context/ModalContext";

interface EditUserParams {
  id: string
}

const EditUser: FC = () => {
  const [allGlobalRoles, setAllGlobalRoles] = useState<GlobalRole[] | null>(null);
  const [allProjectRoles, setAllProjectRoles] = useState<ProjectRole[] | null>(null);
  const [allProjects, setAllProjects] = useState<ProjectListItem[] | null>(null);
  const [assignments, setAssignments] = useState<RolesAssignment[] | null>(null);
  const [initialProjectRoles, setInitialProjectRoles] = useState<UserProjectRole[] | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [user, setUser] = useState<User | null>(null)
  const {get, put, _delete} = useApi();
  const history = useHistory();
  const {openModal, closeModal} = useModals();
  const {id} = useParams<EditUserParams>();
  const {isValid} = useClaims();
  const {setSuccess, setError} = useContext(AlertContext);

  const globalRoleOptions: Option[] = allGlobalRoles == null
    ? []
    : allGlobalRoles.map(x => ({
      value: x.id,
      label: x.roleName
    }));

  useEffect(() => {
    let isSubscribed = true
    get<GlobalRole[]>('/roles/global')
      .then(res => {
        if (isSubscribed) {
          setAllGlobalRoles(res);
        }
      });
    get<ProjectRole[]>('/roles/project')
      .then(res => {
        if (isSubscribed) {
          setAllProjectRoles(res);
        }
      });
    get<ProjectListItem[]>(`/project`)
      .then(res => {
        if (isSubscribed) {
          setAllProjects(res);
        }
      });
    get<User>(`/user/${id}`)
      .then(res => {
        if (isSubscribed) {
          setUser(res);
        }
      });
    get<{ globalRole: GlobalRole, projectRoles: UserProjectRole[] }>(`/user/${id}/roles`)
      .then(res => {
        if (isSubscribed) {
          setInitialProjectRoles(res.projectRoles)
        }
      });

    return () => {
      isSubscribed = false
    };
  }, []);

  const handleGlobalRoleChange = (value: number | null) => {
    if (user != null && value != null) {
      setUser({...user, globalRole: {id: value, roleName: "", claims: []}})
      setIsDirty(true);
    }
  }

  const onProjectsChange = (assignments: RolesAssignment[]) => {
    setAssignments(assignments);
    setIsDirty(true)
  }

  const submit = () => {

    let projectRoles = [];
    if(assignments != null) {
      for (const assignment of assignments) {
        for (const role of assignment.roles) {
          projectRoles.push({projectId: assignment.project.id, roleId: role.id});
        }
      }
    } else {
      projectRoles = (initialProjectRoles || []).map(x => ({projectId: x.projectId, roleId: x.id}));
    }
    put(`/user/${user?.objectId}/roles`, {
      globalRoleId: user?.globalRole.id,
      projectRoles: projectRoles
    }).then(() => {
      setSuccess();
      setIsDirty(false);
    })
      .catch(setError);
  }

  const selectStyle = {marginBottom: "2rem", width: "20rem"};
  const saveDisabled  =
    !isValid({claims: [GlobalClaims.manage.user.project.readWrite.all, GlobalClaims.manage.user.roles.readWrite.all]}) ||
    !isDirty;

  const showDeleteDialog = () => {
    openModal && openModal(DeleteConfirmationModal, {
      context: "User will loose access to the system.",
      onDelete: async () => {
        try {
          await _delete(`user/delete/${id}`);
          history.goBack()
        } catch(error) {
          setError(error);
        } finally {
          if (closeModal) {
            closeModal();
          }
        }
      }
    });
  }

  const secondaryActions =
    [{
      label: "Delete user",
      icon: <DeleteForeverSharp/>,
      action: showDeleteDialog,
    }];

  return (
    <>
      <PanelHeader
        text="Edit user"
        save={{action: submit, disabled: saveDisabled}}
        secondaryActions={secondaryActions}/>

      <div className="edit-user-content">
        <Typography>
          Name: {user ? user.name : <Skeleton width="10rem"/>}
        </Typography>
        <Typography>
          Email: {user ? user.email : <Skeleton width="10rem"/>}
        </Typography>
        {
          user && allGlobalRoles
            ? (<VectSelect value={user.globalRole.id}
                           label="Global role"
                           styles={selectStyle}
                           options={globalRoleOptions}
                           change={handleGlobalRoleChange} />)
            : <Skeleton><Select style={selectStyle}/></Skeleton>
        }


        <PanelHeader text={`Project roles for ${user?.name}`} size="small"/>
        {
          allProjectRoles && allProjects && initialProjectRoles
            ? <RolesAssignmentTable roles={allProjectRoles}
                                    projects={allProjects}
                                    initialAssignments={initialProjectRoles}
                                    onChange={onProjectsChange}/>
            : <Skeleton variant="rect" width="15rem" height="5rem"/>
        }
      </div>
    </>
  )
}

export default EditUser;

