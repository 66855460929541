import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {CommonPaths} from "../urls";
import {ProjectUserContext} from "../interfaces/project.interface";
import {UserContext} from "./UserContext";
import {useApi} from "../hooks/useApi";
import {useHistory, useParams} from "react-router-dom";
import {findValue, findValueInArray} from "../helpers/array";


interface RouteParams {
  projectCode?: string;
}

export interface ProjectContextProps {
  project: ProjectUserContext | null;
  isLoading: boolean;
  /**
   * Check if the project object claims to have a specific grant
   * @param {string} claim  A project grant to inspect
   * @param {boolean} useExactMatch  Default true. When false, partial wildcard claims are allowed (e.g. "Manage.")
   * @returns {boolean}     Is true if the user claims to have this global grant
   */
  hasClaim: (claim: string, useExactMatch?: boolean) => boolean;

  /**
   *
   * @param claims        Project grants to inspect
   * @param useExactMatch Default true. When false, partial wildcard claims are allowed (e.g. "Manage.")
   * @returns {boolean}   Is true if the user claims to have one of these project grants
   */
  hasClaims: (claims: string[], useExactMatch?: boolean) => boolean;
}

export const ProjectContext = React.createContext<ProjectContextProps>({
  project: null,
  isLoading: true,
  hasClaim: () => false,
  hasClaims: () => false
});

export const ProjectProvider: FC = ({children}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [project, setProject] = useState<ProjectUserContext | null>(null);
  const history = useHistory();
  const {post} = useApi();
  const {projectCode} = useParams<RouteParams>();
  const {setReload, user} = useContext(UserContext);

  const getProject = useCallback((projectCode: string, reloadUser: boolean) => {
    setIsLoading(true);
    const payload = {
      projectCode
    };
    post<any, any>('/user/projectContext', payload)
      .then(res => setProject(res))
      .catch(() => history.push({pathname: CommonPaths.project.list}))
      .finally(() => {
        setIsLoading(false);
        if (reloadUser) {
          setReload(true);
        }
      });
  }, []);

  const hasClaim = (claim: string, useExactMatch = true): boolean => {
    if (!project || !project.claims) {
      return false;
    }

    return findValue(project.claims, claim, useExactMatch);
  };

  const hasClaims = (claims: string[], useExactMatch = true):boolean => {
    if(!project || !project.claims) {
      return false;
    }

    return findValueInArray(project.claims, claims, useExactMatch);
  }

  useEffect(() => {
    const userProjectCode = user.activeProject?.code;
    if ((project?.code !== userProjectCode || projectCode !== userProjectCode) && projectCode && user.objectId) {
      getProject(projectCode, userProjectCode !== projectCode);
    } else {
      setIsLoading(false);
    }
  }, [projectCode, user]);

  return (
    <ProjectContext.Provider value={{project, isLoading, hasClaim, hasClaims}}>
      {children}
    </ProjectContext.Provider>
  )
}

