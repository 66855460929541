import React, {FC} from "react";
import {Area} from "../../../shared/interfaces/area.interface";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {Option} from "../../shared/inputs/VectSelect";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {ProjectClaims} from "../../../shared/claims";
import {VectTableRowSelect} from "../../shared/inputs/VectTableRowSelect";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {VectContainer} from "../../shared/layout/VectContainer";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {TableCell} from "@material-ui/core";
import {CopyButton} from "../../shared/table/CopyButton";

interface AreaRowItemProps {
  area: Area;
  change: (value: Area) => void;
  copy: (value: Area) => void;
  foremanOptions: Option[] | null;
  mvzOptions: Option[] | null;
  zone3dOptions: Option[] | null;
}
const AreaRowItem: FC<AreaRowItemProps> = ({
   area,
   change,
   copy,
   foremanOptions,
   mvzOptions,
   zone3dOptions
}) => {
  const cellClasses = tableCellStyles();



  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: Area): boolean => {
      return !newState.code;
    }

    handleCellChange(
      change,
      hasError,
      area,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...area,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(area);
  }

  const style = area.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput style={{width: "7.5%"}} className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.area.write.all}
          // error={!!errors.code}
          // helperText={errors.code}
          fieldName="code"
          value={area.code}
          change={onCellChange}
          variant="standard"/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.area.write.all}
          fieldName="name"
          value={area.name}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.area.write.all}
          fieldName={"areaForemanId"}
          value={area.areaForemanId}
          change={onCellChange}
          options={foremanOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectCheckbox
          claim={ProjectClaims.project.area.write.all}
          fieldName={"isActive"}
          checked={area.isActive}
          change={onCellChange}
          color="primary"
          size={"small"}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.area.write.all}
          fieldName={"mainVerticalZoneId"}
          value={area.mainVerticalZoneId}
          change={onCellChange}
          options={mvzOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.area.write.all}
          fieldName={"zone3DId"}
          value={area.zone3DId}
          change={onCellChange}
          options={zone3dOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.area.write.all}
          fieldName="shortInfoA"
          value={area.shortInfoA}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.area.write.all}
          fieldName="shortInfoB"
          value={area.shortInfoB}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.area.write.all}>
          <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCell>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: 'center'}} claim={ProjectClaims.project.area.write.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={area.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={area.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
}

export default AreaRowItem;
