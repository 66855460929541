import React, {ChangeEvent, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const styles = makeStyles<Theme, {minWidth: string}>(() => ({
    fileSelect: {
      marginTop: "0.5rem",
      display: "flex",
      flexWrap: "wrap-reverse",
      alignItems: "flex-start",
      marginBottom: "0.5rem",
      "& > label": {
        marginRight: "1rem",
        marginTop: "0.5rem"
      }
    },
    fileName: {
      minWidth: ({minWidth}) => minWidth,
    }
  }));

interface FileInputProps {
  /**
   * Accepted file types. See HTML input accept attribute
   */
  accept?: string;
  /**
   * Class name on the outer element
   */
  className?: string;
  fileChange: (file: File) => void;
  minWidth?: string
}

export const FileInput: React.FC<FileInputProps> = ({
  accept,
  className,
  fileChange,
  minWidth= "15rem"
}): JSX.Element => {
  const [file, setFile] = useState<File | null>(null);
  const classNames = styles({
    minWidth
  });

  useEffect(() =>{
    if(file != null) {
      fileChange(file);
    }
  }, [file])

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if(event && event.target && event.target.files && event.target.files.length === 1) {
      setFile(event.target.files[0]);
    }
  };



  return (
    <div className={className}>
      <div className={classNames.fileSelect}>
        <label htmlFor="button-file">
          <input style={{display: "none"}} accept={accept} id="button-file" type="file" onChange={onFileChanged}/>
          <Button variant="contained" component="span">
            Select file
          </Button>
        </label>
        <Typography
          className={classNames.fileName}
          variant="body1">
          {file?.name || <Typography variant="body2">...</Typography>}
        </Typography>
      </div>
    </div>
  )
}
