import React, {ReactElement, useContext} from "react";
import {Redirect, Route} from "react-router-dom";
import {UserContext} from "../../../shared/context/UserContext";
import {useClaims} from "../../../shared/hooks/useClaims";
import {AuthProps} from "../../../shared/interfaces/auth.interface";
import {PageSkeleton} from "../PageSkeleton";
import {CommonPaths} from "../../../shared/urls";

interface ProtectedRouteProps extends AuthProps {
  onDenyRedirectTo?: string
  path?: string
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  claim,
  claims,
  useExactMatch,
  onDenyRedirectTo = CommonPaths.project.list,
  path,
  children
}) : ReactElement<any, any> => {
  const {user} = useContext(UserContext);
  const {isValid} = useClaims();

  if(user.id == null) {
    return <PageSkeleton />;
  }

  const doRenderComponent = isValid({claim, claims, useExactMatch});

  return doRenderComponent
    ? <Route path={path}>{children}</Route>
    : <Redirect to={{pathname: onDenyRedirectTo}}/>
};
