import React, {FC} from 'react';
import {
  AreaForemanBundleTotals,
  AreaForemanCableTotals
} from "../../../shared/interfaces/areaForemanStatusInformation.interface";
import {Table, TableBody, TableHead, TableRow, Typography} from "@material-ui/core";
import './cbsForemanStatus.module.css';
import {TableCellInput} from '../../shared/table/styles';

interface CbsStatusTotalsProps {
  cableTotals: AreaForemanCableTotals,
  bundleTotals?: AreaForemanBundleTotals,
  freePullTotals: number,
  title: string
}

const CbsStatusTotals: FC<CbsStatusTotalsProps> = ({cableTotals, bundleTotals, freePullTotals, title}) => {
  return (
    <div>
    <Typography variant="subtitle1">Based on {title} belongs to CBS / Foreman</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellInput style={{width: '14rem'}} />
            <TableCellInput>Total</TableCellInput>
            <TableCellInput>Cut</TableCellInput>
            <TableCellInput>Fixed</TableCellInput>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellInput>Registered meters of cables</TableCellInput>
            <TableCellInput>{cableTotals.cableMeters}</TableCellInput>
            <TableCellInput>{cableTotals.cableMetersCut}</TableCellInput>
            <TableCellInput>{cableTotals.cableMetersFixed}</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput/>
            <TableCellInput>{cableTotals.metersCutPercentage}</TableCellInput>
            <TableCellInput>{cableTotals.metersFixedPercentage}</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>Registered no of cables</TableCellInput>
            <TableCellInput>{cableTotals.numberOfCables}</TableCellInput>
            <TableCellInput>{cableTotals.numberOfCablesCut}</TableCellInput>
            <TableCellInput>{cableTotals.numberOfCablesFixed}</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput/>
            <TableCellInput>{cableTotals.cableCutPercentage}</TableCellInput>
            <TableCellInput>{cableTotals.cableFixedPercentage}</TableCellInput>
          </TableRow>
          { bundleTotals !== undefined ? <TableRow>
                <TableCellInput>Registered no of bundles</TableCellInput>
                  <TableCellInput>{bundleTotals.bundleTotal}</TableCellInput>
                  <TableCellInput>{bundleTotals.bundleCutTotal}</TableCellInput>
                  <TableCellInput>{bundleTotals.bundlePulledTotal}</TableCellInput>
              </TableRow> : <TableRow ><TableCellInput style={{borderBottom: 'none'}}>&nbsp;</TableCellInput></TableRow>}
          { bundleTotals !== undefined ?<TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput/>
            <TableCellInput>{bundleTotals.cutPercent}</TableCellInput>
            <TableCellInput>{bundleTotals.pulledPercent}</TableCellInput>
          </TableRow> : <TableRow><TableCellInput style={{borderBottom: 'none'}}>&nbsp;</TableCellInput></TableRow> }
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellInput style={{width: '13rem'}} />
            <TableCellInput>Total</TableCellInput>
            <TableCellInput>From</TableCellInput>
            <TableCellInput>To</TableCellInput>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellInput>Registered no of connections</TableCellInput>
            <TableCellInput>{cableTotals.cablesConnectedTotal}</TableCellInput>
            <TableCellInput>{cableTotals.cablesConnectedFrom}</TableCellInput>
            <TableCellInput>{cableTotals.cablesConnectedTo}</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput/>
            <TableCellInput>{cableTotals.connectedFromPercentage}</TableCellInput>
            <TableCellInput>{cableTotals.connectedToPercentage}</TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>'Free - pull' registered</TableCellInput>
            <TableCellInput/>
            <TableCellInput/>
            <TableCellInput>{freePullTotals}</TableCellInput>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
};


export default CbsStatusTotals;
