import React from "react";
import {useTable} from "react-table";
import Pagination from "./Pagination";
import './table.module.css';
import {MuiThemeProvider, Table, TableBody, TableFooter, TableHead, TableRow,} from "@material-ui/core";
import {StyledTableRow, TableCellHeader, TableCellInput, tableCellStyles, tableTheme} from "./styles";
import {ReadOnlyPaginatedTableProps} from "./tableProps";


const ReadOnlyPaginatedTable= (
  {
    state,
    dispatch,
    columns,
    data,
    children,
    click,
    hiddenColumns,
    footer}: ReadOnlyPaginatedTableProps): JSX.Element  => {
  const classes = tableCellStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns || []
      }
    },
  )
  // Render the UI for your table

  return (
    <>
      <Table {...getTableProps()}>
        <TableHead >
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCellHeader style={{width: column.width}}{
                  ...column.getHeaderProps()}>{column.render('Header')} </TableCellHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <MuiThemeProvider theme={tableTheme} >
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <StyledTableRow {...row.getRowProps()} onClick={() => {
                click !== undefined ? click(row): {}}} >
                {row.cells.map((cell) => {
                  return <TableCellInput className={classes.root} {...cell.getCellProps()}>{cell.render('Cell')}</TableCellInput>
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
          {footer && (<TableFooter>{footer}</TableFooter>)}
      </MuiThemeProvider>
      </Table>
      {children}
      <Pagination state={state} dispatch={dispatch} />
    </>
  )
}
export default ReadOnlyPaginatedTable
