import React, {FunctionComponent} from "react";
import {FreePullCable} from "../../../shared/interfaces/freePullCable.interface";
import {Option} from "../../shared/inputs/VectSelect";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {handleCellChange} from "../../../shared/helpers/table";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../shared/theme";
import {ProjectClaims} from "../../../shared/claims";
import {VectTableRowSelect} from "../../shared/inputs/VectTableRowSelect";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {DeleteButton} from "../../shared/table/DeleteButton";
import {VectContainer} from "../../shared/layout/VectContainer";
import {VectTableTextField} from "../../shared/inputs/VectTableTextField";
import {VectDateTimeField} from "../../shared/inputs/VectDateTimeField";

type FreePullCableReadOnlyItemProps = {
  freePullCable: FreePullCable;
  change: (value: FreePullCable) => void;
  systemOptions: Option[] | null;
  cableTypeOptions: Option[] | null;
  areaForemanOptions: Option[] | null;
};

const FreePullCableRowItem: FunctionComponent<FreePullCableReadOnlyItemProps> = ({
  freePullCable,
  change,
  systemOptions,
  cableTypeOptions,
  areaForemanOptions,
}) => {
  const cellClasses = tableCellStyles();

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (): boolean => {
      return false;
    }

    handleCellChange(
      change,
      hasError,
      freePullCable,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...freePullCable,
      state: itemState
    })
  };

  const style = freePullCable.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.engineering.external}
          fieldName={"systemId"}
          value={freePullCable.systemId}
          change={onCellChange}
          options={systemOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.engineering.external}
          fieldName={"cableTypeId"}
          value={freePullCable.cableTypeId}
          change={onCellChange}
          options={cableTypeOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.production.coordinator}
          fieldName={"areaForemanId"}
          value={freePullCable.areaForemanId}
          change={onCellChange}
          options={areaForemanOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableTextField
          claim={ProjectClaims.project.engineering.external}
          fieldName="name"
          value={freePullCable.name}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableTextField
          claim={ProjectClaims.project.routing.external}
          fieldName="meters"
          value={freePullCable.meters}
          change={onCellChange}
          variant="standard"
          type="number"/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectCheckbox
          claim={ProjectClaims.project.engineering.external}
          fieldName={"isFreePullEstimated"}
          checked={freePullCable.isFreePullEstimated}
          change={onCellChange}
          color="primary"
          size={"small"}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableTextField
          claim={ProjectClaims.project.engineering.external}
          fieldName="week"
          value={freePullCable.week}
          change={onCellChange}
          variant="standard"/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectDateTimeField
          claim={ProjectClaims.project.engineering.external}
          fieldName={"registrationDate"}
          value={freePullCable.registrationDate}
          change={onCellChange}
        />
      </TableCellInput>

      <div style={{width: "2rem", display: "flex", flexDirection: "row"}}>
        {/* Delete */}
        <VectContainer claim={ProjectClaims.project.engineering.coordinator}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={freePullCable.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={freePullCable.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  );
};

export default FreePullCableRowItem;
