import {ProjectProps} from "../../../../shared/interfaces/project.interface";
import React, {useState} from "react";
import {VectDateTimeField} from "../../../shared/inputs/VectDateTimeField";
import {GlobalClaims} from "../../../../shared/claims";
import {makeStyles} from "@material-ui/core/styles";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {isProjectCodeValid} from "../../../../shared/validators";
import {Alert, AlertTitle} from "@material-ui/lab";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {useModals} from "../../../../shared/context/ModalContext";
import {ProjectCodeInfoModal} from "../ProjectCodeInfoModal";
import {IconButton} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const containerDefaults = {
  padding: "10px"
}
const gridDefaults = {
  ...containerDefaults,
  display: "grid",
  columnGap: "0.5rem",
  rowGap: "1rem",
  justifyItems: "start",
  alignItems: "end",
}

const useStyles = {
  infoContainer: makeStyles(() => ({
    container: {
      ...gridDefaults,
      gridTemplateColumns: "repeat(2, 12rem)",
      gridTemplateRows: "repeat(6, auto)",
    },
    code: {
      gridColumnStart: 1,
      gridRowStart: 1
    },
    codeInfo: {
      gridColumnStart: 2,
      gridRowStart: 1,
      justifySelf: "start",
      alignSelf: "start"
    },
    name: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2
    },
    start: {
      gridColumnStart: 1,
      gridRowStart: 3
    },
    end: {
      gridColumnStart: 2,
      gridRowStart: 3
    },
    cableListManager: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 4
    },
    projectSiteHull: {
      gridColumnStart: 1,
      gridRowStart: 5
    },
    projectSiteFinishing: {
      gridColumnStart: 2,
      gridRowStart: 5
    },
    acceptZeroMeterBundleOverride: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 6
    }
  }))
};

export const EditProjectProps: React.FC<{
  project: ProjectProps,
  change: (val: ProjectProps, hasError: boolean) => void,
  /**
   * Some different field handling on new and existing projects
   */
  newProject?: boolean
}> = ({
  project,
  change,
  newProject= false
}): JSX.Element => {
  const [didChangeCode, setDidChangeCode] = useState(false);
  const {openModal} = useModals();
  const infoContainer = useStyles.infoContainer();
  const errors = {
    code: isProjectCodeValid(project.code) ? undefined : "This is not a valid Project ID",
    name: project.name && project.name.length > 0 ? undefined : "A project text is required"
  };

  const onChange = (val: string | Date | null | undefined | boolean, fieldName?: string) => {
    if(fieldName != null){
      if(fieldName === "code") {
        setDidChangeCode(true);
      }

      const value = typeof(val) === "string"
        ? (val ? val : null)
        : val;

      const newState = {
        ...project,
        [fieldName]: value
      };

      const hasError = !isProjectCodeValid(newState.code) || project.name == null || project.name.length === 0;
      change(newState, hasError);
    }
  }

  return (
    <div>
      {didChangeCode && !newProject && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Changing project ID should only be done when setting up a new project. Changing the project id with logged on users can result in failures to store unsaved data.</Alert>
      )}
      <div className={infoContainer.container}>
        <VectTextField claim={GlobalClaims.manage.project.readWrite.all}
                       className={infoContainer.code}
                       fullWidth
                       required
                       fieldName="code"
                       change={onChange}
                       value={project.code}
                       label="Project ID"
                       error={!!errors.code}
                       helperText={errors.code}
        />
        <div className={infoContainer.codeInfo}>
          <IconButton color="primary" onClick={() => openModal && openModal(ProjectCodeInfoModal)}>
            <HelpOutlineIcon />
          </IconButton>
        </div>
        <VectTextField claim={GlobalClaims.manage.project.readWrite.all}
                       className={infoContainer.name}
                       fullWidth
                       required
                       fieldName="name"
                       change={onChange}
                       value={project.name}
                       label="Text"
                       error={!!errors.name}
                       helperText={errors.name}/>
        <div className={infoContainer.start}>
          <VectDateTimeField claim={GlobalClaims.manage.project.readWrite.all}
                             inputVariant="filled"
                             fieldName={"startDate"}
                             change={onChange}
                             value={project.startDate || null}
                             label="Project start"/>
        </div>
        <div className={infoContainer.end}>
          <VectDateTimeField claim={GlobalClaims.manage.project.readWrite.all}
                             inputVariant="filled"
                             fieldName={"stopDate"}
                             change={onChange}
                             value={project.stopDate || null}
                             label="Project stop"/>
        </div>
        <VectTextField claim={GlobalClaims.manage.project.readWrite.all}
                       className={infoContainer.cableListManager}
                       fullWidth
                       fieldName="projectManager"
                       change={onChange}
                       value={project.projectManager}
                       label="Cable list manager"/>
        <VectTextField claim={GlobalClaims.manage.project.readWrite.all}
                       className={infoContainer.projectSiteHull}
                       fullWidth
                       fieldName="projectSiteHull"
                       change={onChange}
                       value={project.projectSiteHull}
                       label="Project site 1"/>
        <VectTextField claim={GlobalClaims.manage.project.readWrite.all}
                       className={infoContainer.projectSiteFinishing}
                       fullWidth
                       fieldName="projectSiteFinishing"
                       change={onChange}
                       value={project.projectSiteFinishing}
                       label="Project site 2"/>
        <VectCheckbox claim={GlobalClaims.manage.project.readWrite.all}
                      className={infoContainer.acceptZeroMeterBundleOverride}
                      label="Accept 0 meter bundles"
                      checked={project.acceptZeroMeterBundleOverride}
                      change={onChange}
                      fieldName="acceptZeroMeterBundleOverride"
                      color="primary"/>
      </div>
    </div>
  );
}
