import React, {
  FC,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  PaginationRequest
} from "../../../shared/interfaces/pagination.interface";
import {tableReducer} from "../../../shared/reducers/tableReducer";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import { useApi } from "../../../shared/hooks/useApi";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import ReadOnlyPaginatedTable from "../../shared/table/ReadOnlyPaginatedTable";



const ThreeDZones: FC = () => {
  const [state, dispatch] = useReducer(tableReducer, {values: [], currentPage: 1, currentPageSize: 0, pageSize: 50, totalPages: 0, totalValues: 0});
  const {project} = useContext(ProjectContext);
  const {post} = useApi();
  useEffect(() => {

    let isSubScribed = true;
      const  fetchZones = async() => {
        const paginationQuery: PaginationRequest = {page: state.currentPage, pageSize: state.pageSize}
        const result = await post<PaginationRequest, any>(`/project/${project?.id}/zone3d`, paginationQuery).then(res => res)
        if(isSubScribed && result !== undefined) {
          dispatch({type: "SET_STATE", payload: () =>result})
        }
    }
    fetchZones()
    return () => {
      isSubScribed = false
    };
  }, [state.pageSize, state.currentPage])

  const data = React.useMemo(
    () => state.values,
    [state.values]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: '3D Zone Id',
        accessor: 'code',
        type: "number",
        width: "10rem"

      },
      {
        Header: 'Text, covering areas',
        accessor: 'name',
        type: "text",
        width: "50rem"
      },
    ],
    []
  )

  return(
    <div>
      <PanelHeader text={"3D zones used in model"} />
      <ReadOnlyPaginatedTable state={state} dispatch={dispatch} columns={columns} data={data} />
    </div>)
}
export default ThreeDZones;
