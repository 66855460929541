import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Bundle} from "../../../shared/interfaces/bundle.interface";
import {Areaforeman} from "../../../shared/interfaces/areaforeman.interface";
import {formatDate} from "../../../shared/helpers/date";
import {ProductionResponsible} from "../../../shared/interfaces/productionResponsible.interface";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";
import {handleCellChange} from "../../../shared/helpers/table";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {ProjectClaims} from "../../../shared/claims";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {useModals} from "../../../shared/context/ModalContext";
import {Modal} from "../../shared/Feedback/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {CaptionedText} from "../../shared/CaptionedText";
import {promptIfUnsavedChanges} from "../../shared/UnsavedChangesModal";
import {dp3Options, productionStatusOptions, signalTypeOptions} from '../bundle-register/options';
import {mapAreaForemanOptions} from "../../../shared/helpers/metadata";
import {Project} from "../../../shared/interfaces/project.interface";

/**
 * See also src/components/project/bundle-register/options.ts
 */

const statusOpts: Option[] = [
  {value: 1, selectedLabel: "0", label: "0 Ready - but on HOLD"},
  {value: 2, selectedLabel: "1", label: "1 Ready for measuring"},
  {value: 3, selectedLabel: "2", label: "2 Measured - ready for checkout"},
  {value: 4, selectedLabel: "R", label: "R Released for production"},
  {value: 5, selectedLabel: "L", label: "L Labelled (sent to cut/pull/fix)"},
]

const gridStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "7rem 20rem 20rem 15rem 10rem 10rem",
    gridTemplateRows: "repeat(5, auto)",
    columnGap: "20px",
    rowGap: "10px"
  },
  bundleStatus: {
    gridColumnStart: 1,
    gridRowStart: 1
  },
  bundleId: {
    gridColumnStart: 2,
    gridRowStart: 1
  },
  bundleText: {
    gridColumnStart: 1,
    gridColumnEnd: "span 2",
    gridRowStart: 2
  },
  bundleExtendedInformation: {
    gridColumnStart: 1,
    gridColumnEnd: "span 2",
    gridRowStart: 3,
    gridRowEnd: "span 3",
    alignSelf: "stretch"
  },
  printCableId: {
    gridColumnStart: 4,
    gridRowStart: 1,
    justifySelf: "start"
  },
  areaForeman: {
    gridColumnStart: 3,
    gridRowStart: 2
  },
  dp3Zone: {
    gridColumnStart: 4,
    gridRowStart: 2
  },
  areaForemanText: {
    gridColumnStart: 3,
    gridColumnEnd: "span 2",
    gridRowStart: 3
  },
  areaForemanName: {
    gridColumnStart: 3,
    gridColumnEnd: "span 2",
    gridRowStart: 4
  },
  printedBundle: {
    gridColumnStart: 5,
    gridRowStart: 1
  },
  printedLabel: {
    gridColumnStart: 5,
    gridRowStart: 2
  },
  cutBundle: {
    gridColumnStart: 5,
    gridRowStart: 3
  },
  pulledBundle: {
    gridColumnStart: 5,
    gridRowStart: 4
  },
  productionResponsible: {
    gridColumnStart: 6,
    gridRowStart: 1
  },
  productionStatus: {
    gridColumnStart: 6,
    gridRowStart: 2
  },
  releasedDate: {
    gridColumnStart: 6,
    gridRowStart: 3
  },
  srtpCables: {
    gridColumnStart: 6,
    gridRowStart: 4,
    justifySelf: "start"
  }

}))

interface BundlePropertiesProps {
  areaForemen: Areaforeman[];
  bundle: Bundle | null;
  bundles: Bundle[];
  change: (bundle: Bundle) => void;
  changeBundle: (value: number | null) => void;
  isDirty: boolean;
  productionResponsible: ProductionResponsible[];
  project: Project;
  
}

const BundleProperties: FC<BundlePropertiesProps> = ({
  areaForemen,
  bundle,
  bundles,
  change,
  changeBundle,
  isDirty,
  productionResponsible,
  project
}) => {
  const [filteredBundles, setFilteredBundles] = useState<Option[] | null>(null);
  const [selectedBundleId, setSelectedBundleId] = useState<number | null>(bundle?.id || null);
  const [selectedStatus, setSelectedStatus] = useState<Option | null>(null);
  const grid = gridStyles();
  const {openModal, closeModal} = useModals();

  useEffect(() => {
    if(!bundles) {
      setFilteredBundles(null);
      return;
    }

    if(!selectedStatus) {
      const result = bundles.map(x => ({value: x.id, label: `${x.code} ${x.name}`}));
      setFilteredBundles(result);
      return;
    }

    const result = bundles
      .filter(x => x.status === selectedStatus.selectedLabel)
      .map(x => ({value: x.id, label: `${x.code} ${x.name}`}));
    setFilteredBundles(result);

    if(result.length === 0) {
      changeBundle(null);
      return;
    } else if(selectedBundleId != null) {
      if(!result.map(x => x.value).includes(selectedBundleId)) {
        changeBundle(null);
      }
    }

  }, [bundles, selectedStatus])


  const selectedForeman = areaForemen.find(x => x.id === bundle?.areaForemanId);
  const areaForemanOptions = useMemo(():Option[] | null => mapAreaForemanOptions(areaForemen),
    [areaForemen])

  const prodRespOptions = useMemo(():Option[] | null =>
    productionResponsible?.map(x => ({value: x.id, label: `${x.code} ${x.name}`})) || null,
    [productionResponsible]);

  const handleDp3ZoneChange = useCallback((newValue: Bundle)  => {
    if (openModal) {
      openModal(Modal, {
        title: "Warning",
        description:
          "Should all cables in the bundle be updated with this DP Zone" +
          " Info?",
        ok: () => {
          if (closeModal) {
            closeModal();
          }
          newValue.updateDp3ZoneForAllCables = true;
          change(newValue);
        },
        cancel: () => {
          if (closeModal) {
            closeModal();
          }
          newValue.updateDp3ZoneForAllCables = false;
          change(newValue);
        },
        okText: "Yes",
        cancelText: "No"
      });
    }
  }, [change])

  const handleProductionStatusChange = useCallback((newValue: Bundle, prevValue: Bundle) => {
    const onOkProductionStatus = () => {
      if (closeModal) {
        closeModal();
        change(newValue);
      }
    };
    const onCancelProductionStatus = () => {
      if (closeModal) {
        closeModal();
        change(prevValue);
      }
    };
    const oldStatus = prevValue.productionStatus;
    const checked = "Checked - ready for production"
    const ready = "Ready for measuring";
    const measured = "Measured - ready for checkout"
    const onHold = "Ready - but on HOLD"
    const draft = "Draft - not ready for production"
    const modalStringRemoveAll = "Are you sure you want to change to a" +
      " lower" +
      " status? The" +
      " yy-ww info for all cables which have been set to Ready for Measuring & Production in this bundle will be removed!"
    const modalStringRemoveProd = "Are you sure you want to change to a lower status from Relased for Production? The yy-ww info for all cables which have been set to Ready for Production in this bundle will be removed!";
    switch (newValue.productionStatus) {
      case draft:
      case onHold:
        if(oldStatus === checked || oldStatus === ready || oldStatus === measured) {
          if (openModal) {
            openModal(Modal, {
              title: "Warning",
              description: modalStringRemoveAll,
              ok: onOkProductionStatus,
              cancel: onCancelProductionStatus,
              okText: "Yes",
              cancelText: "No"
            });
          }
        } else {
          change(newValue);
        }

        break;
      case ready:
        if(oldStatus === checked ){
          if (openModal) {
            openModal(Modal, {
              title: "Warning",
              description: modalStringRemoveProd,
              ok: onOkProductionStatus,
              cancel: onCancelProductionStatus,
              okText: "Yes",
              cancelText: "No"
            });
          }
        } else {
          if(newValue.zeroLengthCablesEstimated > 0) {
            if(!project?.acceptZeroMeterBundleOverride) {
              if (openModal) {
                openModal(Modal, {
                  title: "Warning",
                  description: newValue.zeroLengthCablesEstimated + " cables found with 0 value in" +
                    " Calc." +
                    " Meter. Please add values in Calc. Meter before releasing to actual measuring.",
                  ok: onCancelProductionStatus,
                  okText: "Ok",
                });
              }
            } else {
              if (openModal) {
                openModal(Modal, {
                  title: "Warning",
                  description: newValue.zeroLengthCablesEstimated + " cables found with 0 value in Calc. Meter. This is not recommended, and should be corrected. But you can override this by select Yes. Continue with override?",
                  ok: onOkProductionStatus,
                  cancel: onCancelProductionStatus,
                  okText: "Yes",
                  cancelText: "No"
                });
              }
            }
          } else {
            change(newValue);
          }
        }
        break;
      case measured:
        if(oldStatus === checked ){
          if (openModal) {
            openModal(Modal, {
              title: "Warning",
              description: modalStringRemoveProd,
              ok: onOkProductionStatus,
              cancel: onCancelProductionStatus,
              okText: "Yes",
              cancelText: "No"
            });
          }
        } else {
          change(newValue)
        }
        break;
      case checked:

        if(newValue.zeroLengthCables > 0) {
          if(!project?.acceptZeroMeterBundleOverride) {
            if (openModal) {
              openModal(Modal, {
                title: "Warning",
                description: newValue.zeroLengthCables + " cables found with 0 value in" +
                  " Meter. Please add values in Calc. Meter before releasing to actual measuring.",
                ok: onCancelProductionStatus,
                okText: "Ok",
              });
            }
          } else {
            if (openModal) {
              openModal(Modal, {
                title: "Warning",
                description: newValue.zeroLengthCables + " cables found with 0 value in Meter. This is not recommended, and should be corrected. But you can override this by select Yes. Continue with override?",
                ok: onOkProductionStatus,
                cancel: onCancelProductionStatus,
                okText: "Yes",
                cancelText: "No"
              });
            }
          }
        } else {
          change(newValue)
        }
        break;
      default:
        change(newValue)
    }
  }, [change]);

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {

    if(bundle != null) {

      let changeCallback;
      switch (fieldName) {
        case "dp3Zone":
          changeCallback = handleDp3ZoneChange;
          break;
        case "productionStatus":
          changeCallback = handleProductionStatusChange;
          break;
        default:
          changeCallback = change;
          break;
      }

      const hasError = ():boolean => false;

      handleCellChange(
        changeCallback,
        hasError,
        bundle,
        value,
        fieldName
      );
    }
  };

  const onCellChangeBundle = (value: number | null) => {
    const action = () => {
      setSelectedBundleId(value);
      if(value != null) {
        changeBundle(value);
      }
    }

    promptIfUnsavedChanges(openModal, isDirty, action);
  }

  const onCellChangeDp3Zone = (value: number | null) => {
    const dp3zone = value != null
      ? dp3Options.find(x => x.value === value)?.label
      : undefined;
    onCellChange(dp3zone, "dp3Zone");
  }

  const onCellChangePowerSignal = (value: number | null) => {
    const signalType = signalTypeOptions.find(x => x.value === value)?.selectedLabel;
    onCellChange(signalType, "bundleType");
  }

  const onCellChangeProdStatus = (value: number | null) => {
    const prodStatus = productionStatusOptions.find(x => x.value === value)?.label;
    onCellChange(prodStatus, "productionStatus");
  }

  const onStatusChange = (value: number | null) => {
    const action = () => {
      if(value == null) {
        setSelectedStatus(null);
      }
      const option = statusOpts.find(x => x.value === value);
      setSelectedStatus(option!);
    };

    promptIfUnsavedChanges(openModal, isDirty, action);
  }

  return (
    <div className={grid.container}>
      <VectSelect className={grid.bundleStatus}
                  label={"Status"}
                  change={onStatusChange}
                  value={selectedStatus?.value}
                  placeholder="Status"
                  options={statusOpts}
                  isClearable={true}/>
      <VectSelect className={grid.bundleId}
                  label="Bundle ID"
                  change={onCellChangeBundle}
                  value={selectedBundleId}
                  placeholder={"Select a bundle"}
                  options={filteredBundles}/>
      <VectTextField className={grid.bundleText}
                     claim={ProjectClaims.project.routing.external}
                     label="Text"
                     value={bundle?.name}
                     fieldName="name"
                     change={onCellChange}/>
      <VectTextField className={grid.bundleExtendedInformation}
                     claim={ProjectClaims.project.routing.external}
                     label="Extra info"
                     value={bundle?.extendedInformation}
                     fieldName="extendedInformation"
                     change={onCellChange}
                     multiline/>
      <VectSelect claim={ProjectClaims.project.routing.external}
                  label="Signal type"
                  placeholder="Power / Signal"
                  options={signalTypeOptions}
                  styles={{width: "12rem"}}
                  value={signalTypeOptions.find(x => x.selectedLabel === bundle?.bundleType)?.value}
                  change={onCellChangePowerSignal} />
      <VectCheckbox className={grid.printCableId}
                    claim={ProjectClaims.project.routing.external}
                    label="Print Cable ID on Cables:"
                    checked={bundle?.doPrintId}
                    change={onCellChange}
                    fieldName="doPrintId"
                    color="primary"/>
      <VectSelect className={grid.areaForeman}
                  claim={ProjectClaims.project.production.coordinator}
                  label="Area foreman"
                  options={areaForemanOptions}
                  value={bundle?.areaForemanId}
                  fieldName={"areaForemanId"}
                  change={onCellChange}
                  placeholder={"Area ID"}/>
      <VectSelect className={grid.dp3Zone}
                  claim={ProjectClaims.project.routing.external}
                  options={dp3Options}
                  value={dp3Options.find(x => x.label === bundle?.dp3Zone)?.value}
                  isClearable={true}
                  label="DP3 zone"
                  change={onCellChangeDp3Zone}
                  placeholder={"Bundle DP3 Zone"}/>
      <CaptionedText className={grid.areaForemanText} label="Area text">
        {selectedForeman?.foremanText}
      </CaptionedText>
      <CaptionedText className={grid.printedBundle} label="Printed Bundle">
        {formatDate(bundle?.printedDate)}
      </CaptionedText>
      <VectSelect className={grid.productionResponsible}
                  claim={ProjectClaims.project.routing.external}
                  label="Production responsible"
                  options={prodRespOptions}
                  value={bundle?.cableProductionResponsibleId}
                  styles={{width: "12rem"}}
                  fieldName={"cableProductionResponsibleId"}
                  change={onCellChange}
                  placeholder={"Production Responsible"}/>
      <CaptionedText className={grid.printedLabel} label="Printed Label">
        {formatDate(bundle?.labelledDate)}
      </CaptionedText>
      <VectSelect className={grid.productionStatus} options={productionStatusOptions}
                  label="Production status"
                  value={productionStatusOptions.find(x => x.label === bundle?.productionStatus)?.value}
                  change={onCellChangeProdStatus}
                  placeholder={"Production status"}/>
      <CaptionedText className={grid.cutBundle} label="Cutted Bundle">
        {bundle?.cut}
      </CaptionedText>
      <CaptionedText className={grid.pulledBundle} label="Pulled Bundle">
        {bundle?.pulled}
      </CaptionedText>
      <VectCheckbox className={grid.srtpCables}
                    color="primary"
                    checked={bundle?.hasSpecialRequirements}
                    label="SRtP Cables" />
      <CaptionedText className={grid.releasedDate} label="Released Date">
        {formatDate(bundle?.releasedDate)}
      </CaptionedText>
      {/*<div className={styles.propertyChild}>*/}
      {/*  <Button onClick={() => getPdf()}>Print Bundle list</Button>*/}
      {/*</div>*/}
      {/*<div className={styles.propertyChild}>*/}
      {/*  <Button onClick={() => printLabel(true)}>Preview Label</Button>*/}
      {/*  <Button onClick={() => printLabel(false)}>Print Label</Button>*/}
      {/*  <Select value={1} disabled>*/}
      {/*  <MenuItem value={1}>105 x 33.8</MenuItem>*/}
      {/*  </Select>*/}
      {/*</div>*/}
    </div>
  )
};


export default BundleProperties;
