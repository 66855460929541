import React, {FC, useContext, useEffect, useState} from "react";
import {AlertContext} from "../../../shared/context/AlertContext";
import {initialState} from "../../../shared/interfaces/alert.interface";
import './alert.css'
import {Snackbar, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {ProblemDetailError} from "../../../shared/hooks/useApi";


const AlertComponent: FC = () => {

  const {alert, setAlert} = useContext(AlertContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const clearAlert = () => {
   setIsVisible(false);
   setAlert(initialState);
  };

  const autoHideDuration = alert.type === "error" ? 30000 : 5000;

  useEffect(() => {
    if(alert.type !== undefined){
      setIsVisible(true);
      setTimeout(clearAlert, autoHideDuration )
    }

    if(alert.error) {
      if(alert.error instanceof ProblemDetailError) {
        const error: ProblemDetailError = alert.error;
        const data = [
          `status: ${error.status}`,
          `type: ${error.type}`,
          `instance: ${error.instance}`,
          `title: ${error.title}`,
          `detail: ${error.detail}`

        ];
        if(error.errors) {
          data.push(`errors: ${JSON.stringify(error.errors)}`);
        }

        console.error(alert.text, data)
      } else {
        console.error(alert.error);
      }
    }
  }, [alert])
  if(!isVisible)
  {
    return null;
  }


  const standardError: Error | null = alert.error?.name === "Error" ? alert.error as Error: null;
  const problemDetailError: ProblemDetailError | null = alert.error?.name === "ProblemDetailError" ? alert.error as ProblemDetailError : null;

  let key = 0;

  return(
   <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right' }}
             open={isVisible}
             autoHideDuration={autoHideDuration}
             onClose={clearAlert} >
     <Alert severity={alert.type}>
       <Typography variant="subtitle1">{alert.text}</Typography>
       {alert.details && alert.details.map(detail => <Typography key={key++} variant="body1">{detail}</Typography>)}
       {standardError &&(
         <Typography variant="body2">{standardError.message}</Typography>
       )}
       {
         problemDetailError && (
           <div>
             <Typography variant="body1">{problemDetailError.title}</Typography>
             {problemDetailError.detail?.includes("\n")
              ? problemDetailError.detail?.split("\n").map(s => <Typography key={key++} variant="body2">{s}</Typography>)
              : <Typography variant="body2">{problemDetailError.detail}</Typography>}
           </div>
         )
       }

     </Alert>
   </Snackbar>
  )
}
export default AlertComponent;
