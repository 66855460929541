import React, {FC} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {AreaForemanTagTotals} from "../../../shared/interfaces/areaForemanStatusInformation.interface";

interface TagTotalsProps{
  tagTotals: AreaForemanTagTotals
}

const TagTotals: FC<TagTotalsProps> = ({tagTotals}) => {
  return (
    <Table style={{width: '30%'}}>
    <TableHead>
      <TableRow>
        <TableCell/>
        <TableCell>Total</TableCell>
        <TableCell />
        <TableCell>Mounted</TableCell>
      </TableRow>
    </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Registered Tag(Components) </TableCell>
          <TableCell>{tagTotals.totalTagsForeman}</TableCell>
          <TableCell />
          <TableCell>{tagTotals.totalTagsMountedForeman}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell/>
          <TableCell>{tagTotals.totalPercentage}</TableCell>
          <TableCell />
          <TableCell>{tagTotals.mountedPercentage}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
};


export default TagTotals;
