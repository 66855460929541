import {makeStyles} from "@material-ui/core/styles";

export const footerStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    "& > button": {
      marginTop: 5,
      marginBottom: 5,
      marginRight: 20,
      minWidth: "15rem"
    },
    "& > div": {
      marginTop: 5,
      marginBottom: 5,
      marginRight: 20,
      minWidth: "15rem"
    },
    "& > label": {
      marginTop: 5,
      marginBottom: 5,
      marginRight: 20,
      minWidth: "15rem"
    }
  }
}));
