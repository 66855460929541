import React, {FC} from "react";
import {Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import {TableCellInput} from "../../../shared/table/styles";
import {SystemBundleTotal, SystemCableTotal,} from "../../../../shared/interfaces/systemStatusInformation";

interface SystemStatusTotalsProps {
  cableTotals: SystemCableTotal;
  bundleTotals: SystemBundleTotal;
  freePullTotals: number;
}

const SystemStatusTotals: FC<SystemStatusTotalsProps> = ({
  cableTotals,
  bundleTotals,
  freePullTotals,
}) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellInput style={{ width: "14rem" }} />
            <TableCellInput>Total</TableCellInput>
            <TableCellInput>Cut</TableCellInput>
            <TableCellInput>Fixed</TableCellInput>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellInput>
              Registered meters of cables
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cableMeters}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cableMetersCut}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cableMetersFixed}
            </TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput />
            <TableCellInput>
              {cableTotals.metersCutPercentage}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.metersFixedPercentage}
            </TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>Registered no of cables</TableCellInput>
            <TableCellInput>
              {cableTotals.numberOfCables}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.numberOfCablesCut}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.numberOfCablesFixed}
            </TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput />
            <TableCellInput>
              {cableTotals.cableCutPercentage}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cableFixedPercentage}
            </TableCellInput>
          </TableRow>
          {bundleTotals !== undefined ? (
            <TableRow>
              <TableCellInput>
                Registered no of bundles
              </TableCellInput>
              <TableCellInput>
                {bundleTotals.bundleTotal}
              </TableCellInput>
              <TableCellInput>
                {bundleTotals.bundleCutTotal}
              </TableCellInput>
              <TableCellInput>
                {bundleTotals.bundlePulledTotal}
              </TableCellInput>
            </TableRow>
          ) : (
            <TableRow>
              <TableCellInput style={{ borderBottom: "none" }}>
                &nbsp;
              </TableCellInput>
            </TableRow>
          )}
          {bundleTotals !== undefined ? (
            <TableRow>
              <TableCellInput>% of registered</TableCellInput>
              <TableCellInput />
              <TableCellInput>
                {bundleTotals.cutPercent}
              </TableCellInput>
              <TableCellInput>
                {bundleTotals.fixedPercent}
              </TableCellInput>
            </TableRow>
          ) : (
            <TableRow>
              <TableCellInput style={{ borderBottom: "none" }}>
                &nbsp;
              </TableCellInput>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellInput style={{ width: "14rem" }} />
            <TableCellInput>Total</TableCellInput>
            <TableCellInput>From</TableCellInput>
            <TableCellInput>To</TableCellInput>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellInput>
              Registered no of connections
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cablesConnectedTotal}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cablesConnectedFrom}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.cablesConnectedTo}
            </TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>% of registered</TableCellInput>
            <TableCellInput />
            <TableCellInput>
              {cableTotals.connectedFromPercentage}
            </TableCellInput>
            <TableCellInput>
              {cableTotals.connectedToPercentage}
            </TableCellInput>
          </TableRow>
          <TableRow>
            <TableCellInput>
              'Free - pull' registered
            </TableCellInput>
            <TableCellInput />
            <TableCellInput />
            <TableCellInput>{freePullTotals}</TableCellInput>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};


export default SystemStatusTotals;
