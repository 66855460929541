import React from "react"
import VectAppBar from "./layout/VectAppBar";
import MainPanel from "./layout/MainPanel/MainPanel";
import {Skeleton} from "@material-ui/lab";

export const PageSkeleton: React.FC = (): JSX.Element => {
  return <VectAppBar header="Vard Electro Cable Tool">
    <MainPanel>
      <Skeleton height="5rem" width="20rem"/>
    </MainPanel>
  </VectAppBar>
}
