import React from "react";
import {DialogConfirmationChallenge, DialogProps, Modal} from "./Modal";

export interface DeleteConfirmationModalProps {
  confirmation?: DialogConfirmationChallenge;
  context?: string;
  description?: string;
  jsx?: JSX.Element;
  onDelete: () => void;
  open?: boolean;
  title?: string;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  confirmation,
  context= "Going further will delete it and all the items in it permanently.",
  description= "The action you are about to take can not be undone.",
  jsx,
  onDelete,
  title = "Deleting this item is irreversible"

}) => {

  const dialogProps: DialogProps = {
    cancelColor: "default",
    cancelText: "Cancel",
    confirmation,
    description,
    context,
    jsx,
    ok: onDelete,
    okColor: "secondary",
    okText: "Delete",
    progressColor: "secondary",
    title
  }
  return <Modal {...dialogProps} />;
}
