const isStorageAvailable = (): boolean => typeof Storage !== "undefined";

const showDrawer = "showDrawer";

export const getShowDrawer = (): boolean => {
  return isStorageAvailable()
    ? localStorage.getItem(showDrawer) === "true"
    : false;
}

export const setShowDrawer = (val: boolean): void => {
  if (isStorageAvailable()) {
    localStorage.setItem(showDrawer, val.toString());
  }
}
