import {Action, TableItemState} from "./common.interface";
import {getYearWeekFormat} from "../helpers/date";
import {Area} from "./area.interface";
import {Tag} from "./tag.interface";
import {PatchResponse, PatchResponseItem} from "./patchRequest";

export interface CableWithReferenceLabels extends Cable{
  // Read only
  cableTypeCode?: string;
  cableTypeIntStxId?: string;
  fromTagCode?: string;
  fromAreaCode?: string;
  fromTagText?: string;
  toTagCode?: string;
  toAreaCode?: string;
  toTagText?: string;
  bundleCode?: string;
  bundleText?: string;
  fromMainVerticalZoneCode?: string;
  toMainVerticalZoneCode?: string;
  fromConnectionAreaForemanCode?: string;
  toConnectionAreaForemanCode?: string;
  from3dZoneCode?: string;
  to3dZoneCode?: string;
  cableClassCode?: string;

  // Web App Properties
  isMarked: boolean;
  hasError: boolean;
  state?: TableItemState;
}

export interface Cable extends CableProps{
  id: number;
  projectId: number;
}

export interface CableListItem {
  id: number;
  cableNumber?: string;
  code?: string;
  cableTypeCode?: string;
  fromTagId?: number;
  fromTagCode?: string
  fromText?: string;
  fromAreaCode?: string;
  toTagId?: number;
  toTagCode?: string
  toText?: string;
  toAreaCode?: string;
}

/**
 * Updatable properties on Cable
 */
export interface CableProps {
  code: string;
  cableClassId?: number;
  cableNumber?: string;
  cableTypeId?: number;
  systemId: number;
  fromTagId?: number;
  toTagId?: number;
  fromAreaId?: number;
  toAreaId?: number;
  fromText?: string;
  toText?: string;
  bundleId?: number;
  meters: number;
  markPlacement?: string;
  penetrations?: string;
  cutWeek?: string;
  pulledWeek?: string;
  localId?: string;
  cableDate?: Date;
  dp3Zone?: string;
  isConnectedFrom: boolean;
  isConnectedTo: boolean;
  estimatedMeters: number;
  fromMainVerticalZoneId?: number;
  toMainVerticalZoneId?: number;
  fromZone3dId?: number;
  toZone3dId?: number;
  hasSpecialRequirements: boolean;
  fromConnectionAreaForemanId?: number;
  toConnectionAreaForemanId?: number;
  registrationWeek?: string;
  releaseForMeasuringWeek?: string;
  releaseForProductionWeek?: string;
  connectedFromWeek?: string;
  connectedToWeek?: string;
  meterRegistrationInWeek?: string;
  cableTrayMeters?: number;
  cableTrayExtraMeters?: number;
}

export interface BatchUpdateCableProps {
  bundleId?: number;
  cableClassId?: number;
  cableTypeId?: number;
  connectedFrom?: boolean
  connectedTo?: boolean
  convertEstimated?: boolean
  cutWeek?: string;
  dp3Zone?: string
  estimatedMeters?: number;
  fromAreaId?: number;
  fromConnectionAreaForemanId?: number;
  fromTagId?: number;
  fromText?: string;
  hasSpecialRequirements?: boolean;
  markPlacement?: string
  meters?: number;
  penetrations?: string
  pulledWeek?: string
  releaseForMeasuringWeek?: boolean
  switchFromTo?: boolean
  toAreaId?: number;
  toConnectionAreaForemanId?: number;
  toTagId?: number;
  toText?: string;
}

export interface BatchUpdateCablesRequest extends BatchUpdateCableProps {
  cableIds: number[];
  returnCableIds: number[];
  tagTextOverrides?: TagTextOverride[]
}

export interface TagTextOverride {
  cableId: number;
  fromText?: string;
  toText?: string;
}

export const handleSideEffects = (
  prevCable: CableWithReferenceLabels,
  currentCable: CableWithReferenceLabels,
  system: { id: number, code: string },
  areas: Area[],
  tags: Tag[],
  ): void => {
  if (prevCable.cableNumber !== currentCable.cableNumber) {
    currentCable.code = `${system.code}.${currentCable.cableNumber || ""}`;
  }

  if (prevCable.fromTagId !== currentCable.fromTagId) {
    const newFromTag = tags && tags.find(x => x.id === currentCable.fromTagId);
    if (newFromTag) {
      currentCable.fromText = newFromTag.name;
      currentCable.fromAreaId = newFromTag.areaId;
      currentCable.fromConnectionAreaForemanId = newFromTag.areaForemanId;
      currentCable.fromZone3dId = newFromTag.zone3dId;
      if (newFromTag.mainVerticalZoneId != null) {
        currentCable.fromMainVerticalZoneId = newFromTag.mainVerticalZoneId;
      }

      if (newFromTag.zone3dId != null) {
        currentCable.fromZone3dId = newFromTag.zone3dId;
      }
    }
  }

  if (prevCable.toTagId !== currentCable.toTagId) {
    const newToTag = tags && tags.find(x => x.id === currentCable.toTagId);
    if (newToTag) {
      currentCable.toText = newToTag.name;
      currentCable.toAreaId = newToTag.areaId;
      currentCable.toConnectionAreaForemanId = newToTag.areaForemanId;
      currentCable.toZone3dId = newToTag.zone3dId;
      if (newToTag.mainVerticalZoneId != null) {
        currentCable.toMainVerticalZoneId = newToTag.mainVerticalZoneId;
      }

      if (newToTag.zone3dId != null) {
        currentCable.toZone3dId = newToTag.zone3dId;
      }
    }
  }

  if (prevCable.fromAreaId !== currentCable.fromAreaId) {
    const newFromArea = areas && areas.find(x => x.id === currentCable.fromAreaId);
    if (newFromArea) {
      if (newFromArea.mainVerticalZoneId != null) {
        currentCable.fromMainVerticalZoneId = newFromArea.mainVerticalZoneId;
      }

      if (newFromArea.zone3DId != null) {
        currentCable.fromZone3dId = newFromArea.zone3DId;
      }
    }
  }

  if (prevCable.toAreaId !== currentCable.toAreaId) {
    const newFromArea = areas && areas.find(x => x.id === currentCable.toAreaId);
    if (newFromArea) {
      if (newFromArea.mainVerticalZoneId != null) {
        currentCable.toMainVerticalZoneId = newFromArea.mainVerticalZoneId;
      }

      if (newFromArea.zone3DId != null) {
        currentCable.toZone3dId = newFromArea.zone3DId;
      }
    }
  }

  if (prevCable.isConnectedFrom !== currentCable.isConnectedFrom) {
    currentCable.connectedFromWeek = currentCable.isConnectedFrom
      ? getYearWeekFormat(new Date())
      : undefined;
  }

  if (prevCable.isConnectedTo !== currentCable.isConnectedTo) {
    currentCable.connectedToWeek = currentCable.isConnectedTo
      ? getYearWeekFormat(new Date())
      : undefined;
  }
};

export const refreshVisibleCables = (
  dispatch: (action: Action) => void,
  newState: PatchResponse<Cable>,
  setInitialValues?: (value: (((prevState: (CableWithReferenceLabels[] | null)) => (CableWithReferenceLabels[] | null)) | CableWithReferenceLabels[] | null)) => void): void => {
  dispatch({
    type: "SET_VALUES",
    payload: (prevValues: CableWithReferenceLabels[]) => {

      const newValues = prevValues.filter(x => x.state !== "deleted");
      newState.updatedItems.forEach((refresh: PatchResponseItem<Cable>) => {
        const index = newValues.findIndex(x => x.id === refresh.referenceId);
        newValues[index] = {
          ...newValues[index],
          ...refresh.item,
          state: undefined,
          isMarked: false
        }
      });
      setInitialValues && setInitialValues(newValues);
      return newValues;
    }
  });
}
