import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";

interface InformationCardProps {
  context: string;
  header: string;
  subTitle?: string;
  content?: string;
  whatNow?: string
}

export const InformationCard: React.FC<InformationCardProps> = ({
  context,
  header,
  subTitle,
  content,
  whatNow
}) => {
  return (
    <Grid container
          justify="center"
          direction="column"
          alignItems="center"
          style={{marginTop: "200px"}}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{context}</Typography>
            <Typography variant="h5">{header}</Typography>
            {subTitle && <Typography variant="subtitle1" color="textSecondary" gutterBottom>{subTitle}</Typography>}
            {content && <Typography variant="body1" color="textSecondary" gutterBottom>{content}</Typography>}
            {whatNow && <Typography variant="body2" color="textSecondary" >{whatNow}</Typography>}
          </CardContent>
        </Card>
      </Grid>
    </Grid>

  );
}
