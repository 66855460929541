import React, {FC, useEffect, useReducer, useState} from "react";
import {CableClass} from "../../../shared/interfaces/cableClass.interface";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {useApi} from "../../../shared/hooks/useApi";
import {simpleReducer} from "../../../shared/reducers/simpleReducer";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {tableTheme} from "../../shared/table/styles";
import {CableClassReadOnlyRowItem} from "./CableClassReadOnlyRowItem";

const CableClassReadOnly: FC = () => {
  const [state, dispatch] = useReducer(simpleReducer, {values: []});
  const [isLoading, setIsLoading] = useState(true);

  const {get} = useApi();

  useEffect(() => {
    let isSubscribed = true;
    try {
      setIsLoading(true);
      (async () => {
        const result = await get<CableClass[]>(`/cableClass`).then(res => res)
        if (isSubscribed) {
          dispatch({
            type: "SET_STATE",
            payload: () => ({
              ...result,
              values: result.map((x: CableClass) => ({
                ...x,
                isMarked: false
              }))
            })
          });
        }
      })();
    } finally {
      setIsLoading(false);
    }
    return () => {
      isSubscribed = false
    };
  }, []);

  return (
    <div>
      <PanelHeader
        text={"Cable class"}/>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {state?.values?.map(cableClass => {
                return (
                  <CableClassReadOnlyRowItem
                    key={cableClass.id}
                    cableClass={cableClass}
                  />
                );
              })}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </div>
  )
}
export default CableClassReadOnly;
