import './users.css'
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import React, {FC, useContext, useEffect, useState} from "react";
import UserButton from "./UserButton";
import {VectButton} from "../../../shared/navigation/VectButton";
import {CommonPaths} from "../../../../shared/urls";
import {DomainDisabled, Edit, PersonOutline} from "@material-ui/icons";
import {GlobalClaims} from "../../../../shared/claims";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {Tooltip} from "@material-ui/core";
import {UserContext} from "../../../../shared/context/UserContext";
import {UserListItem} from "../../../../shared/interfaces/user.interface";
import {useApi} from "../../../../shared/hooks/useApi";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";

const Users: FC = () => {
  const [reload, setReload] = useState<boolean>(false);
  const [isChecked, setChecked] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<UserListItem>>([]);
  const history = useHistory();
  const {get, post, deleteMethod} = useApi();
  const {hasClaim} = useContext(UserContext);

  useEffect(() => {
    let isSubScribed = true
    const userFetch = async () => {
      await get<UserListItem[]>(
        `/user/list`,
        {
          queryParams: {
            showDisabled: isChecked
          }
        }).then(data => {
          if (isSubScribed) {
            setUsers(data);
          }
        });
    };
    userFetch();
    return () => {
      isSubScribed = false
    };
  }, [reload, isChecked])

  const deleteUser = async (objectId: string) => {
    const result = await deleteMethod(`/user`, objectId);
    setReload(!reload);
    return result;
  };

  const enableUser = async (objectId: string) => {
    const result = await post(`/user/enable/${objectId}`, {});
    setReload(!reload);
    return result;
  };

  const addUserAction = {
    action: () => history.push(CommonPaths.manage.users.newUser),
    disabled: !hasClaim(GlobalClaims.manage.user.roles.readWrite.all),
    label: "Add VARD users"
  };

  return (
    <div>
      <PanelHeader text="VECT users" add={addUserAction}/>
      <VectCheckbox
        fieldName={"isChecked"}
        checked={isChecked}
        change={() => setChecked(!isChecked)}
        label="Show disabled users"
        color="primary"
        size={"small"}
      />

      <table className="table">
        <thead>
        <tr>
          <th>
          </th>
          <th>
            Name
          </th>
          <th>
            Email
          </th>
          <th>
            Role
          </th>
          <th>
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        {users != null && users.map(ele => {

          return (
            <tr key={ele.objectId}>
              <td>
                <VectButton
                  claim={GlobalClaims.manage.user.roles.readWrite.all}
                  startIcon={<Edit/>}
                  component={RouterLink}
                  variant="contained"
                  to={`/manage/users/edit/${ele.objectId}`}>
                  Edit user
                </VectButton>
              </td>
              <td>
                {ele.name}
              </td>
              <td>
                {ele.email}
              </td>
              <td>
                {ele.globalRoleName}
              </td>
              <td>
                {ele.isDeleted && (
                  <Tooltip title="The account has been disabled locally." placement="top" arrow>
                    <PersonOutline/>
                  </Tooltip>
                )}
                {!ele.isAzureAdAccountEnabled && (
                  <Tooltip title="The account has been disabled in Azure Active Directory" placement="top" arrow>
                    <DomainDisabled/>
                  </Tooltip>
                )}
              </td>
              <td className="button-container">
                <UserButton objectId={ele.objectId} isDeleted={ele.isDeleted}
                            userAction={ele.isDeleted ? enableUser : deleteUser}/>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
