export const getValidFileName = (fileName: string):string => {
  return fileName.replace(/[/\\?%*:|"<>]/g, '_');
}

export const isProjectCodeValid = (code: string | null) => {
  if(code == null || code.length === 0) {
    return false;
  }
  const validProjectCode = new RegExp('^[\\w -]+$');
  if (code.trim() === "") {
    return true;
  }

  return validProjectCode.test(code);
};
