import {ProjectRole, RolesAssignment, UserProjectRole} from "../../../../shared/interfaces/user.interface";
import {ProjectListItem} from "../../../../shared/interfaces/project.interface";
import React, {useEffect, useState} from "react";
import {MultiSelect, MultiSelectItem} from "../../../shared/inputs/MultiSelect";
import {
  Button,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import {TableCellAutoComplete, TableCellHeader, TableCellInput, tableTheme} from "../../../shared/table/styles";
import {makeStyles} from "@material-ui/core/styles";
import {DeleteForeverSharp} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {Option, VectSelect} from "../../../shared/inputs/VectSelect";

interface RolesAssignmentTableProps {
  initialAssignments?: UserProjectRole[];
  projects: ProjectListItem[];
  roles: ProjectRole[];
  onChange: (assignments: RolesAssignment[]) => void;
}

export const rolesAssignmentStyles = makeStyles(() => ({
  projectCell: {
    verticalAlign: "top",
    width: "20em"
  },
  roleCell: {
    verticalAlign: "top",
    width: "30rem"
  },
  multiSelect: {
    width: "100%"
  }
}));

export const RolesAssignmentTable: React.FC<RolesAssignmentTableProps>  = ({
  initialAssignments,
  onChange,
  projects,
  roles
}): JSX.Element => {

  const [data, setData] = useState<RolesAssignment[]>([]);
  const [newProject, setNewProject] = useState<number | null>(null);
  const [projectSelectOptions, setProjectSelectOptions] = useState<Option[]>([]);
  const roleSelectItems: MultiSelectItem[] = roles.map(x => ({id: x.id, value: x.roleName}));

  useEffect(() => {
    if(initialAssignments) {
      const initialData: RolesAssignment[] = initialAssignments.reduce<RolesAssignment[]>(
        (previousValue, currentValue) => {
          const index = previousValue.findIndex(x => x.project && x.project.id === currentValue.projectId);
          const projectIndex = projects.findIndex(x => x.id === currentValue.projectId);
          // Note the old assignments where users was assigned to a project without
          // any roles on that project. Do not push these entries to roles.
          if(index === -1) {
            previousValue.push({
              project: projects[projectIndex],
              roles: currentValue.id ? [currentValue] : []
            });
          } else if(currentValue.id) {
            previousValue[index].roles.push(currentValue);
          }
          return previousValue;
        }, []);
      setData(initialData);
    } else {
      setData([]);
    }


  }, [initialAssignments, projects, roles])

  useEffect(() => {
    const value : Option[] = projects
      .filter(x => data.findIndex(d => d.project && d.project.id === x.id) === -1)
      .map(x => ({value: x.id, label: `${x.code} ${x.name}`}));
    setProjectSelectOptions(value);
  }, [data, projects])


  const onAddProject = () => {
    if(newProject) {
      const project = projects.find(x => x.id === newProject);
      if(project){
        const newState = [...data, {project: project, roles: []}];
        setData(newState);
        setNewProject(null);
        onChange(newState)
      }
      setNewProject(null);
    }
  }
  const onDeleteProject = (projectId: number) => {
    const newState = data.filter(x => x.project && x.project.id !== projectId);
    setData(newState);
    onChange(newState);
  }
  const onRolesChange = (projectId: number, roleIds: number[]) => {
    const projectIndex = data.findIndex(x => x.project && x.project.id === projectId);
    if(projectIndex > -1){
      const newRoles = roles.filter(x => roleIds.indexOf(x.id) > -1);
      const newState = [...data];
      newState.splice(
        projectIndex,
        1,
        {
          roles: newRoles,
          project: data[projectIndex].project}
          );
      setData(newState);
      onChange(newState);
    }
  }
  const onNewProjectChange = (value: number | null) => {
    setNewProject(value);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellHeader>Project</TableCellHeader>
          <TableCellHeader>Roles</TableCellHeader>
          <TableCellHeader></TableCellHeader>
        </TableRow>
      </TableHead>
      <MuiThemeProvider theme={tableTheme}>
        <TableBody>
          {data.map(x => <RolesAssignmentTableRow availableRoles={roleSelectItems}
                                                  key={x.project.code}
                                                  {...x}
                                                  onChange={onRolesChange}
                                                  onDelete={onDeleteProject}/>)}
        </TableBody>
        <TableFooter>
          <TableCellAutoComplete>
            <VectSelect change={onNewProjectChange}
                        options={projectSelectOptions}
                        placeholder="Add a new project..."
                        value={newProject}
            />
          </TableCellAutoComplete>
          <TableCell />
          <TableCellInput>
            <Button onClick={onAddProject}
                    disabled={!newProject}>
              <AddIcon/>
            </Button>
          </TableCellInput>
        </TableFooter>
      </MuiThemeProvider>
    </Table>
  )
}

interface RolesAssignmentTableRowProps extends RolesAssignment {
  availableRoles: MultiSelectItem[],
  onChange: (projectId: number, roleIds: number[]) => void;
  onDelete: (projectId: number) => void;
}

const RolesAssignmentTableRow: React.FC<RolesAssignmentTableRowProps>  = ({
  availableRoles,
  onChange,
  onDelete,
  project,
  roles
}): JSX.Element => {

  const classNames = rolesAssignmentStyles();

  const onRolesChange = (roleIds: number[]) => {
    onChange(project.id, roleIds);
  };

  return (
    <TableRow>
      <TableCell className={classNames.projectCell}>
        {`${project.code} ${project.name}`}
      </TableCell>
      <TableCellInput className={classNames.roleCell}>
        <MultiSelect className={classNames.multiSelect}
                     labelId={project.code}
                     selectId={`${project.code}-select`}
                     items={availableRoles}
                     onChange={onRolesChange}
                     value={roles.map(x => x.id)} />
      </TableCellInput>
      <TableCellInput>
        <Button onClick={() => onDelete(project.id)}>
          <DeleteForeverSharp/>
        </Button>
      </TableCellInput>
    </TableRow>
  );
}
