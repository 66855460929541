import FolderIcon from '@material-ui/icons/Folder';
import MainPanel from "../../shared/layout/MainPanel/MainPanel";
import React, {FC, useContext, useEffect, useState} from "react";
import VectAppBar from "../../shared/layout/VectAppBar";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {UserContext} from "../../../shared/context/UserContext";
import {UserProjectListItem} from "../../../shared/interfaces/project.interface";
import {useApi} from "../../../shared/hooks/useApi";
import {useStyles} from "./Projects.styles";


const Projects: FC = () => {
  const [projects, setProjects] = useState([] as UserProjectListItem[]);
  const classes = useStyles();
  const {get} = useApi();
  const {user} = useContext(UserContext);

  useEffect(() => {
    if(user.objectId === "") {
      return;
    }
    let isSubscribed = true;
    get<UserProjectListItem[]>(`/user/${user.objectId}/projects`)
      .then(data => {
        if(isSubscribed) {
          setProjects(data)
        }
      });
    return () => {
      isSubscribed = false
    };
  }, [user]);

  const returnListItem = (project: UserProjectListItem) => {
    return (
      <ListItem key={project.id}>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <NavLink color="primary"
                     className={classes.link}
                     to={`/project/${project.codeUrlEncoded}`}>
              <Typography variant="h6"><Box color="text.primary">{project.code} - {project.name}</Box></Typography>
            </NavLink>
          }
          secondary={
            project.startDate ?
              <Typography variant="body1">
                <Box color="text.secondary">
                  {Intl.DateTimeFormat("no", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  }).format(new Date(project.startDate))}
                </Box>
              </Typography>
              : null
          }
        />
      </ListItem>
    )
  };

  return (
    <VectAppBar header="All projects">
      <MainPanel>
        <List className={classes.listWrapper}>
          {/* My Projects */}
          <Typography className={classes.header} variant="h5"><Box color="text.primary">My Projects</Box></Typography>
          {projects.filter(project => project.isUserAssigned).map(project => returnListItem(project))}
          {/* Other Projects */}
          <Typography className={classes.header} variant="h5"><Box color="text.primary">Other
            Projects</Box></Typography>
          {projects.filter(project => !project.isUserAssigned && project.isReadOnly).map(project => returnListItem(project))}
        </List>
      </MainPanel>
    </VectAppBar>
  );
}

export default Projects;
