import React, {FunctionComponent} from "react";
import {CableWithReferenceLabels} from "../../../shared/interfaces/cable.interface";
import {generalStyles, StyledTableRow, TableCellInput, tableCellStyles, tableStyles} from "../../shared/table/styles";
import {vectLightExtension} from "../../../shared/theme";
import {Option} from "../../shared/inputs/VectSelect";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {handleCellChange} from "../../../shared/helpers/table";
import {ProjectClaims} from "../../../shared/claims";
import {VectTableRowSelect} from "../../shared/inputs/VectTableRowSelect";
import {InputAdornment, TableCell, Typography} from "@material-ui/core";
import {VectCheckbox} from "../../shared/inputs/VectCheckbox";
import {ModifiedIcon} from "../../shared/table/ModifiedIcon";
import {getYearWeekFormat, validateYearWeekFormat} from "../../../shared/helpers/date";
import {VectTableTextField} from "../../shared/inputs/VectTableTextField";
import {TodaySharp} from "@material-ui/icons";


type BundleAdminCableRowProps = {
  cable: CableWithReferenceLabels;
  change: (cable: CableWithReferenceLabels) => void;
  tagOptions: Option[];
  areaOptions: Option[];
  mvZoneOptions: Option[];
  dpZoneOptions: Option[];
  cableClassOptions: Option[] | null;
  cableTypeOptions: Option[];
  areaForemanOptions: Option[];
};

const BundleAdminCableRow: FunctionComponent<BundleAdminCableRowProps> = ({
  cable,
  change,
  tagOptions,
  areaOptions,
  mvZoneOptions,
  cableClassOptions,
  cableTypeOptions,
  areaForemanOptions,
  dpZoneOptions,
}) => {

  const cellClasses = tableCellStyles();
  const classes = tableStyles();
  const general = generalStyles();

  const errors = {
    cableNumber: cable.cableNumber ? undefined : "A cable number is required",
    cutWeek: validateYearWeekFormat(cable.cutWeek).message,
    pulledWeek: validateYearWeekFormat(cable.pulledWeek).message
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {

    const hasError = (newState: CableWithReferenceLabels):boolean => {
      return  !newState.cableNumber ||
              !validateYearWeekFormat(newState.cutWeek).isValid ||
              !validateYearWeekFormat(newState.pulledWeek).isValid
    }

    handleCellChange(
      change,
      hasError,
      cable,
      value,
      fieldName
    );
  };

  const setCutWeek = () => {
    onCellChange(getYearWeekFormat(new Date()), "cutWeek");
  }

  const setPulledWeek = () => {
    onCellChange(getYearWeekFormat(new Date()), "pulledWeek");
  }

  return (
    <StyledTableRow className={classes.tableRowWrapper} >
      <div
        style={{width: "14rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>

          <VectTableTextField
            claim={ProjectClaims.project.engineering.external}
            fieldName={"cableNumber"}
            value={cable.cableNumber}
            change={onCellChange}
            error={!!errors.cableNumber}
            helperText={errors.cableNumber}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.cableTypeId}
            change={onCellChange}
            fieldName={"cableTypeId"}
            options={cableTypeOptions}
          />
          <Typography
            style={
              cable.releaseForProductionWeek
                ? {backgroundColor: vectLightExtension.palette.custom.status.allOk}
                : cable.releaseForMeasuringWeek
                ? {backgroundColor: vectLightExtension.palette.custom.status.incomplete}
                : {backgroundColor: vectLightExtension.palette.custom.status.notOkay}
            }
            variant="body1">{cable.code}</Typography>
        </TableCellInput>
      </div>
      <div
        style={{width: "8rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromTagId}
            change={onCellChange}
            fieldName={"fromTagId"}
            options={tagOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromAreaId}
            change={onCellChange}
            fieldName={"fromAreaId"}
            options={areaOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.fromMainVerticalZoneId}
            change={onCellChange}
            fieldName={"fromMainVerticalZoneId"}
            options={mvZoneOptions}
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "20rem"}}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.yFlexWSpace}>
            <div style={{marginBottom: 29}}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                fieldName={"fromText"}
                multiline={true}
                value={cable.fromText}
                change={onCellChange}
                variant="standard"
                fullWidth
              />
            </div>
            <div className={general.xFlex}>
              <div style={{marginLeft: -10, width: "4rem"}}>
                <VectCheckbox
                  claim={ProjectClaims.project.production.external}
                  fieldName={"isConnectedFrom"}
                  checked={cable.isConnectedFrom}
                  change={onCellChange}
                  color="primary"
                />
              </div>
              <div style={{width: "5rem"}} className={general.spaceRight}>
                <VectTableRowSelect
                  claim={ProjectClaims.project.production.coordinator}
                  value={cable.fromConnectionAreaForemanId}
                  change={onCellChange}
                  fieldName={"fromConnectionAreaForemanId"}
                  options={areaForemanOptions}
                />
              </div>
              <div style={{width: "3rem"}} className={general.spaceRight}>
                <Typography variant="body1">{cable.from3dZoneCode}</Typography>
              </div>
              <div style={{width: "6rem", marginBottom: 10}}>
                <VectTableRowSelect claim={ProjectClaims.project.engineering.external}
                                    value={cable.cableClassId}
                                    change={onCellChange}
                                    fieldName={"cableClassId"}
                                    options={cableClassOptions}
                />
              </div>
            </div>
          </div>
        </TableCellInput>
      </div>
      <div
        style={{width: "8rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toTagId}
            change={onCellChange}
            fieldName={"toTagId"}
            options={tagOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toAreaId}
            change={onCellChange}
            fieldName={"toAreaId"}
            options={areaOptions}
          />
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            value={cable.toMainVerticalZoneId}
            change={onCellChange}
            fieldName={"toMainVerticalZoneId"}
            options={mvZoneOptions}
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "20rem"}}
        className={classes.tableHeaderColumnWrapperWithSpace}
      >
        <TableCellInput className={cellClasses.cell}>
          <div className={general.yFlexWSpace}>
            <div style={{marginBottom: 29}}>
              <VectTextField
                claim={ProjectClaims.project.engineering.external}
                fieldName={"toText"}
                value={cable.toText}
                multiline={true}
                change={onCellChange}
                variant="standard"
                fullWidth
              />
            </div>
            <div className={general.xFlex}>
              <div style={{marginLeft: -10, width: "7rem"}}>
                <VectCheckbox
                  claim={ProjectClaims.project.production.external}
                  fieldName={"isConnectedTo"}
                  checked={cable.isConnectedTo}
                  change={onCellChange}
                  color="primary"
                />
              </div>
              <div style={{width: "11rem"}} className={general.spaceRight}>
                <VectTableRowSelect
                  claim={ProjectClaims.project.production.coordinator}
                  value={cable.toConnectionAreaForemanId}
                  change={onCellChange}
                  fieldName={"toConnectionAreaForemanId"}
                  options={areaForemanOptions}
                />
              </div>
              <Typography variant="body1">{cable.to3dZoneCode}</Typography>

              <VectCheckbox
                claim={"TODO"}
                fieldName={"hasSpecialRequirements"}
                checked={cable.hasSpecialRequirements}
                change={onCellChange}
                color="primary"
              />
            </div>
          </div>
        </TableCellInput>
      </div>
      <div
        style={{width: "10rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>

          <VectTextField
            claim={ProjectClaims.project.engineering.external}
            fieldName={"estimatedMeters"}
            value={cable.estimatedMeters}
            change={onCellChange}
            type="number"
            variant="standard"
            fullWidth
          />
          <VectTextField
            claim={ProjectClaims.project.routing.external}
            fieldName={"meters"}
            value={cable.meters}
            change={onCellChange}
            type="number"
            variant="standard"
            fullWidth
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "11rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTextField
            claim={ProjectClaims.project.routing.external}
            fieldName={"markPlacement"}
            value={cable.markPlacement}
            change={onCellChange}
            variant="standard"
            fullWidth
          />
          <div>
            <VectTextField
              claim={ProjectClaims.project.routing.external}
              fieldName={"penetrations"}
              value={cable.penetrations}
              change={onCellChange}
              variant="standard"
              fullWidth
            />
          </div>
        </TableCellInput>
      </div>
      <div
        style={{width: "10rem"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectTableTextField claim={ProjectClaims.project.production.external}
                              value={cable.cutWeek}
                              fieldName="cutWeek"
                              change={onCellChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={setCutWeek}>
                                    <TodaySharp/>
                                  </InputAdornment>
                                )
                              }}
                              error={!!errors.cutWeek}
                              helperText={errors.cutWeek}/>
          <VectTableTextField claim={ProjectClaims.project.production.external}
                              value={cable.pulledWeek}
                              fieldName="pulledWeek"
                              change={onCellChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={setPulledWeek}>
                                    <TodaySharp/>
                                  </InputAdornment>
                                )
                              }}
                              error={!!errors.pulledWeek}
                              helperText={errors.pulledWeek}/>
          <VectTableRowSelect
            claim={ProjectClaims.project.engineering.external}
            fieldName={"dp3Zone"}
            value={dpZoneOptions.find(x => x.label === cable.dp3Zone)?.value}
            change={(value: number | null, fieldName?: string) => onCellChange(dpZoneOptions.find(x => x.value === value)?.label, fieldName)}
            options={dpZoneOptions}
          />
        </TableCellInput>
      </div>
      <div
        style={{width: "3rem", alignSelf: "center"}}
        className={classes.tableHeaderColumnWrapper}
      >
        <TableCellInput className={cellClasses.cell}>
          <VectCheckbox
            fieldName={"isMarked"}
            checked={cable.isMarked || false}
            change={onCellChange}
            color="default"
          />
        </TableCellInput>
      </div>
      <TableCell style={{ display: "flex" }} className={cellClasses.cell}>
        <ModifiedIcon itemState={cable.state} />
      </TableCell>
    </StyledTableRow>
  );
};

export default BundleAdminCableRow;
