import {Option, VectSelect} from "../../components/shared/inputs/VectSelect";
import React, {useMemo} from "react";

export function useFilter(
  field: string,
  label: string,
  value: number | undefined | null,
  options: Option[] | null,
  callback: (val: number | undefined | null) => void,
): JSX.Element {
  return useMemo(() => {
    return (
      <VectSelect
        id={field}
        isClearable={true}
        placeholder={label}
        label={label}
        value={value}
        change={val => callback(val)}
        options={options}
      />
    );
  }, [value, options]);
}
