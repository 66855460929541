import React, {FunctionComponent, memo} from "react";
import {System} from "../../../../shared/interfaces/system.interface";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../../shared/table/styles";
import {Option} from "../../../shared/inputs/VectSelect";
import {handleCellChange} from "../../../../shared/helpers/table";
import {TableItemState} from "../../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../../shared/theme";
import {ProjectClaims} from "../../../../shared/claims";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {VectTableRowSelect} from "../../../shared/inputs/VectTableRowSelect";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {VectContainer} from "../../../shared/layout/VectContainer";
import {DeleteButton} from "../../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../../shared/table/ModifiedIcon";
import {CopyButton} from "../../../shared/table/CopyButton";

type SystemRowReadOnlyItemProps = {
  system: System;
  change: (value: System) => void;
  copy: (value: System) => void;
  responsibleForemanOptions: Option[] | null;
  systemGroupOptions: Option[] | null;
};

const SystemRowItem: FunctionComponent<SystemRowReadOnlyItemProps> = ({
  system,
  change,
  copy,
  responsibleForemanOptions,
  systemGroupOptions
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: system.code ? undefined : "A system code is required"
  };

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (newState: System): boolean => {
      return !newState.code;
    }

    handleCellChange(
      change,
      hasError,
      system,
      value,
      fieldName
    );
  };

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...system,
      state: itemState
    })
  };

  const onCopy = () => {
    copy(system);
  }

    const style = system.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow style={style}>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.system.write.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={system.code}
          change={onCellChange}
          variant="standard"
          fullWidth
          required
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={ProjectClaims.project.system.write.all}
          fieldName="name"
          value={system.name}
          change={onCellChange}
          variant="standard"
          fullWidth
        />
        <VectTextField
          claims={[
            ProjectClaims.project.system.write.all,
            ProjectClaims.project.engineering.external
          ]}
          fieldName="drawingPlacement"
          value={system.drawingPlacement}
          change={onCellChange}
          variant="standard"
          fullWidth
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.system.write.all}
          value={system.areaForemanId}
          change={onCellChange}
          fieldName={"areaForemanId"}
          options={responsibleForemanOptions}
        />
      </TableCellInput>

      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect
          claim={ProjectClaims.project.system.write.all}
          value={system.systemGroupId}
          change={onCellChange}
          fieldName={"systemGroupId"}
          options={systemGroupOptions}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectCheckbox
          claim={ProjectClaims.project.system.write.all}
          checked={system.isActive}
          fieldName={"isActive"}
          change={onCellChange}
          color="primary"
          size={"small"}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectCheckbox
          claims={[
            ProjectClaims.project.system.write.all,
            ProjectClaims.project.engineering.external
          ]}
          checked={system.isFinished}
          fieldName={"isFinished"}
          change={onCellChange}
          color="primary"
          size={"small"}
        />
      </TableCellInput>

      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Copy */}
        <VectContainer claim={ProjectClaims.project.system.write.all}>
          <TableCellInput style={{ display: "flex" }} className={cellClasses.cell}>
            <CopyButton onClick={onCopy}/>
          </TableCellInput>
        </VectContainer>

        {/* Delete */}
        <VectContainer style={{alignSelf: 'center'}} claim={ProjectClaims.project.system.write.all}>
          <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
            <DeleteButton change={onDelete} itemState={system.state}/>
          </TableCellInput>
        </VectContainer>

        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={system.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
};

export default memo(SystemRowItem);
