import React, {useCallback, useContext, useEffect, useState} from "react";
import PanelHeader from "../../shared/layout/PanelHeader/PanelHeader";
import {AlertContext} from "../../../shared/context/AlertContext";
import {CableType} from "../../../shared/interfaces/cableType.interface";
import {Edit, List} from "@material-ui/icons";
import {LoadingIndicator} from "../../shared/table/LoadingIndicator";
import {MuiThemeProvider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {ProjectCableTypeRowItem} from "./ProjectCableTypeRowItem";
import {ProjectClaims} from "../../../shared/claims";
import {ProjectContext} from "../../../shared/context/ProjectContext";
import {Skeleton} from "@material-ui/lab";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {tableTheme} from "../../shared/table/styles";
import {useApi} from "../../../shared/hooks/useApi";
import {useRouteMatch} from "react-router-dom";
import {useStyles} from "../tag/Tags.styles";


export const ProjectCableTypes: React.FC = () => {
  const [cableTypeFilter, setCableTypeFilter] = useState<string | null>(null);
  const [cableTypes, setCableTypes] = useState<CableType[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const classes = useStyles();
  const {get} = useApi();
  const {project} = useContext(ProjectContext);
  const {setError} = useContext(AlertContext)
  const {url} = useRouteMatch()

  useEffect(() => {
    let isSubscribed = true;
    setIsLoading(true);

    get<CableType[]>(`project/${project?.id}/cableTypes`, {
      queryParams: {
        cableTypeFilter
      }
    })
      .then(res => {
        if (isSubscribed) {
          setCableTypes(res);
          setIsLoading(false);
        }
      })
      .catch(err => setError(err));

    return () => {
      isSubscribed = false;
    }
  }, []);

  const renderList = useCallback((): JSX.Element[] => {
    if (cableTypes == null) {
      return [];
    }

    const list = cableTypeFilter
      ? cableTypes.filter(x => x.code.toLowerCase().includes(cableTypeFilter.toLowerCase()))
      : cableTypes;

    return list.map(cableType => (
      <ProjectCableTypeRowItem
        key={cableType.id}
        cableType={cableType}
      />
    ));
  }, [cableTypeFilter, cableTypes])

  return (
    <>
      <PanelHeader
        text={"Cable types"}
        navigationLinks={[
          {
            label: "Enable/Disable cable types",
            route: `${url}/edit`,
            icon: <Edit/>,
            claim: ProjectClaims.project.engineering.coordinator
          },
          {
            label: "Orders",
            route: `${url}/orders`,
            icon: <List/>
          }
        ]}
      />
      {/* Header filter */}
      {cableTypes ? (
        <div className={classes.horizontalFilterWrapper}>
          <div style={{maxWidth: "16rem"}} className={classes.filterItem}>
            <VectTextField
              value={cableTypeFilter}
              change={setCableTypeFilter}
              label={"Type ID"}
              debounceDelay={500}
            />
          </div>
        </div>
      ) : <Skeleton variant="rect" width="40rem" height="5rem"/>}

      {/* Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: "12rem"}}>
              Cable Type Id
            </TableCell>
            <TableCell>
              Signal type
            </TableCell>
            <TableCell style={{width: "6rem"}}>
              Int Vard ID
            </TableCell>
            <TableCell>
              Mini. Order Quantity
            </TableCell>
            <TableCell>
              Delivery Time(W)
            </TableCell>
            <TableCell>
              Copper Weight/KM
            </TableCell>
            <TableCell>
              Cable Weight/KM
            </TableCell>
            <TableCell>
              Outer mm.
            </TableCell>
            <TableCell>
              Std Rox.
            </TableCell>
            <TableCell>
              Hr. pr Conn.
            </TableCell>
            <TableCell>
              Hr. pr M.
            </TableCell>
          </TableRow>
        </TableHead>
        <MuiThemeProvider theme={tableTheme}>
          <TableBody>
            <LoadingIndicator isLoading={isLoading}>
              {renderList()}
            </LoadingIndicator>
          </TableBody>
        </MuiThemeProvider>
      </Table>
    </>
  );
}
