import React, {FC} from 'react';
import {ChartLine} from "../../../shared/interfaces/common.interface";
import styles from './legend.module.css'
interface ChartLegendProps{
 lines: ChartLine[]
}

const ChartLegend: FC<ChartLegendProps> = ({lines}) => {
  return (
    <div className={styles.legendContainer}>
      {lines.map(x => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', }} key={x.name}>
            <div className={styles.legendBubble} style={{ background: x.color }} />
            {x.name}
          </div>
          )})}
    </div>
  )
};


export default ChartLegend;
