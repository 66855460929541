import React, {useEffect, useRef, useState} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {VectButton} from "../navigation/VectButton";
import {LinearProgress} from "@material-ui/core";

export const DialogActionsReady: React.FC<{
  close: () => void;
  importData: () => void;
  importLabel: string;
  enableImport: boolean;
}> = ({close, importData, importLabel, enableImport}) => {

  const [isLoading, setIsLoading] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onImport = async () => {
    try {
      setIsLoading(true)
      await importData();
    } finally {
      if(mounted.current){
        setIsLoading(false);
      }
    }
  }

  return (
    <DialogActions>
      <Button onClick={close} color="secondary" variant="contained" disabled={isLoading}>
        Cancel
      </Button>
      <div>
        <VectButton color="primary"
                    variant="contained"
                    disabled={!enableImport || isLoading}
                    onClick={onImport}
        >
          {importLabel}
        </VectButton>
        {isLoading && <LinearProgress color="primary"/>}
      </div>
    </DialogActions>
  )
}
