import {AuthUserContext} from "../interfaces/user.interface";
import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {AuthContext} from "./AuthContext";
import {ConfigContext} from "./ConfigContext";
import {findValue, findValueInArray} from "../helpers/array";

const initialUser: AuthUserContext = {
  claims: [],
  email: '',
  globalRole: {id: 0, roleName: "", claims: []},
  isAzureAdAccountEnabled: false,
  isDeleted: false,
  name: '',
  objectId: '',
  projects: [],
}

export type UserContextType = {
  user: AuthUserContext;
  setReload: (reload: boolean) => void;
  /**
   * Check if the user object claims to have a specific grant
   * @param {string} claim  A global grant to inspect
   * @param {boolean} useExactMatch  Default true. When false, partial wildcard claims are allowed (e.g. "Manage.")
   * @returns {boolean}     Is true if the user claims to have this global grant
   */
  hasClaim: (claim: string, useExactMatch?: boolean) => boolean;

  /**
   *
   * @param claims        Global grants to inspect
   * @param useExactMatch Default true. When false, partial wildcard claims are allowed (e.g. "Manage.")
   * @returns {boolean}   Is true if the user claims to have one of these global grants
   */
  hasClaims: (claims: string[], useExactMatch?: boolean) => boolean;
}

export const UserContext = React.createContext<UserContextType>({
  user: initialUser,
  setReload: () => {
    return {}
  },
  hasClaim: () => false,
  hasClaims: () => false
});

const UserProvider: FC = ({children}) => {
  const [user, setUser] = useState<AuthUserContext>(initialUser);
  const [reload, setReload] = useState<boolean>(false);
  const {authState} = useContext(AuthContext);
  const {api} = useContext(ConfigContext);
  const getUser = useCallback(async () => {
    const authorization = `Bearer ${authState.accessToken}`
    const defaultRequestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authorization
      }
    };

    const fetchUser = await fetch(`${api.hostname}/auth/me`, defaultRequestOptions).then(res => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res;
    }).then(response => response.json()).then(data => {
      return data;
    }).catch(error => {
      console.error(error);
    });
    setUser(fetchUser);
    if (reload) {
      setReload(false);
    }

  }, [setReload, setUser, reload])

  const hasClaim = (claim: string, useExactMatch = true):boolean => {
    if(!user || !user.claims) {
      return false;
    }

    return findValue(user.claims, claim, useExactMatch);
  }

  const hasClaims = (claims: string[], useExactMatch = true):boolean => {
    if(!user || !user.claims) {
      return false;
    }

    return findValueInArray(user.claims, claims, useExactMatch);
  }

  useEffect(() => {
    if (reload || user?.claims?.length === 0) {
      getUser();
    }
  }, [reload])
  return (
    <UserContext.Provider value={{user, setReload, hasClaim, hasClaims}}>
      {children}
    </UserContext.Provider>
  )
};

export default UserProvider
