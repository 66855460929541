import {AuthProps} from "../../../shared/interfaces/auth.interface";
import React, {ChangeEvent, CSSProperties} from "react";
import {useClaims} from "../../../shared/hooks/useClaims";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

interface AuthCheckboxProps extends AuthProps {
  checked?: boolean;
  className?: string;
  color?: "primary" | "secondary" | "default";
  disabled?: boolean;
  fieldName?: string;
  id?: string;
  label?: string;
  /**
   * Enable or disable the label margin.
   */
  labelMarginLeft?: boolean;
  change?: (checked: boolean, fieldName?: string) => void;
  size?: "small" | "medium";
  style?: CSSProperties;
}

export const VectCheckbox: React.FC<AuthCheckboxProps> = ({
  checked,
  claim,
  claims,
  className,
  color,
  disabled,
  fieldName,
  id,
  label,
  labelMarginLeft = true,
  change,
  size,
  style,
  useExactMatch
}): JSX.Element => {
  const{isValid} = useClaims();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(change) {
      change(event.target.checked, fieldName);
    }
  }

  const checkboxReadonlyProps = {
    checked,
    color,
    id,
    size,
    style
  }
  const checkboxProps = {
    checked,
    disabled,
    onChange,
    color,
    size,
    style
  }

  const labelStyle = labelMarginLeft ? undefined : {marginLeft: 0};

  if(checked === undefined) {
    return label
    ? <FormControlLabel className={className} value="label" label={label} labelPlacement="start" control={<Skeleton><Checkbox /></Skeleton>}/>
    : <Skeleton className={className}><Checkbox /></Skeleton>;
  }

  const checkBox = isValid({claim, claims, useExactMatch})
    ? <Checkbox className={className} {...checkboxProps}/>
    : <Checkbox className={className} {...checkboxReadonlyProps} disabled />;

  return label
    ? <FormControlLabel className={className}
                        style={labelStyle}
                        value="label"
                        label={label}
                        labelPlacement="start"
                        control={checkBox}/>
    : checkBox;
}
