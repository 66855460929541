import React from "react";


const MainPanel: React.FC = ({children}) => (
  <div>
    {children}
  </div>
)


export default MainPanel;
