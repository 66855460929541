import React, {useEffect, useState} from "react";
import {
  Button,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import {TableCellAutoComplete, TableCellHeader, TableCellInput, tableTheme} from "../../shared/table/styles";
import {makeStyles} from "@material-ui/core/styles";
import {DeleteForeverSharp} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {ProjectRole, UserListItem} from "../../../shared/interfaces/user.interface";
import {MultiSelect, MultiSelectItem} from "../../shared/inputs/MultiSelect";
import {Option, VectSelect} from "../../shared/inputs/VectSelect";

interface UsersAssignmentTableProps {
  initialAssignments?: UsersAssignment[];
  users: UserListItem[];
  roles: ProjectRole[];
  onChange: (assignments: UsersAssignment[]) => void;
}

export interface UsersAssignment {
  userId: string
  name: string;
  roles: ProjectRole[]
}

export const usersAssignmentStyles = makeStyles(() => ({
  userCell: {
    verticalAlign: "top",
    width: "20em"
  },
  roleCell: {
    verticalAlign: "top",
    width: "30rem"
  },
  multiSelect: {
    width: "100%"
  }
}));

export const UsersAssignmentTable: React.FC<UsersAssignmentTableProps>  = ({
  initialAssignments,
  onChange,
  users,
  roles
}): JSX.Element => {

  const [data, setData] = useState<UsersAssignment[]>([]);
  const [newUser, setNewUser] = useState<number | null>(null);
  const [userSelectOptions, setUserSelectOptions] = useState<Option[]>([]);
  const roleSelectItems: MultiSelectItem[] = roles.map(x => ({id: x.id, value: x.roleName}));

  useEffect(() => {
    if(initialAssignments) {
      setData(initialAssignments);
    } else {
      setData([]);
    }


  }, [initialAssignments, users, roles])

  useEffect(() => {
    const value : Option[] = users
      .filter(x => data.findIndex(d => d.userId === x.objectId) === -1)
      .map(x => ({value: x.id, label: x.name}));
    setUserSelectOptions(value);
  }, [data, users])


  const onAddUser = () => {
    if(newUser) {
      const user = users.find(x => x.id === newUser);
      if(user){
        const newState = [...data, {userId: user.objectId, name: user.name, roles: []}];
        setData(newState);
        setNewUser(null);
        onChange(newState)
      }
      setNewUser(null);
    }
  }
  const onDeleteUser = (userId: string) => {
    const newState = data.filter(x => x.userId !== userId);
    setData(newState);
    onChange(newState);
  }
  const onRolesChange = (userId: string, roleIds: number[]) => {
    const userIndex = data.findIndex(x => x.userId === userId);
    if(userIndex > -1){
      const newRoles = roles.filter(x => roleIds.indexOf(x.id) > -1);
      const newState = [...data];
      newState.splice(
        userIndex,
        1,
        {
          roles: newRoles,
          userId: userId,
          name: data[userIndex].name}
          );
      setData(newState);
      onChange(newState);
    }
  }
  const onNewUserChange = (value: number | null) => {
    setNewUser(value);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellHeader>User</TableCellHeader>
          <TableCellHeader>Roles</TableCellHeader>
          <TableCellHeader/>
        </TableRow>
      </TableHead>
      <MuiThemeProvider theme={tableTheme}>
        <TableBody>
          {data.map(x => <UsersAssignmentTableRow availableRoles={roleSelectItems}
                                                  key={x.userId}
                                                  {...x}
                                                  onChange={onRolesChange}
                                                  onDelete={onDeleteUser}/>)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCellAutoComplete>
              <VectSelect change={onNewUserChange}
                          options={userSelectOptions}
                          placeholder="Add a new user..."
                          value={newUser}
              />
            </TableCellAutoComplete>
            <TableCell />
            <TableCellInput>
              <Button onClick={onAddUser}
                      disabled={!newUser}>
                <AddIcon/>
              </Button>
            </TableCellInput>
          </TableRow>
        </TableFooter>
      </MuiThemeProvider>
    </Table>
  )
}

interface UsersAssignmentTableRowProps extends UsersAssignment {
  availableRoles: MultiSelectItem[],
  onChange: (userId: string, roleIds: number[]) => void;
  onDelete: (userId: string) => void;
}

const UsersAssignmentTableRow: React.FC<UsersAssignmentTableRowProps>  = ({
  availableRoles,
  onChange,
  onDelete,
  userId,
  name,
  roles
}): JSX.Element => {

  const classNames = usersAssignmentStyles();

  const onRolesChange = (roleIds: number[]) => {
    onChange(userId, roleIds);
  };

  return (
    <TableRow>
      <TableCell className={classNames.userCell}>
        {name}
      </TableCell>
      <TableCellInput className={classNames.roleCell}>
        <MultiSelect className={classNames.multiSelect}
                     labelId={userId}
                     selectId={`${userId}-select`}
                     items={availableRoles}
                     onChange={onRolesChange}
                     value={roles.map(x => x.id)} />
      </TableCellInput>
      <TableCellInput>
        <Button onClick={() => onDelete(userId)}>
          <DeleteForeverSharp/>
        </Button>
      </TableCellInput>
    </TableRow>
  );
}
