import React, {FC, useCallback, useEffect, useReducer, useState,} from "react";
import {Cell} from "react-table";
import {DeleteForeverSharp, Edit} from '@material-ui/icons';
import {Button} from "@material-ui/core";
import {tableReducer} from "../../../../shared/reducers/tableReducer";
import PaginatedTable from "../../../shared/table/PaginatedTable";
import {SystemGroup} from "../../../../shared/interfaces/systemGroup.interface";
import {PaginationRequest} from "../../../../shared/interfaces/pagination.interface";
import PanelHeader from "../../../shared/layout/PanelHeader/PanelHeader";
import {useApi} from "../../../../shared/hooks/useApi";


const SystemGroups: FC = () => {
  const [state, dispatch] = useReducer(tableReducer, {values: [], currentPage: 1, currentPageSize: 0, pageSize: 20, totalPages: 0, totalValues: 0});
  const [newVals, setNewVals] = useState<SystemGroup[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [reFetchData, setRefetchData] = useState<boolean>(false);
  /*
 need some number
 which is different for every new item to update state properly. doesnt
 matter if there are existing items with same id's since the new items get
  id on insert
 */
  const [prevNewId, setPrevNewId] = useState<number>(0)
  const {post, put, deleteMethod} = useApi();


  useEffect(() => {
    let isSubscribed = true
    const  fetchGroups = async() => {
      const paginationQuery: PaginationRequest = {page: state.currentPage, pageSize: state.pageSize}
      const groups = await post<PaginationRequest, any>(`/systemgroup/paginated`, paginationQuery).then(res => res)
      if(isSubscribed && groups !== undefined) {
        dispatch({type: "SET_STATE", payload: () => groups})
      }
    }
    fetchGroups();
    return () => {
      isSubscribed = false
    };
  }, [ state.pageSize, state.currentPage, reFetchData])

  const data = React.useMemo(
    () => newVals.concat(state.values as SystemGroup[]),
    [state.values, newVals, reload]
  )
  const addNewItem = () => {
    const newId = prevNewId - 1;
    const newItem: SystemGroup = {isActive: false, state: "new", name: "", code: "", id: newId }
    const vals = newVals;
    vals.push(newItem);
    setNewVals(vals);
    setReload(!reload);
    setPrevNewId(newId)
  }
  const update = async () => {
    const updatedGroups = state.values.filter((x): boolean => {
      const item = x as SystemGroup;
      return item.state === "modified";
    });
    const newGroups = newVals.filter(x => x.name.length > 0 && x.code.length > 0)
    const payload = updatedGroups as SystemGroup[]
    put('/SystemGroup/batchUpdate',payload.concat(newGroups))
      .then((res) => {
        if(res !== undefined){
          setRefetchData(!reFetchData);
          setNewVals([])
        }
      });
  }
  const deleteGroup = async (id: number) => {
    deleteMethod("/SystemGroup", id)
      .then((res) => {
        if(res !== undefined){
          setRefetchData(!reFetchData);
          setNewVals([])
        }
      });
  }
  const change= useCallback(
    (value, id: number, field: string, cellState: string) => {
      if(state && cellState === "new"){
        const item = newVals.find(x => x.id === id)
        if(!(item) || item[field as keyof SystemGroup] === value)return;
        const newGroups = newVals.map(item => {
          return item.id === id ? {
            ...item,
            [field]: value,
          } : item
        })
        setNewVals(newGroups);
      }
      else {
        const item = state.values.find((x) => {
          const item = x as SystemGroup;
          return item.id === id
        })
        if(!item) return;
        const object = item as SystemGroup
        if(object[field as keyof SystemGroup] === value) return;
        const newGroups = state.values.map((x) => {
          const item = x as SystemGroup;
          return item.id === id ? {
            ...item,
            [field]: value,
            state: "modified"
          } : item
        })
        dispatch({type: "SET_VALUES", payload: () => newGroups})
      }
    },
    [dispatch, state.values, newVals]
  );
  const columns = React.useMemo(
    () => [

      {
        Header: 'Group ID',
        accessor: 'code',
        type: "text",
        width: "1rem"

      },
      {
        Header: 'Name',
        accessor: 'name',
        type: "text",
        width: "50rem"
      },
      {
        Header: '',
        id: 'id',
        accessor: 'id',
        Cell: ({row} : Cell) => ( row.values &&
          <Button  onClick={() => deleteGroup(row.values.id)}>
            <DeleteForeverSharp />
          </Button>
        ),
        width: "1rem"
      },
      {
        Header: '',
        accessor: 'state',
        Cell: ({row} : Cell) => (
          <span>
            {row.values.state && (row.values.state === "new"|| row.values.state ==="modified") ? <Edit />: ""}
          </span>
        ),
        width: "1rem"
      },
    ],
    []
  )

  return (
    <div>
      <PanelHeader text={"System groups"} add={{action: addNewItem}} save={{action: update}}/>
      { <PaginatedTable state={state} dispatch={dispatch} change={change}
                       columns={columns} data={data} />}
    </div>
  )
}
export default SystemGroups;
