import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useModals} from "../../../../shared/context/ModalContext";
import {
  CableListItem,
  TagTextOverride
} from "../../../../shared/interfaces/cable.interface";
import {Tag} from "../../../../shared/interfaces/tag.interface";
import CableRowModal from "../components/CableRowModal";
import {DialogContent, InputLabel, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {gridStyles} from "./BatchUpdateTagsModal.styles";

interface CableWithTags {
  cable: CableListItem;
  prevFromTag?: Tag;
  prevToTag?: Tag;
}

const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));



type BatchUpdateTagsModalProps = {
  cables: CableListItem[],
  tags: Tag[],
  fromTagId?: number,
  toTagId?: number,
  open?: boolean,
  update: (tagTextOverrides: TagTextOverride[], fromText?: string, toText?: string) => Promise<void>
};



export const BatchUpdateTagsModal: FunctionComponent<BatchUpdateTagsModalProps> = ({
  cables: cableList,
  tags,
  fromTagId,
  toTagId,
  update
 }) => {
  const classNames = useStyles();
  const grid = gridStyles();

  const [fromText, setFromText] = useState<string | undefined>(undefined);
  const [toText, setToText] = useState<string | undefined>(undefined);
  const [updates, setUpdates] = useState<TagTextOverride[]>([]);
  const {closeModal} = useModals();

  const fromTag = fromTagId ? tags.find(x => x.id === fromTagId) : undefined;
  const toTag = toTagId ? tags.find(x => x.id === toTagId) : undefined;
  const cables = useMemo(() => {
    const result : CableWithTags[] = cableList.map(x => {
      const cableFromTag = x.fromTagId ? tags.find(t => t.id === x.fromTagId) : tags.find(t => t.code === "000");
      const cableToTag = x.toTagId ? tags.find(t => t.id === x.toTagId) : tags.find(t => t.code === "000");
      return {
        cable: x,
        prevFromTag: cableFromTag,
        prevToTag: cableToTag
      }
    });

    return result;
  }, [cableList, tags]);

  const onFromTextChanged = useCallback((value: string, id: number) => {
    setUpdates(prevState => {
      const prevIndex = prevState.findIndex(x => x.cableId === id);
      if(prevIndex === -1) {
        return [
          ...prevState,
          {cableId: id, fromText: value}
        ];
      } else {
        const newState = [...prevState];
        newState.splice(prevIndex, 1, {...newState[prevIndex], fromText: value})
        return newState;
      }
    })
  }, []);

  const ontoTextChanged = useCallback((value: string, id: number) => {

    setUpdates(prevState => {
      const prevIndex = prevState.findIndex(x => x.cableId === id);
      if(prevIndex === -1) {
        return [
          ...prevState,
          {cableId: id, toText: value}
        ];
      } else {
        const newState = [...prevState];
        newState.splice(prevIndex, 1, {...newState[prevIndex], toText: value})
        return newState;
      }
    })
  }, [])

  const overWriteAll = async () => {
    await update([], fromText, toText);
    if (closeModal) {
      closeModal();
    }
  };

  const approveChanges = async () => {
    const tagTextOverrides: TagTextOverride[] = [];
    cables.forEach(cable => {
      const updateOverride = updates.find(x => x.cableId === cable.cable.id); 
      const fromTextOverride = fromTag
        ? (updateOverride && updateOverride.fromText
          ? updateOverride.fromText
          : (cable.prevFromTag
            ? (cable.cable.fromText !== cable.prevFromTag.name
              ? cable.cable.fromText
              : undefined)
            : cable.cable.fromText))
        : undefined;

      const toTextOverride = toTag
        ? (updateOverride && updateOverride.toText
          ? updateOverride.toText
          : (cable.prevToTag
            ? (cable.cable.toText !== cable.prevToTag.name
              ? cable.cable.toText
              : undefined)
            : cable.cable.toText))
        : undefined;

      if(fromTextOverride != null || toTextOverride != null) {
        tagTextOverrides.push({
          cableId: cable.cable.id,
          toText: toTextOverride,
          fromText: fromTextOverride
        });
      }
    });
    
    
    await update(tagTextOverrides, fromText, toText);
    if (closeModal) {
      closeModal();
    }
  };


  return <div>
    <Dialog
      maxWidth={"lg"}
      open={true}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classNames.header}>
        <DialogTitle id="alert-dialog-title">Tag update </DialogTitle>
        <div className={classNames.actionButtons}>
          <Button color={"primary"} onClick={() => overWriteAll()}>Overwrite all</Button>
          <Button color={"primary"} onClick={() => approveChanges()}>Accept changes</Button>
        </div>
      </div>

      <DialogContent style={{maxWidth: "1200px"}}>
        <div className={grid.grid} style={{marginBottom: 15}}>
          <InputLabel className={grid.newFromTagLabel} id={"fromTagCode"} >New Tag Id</InputLabel>
          <Typography className={grid.newFromTagCode} id={"fromTagCode"}>{fromTag?.code}</Typography>
          <InputLabel className={grid.newFromTextLabel} id={"fromTagText"}>New From Text</InputLabel>
          <VectTextField className={grid.newFromText}
                         id={"fromTagText"}
                         disabled={!fromTag}
                         value={fromTag?.name || ""}
                         multiline
                         fullWidth
                         change={value => setFromText(value)}/>
          <InputLabel className={grid.newToTagLabel} id={"toTagCode"}>New Tag Id</InputLabel>
          <Typography className={grid.newToTagCode} id={"toTagCode"}>{toTag?.code}</Typography>
          <InputLabel className={grid.newToTextLabel} id={"toTagText"}>New To Text</InputLabel>
          <VectTextField className={grid.newToText}
                         id={"toTagText"}
                         disabled={!toTag}
                         value={toTag?.name || ""}
                         multiline
                         fullWidth
                         change={value => setToText(value)}/>
        </div>
        <hr />
        {cables.map((x) => {
          return <CableRowModal key={x.cable.id}
                                cable={x.cable}
                                newFromTag={fromTag}
                                newToTag={toTag}
                                prevFromTag={x.prevFromTag}
                                prevToTag={x.prevToTag}
                                fromTextChange={value => onFromTextChanged(value, x.cable.id)}
                                toTextChange={value => ontoTextChanged(value, x.cable.id)}  />
        })}
      </DialogContent>

    </Dialog>
  </div>;
};
