import {organizeFilter} from "../../../shared/interfaces/organizeFilter.interface";
import React, {FC, useMemo} from "react";
import styles from './organizeFilterHeader.module.css'
import {Option, selectStylesWideMenu, VectSelect} from "../../shared/inputs/VectSelect";
import {SystemListItem} from "../../../shared/interfaces/system.interface";
import {Zone3d} from "../../../shared/interfaces/zone3d.interface";
import {Tag} from "../../../shared/interfaces/tag.interface";
import {Area} from "../../../shared/interfaces/area.interface";
import {MainVerticalZone} from "../../../shared/interfaces/mainVerticalZone.interface";
import {Bundle} from "../../../shared/interfaces/bundle.interface";
import {VectTextField} from "../../shared/inputs/VectTextField";
import {mapAreaOptions, mapSystemOptions, mapTagOptions} from "../../../shared/helpers/metadata";

interface OrganizeFilterHeaderProps {
    filter: organizeFilter,
    change: (value: number | string | undefined | null, field: string | undefined) => void,
    systems: SystemListItem[] | null;
    tags: Tag[] | null;
    areas: Area[] | null;
    mvZones: MainVerticalZone[] | null;
    bundles: Bundle[] | null;
    zone3ds: Zone3d[] | null;
}

const OrganizeFilterHeader: FC<OrganizeFilterHeaderProps> = ({
   filter,
   change,
   systems,
   tags,
   areas,
   mvZones,
   bundles,
   zone3ds,
}) => {

  const areaOptions = useMemo(():Option[] | null => mapAreaOptions(areas), [areas]);

  const bundleOptions = useMemo((): Option[] | null => {
    if(bundles == null) {
      return null;
    }

    return bundles.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`
    }));
  }, [bundles])

  const mvZoneOptions = useMemo((): Option[] | null => {
    if(mvZones == null) {
      return null;
    }

    return mvZones.map(x => ({
      value: x.id,
      label: x.code}));
  }, [mvZones]);

  const systemOptions = useMemo((): Option[] | null => mapSystemOptions(systems), [systems])

  const tagOptions = useMemo(():Option[] | null => mapTagOptions(tags), [tags]);

  const zone3dOptions = useMemo(():Option[] | null => {
    if(zone3ds == null) {
      return null;
    }

    return zone3ds.map(x => ({
      value: x.id,
      label: `${x.code} ${x.name}`}));
  }, [zone3ds]);



    return (
        <div className={styles.row}>
            <div className={styles.column}>
              <VectSelect
                id="fromTagId"
                isClearable={true}
                fieldName="fromTagId"
                label="From Tag"
                placeholder={"From Tag"}
                value={filter.fromTagId}
                change={change}
                options={tagOptions}
                styles={selectStylesWideMenu()}
              />
              <VectSelect
                id="fromAreaId"
                isClearable={true}
                fieldName="fromAreaId"
                label="From Area"
                placeholder={"From Area"}
                value={filter.fromAreaId}
                change={change}
                options={areaOptions}
              />
                <div className={styles.row}>
                    <div className={styles.zoneDiv}>
                      <VectSelect
                        id="from3dZoneId"
                        isClearable={true}
                        fieldName="from3dZoneId"
                        label="From 3D Zone"
                        placeholder={"From 3D Zone"}
                        value={filter.from3dZoneId}
                        change={change}
                        options={zone3dOptions}
                        styles={selectStylesWideMenu()}
                      />
                    </div>
                    <div className={styles.mvDiv}>
                      <VectSelect
                        id="fromMainVerticalZoneId"
                        isClearable={true}
                        fieldName="fromMainVerticalZoneId"
                        label="From MV Zone"
                        placeholder={"From MV Zone"}
                        value={filter.fromMainVerticalZoneId}
                        change={change}
                        options={mvZoneOptions}
                      />
                    </div>
                </div>
                <VectTextField label="From Text"
                               value={filter.fromText}
                               fieldName="fromText"
                               change={change}
                               style={{marginTop: "10px"}}/>
            </div>
            <div className={styles.column}>
              <VectSelect
                id="toTagId"
                isClearable={true}
                fieldName="toTagId"
                label="To Tag"
                placeholder={"To Tag"}
                value={filter.toTagId}
                change={change}
                options={tagOptions}
                styles={selectStylesWideMenu()}
              />
              <VectSelect
                id="toAreaId"
                isClearable={true}
                fieldName="toAreaId"
                label="To Area"
                placeholder={"To Area"}
                value={filter.toAreaId}
                change={change}
                options={areaOptions}
              />
                <div className={styles.row}>
                    <div className={styles.zoneDiv}>
                      <VectSelect
                        id="to3dZoneId"
                        isClearable={true}
                        fieldName="to3dZoneId"
                        label="To 3D Zone"
                        placeholder={"To 3D Zone"}
                        value={filter.to3dZoneId}
                        change={change}
                        options={zone3dOptions}
                        styles={selectStylesWideMenu()}
                      />
                    </div>
                    <div className={styles.mvDiv}>
                      <VectSelect
                        id="toMainVerticalZoneId"
                        isClearable={true}
                        fieldName="toMainVerticalZoneId"
                        label="To MV Zone"
                        placeholder={"To MV Zone"}
                        value={filter.toMainVerticalZoneId}
                        change={change}
                        options={mvZoneOptions}
                      />
                    </div>
                </div>
                <VectTextField value={filter.toText}
                               label="To Text"
                               fieldName="toText"
                               change={change}
                               style={{marginTop: "10px"}}
                />
            </div>
            <div className={styles.column}>
              <VectSelect
                id="bundleId"
                isClearable={true}
                fieldName="bundleId"
                label="Bundle ID"
                placeholder={"Bundle ID"}
                value={filter.bundleId}
                change={change}
                options={bundleOptions}
                styles={selectStylesWideMenu("50rem", true)}
              />
              <VectSelect
                id="systemId"
                isClearable={true}
                fieldName="systemId"
                label="System ID"
                placeholder={"System ID"}
                value={filter.systemId}
                change={change}
                options={systemOptions}
                styles={selectStylesWideMenu("50rem", true)}
              />
                <VectTextField value={filter.cableCode}
                               change={change}
                               label="Cable ID"
                               fieldName="cableCode"
                               style={{marginTop: "10px"}}
                />
            </div>
        </div>
    )
}

export default OrganizeFilterHeader;

