import React from "react";
import {Alert} from "@material-ui/lab";

export interface ReceiveDeleteProjectMessage
{
    key: string;
    text: string;
    severity: any;
}

export const DeletedProjectLog: React.FC<{messages: ReceiveDeleteProjectMessage[]}> = ({
messages
}) =>  {


  return (
    <div>
      {messages.map(x => <LogItem key={x.key} message={x}/> )}
    </div>
  )
}


const LogItem: React.FC<{
      message: ReceiveDeleteProjectMessage
}> = ({
  message
}) => {

  const severity = (messageSeverity: string): "error" | "warning" | "info" => {
    switch (messageSeverity){
      case "Information": return "info";
      case "Warning": return "warning";
      case "Error": return "error";
      default: return "info";
    }
  };

  return (
    <Alert severity={severity(message.severity)} style={{marginTop: "5px"}}>{message.text}</Alert>
  );
}
