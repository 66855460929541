import React, {CSSProperties} from "react";
import {StyledTableRow} from "../../shared/table/styles";
import {TableCell} from "@material-ui/core";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";
import {AreaListValidationItem} from "./support";

export const AreaImportRowItem: React.FC<AreaListValidationItem> = ({
  code,
  name,
  areaForemanCode,
  areaForemanName,
  mvZoneCode,
  zone3dCode,
  zone3dName,
  areaForemanId,
  mvZoneId,
  zone3dId
  }) => {

  const newItemStyle: CSSProperties = {
    fontWeight: "bold"
  };

  return (
    <StyledTableRow>
      <TableCell>
        <VectTableReadOnlyText value={code.value} error={code.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText value={name.value} error={name.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={areaForemanId != null ? undefined : newItemStyle} value={areaForemanCode.value} error={areaForemanCode.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={areaForemanId != null ? undefined : newItemStyle} value={areaForemanName.value} error={areaForemanName.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={mvZoneId != null ? undefined : newItemStyle} value={mvZoneCode.value} error={mvZoneCode.error}/>
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={zone3dId != null ? undefined : newItemStyle} value={zone3dCode.value} error={zone3dCode.error} />
      </TableCell>
      <TableCell>
        <VectTableReadOnlyText style={zone3dId != null ? undefined : newItemStyle} value={zone3dName.value} error={zone3dName.error}/>
      </TableCell>
    </StyledTableRow>
  );
}
