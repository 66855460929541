import React, {FC,} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../../shared/table/styles";
import {TableItemState} from "../../../../shared/interfaces/common.interface";
import {vectLightExtension} from "../../../../shared/theme";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {GlobalClaims} from "../../../../shared/claims";
import {DeleteButton} from "../../../shared/table/DeleteButton";
import {ModifiedIcon} from "../../../shared/table/ModifiedIcon";
import {handleCellChange} from "../../../../shared/helpers/table";
import {CableType} from "../../../../shared/interfaces/cableType.interface";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {TableCell} from "@material-ui/core";
import {CopyButton} from "../../../shared/table/CopyButton";
import {signalTypeOptions} from "../../../project/bundle-register/options";
import {VectTableRowSelect} from "../../../shared/inputs/VectTableRowSelect";

interface CableTypeRowItemProps {
  cableType: CableType;
  change: (value: CableType) => void;
  copy: (value: CableType) => void;
}

const CableTypeRowItem: FC<CableTypeRowItemProps> = ({
 cableType,
 change,
  copy
}) => {
  const cellClasses = tableCellStyles();

  const errors = {
    code: cableType.code ? undefined : "An id is required"
  }

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {

    const hasError = (newState: CableType): boolean => {
      return newState.code == null || newState.code === "";
    }

    handleCellChange(
      change,
      hasError,
      cableType,
      value,
      fieldName
    );
  };

  const onSignalTypeChange = (value: number | null) => {
    const fieldName = "signalType";
    if(value === null) {
      onCellChange(null, fieldName);
    } else {
      const code = signalTypeOptions.find(x => x.value === value);
      onCellChange(code?.selectedLabel, fieldName);
    }
  }

  const onCopy = () => {
    copy(cableType);
  }

  const onDelete = (itemState?: TableItemState) => {
    change({
      ...cableType,
      state: itemState
    })
  };

  const style = cableType.state === "deleted"
    ? {background: vectLightExtension.palette.custom.deletedRow}
    : undefined;

  return (
    <StyledTableRow  style={style}>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          error={!!errors.code}
          helperText={errors.code}
          fieldName="code"
          value={cableType.code}
          change={onCellChange}
          variant="standard"/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableRowSelect claim={GlobalClaims.manage.baseline.readWrite.all}
                            value={signalTypeOptions.find(x => x.selectedLabel === cableType.signalType)?.value}
                            change={onSignalTypeChange}
                            options={signalTypeOptions}/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName="typeIdLogistics"
          value={cableType.typeIdLogistics}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectCheckbox
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName={"isActive"}
          checked={cableType.isActive}
          change={onCellChange}
          color="primary"
          size={"small"}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          value={cableType.minimumOrderQuantity}
          fieldName="minimumOrderQuantity"
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          value={cableType.deliveryTimeWeeks}
          fieldName="deliveryTimeWeeks"
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName="copperWeightGramsPerMeter"
          value={cableType.copperWeightGramsPerMeter}
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          value={cableType.weightGramsPerMeter}
          fieldName="weightGramsPerMeter"
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName="outerDimension"
          value={cableType.outerDimension}
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName="roxType"
          value={cableType.roxType}
          change={onCellChange}
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName="estimatedHoursPerPieceConnecting"
          value={cableType.estimatedHoursPerPieceConnecting}
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTextField
          claim={GlobalClaims.manage.baseline.readWrite.all}
          fieldName="estimatedHoursPerMetersPulling"
          value={cableType.estimatedHoursPerMetersPulling}
          change={onCellChange}
          type="number"
          variant="standard"
          fullWidth/>
      </TableCellInput>
      <TableCell className={cellClasses.cell}>
        <ModifiedIcon itemState={cableType.state}/>
      </TableCell>
      <TableCell className={cellClasses.cell}>
        <CopyButton onClick={onCopy}/>
      </TableCell>
      <TableCell className={cellClasses.cell}>
        <DeleteButton change={onDelete} itemState={cableType.state}/>
      </TableCell>
    </StyledTableRow>
  )
}

export default CableTypeRowItem;
