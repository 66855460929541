import React, {useEffect, useState} from "react";
import {TableItemState} from "../../../shared/interfaces/common.interface";
import {Button} from "@material-ui/core";
import {DeleteForeverSharp, UndoSharp} from "@material-ui/icons";

export const DeleteButton = ({change, itemState} :{
  change: (itemState?: TableItemState) => void,
  itemState?: TableItemState}): JSX.Element => {

  const [initialState, setInitialState] = useState<TableItemState | undefined>(undefined);

  useEffect(() => {
    if(itemState !== "deleted") {
      setInitialState(itemState);
    }
  }, [itemState])

  const onClick = () => {
    const newState = itemState === "deleted" ? initialState : "deleted";
    change(newState);
  }

  const icon = itemState === "deleted"
    ? <UndoSharp />
    : <DeleteForeverSharp />;

  return (
    <Button onClick={onClick}>
      {icon}
    </Button>
  )
}
