export type Action =
  { type: "SET_VALUES", payload: (prevValues: any[]) => any[] }
  | { type: "SET_STATE", payload: (prevState: Result) => Result };

interface Result {
  values: any[]
}

export const simpleReducer = (state: Result, action: Action): Result => {
  switch (action.type) {
    case "SET_VALUES":
      return {...state, values: action.payload(state.values)}
    case "SET_STATE":
      return action.payload(state);
    default:
      return state;
  }
}
