import React, {FunctionComponent} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles,} from "../../../shared/table/styles";
import {CableWithReferenceLabels} from "../../../../shared/interfaces/cable.interface";
import {handleCellChange} from "../../../../shared/helpers/table";
import {VectTextField} from "../../../shared/inputs/VectTextField";
import {VectCheckbox} from "../../../shared/inputs/VectCheckbox";
import {ProjectClaims} from "../../../../shared/claims";
import {ModifiedIcon} from "../../../shared/table/ModifiedIcon";

type ConnectionAdminRowItemProps = {
  cable: CableWithReferenceLabels;
  change: (value: CableWithReferenceLabels) => void;
};

const ConnectionAdminRowItem: FunctionComponent<ConnectionAdminRowItemProps> = ({
  cable,
  change,
}) => {
  const cellClasses = tableCellStyles();

  const onCellChange = (
    value: number | string | boolean | Date | undefined | null,
    fieldName?: string,
  ) => {
    const hasError = (): boolean => {
      return false;
    }

    handleCellChange(
      change,
      hasError,
      cable,
      value,
      fieldName
    );
  };

  return (
    <StyledTableRow>
      <TableCellInput style={{width: "20rem"}} className={cellClasses.cell}>
        <VectTextField
          fieldName="code"
          value={cable.code}
          variant="standard"
          disabled={true}
          fullWidth={true}
        />
        <VectTextField
          fieldName="cableTypeCode"
          value={cable.cableTypeCode}
          variant="standard"
          disabled={true}
          fullWidth={true}
        />
      </TableCellInput>
      <TableCellInput style={{width: "10rem"}} className={cellClasses.cell}>
        <VectTextField
          fieldName="fromTagCode"
          value={cable.fromTagCode}
          variant="standard"
          disabled={true}
        />
        <VectTextField
          fieldName="toTagCode"
          value={cable.toTagCode}
          variant="standard"
          disabled={true}
        />
      </TableCellInput>
      <TableCellInput style={{width: "50rem"}} className={cellClasses.cell}>
        <VectTextField
          fieldName="fromText"
          value={cable.fromText}
          variant="standard"
          disabled={true}
          fullWidth={true}
        />
        <VectTextField
          fieldName="toText"
          value={cable.toText}
          variant="standard"
          disabled={true}
          fullWidth={true}
        />
      </TableCellInput>
      <TableCellInput style={{width: "10rem"}} className={cellClasses.cell}>
        <VectTextField
          fieldName="fromConnectionAreaForemanCode"
          value={cable.fromConnectionAreaForemanCode}
          variant="standard"
          disabled={true}
        />
        <VectTextField
          fieldName="toConnectionAreaForemanCode"
          value={cable.toConnectionAreaForemanCode}
          variant="standard"
          disabled={true}
        />
      </TableCellInput>
      <TableCellInput style={{width: "10rem"}} className={cellClasses.cell}>
        <VectTextField
          fieldName="fromAreaCode"
          value={cable.fromAreaCode}
          variant="standard"
          disabled={true}
        />
        <VectTextField
          fieldName="toAreaCode"
          value={cable.toAreaCode}
          variant="standard"
          disabled={true}
        />
      </TableCellInput>
      <TableCellInput style={{width: "10rem"}} className={cellClasses.cell}>
        <VectTextField
          fieldName="from3dZoneCode"
          value={cable.from3dZoneCode}
          variant="standard"
          disabled={true}
        />
        <VectTextField
          fieldName="to3dZoneCode"
          value={cable.to3dZoneCode}
          variant="standard"
          disabled={true}
        />
      </TableCellInput>
      <TableCellInput style={{width: "10rem", padding: 0}} className={cellClasses.cell}>
        <div style={{display: "flex", flexDirection: "column", width: "2.3rem"}}>
          <VectCheckbox
            claim={ProjectClaims.project.production.external}
            fieldName={"isConnectedFrom"}
            checked={cable.isConnectedFrom}
            change={onCellChange}
            color="primary"
            size={"small"}
          />
          <VectCheckbox
            claim={ProjectClaims.project.production.external}
            fieldName={"isConnectedTo"}
            checked={cable.isConnectedTo}
            change={onCellChange}
            color="primary"
            size={"small"}
          />
        </div>
      </TableCellInput>
      <div style={{display: "flex", flexDirection: "row"}}>
        {/* Edited mark */}
        <TableCellInput style={{display: "flex"}} className={cellClasses.cell}>
          <ModifiedIcon itemState={cable.state}/>
        </TableCellInput>
      </div>
    </StyledTableRow>
  )
};

export default ConnectionAdminRowItem;
