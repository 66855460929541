import React, {FunctionComponent} from "react";
import {StyledTableRow, TableCellInput, tableCellStyles} from "../../shared/table/styles";
import {vectLightExtension} from "../../../shared/theme";
import {Bundle} from "../../../shared/interfaces/bundle.interface";
import {VectTableReadOnlyText} from "../../shared/inputs/VectTableReadOnlyText";

type BundleStatusRowItemProps = {
  bundle: Bundle;
};

const BundleStatusRowItem: FunctionComponent<BundleStatusRowItemProps> = ({
  bundle,
}) => {
  const cellClasses = tableCellStyles();

  const cutBackgroundColor = bundle.cut === "Yes"
    ? vectLightExtension.palette.custom.status.allOk
    : vectLightExtension.palette.custom.status.notOkay

  const pulledBackgroundColor = bundle.pulled === "Yes"
    ? vectLightExtension.palette.custom.status.allOk
    : vectLightExtension.palette.custom.status.notOkay

  return (
    <StyledTableRow>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.code}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.name}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.productionStatus}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          type="date"
          value={bundle.releasedDate}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          type="date"
          value={bundle.printedDate}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          type="date"
          value={bundle.labelledDate}
        />
      </TableCellInput>
      <TableCellInput style={{backgroundColor: cutBackgroundColor, textAlign: "center"}} className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.cut}
        />
      </TableCellInput>
      <TableCellInput style={{backgroundColor: pulledBackgroundColor, textAlign: "center"}} className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.pulled}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.cableProductionResponsibleCode}
        />
      </TableCellInput>
      <TableCellInput className={cellClasses.cell}>
        <VectTableReadOnlyText
          value={bundle.cableProductionResponsibleName}
        />
      </TableCellInput>
    </StyledTableRow>
  )
};

export default BundleStatusRowItem;
